<p-toast></p-toast>
<div class="p-3">
    <div class="p-fluid p-formgrid grid">
        <div class="field col-12 md:col-4">
            <div class="p-inputgroup">
                <span class="p-float-label p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" id="search1" class="inputSearch" pInputText
                        placeholder="{{dashboardConstants.requirements.placehorders.applicant}}" [(ngModel)]="busqueda"
                        (keyup)="searchData($event)" />
                    <label for="search1"
                        style="font-size: 13px !important;">{{dashboardConstants.requirements.search}}</label>
                </span>
            </div>
        </div>
        <div class="field col-12 md:col-4">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <p-calendar [(ngModel)]="fechaInicio" dateFormat="dd-mm-yy" placeholder="día-mes-año"
                        class="calendarData" (onSelect)="selectFechas()" (keyup)="selectFechas()"></p-calendar>
                    <label for="search1"
                        style="font-size: 13px !important;">{{dashboardConstants.requirements.placehorders.dateForm}}</label>
                </span>
            </div>
            <small class="ng-dirty ng-invalid" *ngIf="selectFechaInit && !fechaInicio">El formato de fecha debe ser
                día-mes-año Ejem. 25-12-2024</small>
        </div>

        <div class="field col-12 md:col-4">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <p-calendar [(ngModel)]="fechaFin" dateFormat="dd-mm-yy" placeholder="día-mes-año"
                        class="calendarData" (onSelect)="selectFechas()" (keyup)="selectFechas()"></p-calendar>
                    <label style="font-size: 13px !important;"
                        for="search1">{{dashboardConstants.requirements.placehorders.dateTo}}</label>
                </span>
            </div>
            <small class="ng-dirty ng-invalid" *ngIf="selectFechaFin && !fechaFin">El formato de fecha debe ser
                día-mes-año Ejem. 25-12-2024</small>
        </div>
    </div>


    <p-table #dt [value]="requirements" responsiveLayout="stack" [rows]="properties.paginationRange"
        [globalFilterFields]="['solicitante.nombre','idSolicitud','estado']" [paginator]="true"
        styleClass="p-datatable-products" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
        [loading]="blocked" currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        selectionMode="multiple" [rowHover]="true" dataKey="idSolicitud" [(selection)]="selectedProduct"
        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowSelect($event)" selectionMode="single">
        <ng-template pTemplate="caption">
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 class="m-0"></h5>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>

                <th pSortableColumn="solicitante.nombre">{{dashboardConstants.requirements.applicant.label}}<p-sortIcon
                        field="solicitante.nombre">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="fondo.nombreFondo">{{dashboardConstants.requirements.fundName.label}}<p-sortIcon
                        field="fondo.nombreFondo">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="idSolicitud">{{dashboardConstants.requirements.numberSoli.label}}<p-sortIcon
                        field="idSolicitud"></p-sortIcon>
                </th>
                <th pSortableColumn="valorSolicitado">
                    {{dashboardConstants.requirements.amountRequested.label}}<p-sortIcon
                        field="valorSolicitado"></p-sortIcon>
                </th>
                <th pSortableColumn="fechaSolicitud">{{dashboardConstants.requirements.createDate.label}}<p-sortIcon
                        field="fechaSolicitud"></p-sortIcon>
                </th>
                <th pSortableColumn="fechaEsperadaLiquidacion">
                    {{dashboardConstants.requirements.liquitationDate.label}}<p-sortIcon
                        field="fechaEsperadaLiquidacion"></p-sortIcon>
                </th>
                <th pSortableColumn="tipoSolicitud.nombre">
                    {{dashboardConstants.requirements.requirementType.label}}<p-sortIcon field="tipoSolicitud.nombre">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="aprobador[0].userAprobador.nombre">
                    {{dashboardConstants.requirements.aprobateFor.label}}<p-sortIcon
                        field="aprobador[0].userAprobador.nombre"></p-sortIcon>
                </th>
                <th pSortableColumn="observaciones">{{dashboardConstants.requirements.description.label}} <p-sortIcon
                        field="observaciones">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="estado">{{dashboardConstants.requirements.status.label}}<p-sortIcon field="estado">
                    </p-sortIcon>
                </th>
                <th>{{properties.accions}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-requirement>
            <tr [pSelectableRow]="requirement">

                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span class="p-column-title">{{dashboardConstants.requirements.applicant.label}}</span>

                    <div class="ml-1">
                        <span><b>{{ requirement.solicitante?.nombre}} {{requirement.solicitante?.apellido}}
                            </b></span><br>
                        <span>{{ requirement.solicitante?.email}}</span>
                    </div>

                </td>
                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span class="p-column-title">{{dashboardConstants.requirements.applicant.label}}</span>
                    <div class="ml-1">
                        <span>{{ requirement.fondo?.nombreFondo}}
                        </span>
                    </div>
                </td>
                <td
                    [ngClass]="
                    { 'vencida': ((requirement.estado == 'T' || requirement.estado == 'RE') || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span
                        class="p-column-title">{{dashboardConstants.requirements.numberSoli.label}}</span>{{requirement.idSolicitud}}
                </td>
                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span class="p-column-title">{{dashboardConstants.requirements.amountRequested.label}}</span><b>
                        {{requirement.valorSolicitado | currency: 'USD'}}</b>
                </td>
                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0 }">
                    <span
                        class="p-column-title">{{dashboardConstants.requirements.createDate.label}}</span>{{requirement.fechaSolicitud
                    | date: 'dd-MM-yyyy'}}
                </td>
                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span
                        class="p-column-title">{{dashboardConstants.requirements.liquitationDate.label}}</span>{{requirement.fechaEsperadaLiquidacion
                    | date: 'dd-MM-yyyy'}}
                </td>
                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span
                        class="p-column-title">{{dashboardConstants.requirements.requirementType.label}}</span>{{requirement.tipoSolicitud?.nombre}}
                </td>
                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span
                        class="p-column-title">{{dashboardConstants.requirements.aprobateFor.label}}</span>{{requirement.aprobador[0].userAprobador?.nombre}}
                    {{requirement.aprobador[0].userAprobador?.apellido}}
                </td>
                <td
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span
                        class="p-column-title">{{dashboardConstants.requirements.description.label}}</span>{{requirement.observaciones}}
                </td>
                <td style="min-width: 7rem;"
                    [ngClass]="
                    { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span class="p-column-title">{{dashboardConstants.requirements.status.label}}</span>
                    <div>
                        <span *ngIf="requirement.estado == 'S'">Solicitado</span>
                        <span *ngIf="requirement.estado == 'L'">Liquidado</span>
                        <span *ngIf="requirement.estado == 'A'">Aprobado</span>
                        <span *ngIf="(requirement.estado == 'T')">Entregado</span>
                        <span *ngIf="requirement.estado == 'N'">Rechazado</span>
                        <span *ngIf="requirement.estado == 'R'">Recibido</span>
                        <span *ngIf="requirement.estado == 'E'">Registrado</span>
                        <span *ngIf="requirement.estado == 'B'">Por aprobar</span>
                        <span *ngIf="requirement.estado == 'P'">En proceso</span>
                        <span *ngIf="requirement.estado == 'PL'">Por liquidar</span>
                        <span *ngIf="requirement.estado == 'RE'">Documentos entregados</span>
                        <span *ngIf="requirement.estado == 'OE'">Error</span>
                        <span *ngIf="requirement.estado == 'PE'">Pendiente RPA</span>
                        <span *ngIf="requirement.estado == 'NA'">No aprobado</span>
                    </div>
                </td>
                <td [ngClass]="
                { 'vencida': (requirement.estado == 'T' || requirement.estado == 'RE') && requirement.countDays < 0}">
                    <span class="p-column-title">{{properties.accions}}</span>
                    <div class="flex">
                        <!-- acciones de acuerdo al dashboard -->
                        <button pButton pRipple icon="pi pi-eye" *ngIf="action !== 'cashReplenishment' "
                            class="p-button-rounded p-button-text button-view-action"
                            (click)="openViewRequirement(accion = 'vizualizar', requirement)"
                            title="Visualizar solicitud"></button>
                        <!-- --------- Reposiciones de caja -->
                        <button pButton pRipple icon="pi pi-check"
                            *ngIf="action === 'cashReplenishment' && requirement.estado !== properties.ESTADO_SOLI_POR_RECIBIDO &&  requirement.estado === properties.ESTADO_SOLI_REGISTRADA"
                            class="p-button-rounded p-button-text button-view-action"
                            (click)="confirmarRecibirSolicitud(requirement)"
                            title="Confirmar recibido de solicitud"></button>
                        <!--<button pButton pRipple icon="pi pi-eye" *ngIf="action === 'cashReplenishment' && requirement.estado !== properties.ESTADO_SOLI_POR_RECIBIDO && requirement.estado !== properties.ESTADO_SOLI_REGISTRADA"
                            class="p-button-rounded p-button-text button-view-action" 
                            (click)="openViewRequirement(accion = 'reposicionCaja', requirement, true)" title="Visualizar solicitud"></button>
                        --------- -->
                        <button pButton pRipple icon="pi pi-comment"
                            class="p-button-rounded p-button-text button-view-action" (click)="openComment(requirement)"
                            title="Agregar comentario"></button>
                        <button pButton pRipple icon="pi pi-dollar"
                            *ngIf="requirement.estado === properties.ESTADO_SOLI_REGISTRADA && action === 'dashboard'"
                            class="p-button-rounded p-button-text button-view-action"
                            (click)="confirmarRecibirEfectivo(requirement)"
                            title="Recibir efectivo"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="deleteRequerimiento" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de eliminar el requerimiento?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="new-button" label="{{properties.no}}"
            (click)="deleteRequerimiento = false"></button>
        <button pButton pRipple icon="pi pi-check" class="cancel-button" label="{{properties.yes}}"
            (click)="updateSolicitud()"></button>
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="recibirRequerimiento" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de recibir el requerimiento?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="new-button" label="{{properties.no}}"
            (click)="recibirRequerimiento = false"></button>
        <button pButton pRipple icon="pi pi-check" class="cancel-button" label="{{properties.yes}}"
            (click)="recibirSolicitud()"></button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="recibirEfectivoD" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de recibir el efectivo?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="new-button" label="{{properties.no}}"
            (click)="recibirEfectivoD = false"></button>
        <button pButton pRipple icon="pi pi-check" class="cancel-button" label="{{properties.yes}}"
            (click)="recibirDineroEfectivo()"></button>
    </ng-template>
</p-dialog>

<app-comments *ngIf="createComment" [createComment]="createComment" (closeComment)="closeCommentForm($event)"
    [singleSolicitud]="singleSolicitud">
</app-comments>