import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from 'src/app/common/auth.service';
import { SecurityService } from 'src/app/service/securityservice';
import { CommonService, MESSAGE_TYPE } from 'src/app/utilities/commonservice';
import { SecurityConstants } from '../security.constants';

@Component({
  selector: 'app-contraseniaReenvio',
  templateUrl: './contraseniaReenvio.component.html',
})
export class ContraseniaReenvioComponent {

    constructor(
        public activatedRoute: ActivatedRoute,
        private securityServices: SecurityService,
        private authService: AuthorizationService,
        private commonService: CommonService) {

      }

      username: string;
      public readonly securityConstants = SecurityConstants;

      reenvio(){
          if(this.username != undefined){
              this.commonService.clearMessage();
              this.securityServices.getEmailReset(this.username).then(resp => {
                if (resp.code === 0) {
                  this.authService.setCurrentUser(resp.data);
                  location.assign('/login');
                } else {
                  this.commonService.addMessage(MESSAGE_TYPE.ERROR, resp.description);
                }
              }).catch(err => {
                console.log(err);
                this.commonService.addMessage(MESSAGE_TYPE.ERROR, this.securityConstants.reset.messages.error);
              });
          }else{
            this.commonService.addMessage(MESSAGE_TYPE.ERROR, "No ingreso datos");
          }
      }

}
