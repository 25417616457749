<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3">
            <div class="field col-12 md:col-10">
                <h2 style="color: white;">{{pendingsConstants.requirements.title}} (5)</h2>
                <h6 style="color: white;">{{pendingsConstants.requirements.subtitle}}</h6>
            </div>

        </div>
    </div>
    <p-toast></p-toast>
    <div class="col-12">
            <div class="p-fluid p-formgrid grid px-3">
                <div class="col-12 md:col-10">
                    <span class="title-screen">{{pendingsConstants.requirements.actions.label}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-plus" class="new-button" label="{{pendingsConstants.requirements.addButton}}"
                    ></button>
                </div>
            </div>     
    </div>
    <div class="col-12">
        <app-celula-form *ngIf="isNewRequirement" (celulaAccion)="cancelcelula()" [requirement]="requirement">
        </app-celula-form>
        <app-pendings-approval-list *ngIf="!isNewRequirement" (celulaAccion)="celulaAccion($event)"></app-pendings-approval-list>   
    </div>
</div>
