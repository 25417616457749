<p-toast></p-toast>
<div *ngIf="!assignMenusRol">
    <p-table #dt [value]="roles" responsiveLayout="stack" [rows]="properties.paginationRange"
        [globalFilterFields]="['nombreRol','descripcion','estado']" [paginator]="true" styleClass="p-datatable-products"
        [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [(selection)]="selectedRoles"
        selectionMode="multiple" [rowHover]="true" dataKey="idRol" [loading]="blockedRole">
        <ng-template pTemplate="caption">
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 class="m-0"></h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Buscar..." />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
               
                <th pSortableColumn="nombreRol">{{securityConstants.rol.name.field}} <p-sortIcon field="nombreRol">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="descripcion">{{securityConstants.rol.description.field}}<p-sortIcon
                        field="descripcion"></p-sortIcon>
                </th>
                <th pSortableColumn="estado">{{securityConstants.rol.status.field}} <p-sortIcon field="estado">
                    </p-sortIcon>
                </th>
                <th>{{properties.accions}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rol>
            <tr>
                
                <td><span class="p-column-title">{{securityConstants.rol.name.field}}</span>{{rol.nombreRol}}</td>
                <td><span class="p-column-title">{{securityConstants.rol.description.field}}</span>{{rol.descripcion}}</td>
                <td><span class="p-column-title">{{securityConstants.rol.status.field}}</span>
                    <span *ngIf="rol.estado == properties.stateActive"
                        [class]="'state-badge state-A'" (click)="updateStateRol(rol)"> {{properties.active}}</span>
                    <span *ngIf="rol.estado == properties.stateInactive"
                        [class]="'state-badge state-I'" (click)="updateStateRol(rol)"> {{properties.inactive}}</span>
                    <span *ngIf="rol.estado == properties.stateDelete" [class]="'state-badge state-E'">
                        {{properties.delete}}</span>
                </td>
                <td style="width:14%; min-width:10rem;">
                    <span class="p-column-title">{{properties.accions}}</span>
                    <div class="flex">
                        <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-text button-view-action mr-2"
                            (click)="editRol(rol)"></button>
                        <button pButton pRipple icon="pi pi-bars"
                            class="p-button-rounded p-button-text button-view-action" title="Asignar menus"
                            (click)="assignMenus(rol)"></button>
                        <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        (click)="deleteRol(rol)"></button> -->
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<!-- asignacion de roles -->
<div *ngIf="assignMenusRol">
    <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
        </ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple id="saveRolMenu" icon="{{properties.guardar.icono}}"
                label="{{properties.guardar.valor}}" class="new-button mr-2" (click)="saveRolMenu()"></button>
            <button pButton pRipple id="cancelRolMenu" icon="{{properties.cancelar.icono}}"
                label="{{properties.cancelar.valor}}" class="p-button-danger" (click)="cancelRolMenu()"></button>
        </ng-template>
    </p-toolbar>
    <div class="field col-12 md:col-4">

        <label htmlFor="assignRol">Asignacion de menus a rol: <b>{{rol.nombreRol}}</b></label>

    </div>

    <p-treeTable [value]="listTreeMenu" [columns]="cols" selectionMode="checkbox" [(selection)]="selectedNodeMenus">
        <ng-template pTemplate="caption">
            <div class="flex">
                <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
                <span class="ml-2">Seleccionar todo</span>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <ng-container *ngFor="let col of columns; let i = index"> 
                    <th *ngIf="i <= 3" [ngClass]="{'text-center': i === 3}">
                        <span [innerHTML]="col.header"></span>
                    </th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr>
                <ng-container *ngFor="let col of columns; let i = index"> 
                    <td *ngIf="i <= 3" [ngClass]="{'text-center': i === 3}">
                        <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0" >
                        </p-treeTableToggler>
                        <p-treeTableCheckbox [value]="rowNode" *ngIf="i == 0">
                        </p-treeTableCheckbox>
                        <span *ngIf="i <  3">{{rowData[col.field]}}</span>
                        <span *ngIf="i === 3" >
                            <span style="cursor: pointer;" *ngIf="rowData['hasNew'] && rowData.idParent !== null" (click)="updateListSelectMenu(rowData, 'N')">
                                 <img class="m-1 ml-0" src="assets/lamoderna/images/iconos/activo.png" alt="{{properties.active}}" width="25px"/>
                            </span>
                            <span style="cursor: pointer;" *ngIf="!rowData['hasNew'] && rowData.idParent !== null" (click)="updateListSelectMenu(rowData, 'N')">
                                <img class="m-1 ml-0" src="assets/lamoderna/images/iconos/inactivo.png" alt="{{properties.inactive}}" width="25px"/>
                            </span>
                            <span style="cursor: pointer;" class="ml-4" *ngIf="rowData['hasUpdate'] && rowData.idParent !== null" (click)="updateListSelectMenu(rowData, 'U')">
                                <img class="m-1" src="assets/lamoderna/images/iconos/activo.png" alt="{{properties.active}}" width="25px"/>    
                            </span>
                            <span style="cursor: pointer;" class="ml-4" *ngIf="!rowData['hasUpdate'] && rowData.idParent !== null" (click)="updateListSelectMenu(rowData, 'U')">
                                <img class="m-1" src="assets/lamoderna/images/iconos/inactivo.png" alt="{{properties.inactive}}" width="25px"/>
                            </span>

                        </span>
                        
                    </td>
                </ng-container>
            </tr>
        </ng-template>
    </p-treeTable>
</div>


<p-dialog [(visible)]="deleteRolDialog" header="{{properties.confirmation}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="rol">{{securityConstants.rol.confirmationMessages.delete}} <b>{{rol.nombreRol}}</b>?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="deleteRolDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="confirmDelete()"></button>
    </ng-template>
</p-dialog>