import { Component, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AuthorizationService } from './common/auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
              ]),
              transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
              ])
        ])
    ]
})
export class AppTopBarComponent {

    constructor(public appMain: AppMainComponent, public app: AppComponent, private authService: AuthorizationService) {
        this.isMobile();
        if (this.authService.getCurrentUser()) {
            this.currentUser = this.authService.getCurrentUser().userData;
        }
      
    }

    activeItem: number; 
    currentUser: any;


    @ViewChild('searchInput') searchInputViewChild: ElementRef;

    onSearchAnimationEnd(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
            break;
        }
    }

    logout() {
        this.authService.clearUser();
        location.assign('')
    }
    isMobile() {
        return window.innerWidth <= 768; // Puedes ajustar este valor según tus necesidades
    }
}
