<p-dialog [(visible)]="viewNewTonage" [modal]="true" [responsive]="true" [showHeader]="false"
[breakpoints]="{'960px': '90vw'}" [style]="{width: '85%', minHeight:'650px'}" [minY]="100" position="right"
[contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">    
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-4 px-3 pb-2">
                <div class="col-11 md:col-11">
                    <span style="color: white; font-size: 30px;"><b>{{isSumary ? 'Resumen' : fundConstants.newTonnage.grandTitle}} de {{fondo?.nombreFondo}} </b></span><br><br>
                    <span style="color: white;">{{isSumary ? 'Aquí se encuentra el resumen de tu fondo' : fundConstants.newTonnage.subtitle}}</span>

                </div>
                <div class="col-1 md:col-1" style="text-align: end;">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                    class="p-button-rounded p-button-outlined" (click)="closeNewTonage()"></button>
                </div>
            </div>
        </div>
        <p-blockUI [blocked]="blocked"></p-blockUI>
        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid mx-2">
                <div class="col-12 md:col-8">
                    <!-- <span class="title-screen">{{fundConstants.newTonnage.title}}</span> -->
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-times" iconPos="left" class="cancel-button" label="{{fundConstants.newTonnage.cancelButton}}"
                    (click)="closeNewTonage()" *ngIf="isEdit" ></button>
                    
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-save" iconPos="left" class="new-button" label="{{fundConstants.newTonnage.saveButton}}" (click)="sendArqueo()"
                    *ngIf="isEdit || !isSumary"></button>
                </div>
            </div>
                
        </div>
        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid mx-2">
                <div class="col-12 md:col-4">
                    <label htmlFor="managementFor">Custodio del fondo</label>
                    <input type="text" pInputText id="managementFor" readonly  maxlength="100" value="{{fondo?.custodio?.nombre}} {{fondo?.custodio?.apellido}}"  />
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="fundName">{{fundConstants.newTonnage.fundName.label}}</label>
                    <input type="text" pInputText id="fundName" readonly  maxlength="100" value="{{fondo?.nombreFondo}}"/>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="ammountFund">{{fundConstants.newTonnage.ammountFund.label}}</label>
                    <input type="text" pInputText id="ammountFund" required autofocus maxlength="100" readonly value="{{fondo.valorFondo}}" />
                    <small>{{fundConstants.newTonnage.smallText}}</small>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !fondo.valorFondo">{{fundConstants.newTonnage.ammountFund.required}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="ammountFund">{{fundConstants.newTonnage.porcentajeUso.label}}</label>
                    <input type="text" pInputText id="ammountFund" required autofocus maxlength="100" readonly value="{{arqueo.porcentajeUso}}" />
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="dateCreation">{{fundConstants.newTonnage.dateCreation.label}}</label>
                    <p-calendar dateFormat="dd/mm/yy"  [(ngModel)]="arqueo.fecha" [disabled] = "true"></p-calendar>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !arqueo.fecha">{{fundConstants.newTonnage.dateCreation.required}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="managementFor">{{fundConstants.newTonnage.managementFor.label}}</label>
                    <input type="text" pInputText id="managementFor" readonly autofocus maxlength="100" value="{{usuario.nombre}} {{usuario.apellido}}"  />
                </div>
                
                <div class="col-12 md:col-4">
                    <label htmlFor="valueDocuments">{{fundConstants.newTonnage.valueEntregados.label}}</label>
                    <p-inputNumber [(ngModel)]="arqueo.valorRequerimientosEntregadas" inputid="valorDocumentos" mode="currency" currency="USD" locale="en-US"
                         min="0" [readonly]="true"></p-inputNumber>
                    <small>{{fundConstants.newTonnage.smallText}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="valueDocuments">{{fundConstants.newTonnage.valueLiquidadas.label}}</label>
                    <p-inputNumber [(ngModel)]="arqueo.valorRequerimientosAprobadas" inputid="valorDocumentos" mode="currency" currency="USD" locale="en-US"
                         min="0" [readonly]="true"></p-inputNumber>
                    <small>{{fundConstants.newTonnage.smallText}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="valueMoney">{{fundConstants.newTonnage.valueMoney.label}}</label>
                    <p-inputNumber [(ngModel)]="valorDinero" inputid="valorDinero" mode="currency" currency="USD" locale="en-US"
                         min="0" [readonly]="true"></p-inputNumber>
                    <small>{{fundConstants.newTonnage.smallText}}</small>
                </div>
                
                <div *ngIf="!isSumary" class="col-12 md:col-4">
                    <label htmlFor="valueTonnageDocuments">{{fundConstants.newTonnage.valueTonnageDocuments.label}}</label>
                    <p-inputNumber [(ngModel)]="arqueo.totalDocumentos" inputid="valorDocumentos" mode="currency" currency="USD" locale="en-US"
                         min="0" [disabled]="!isEdit" ></p-inputNumber>
                    <small>{{fundConstants.newTonnage.smallText}}</small><br>
                    
                </div> 
                <div *ngIf="!isSumary" class="col-12 md:col-4">
                    <label htmlFor="valueTonnageMoney">{{fundConstants.newTonnage.valueTonnageMoney.label}}</label>
                    <p-inputNumber [(ngModel)]="arqueo.totalArqueo" inputid="totalArqueo" mode="currency" currency="USD" locale="en-US"
                         min="0" [readonly]="true"></p-inputNumber>
                    <small>{{fundConstants.newTonnage.smallText}}</small><br>
                    <small class="ng-dirty ng-invalid"
                    *ngIf="submitted && !arqueo.totalArqueo">{{fundConstants.newTonnage.valueTonnageMoney.required}}</small>
                </div>
                <div *ngIf="!isSumary" class="col-12 md:col-4">
                    <label htmlFor="name"> &nbsp;</label>
                    <button pButton pRipple iconPos="left" class="cancel-button-border" [label]="botonLabel" 
                    (click)="showDialog()" ></button>
                </div>
               
            </div>
        </div>
        <div *ngIf="!isSumary" class="col-12 p-3">
            <div class="p-fluid p-formgrid grid px-2">
                <div class="col-12 md:col-12">
                    <span class="title-screen">{{fundConstants.tonnage.remarks.label}}</span>
                </div>
                <div class="col-12 md:col-12">
                    <label htmlFor="name">{{fundConstants.newTonnage.remarks.subtitle}}</label>
                    <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="arqueo.observaciones" [disabled]="!isEdit"></textarea>
                </div>
            </div>
        </div>
        <div class="col-12 p-3" >
            <div class="p-fluid p-formgrid grid px-2">
                <div class="col-12 md:col-12">
                    <span style="font-size: 20px; letter-spacing: -1px !important;">Detalle de requerimientos entregados</span>

                        <p-table #dt [value]="valesProvisionales" responsiveLayout="stack" [rows]="properties.paginationRange"
                        [globalFilterFields]="['nombre','valor','estado']"  styleClass="p-datatable-products"
                        [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedVale"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
                        selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                            <ng-template pTemplate="caption">
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <h5 class="m-0"></h5>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
            
                                    <th pSortableColumn="nombre">Fecha<p-sortIcon field="nombre">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="valor">Entregado a<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="abreviacion">Descripción del Gasto<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="valorIngles">Monto<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-vale>
                                <tr> 
                                    <td><span class="p-column-title">Fecha</span><span>{{vale.fechaSolicitud | date: 'dd-MM-yyyy'}}</span></td>
                                    <td><span class="p-column-title">Entregado a</span>{{vale.solicitante?.nombre}} {{vale.solicitante?.apellido}}</td>
                                    <td><span class="p-column-title">Descripción del Gasto</span>{{vale.observaciones}}</td>
                                    <td><span class="p-column-title">Monto</span>{{vale.valorSolicitado | currency: 'USD'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>

                </div>

                <div class="col-12 md:col-12">
                    <span style="font-size: 20px; letter-spacing: -1px !important;">Detalles de liquidaciones aprobadas</span>
                    <p-table #dt [value]="comprobantesGastos" responsiveLayout="stack" [rows]="properties.paginationRange"
                        [globalFilterFields]="['nombre','valor','estado']"  styleClass="p-datatable-products"
                        [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedLiquidacion"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
                        selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                            <ng-template pTemplate="caption">
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <h5 class="m-0"></h5>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
            
                                    <th pSortableColumn="nombre">Fecha<p-sortIcon field="nombre">
                                        </p-sortIcon>
                                    </th>
                                    <th pSortableColumn="valor">Tipo documento<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="abreviacion">Número documento<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="abreviacion">Proveedor<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="abreviacion">Descripción gasto<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="valorIngles">Monto<p-sortIcon field="valor"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-comprobante>
                                <tr> 
                                    <td><span class="p-column-title">Fecha</span><span>{{comprobante.fechaFactura | date: 'dd-MM-yyyy'}}</span></td>
                                    <td><span class="p-column-title">Tipo documento</span>{{comprobante.tipoDocumento?.nombre}}</td>
                                    <td><span class="p-column-title">Número documento</span>{{comprobante.numeroDocumento}}</td>
                                    <td><span class="p-column-title">Proveedor</span>{{comprobante.razonSocial}}</td>
                                    <td><span class="p-column-title">Descripción gasto</span>{{comprobante.descripcionGasto}}</td>
                                    <td><span class="p-column-title">Monto</span>{{comprobante.valorTotal | currency: 'USD'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                </div>
            </div>
        </div>

        <p-dialog header=" &nbsp;&nbsp; Ingreso de denominación" [(visible)]="visible" [modal]="true" [responsive]="true" 
        [breakpoints]="{'960px': '90vw'}" [style]="{width: '80%', minHeight:'100%'}" [minY]="100" position="right"
        position="right" styleClass="p-cdialog-right"
        transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
                <hr>
                <div class="p-fluid p-formgrid grid px-2">
                    <div class="col-6 md:col-11">

                    </div>
                    <div class="col-6 md:col-1">
                        <button pButton pRipple icon="pi pi-plus" iconPos="left" class="comment-button" style="border: 1px solid #D8DEE4;" 
                        (click)="agregarDenominacion()" [disabled]="!isEdit"></button>
                    </div>
                    <div class="col-12 md:col-4">
                        <label htmlFor="name">Denominación</label>
                    </div>
                    <div class="col-12 md:col-3">
                        <label htmlFor="name">Cantidad</label>
                    </div>
                    <div class="col-12 md:col-4">
                        <label htmlFor="name">Valor</label>
                    </div>
                    <div class="col-12">
                        <div *ngFor="let item of arqueo.denominaciones" class="p-formgrid grid">
                            <div class="col-12 md:col-4">
                                <p-dropdown  [options]="listDenominaciones" optionLabel="nombre" 
                                optionValue="idCatalogo" [(ngModel)]="item.denominacion"  
                                emptyFilterMessage="No existen registros"
                                emptyMessage="No existen registros" 
                                filter="true" [disabled]="!isEdit" 
                                (onChange)="validarDenominacion($event, item)"
                                ></p-dropdown>
                            </div>
                            <div class="col-12 md:col-3">
                                <p-inputNumber [(ngModel)]="item.cantidad" inputid="itemValue" step="1" 
                                [disabled]="item.isTypeView" (keyup)="calcularValorFinal()" [disabled]="!item.isTypeView || !isEdit" (keyup)="enviarValor(item)" [min]= "0"></p-inputNumber>
                            </div>
                            <div class="col-12 md:col-4">
                                <p-inputNumber [(ngModel)]="item.valor" inputid="itemValue" mode="currency" currency="USD" locale="en-US"
                                 [disabled]="item.isTypeView" (keyup)="calcularValorFinal()" [min] = "0"></p-inputNumber>
                            </div>
                            <div class="col-12 md:col-1">
                                <button pButton pRipple icon="pi pi-times" iconPos="left" class="comment-button" style="border: 1px solid #D8DEE4;"
                                (click)="deleteDenominacion(item)" [disabled]="!isEdit" ></button>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                <hr>
                <div class="p-fluid p-formgrid grid px-2">
                    <div class="col-12 md:col-9">
                        <span style="color: #E11F1D; font-size: 20px;"><b>Total</b></span>
                    </div>
                    <div class="col-12 md:col-2">
                        <span style="color: #E11F1D; font-size: 20px;"><b>${{arqueo.totalArqueo}}</b></span>
                    </div>
                </div>
                
        </p-dialog>
    </div>
</p-dialog>