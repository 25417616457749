import {Component, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

    topbarTheme = 'blue';

    menuTheme = 'light';

    layoutMode = 'light';

    menuMode = 'static';

    inlineMenuPosition = 'bottom';

    inputStyle = 'filled';

    ripple = true;

    isRTL = false;

    constructor(private primengConfig: PrimeNGConfig) {}

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation({
            accept: 'Accept',
            reject: 'Cancel',
            monthNames:['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto', 'Septiembre','Octubre', "Noviembre", "Diciembre"],
            monthNamesShort:['Ene', 'Feb','Mar','Abr','May','Jun','Jul','Ago', 'Sep','Oct', "Nov", "Dic"],
            dayNamesShort:['Do', 'Lu','Ma','Mi','Ju','Vi','Sa'],
            dayNamesMin:['Do', 'Lu','Ma','Mi','Ju','Vi','Sa'],
            //translations
        });
    }

}
