import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/common/auth.service';
import { SecurityService } from 'src/app/service/securityservice';
import { CommonService, MESSAGE_TYPE } from 'src/app/utilities/commonservice';
import { SecurityConstants } from '../security.constants';

@Component({
  selector: 'app-confirmUser',
  templateUrl: './confirmUser.component.html'
})
export class ConfirmUserComponent {

  constructor(
    private router: Router,
    private securityServices: SecurityService,
    private authService: AuthorizationService,
    private commonService: CommonService) {

  }
  urlTree: any;
  passWd: string;
  public readonly securityConstants = SecurityConstants;

  login() {
    this.urlTree = this.router.parseUrl(this.router.url);
    const code = this.urlTree.queryParams.code;
    const username = this.urlTree.queryParams.username;

    const userModel = {
      email: username,
      cognitoCode: code
    }
    this.commonService.clearMessage();
    this.securityServices.ConfirmUser(userModel).then(resp => {
      if (resp.code === 200) {
        this.authService.setCurrentUser(resp.data);
        location.assign('/login');
      } else {
        if (resp.description.indexOf('CONFIRMED') > 0){
          this.authService.setCurrentUser(resp.data);
          location.assign('/login');
        } else {
          this.commonService.addMessage(MESSAGE_TYPE.ERROR, resp.description);
        }
      }
    }).catch(err => {
      console.log(err);
      this.commonService.addMessage(MESSAGE_TYPE.ERROR, this.securityConstants.login.messages.error);
    });
  }
  
  reenviar() {
    this.commonService.clearMessage();
    this.urlTree = this.router.parseUrl(this.router.url);
    this.securityServices.getEmailVerification(this.urlTree.queryParams.username).then(resp => {
      if (resp.code === 0) {
        this.commonService.addMessage(MESSAGE_TYPE.SUCCESS, 'Mail enviado a su correo registrado. Por favor verifique su buzón de entrada');
      } else {  
        this.commonService.addMessage(MESSAGE_TYPE.ERROR, resp.description);
      }
    }).catch(err => {
      console.log(err);
      this.commonService.addMessage(MESSAGE_TYPE.ERROR, this.securityConstants.reset.messages.error);
    });
  }

}
