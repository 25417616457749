<p-toast></p-toast>
<div class="p-fluid p-formgrid grid">
    <div class="field col-12 md:col-4">
        <label htmlFor="name">{{securityConstants.menu.name.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="name" [(ngModel)]="menu.nombreMenu" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !menu.nombreMenu}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !menu.nombreMenu">{{securityConstants.menu.name.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="description">{{securityConstants.menu.description.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="description" [(ngModel)]="menu.descripcion" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !menu.descripcion}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !menu.descripcion">{{securityConstants.menu.description.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="icon">{{securityConstants.menu.icono.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="icon" [(ngModel)]="menu.icono" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !menu.icono}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !menu.icono">{{securityConstants.menu.icono.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="url">{{securityConstants.menu.url.field}} <span *ngIf="isMandatoryUrl" class="ng-invalid">*</span></label>
        <input type="text" pInputText id="url" [(ngModel)]="menu.url" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !menu.url}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !menu.url && isMandatoryUrl">{{securityConstants.menu.url.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="url">{{securityConstants.menu.orden.field}} <span *ngIf="isMandatoryUrl" class="ng-invalid">*</span></label>
        <input type="number" pInputText id="url" [(ngModel)]="menu.orden" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !menu.orden}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !menu.orden">{{securityConstants.menu.orden.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="state">{{securityConstants.menu.status.field}}</label><br>
        <p-toggleButton [(ngModel)]="state" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="visible">{{securityConstants.menu.visible.field}}</label><br>
        <p-toggleButton [(ngModel)]="menu.visible" onLabel="{{properties.visible}}" offLabel="{{properties.noVisible}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div>
</div>