export const SecurityConstants = {
    security: 'Seguridad',
    rol : {
        title:'Roles',
        name: {
            field :'Nombre',
            requiered : 'El nombre es requerido.'
        },
        description: {
            field :'Descripción',
            requiered : 'La descripción es requerido.'
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        messages :{
            create :'Rol creado correctamente.',
            update :'Rol actualizado correctamente.',
            delete :'Rol eliminado correctamente.',
            deleteRoles :'Roles eliminados correctamente.',
            menuRoles : 'Asignación de menus realizadas correctamente'
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar los roles seleccionados?.',
            delete: '¿Está seguro de que desea eliminar el rol '
        }
    },
    rolUsuario : {
        title:'Asignacion de roles',
        celula: {
            field :'Celula',
            requiered : 'La célula es requerida.'
        },
        roles: {
            field :'Roles',
            requiered : 'Los roles son requeridos.'
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        messages :{
            create :'Asigación de rol a usuario creado correctamente.',
            update :'Asigación de rol a usuario actualizado correctamente.',
            delete :'Asigación de rol a usuario eliminado correctamente.',
            deleteRoles :'Asigación de rol a usuario eliminados correctamente.',
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar las asignaciones de roles seleccionados?.',
            delete: '¿Está seguro de que desea eliminar la asignacion de rol '
        }
    },
    menu : {
        title: 'Menús',
        name: {
            field :'Nombre',
            requiered : 'El nombre es requerido.'
        },
        description: {
            field :'Descripción',
            requiered : 'La descripción es requerido.'
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        visible: {
            field :'Visible'
        },
        icono: {
            field :'Ícono',
            requiered : 'El icono es requerido.'
        },
        url: {
            field :'Url',
            requiered : 'La url es requerida.'
        },
        orden: {
            field :'Orden',
            requiered : 'El orden es requerido.'
        },
        messages :{
            create :'Menú creado correctamente.',
            update :'Menú actualizado correctamente.',
            delete :'Menú eliminado correctamente.',
            deletes :'Menús eliminados correctamente.',
            errorLevelMenu :'No se pueden agregar mas subniveles de menu.',
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar los menus seleccionados?.',
            delete: '¿Está seguro de que desea eliminar el menú '
        }
    },
    timeZone : {
        title: 'Zonas horarias',
        name: {
            field :'Nombre',
            requiered : 'El nombre es requerido.'
        },
        attentionPriority: {
            field :'Prioridad Atención',
            requiered : 'La prioridad de atención es requerida.'
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        messages :{
            create :'Zona horaria creada correctamente.',
            update :'Zona horaria actualizada correctamente.',
            delete :'Zona horaria eliminada correctamente.',
            deletes :'Zonas horarias eliminadas correctamente.',
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar las Zonas horarias seleccionadas?.',
            delete: '¿Está seguro de que desea eliminar la Zona horaria '
        }
    },
    airport : {
        title: 'Puertos / Aeropuertos',
        name: {
            field :'Nombre Puerto',
            requiered : 'El nombre del Puerto es requerido.'
        },
        type: {
            field :'Tipo Puerto',
            requiered : 'El tipo de Puerto es requerido.'
        },
        city: {
            field :'Ciudad',
        },
        country: {
            field :'País',
            requiered : 'El País es requerido.'
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        iata: {
            field :'Código IATA',
            requiered : 'El Código IATA es requerido.'
        },
        messages :{
            create :'Puerto / Aeropuerto creado correctamente.',
            update :'Puerto / Aeropuerto actualizado correctamente.',
            delete :'Puerto / Aeropuerto eliminado correctamente.',
            deletes :'Puertos / Aeropuertos eliminados correctamente.',
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar los puertos seleccionados?.',
            delete: '¿Está seguro de que desea eliminar el Puerto / Aeropuerto '
        }
    },
    user : {
        title: 'Usuarios',
        name: {
            field :'Nombre',
            requiered : 'El nombre es requerido.'
        },
        nameValido: {
            requiered : 'El campo nombre no admite caracteres especiales ni números.'
        },
        lastName: {
            field :'Apellido',
            requiered : 'El apellido es requerido.'
        },
        lastNameValido: {
            requiered : 'El campo apellido no admite caracteres especiales ni números.'
        },
        email: {
            field :'E-mail',
            requiered : 'El email es requerido.'
        },
        numeroIdentidad: {
            field :'Número de identificación',
            requiered : 'El número de identificación es requerido.'
        },
        numeroIdentidadIncorrecta:{
            requiered : 'El número de identificación es incorrecto o no existe.'
        },
        empresa: {
            field :'Empresa',
            requiered : 'El campo empresa es requerido.'
        },
        phone: {
            field :'Teléfono',
            requiered : 'El teléfono es requerido.',
            pattern: "El formato debe ser, Ejemplo +593900000000."
        },
        branches: {
            field :'Sucursal',
            requiered : 'El sucursal es requerido.',
        },
        multifactor: {
            field :'Estado MFA',
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        lastConection:{
            field: 'Última Conexión'
        },
        sendCode: {
            field: "Enviar Mail Activación"
        },
        mfa: {
            field: "MFA"
        },
        signature: {
            field: "Firma"
        },
        backup:{
            field :'Usuario backup',
            requiered : 'Usuario backup es requerido.',  
        },
        backupActivo:{
            field :'Activar usuario backup',
            //requiered : 'Usuario backup es requerido.',  
        },
        messages :{
            create :'Usuario creado correctamente.',
            update :'Usuario actualizado correctamente.',
            delete :'Usuario eliminado correctamente.',
            deletes :'Usuarios eliminados correctamente.',
            assignRoles: 'Assignar Roles',
            rolesUser: 'Roles de usuario',
            adminNoInactivate: 'No puede inactivar al administrador del sistema'
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar los usuarios seleccionados?.',
            delete: '¿Está seguro de que desea eliminar el usuario '
        }
    },
    celula : {
        title: 'Celulas',
        name: {
            field :'Nombre',
            requiered : 'El nombre es requerido.'
        },
        description: {
            field :'Descripción',
            requiered : 'La descripción es requerida.'
        },
        usuario: {
            field :'Usuario',
            requiered : 'El usuario es requerido.'
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        messages :{
            create :'Celula creada correctamente.',
            update :'Celula actualizada correctamente.',
            delete :'Celula eliminada correctamente.',
            deletes :'Celulas eliminadas correctamente.',
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar las celulas seleccionadas?.',
            delete: '¿Está seguro de que desea eliminar la celula '
        }
    },
    login: {
        title: 'Login',
        fogotPassword: '¿Olvidaste tu Contraseña?',
        mfaCode: 'Código MFA',
        input: 'INGRESO',
        inputA: 'INGRESAR',
        validate: 'VALIDAR',
        welcome: 'Bienvenido',
        restorePassword: 'RESTABLECER CONTRASEÑA',
        restore: 'RESTABLECER',
        confirm: 'CONFIRMAR',
        continue: 'CONTINUAR',
        email: {
            field :'Email',
            requiered : 'El email es requerido.'
        },
        password: {
            field :'Contraseña',
        },
        newPassword: {
            field :'Nueva Contraseña',
        },
        confirmPassword: {
            field :'Confirmar Contraseña',
        },
        messages: {
            error: 'Error no se pudo invocar al servicio de validación de usuario',
            codigoMfaError: 'Código ingresado no válido',
            resultError: 'No se pudo realizar la validación MFA',
            requiredFields: 'Los campos usuario y contraseña son obligatorios',
            registreCredenciales:  'Iniciar sersión aquí',
            multifactorActivated: 'Multifactor Activado, ingrese el código de su dispositivo MFA',
            newPassword: 'Por favor ingresa una nueva Contraseña',
            enterEmail: 'Por favor ingresa tu email',
            nextButton: 'Por favor presiona el siguiente boton para ingresar tus credenciales',
            confirmed: 'Hemos Confirmado tu email',
            reenviar: 'Reenviar código de activación aquí '
        }
    },
    notFound: {
        title: 'Página no encontrada',
        resourceNotFound: ' El recurso solicitado no esta disponible.',
        accessNotAllowed: 'Acceso Denegado',
        back: 'Regresar'
    },
    returnDocu: {
        title: 'Permiso Autorizado',
        resourceEmited: ' El permiso ha sido consedido para continuar prepsione el boton Continuar.',
        nextButton: 'Continuar'
    },
    reset: {
        title: 'Reset',
        messages: {
            error: 'Error no se pudo encontrar el usuario'
        }
    },
    catalog : {
        title: 'Catalogos del Sistema',
        name: {
            field :'Nombre',
            requiered : 'El nombre es requerido.'
        },
        value: {
            field :'Valor',
            requiered : 'El valor es requerido.'
        },
        valueEnglish: {
            field :'Valor Inglés',
            requiered : 'El valor en inglés es requerido.'
        },
        order: {
            field :'Orden',
            requiered : 'El orden es requerido.'
        },
        relacionado: {
            field :'Relacionado'
        },
        status: {
            field :'Estado',
            requiered : 'El estado es requerido.'
        },
        abreviation: {
            field :'Abreviación',
            requiered : 'La Abreviación es requerida.'
        },
        abreviationEnglish: {
            field :'Abreviación Ingles',
            requiered : 'La Abreviación en Inglés es requerida.'
        },
        messages :{
            create :'Catalogo creado correctamente.',
            update :'Catalogo actualizado correctamente.',
            delete :'Catalogo eliminado correctamente.',
            deletes :'Catalogos eliminados correctamente.',
        },
        confirmationMessages :{
            deletes: '¿Está seguro de que desea eliminar los catalogos seleccionados?.',
            delete: '¿Está seguro de que desea eliminar el catálogo '
        }
    },
    perfil: {
        title: 'Perfil',
        messages: {
            mfaNew: 'Ingrese un código de MFA para completar el proceso.',
            update: 'Datos almacenados correctamente',
            mfaActive: 'Doble factor de autenticación activo',
            mfaInactive: 'Doble factor de autenticación inactivo',
            codeNotValid: 'Código no válido',
            erroValidation : 'No se pudo realizar la validación del Código MFA',
            errorQR: 'No se pudo generar el código QR al usuario',
            gmailErrorConfig: 'No se pudo cargar las credenciales de acceso a Gmail',
            activeMfa: 'Activo',
            inactiveMfa: 'Inactivo',
            emptyCode: 'Ingrese un código',
            disable:'Inactivar' ,
            validate:'Validar', 
            gmailActive: 'Gmail Activo',
            gmailInactive: 'Gmail Inactivo',
            gmailCredential: 'Credenciales almacenadas correctamente',
            docuSignConnect: 'Conectar a Docusign',
        }
    }

}
