import { Component, OnInit, ViewChild } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';
import { PermisoModel } from 'src/app/models/permiso.model';
import { DashboardConstants } from '../dashboard.constants';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DashboardListComponent } from './dashboard-list.component';
import { SolicitudModel } from 'src/app/models/caja-chica/solitud.model';
import { SecurityService } from 'src/app/service/securityservice';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {

  public readonly dashboardConstants = DashboardConstants;
  properties: Properties = new Properties();
  deleteCelulaDialog: boolean = false;
  isNewRequirement: boolean;
  permiso: PermisoModel;
  tittle : string = '';
  action : any;
  visibleButton: boolean = false;
  isView : boolean = false;
  isDataSoli : boolean = false;
  isCashReplenishment : boolean = false;
  viewSettleRequest : boolean = false;
  isApproveCash : boolean = false;
  requirement :any = {}
  createRequeriment = false;
  isViewRepoCaja : boolean = false
  viewSupportSettle: boolean = false
  vizualizarSoportes: boolean = false
  @ViewChild(DashboardListComponent) dashboardListComponent: DashboardListComponent;
  constructor( private router : Router, private activatedRoute: ActivatedRoute) {

  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.action = params['type'];
      this.changeValues();
    });
    
  }
  //Cambia los valores de la pantalla de acuerdo a la url
  changeValues(){
    switch (this.action) {
      case 'requirements': 
        this.tittle = 'Mis requerimientos'
        this.visibleButton = true;
        this.viewSettleRequest = false;
        break;

      case 'requirementsApprove':
        this.tittle = 'Requerimientos por aprobar'
        this.visibleButton = false;
        this.viewSettleRequest = false;

        break;

      case 'cashReplenishment':
        this.tittle = 'Solicitudes de reposición'
        this.visibleButton = false;
        this.viewSettleRequest = false;
      break;

      default :
        this.tittle = 'Solicitudes de fondos';
        this.visibleButton = false;
        this.viewSettleRequest = false;

        break;
    }

  }
  newRequirement(){
    this.isNewRequirement = true;
    this.isView = false;
    this.requirement = {}
  }
  
  closeNewRequirement(event) {
    this.isNewRequirement = false;
    this.isView = false;
    this.isDataSoli = false;
    this.viewSettleRequest = false;
    this.isApproveCash = false;
    if(event.type == 'new'){
      this.createRequeriment == true;
      this.dashboardListComponent.getRequerimientos()
    }

  }
  openViewRequirement(event){

    switch (event.accion) {
      case 'vizualizar':
       
        this.requirement = event.requirement
        if(this.requirement.estado == this.properties.ESTADO_SOLI_POR_SOLICITADA  || 
          this.requirement.estado == this.properties.ESTADO_SOLI_POR_APROBAR  || 
          this.requirement.estado == this.properties.ESTADO_SOLI_ENTREGADA || 
          this.requirement.estado == this.properties.ESTADO_SOLI_ANULADA || 
          this.requirement.estado == this.properties.ESTADO_SOLI_RECIBO_ENTREGADO 
          ){
            this.isNewRequirement = true;
            this.isView = true;
        }else{
          this.viewSupportSettle = true
          this.vizualizarSoportes = true
        }
        break;
      case 'datosSolicitante':
        this.isNewRequirement = true;
        this.isDataSoli = true;
        this.requirement = event.requirement
        break;
      case 'datosSolicitanteLiquidacion':
        this.requirement = event.requirement
        this.viewSettleRequest = true;
        break;
      case 'requirementsApprove' :
        this.isNewRequirement = true;
        this.isApproveCash = true;
        this.requirement = event.requirement
        break;
      case 'reposicionCaja':
        this.isCashReplenishment = true;
        this.requirement = event.requirement
        this.isViewRepoCaja = event.isView
        break;    
      default:
        break;
    }
   
  }
  closeApproveReposition(event){
    if(event.type == 'return'){
      this.isCashReplenishment = false;
      this.dashboardListComponent.getRequerimientos()

    }

  }

  closeSupportSettle(event){
    if(event.type === 'return'){
      this.viewSupportSettle = false;
    }
    
  }
}