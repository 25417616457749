import { Injectable } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { CatalogModel } from '../models/catalog.model';
import { SecurityService } from '../service/securityservice';
import { Properties } from './properties';
import { Response } from '../common/Response';
import { NgxImageCompressService } from 'ngx-image-compress';
export enum MESSAGE_TYPE {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warn'
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  properties: Properties = new Properties();
  mapUsers = new Map();
  constructor(private messageService: MessageService, private securityService: SecurityService,
    private imageCompress: NgxImageCompressService) {
  }

  /**
   * 
   * @param severity Severitu type
   * @param detail Message detailed
   */
  addMessage(severity: MESSAGE_TYPE, detail: string) {
    let summary = 'Alerta';
    if (severity === 'error') {
      summary = 'Error'
    } else if (severity === 'success') {
      summary = 'Éxito'
    } else if (severity === 'info') {
      summary = 'Información'
    }
    this.messageService.add({ severity: severity, summary: summary, detail: detail, life: this.properties.timeLifeMessages });
  }
  /**
   * Clear array message of toast
   */
  clearMessage() {
    this.messageService.clear();
  }

  /**
 * Valida que el valor ingresado sea solo numerico
 * @param event Evento disparado de un cuadro de texto
 */
  onlyNumbers(event, esMonto = false) {
    // this.messageService.clear();
    let valido = event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57);
    if (esMonto) {
      valido = event.charCode === 0 || event.charCode === 46 || (event.charCode >= 48 && event.charCode <= 57);
    }
    if (event.charCode === 46 && event.target.value) {
      let indexOf = event.target.value.indexOf('.');
      if (indexOf > 0) {
        valido = false;
      }
    }
    return valido;
  }
  /**
   * Se consulta la lista de catalogos del sistema
   * @param idCatalogoPadre Identidficador del catalogo padre
   * @param callback Función de retorno
   */
  getCatalogList(idCatalogoPadre: number, callback) {
    this.securityService.getCatalogsByFather(idCatalogoPadre).then(resp => {
      if (resp.code === 200) {
        const respItems: SelectItem[] = [];
        resp.data.forEach(catalog => {
          const selectItem = {label: catalog.nombre, value: catalog.idCatalogo, title: catalog.abreviacion};
          respItems.push(selectItem);
        });
        callback(respItems);        
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  call(result: Promise<Response>, callbackResp) {
    result.then(resp => {
      if (resp.code === 200){
        callbackResp(resp);
      } else if (resp.description) {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: "Ocurrio un error al procesar la solicitud", life: this.properties.timeLifeMessages });

      }
    }).catch(err => {
      console.log(err)
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Cambia a base64 cualquier archivo
   * @param file archivo de entrada
   */
  toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });

  keyEnter(event, callback) {
    if (event.keyCode === 13) {
      callback();
    }
  }

  downloadFile(base64String, fileName, type = "application/pdf") {
    const source = `data:${type};base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName
    link.click();
  }
  /***
   * Alto de scroll de Ficha
   */
  getHeight( adicional: number) {
    const result =  window.innerHeight;
    let tamanio = 740 + adicional;
    if (result > 1100) {
      tamanio = 800;
    }
    else if (result > 800 && result < 900) {
      tamanio = 670;
    }
    else if (result > 700 && result < 800) {
      tamanio = 580;
    }
    else if (result > 500 && result < 700) {
      tamanio = 470;
    }
    return { 'height': tamanio + 'px'};
  }

  async getUserMap(idUsuarioCongnito: string) {
    if (!idUsuarioCongnito) {
      return undefined;
    }
    if (!this.mapUsers.has(idUsuarioCongnito)) {
        const user = await new Promise(resolve => {
          this.securityService.getUsers(null, idUsuarioCongnito).then(userResp => {
              if (userResp.code === 200 && userResp.data) {
                  resolve(userResp.data[0]);
              }
          });
        });
        this.mapUsers.set(idUsuarioCongnito, user);
    }
    return this.mapUsers.get(idUsuarioCongnito);
  }

  calcMetersFromLong(valor, unidad: string) {
    let coeficiente = 1;
    switch (unidad) {
      case 'MM':
        coeficiente = 1000;
        break;
      case 'CM':
          coeficiente = 100;
          break
      case 'IN':
          coeficiente = 39.37;
          break
      default:
        break;
    }
    return valor / coeficiente;
  }

  calcPesoToKG(valor, unidad: string) {
    let coeficiente = 1;
    switch (unidad) {
      case 'KG':
        coeficiente = 1;
        break;
      case 'LB':
          coeficiente = 2.20462;
          break
      default:
        break;
    }
    return valor / coeficiente;
  }

  calcCmetersFromLong(valor, unidad: string) {
    let coeficiente = 1;
    switch (unidad) {
      case 'MM':
        coeficiente = 100;
        break;
      case 'M':
          coeficiente = 0.1;
          break
      case 'IN':
          coeficiente = 0.3937;
          break
      default:
        break;
    }
    return valor / coeficiente;
  }

  getCatalogo(idCatalogo, catalogosGlobal): any {
    let catalogList: any[] = [];
    let catalogos = catalogosGlobal[idCatalogo];
    catalogos = catalogos ? catalogos: [];
    catalogos.forEach(catalog => {
      let catalogo = new CatalogModel();
      catalogo = catalog;
      let selectItem = { label: catalogo.nombre, value: catalogo.idCatalogo, code: catalogo.abreviacion };
      if (catalogo.estado === this.properties.stateActive) {
        catalogList.push(selectItem);
      }
    });
    return catalogList;
  }
  /**
   * Comprime una imagen para disminuir el tamaño
   * @param image Imagen a ser comprimida
   */
  async compressFile(image) {
    let orientation = -1;
    let sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
    const resp = await new Promise(resolve => {
      this.imageCompress.compressFile(image, orientation, 100, 70, 1024, 1024).then(
      result => {
          const vl = String(result).split(",");
          let sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024)
          resolve(vl[1]);
      });
    });
    return resp;
  }
}