<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3">
            <div class="field col-12 md:col-10">
                <h2 style="color: white;">Días festivos</h2>
                <h6 style="color: white;">Aquí puedes gestionar tus días festivos</h6>
            </div>

        </div>
    </div>
    <div class="col-12">

        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-1">
                <ng-template pTemplate="left">
                    <h5 class="title-screen"></h5>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="isNewFeriado" pButton pRipple id="saveFeriado" icon="{{properties.guardar.icono}}"
                        label="{{properties.guardar.valor}}" class="new-button mr-2" (click)="saveFeriado()"></button>
                    <button *ngIf="isNewFeriado" pButton pRipple id="cancelFeriado" icon="{{properties.cancelar.icono}}"
                        label="{{properties.cancelar.valor}}" class="cancel-button" (click)="cancelFeriado()"></button>

                    <button *ngIf="!isNewFeriado" pButton pRipple id="newFeriado" icon="{{properties.nuevo.icono}}"
                        label="{{properties.nuevo.valor}}" class="new-button mr-2 mb-2" (click)="newFeriado()"></button>
                    <button *ngIf="!isNewFeriado && selectedFeriados && selectedFeriados.length> 0" pButton pRipple
                        id="deleteCatalog" icon="{{properties.eliminar.icono}}" label="{{properties.eliminar.valor}}"
                        class="p-button-danger mr-2 mb-2" (click)="deleteSelectedFeriados()"></button>
                </ng-template>
            </p-toolbar>
           
            <app-feriado-form *ngIf="isNewFeriado" (feriadoAccion)="cancelFeriado()" [feriado]="feriado" [isUpdate] = "isUpdate" ></app-feriado-form>
            <app-feriado-list *ngIf="!isNewFeriado" (feriadoAccion)="feriadoAccion($event)"></app-feriado-list>
        </div>
    </div>
</div>