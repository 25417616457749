import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Properties } from "src/app/utilities/properties";
import { PermisoModel } from "src/app/models/permiso.model";
import { Router } from "@angular/router";
import { FundConstants } from "../fund.constants";
import { SecurityService } from "src/app/service/securityservice";
import { MessageService } from "primeng/api";
import { CatalogModel } from "src/app/models/catalog.model";
import { AuthorizationService } from "src/app/common/auth.service";
import { FondoModel } from "src/app/models/caja-chica/fondo.model";

@Component({
    selector: "app-new-fund",
    templateUrl: "./new-fund.component.html",
    providers: [MessageService],
})
export class NewFundComponent implements OnInit {
    public readonly fundConstants = FundConstants;
    properties: Properties = new Properties();
    permiso: PermisoModel;
    datosSolicitud: any = {};
    soportesLiquidacion: any[] = [];
    @Input() fondo: FondoModel;
    blockedRequirement: boolean = false;
    resumen: boolean = true;
    visible: boolean = false;
    submitted: boolean;
    @Input() viewNewFund: boolean;
    @Output() newFundForm = new EventEmitter<any>();
    state: boolean = false;
    listTypeFund: CatalogModel[] = [];
    fundTitle: string
    fundSubtitle:string
    listRolUser:any
    constructor(
        private router: Router,
        private messageService: MessageService,
        private securityServices: SecurityService,
        private auth: AuthorizationService
    ) {}
    /**
     * Inicio de pantalla
     */
    async ngOnInit() {
        if (this.fondo.idFondo) {
            this.state = this.fondo.estado == "A" ? true : false;
            this.fundTitle = this.fundConstants.updateFund.grandTitle
            this.fundSubtitle = this.fundConstants.updateFund.subtitle
            this.fondo.fechaCreacion = new Date(this.fondo.fechaCreacion)
            this.fondo.idCatalogoTipoFondo = 14
        }else{
          this.fundTitle = this.fundConstants.newFund.grandTitle
            this.fundSubtitle = this.fundConstants.newFund.subtitle
        }
        await this.getCatalog()
        this.getRolesByUser();
        
    }
    showResumen() {
        this.resumen = !this.resumen;
    }
    showDialog() {
        this.visible = true;
    }
    closeNewFund() {
        const event = {
            type: "close",
        };
        this.newFundForm.emit(event);
    }
    async createFondo() {
        this.fondo.idEmpresa =  this.auth.getCurrentUser().userData.idEmpresa
        this.fondo.estado = this.state
            ? this.properties.stateActive
            : this.properties.stateInactive;
        this.securityServices
            .createFondo(this.fondo)
            .then((resp) => {
                if (resp.code === 200) {
                    this.gotToFondos();
                    this.submitted = false;
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: this.fundConstants.newFund.messages.create,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    async crearFondo() {
        this.submitted = true;
        if (
            this.fondo.nombreFondo &&
            this.fondo.valorFondo &&
            this.fondo.valorFondo > 0 &&
            this.fondo.porcentajeReposicionFondo &&
            (this.fondo.porcentajeReposicionFondo > 0 &&
                this.fondo.porcentajeReposicionFondo <= 100) &&
            (this.fondo.montoAprobacion && (this.fondo.montoAprobacion > 0 &&  this.fondo.montoAprobacion <= this.fondo.valorFondo) ) &&
            this.fondo.idCatalogoTipoFondo &&
            this.fondo.fechaCreacion && 
            this.fondo.idUsuarioCustodio
        ) {
            if (this.fondo.idFondo) {
                await this.updateFondo();
            } else {
                this.createFondo();
            }
        }
    }
    /**
     * Guarda los datos modificados de un catalogo
     */
    async updateFondo() {
        this.fondo.idEmpresa =  this.auth.getCurrentUser().userData.idEmpresa
        this.fondo.estado = this.state
            ? this.properties.stateActive
            : this.properties.stateInactive;

        this.securityServices
            .updateFondo(this.fondo)
            .then((resp) => {
                if (resp.code === 200) {
                    this.gotToFondos();
                    this.submitted = false;
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: this.fundConstants.newFund.messages.update,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    gotToFondos() {
        const event: any = {};
        setTimeout(() => {
            this.fondo = new FondoModel();
            event.type = "new";
            this.newFundForm.emit(event);
        }, 2000);
    }
    async getCatalog(){
        await this.securityServices.getCatalogsByFather(this.properties.idCatalogoTipoFondo).then(resp => {
          if (resp.code === 200) {
            this.listTypeFund = resp.data;  
            if (resp.data.length === 0) {
              this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
            }
          } else {
            this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
          }
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
        });
      }
      getRolesByUser() {
        this.securityServices.getUsersByRol(this.properties.idRolCustodio).then(resp => {         
            this.listRolUser = resp           
            for (const user of this.listRolUser) {
                user.nombreCompleto = user.usuario.nombre + ' ' + user.usuario.apellido
            }
            if (this.listRolUser.length === 0) {
              this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
            }
          
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
        });
      }

}
