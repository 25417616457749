import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CatalogModel } from 'src/app/models/catalog.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { AuthorizationService } from 'src/app/common/auth.service';

@Component({
  selector: 'app-catalog-form',
  templateUrl: './catalog-form.component.html',
  providers: [MessageService, ConfirmationService],
})
export class CatalogFormComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  @Input() catalog = new CatalogModel();
  @Input() catalogPadre = new CatalogModel();
  catalogsRel: CatalogModel[] = [];
  txtRelacionado: string;
  submitted: boolean = false;
  state: boolean = false;
  showRelacionados: boolean = false;
  blockedCatalog: boolean = false;
  @Output() catalogAccion = new EventEmitter<any>();

  constructor(private messageService: MessageService, 
    private securityServices: SecurityService,
    private auth: AuthorizationService,

    ) {

  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.catalog.idUsuarioActualizacion = this.auth.getCurrentUser().userData.email
    if (this.catalog.idCatalogo) {
      this.state = this.catalog.estado == 'A' ? true : false;
    }
  }
  /**
   * Guarda los datos del catálogo seleccionado
   */
  saveCatalog() {
    try {
      this.submitted = true;
      if (this.catalogPadre && this.catalogPadre.idCatalogo) {
        this.catalog.idCatalogoPadre = Number.parseInt(this.catalogPadre.idCatalogo.toString());
      }
      if (this.catalog.nombre && this.catalog.orden && this.catalog.abreviacion && 
        this.catalog.abreviacionIngles && this.catalog.valorIngles) {
          this.catalog.valor = this.catalog.valor != null ? this.catalog.valor : " "
        this.catalog.estado = this.state ? this.properties.stateActive : this.properties.stateInactive;
          this.catalog.idUsuarioActualizacion = this.auth.getCurrentUser().userData.email
        if (this.catalog.idCatalogo) {
          this.catalog.idCatalogo = Number.parseInt(this.catalog.idCatalogo.toString());
          this.updateCatalog();
        } else {
          this.createCatalog();
        }
      }
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err, life: this.properties.timeLifeMessages });
    }

  }

  /**
   * Cancela la edición de un catalogo
   */
  cancelCatalog() {
    this.catalogAccion.emit();
  }

  confirmRelacionado(catalog) {
    this.txtRelacionado = catalog.nombre;
    this.catalog.idCatalogoRelacionado = catalog.idCatalogo;
    this.showRelacionados = false;
  }
  /**
   * Obtiene la lista de catalogos del sistema
   */
  searchCatalogo() {
    this.catalogsRel = [];
    this.blockedCatalog = true;
    this.securityServices.getCatalogs(this.txtRelacionado).then(resp => {
      this.blockedCatalog = false;
      if (resp.code === 200) {
        this.catalogsRel = resp.data;
        this.showRelacionados = true;
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blockedCatalog = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Crea un catalogo en la base de datos
   */
  createCatalog() {
    this.securityServices.createCatalog(this.catalog).then(resp => {
      if (resp.code === 200) {
        this.gotToCatalogs();
        this.submitted = false
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.catalog.messages.create, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Guarda los datos modificados de un catalogo
   */
  updateCatalog() {
    this.securityServices.updateCatalog(this.catalog).then(resp => {
      if (resp.code === 200) {
        this.gotToCatalogs();
        this.submitted = false;
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.catalog.messages.update, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Se va a la lista de catalogos
   */
  gotToCatalogs() {
    setTimeout(() => {
      this.catalog = new CatalogModel();
      if (this.catalogPadre) {
        this.catalog = this.catalogPadre;
      }
      this.catalogAccion.emit();
    }, 2000);

  }

}
