import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Properties } from "src/app/utilities/properties";

import { PermisoModel } from "src/app/models/permiso.model";
import { SupportConstants } from "../support.constants";
import { MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { FileUpload } from "primeng/fileupload";
import { MovimientoComprobanteModel } from "src/app/models/caja-chica/movimientoComprobante.model";
import { SecurityService } from "src/app/service/securityservice";
import { CatalogModel } from "src/app/models/catalog.model";
import { CentroCostoMovimientoModel } from "src/app/models/caja-chica/centroCostoMovimiento.model";
import { CentroCostoCuentaGastoModel } from "src/app/models/caja-chica/centroCostoCuentaGasto.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SolicitudModel } from "src/app/models/caja-chica/solitud.model";
import { CommonService } from "src/app/utilities/commonservice";
import { AuthorizationService } from "src/app/common/auth.service";
interface UploadEvent {
    originalEvent: Event;
    files: File[];
}

@Component({
    selector: "app-new-support",
    templateUrl: "./new-support.component.html",
})
export class NewSupportComponent implements OnInit {
    public readonly supportConstants = SupportConstants;
    properties: Properties = new Properties();
    permiso: PermisoModel;
    datosSolicitud: any = {};
    soportesLiquidacion: any[] = [];
    blocked: boolean = true;
    @Input() isView: boolean;
    @Input() newSupport: boolean;
    @Output() supportForm = new EventEmitter<any>();
    @Input() isDocuments: boolean;
    createComment: boolean = false;
    idMovimiento: number;
    documentoElectronico: boolean = false;
    documentosUpload: any = [];
    @ViewChild('fileImages') fileImages: FileUpload;
    @ViewChild('fileXml') fileXml: FileUpload;
    selectedFile: File;
    previewUrl: string;
    eventDoc: any;
    catalog: any;
    @Input() movimiento: MovimientoComprobanteModel;
    centroCosto: CentroCostoMovimientoModel = new CentroCostoMovimientoModel();
    listGrupos: CatalogModel[] = [];
    listDocumentos: CatalogModel[] = [];
    newCenterCost: boolean = false;
    createDistribucion: boolean = false;
    totalBaseIva0: number = 0;
    totalBaseIva12: number = 0;
    totalIce: number = 0;
    totalIva0: number = 0;
    totalFactura: number = 0;
    listCentoCosto: any[];
    listCuentaContable: any[];
    submitDistribucion: boolean = false;
    valorTotalEdit: boolean = false;
    editing: boolean = false;
    errorValorFactura: boolean = false;
    documento: any;
    @Input() requirement: SolicitudModel;
    @Input() diferenciaLiquidar: any
    files = [];
    filesImages = []
    submitMovimiento: boolean = false
    diferencia: boolean = false
    maxDate: Date
    isValeComprobante: boolean = false;
    documentoRepetido: boolean = false;

    constructor(
        private messageService: MessageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private securityServices: SecurityService,
        private http: HttpClient,
        private commonService: CommonService,
        private auth: AuthorizationService
    ) { }
    /**
     * Inicio de pantalla
     */
    async ngOnInit() {
        this.maxDate = new Date()
        if (this.movimiento.idMovimiento) {
            this.documentoElectronico = this.isView;
            this.movimiento.fechaFactura = new Date(
                this.movimiento.fechaFactura
            );
            let numeroDoc = this.movimiento.numeroDocumento ? this.movimiento.numeroDocumento.split("-") : []
            this.movimiento.establecimiento = numeroDoc[0] ?? '';
            this.movimiento.puntoVenta = numeroDoc[1] ?? '';
            this.movimiento.secuencial = numeroDoc[2] ?? '';
            this.initValores();
            this.movimiento.centrosCosto.forEach((objeto) => {
                this.totalBaseIva0 += objeto.baseiva0 == undefined ? 0 : Number(objeto.baseiva0);
                this.totalBaseIva12 += objeto.baseiva == undefined ? 0 : Number(objeto.baseiva);
                this.totalIce += objeto.ice == undefined ? 0 : Number(objeto.ice);
                this.totalIva0 += objeto.totaliva == undefined ? 0 : Number(objeto.totaliva);
                this.totalFactura += objeto.montoAsignado == undefined ? 0 : Number(objeto.montoAsignado);
            });
        }
        this.listGrupos = await this.getCatalogList(
            this.properties.idCatalogoGrupo
        );
        this.listDocumentos = await this.getCatalogList(
            this.properties.idCatalogoDocumento
        );
        this.listCentoCosto = await this.getCentroCosto(
            this.properties.idCatalogoCentroCosto
        );
        this.listCentoCosto = this.listCentoCosto.map(x => {
            x.nombre = x.codigoErp + " - " + x.nombreErp
            return x
        })
        this.listCuentaContable = await this.getCentroCosto(
            this.properties.idCatalogoCuentaContable
        );
        this.listCuentaContable = this.listCuentaContable.map(x => {
            x.nombre = x.codigoErp + " - " + x.nombreErp
            return x
        })
        this.blocked = false;

        if (!this.movimiento.idMovimiento) {
            this.movimiento.idSolicitud = this.requirement.idSolicitud;
            this.listDocumentos = this.listDocumentos.filter(item => item.value !== this.properties.idFacturaElectronica)
        }

    }


    onUpload(event: UploadEvent) {
        this.files = [];
        const reNombres = /^[a-zA-Z0-9\s_-]+$/i;

        if (event.files[0].name.split('.')[0].match(reNombres) === null) {
            this.cleanFile()
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: "El nombre del archivo contiene carácteres especiales. Por favor modifique el nombre y vuelva a intentar.",
                life: this.properties.timeLifeMessages,
            });
        } else {
            this.files = Object.assign(this.files, event.files);
            this.files.forEach((file) => {
                const nombre = file.name
                const tipo = file.type
                this.commonService.toBase64(file).then((value) => {
                    const vl = String(value).split(",");
                    file.base64 = vl[1];
                    file.nombre = nombre;
                    file.tipo = tipo
                });
            });
            if (this.files.length > 0) {
                const file = this.files[0];
                this.readFileContent(file);
            }
            this.validateCreateDistribucion()
        }
    }
    onUploadImages(event: UploadEvent) {
        this.filesImages = [];
        const reNombres = /^[a-zA-Z0-9\s_-]+$/i;

        if (event.files[0].name.split('.')[0].match(reNombres) === null) {
            this.cleanFileImages()
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: "El nombre del archivo contiene carácteres especiales. Por favor modifique el nombre y vuelva a intentar.",
                life: this.properties.timeLifeMessages,
            });

        } else {
            this.filesImages = Object.assign(this.filesImages, event.files);
            this.filesImages.forEach((file) => {
                const nombre = file.name
                const tipo = file.type
                this.commonService.toBase64(file).then((value) => {
                    const vl = String(value).split(",");
                    file.base64 = vl[1];
                    file.nombre = nombre;
                    file.tipo = tipo
                });
            });

            this.validateCreateDistribucion()
        }
    }
    cleanFile() {
        this.movimiento.autorizacionTributaria = null;
        this.movimiento.razonSocial = null;
        this.movimiento.numeroIdentificacion = null;
        this.movimiento.establecimiento = null;
        this.movimiento.puntoVenta = null;
        this.movimiento.secuencial = null;
        this.movimiento.valorTotal = null;
        this.movimiento.baseiva = null;
        this.movimiento.totaliva = null;
        this.movimiento.baseiva0 = null;
        this.movimiento.ice = null;
        this.movimiento.fechaFactura = null;
        this.createDistribucion = false;
        //this.fileImages.clear();
        if (this.fileXml) {
            this.fileXml.clear();
        }
        this.files = [];
        //this.filesImages = []

    }
    cleanFileImages() {
        this.fileImages.clear()
        this.filesImages = []
        this.createDistribucion = false
        this.validateCreateDistribucion()
    }
    readFileContent(file: File): void {
        const fileReader = new FileReader();
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop();
        if (this.documentoElectronico && fileExtension.toLowerCase() == "xml") {
            fileReader.onload = (e) => {
                const xmlString = fileReader.result as string;
                this.parseXmlString(xmlString);
            };
        } else {
            fileReader.onload = (e) => {
                const fileContent = fileReader.result as string;
            };
        }
        fileReader.readAsText(file);
    }
    async parseXmlString(xmlString: string) {
        try {
            xmlString = xmlString.replace('<?xml version="1.0" encoding="UTF-8"?>', '');
            let numeroAutorizacion = null
            let razonSocial = null;
            let numeroIdentificacionFiscal = null;
            let establecimiento = null;
            let puntoVenta = null;
            let secuencial = null;
            let importeTotal = null;
            let baseIva12 = null;
            let valorIva12 = null;
            let valorICE = null;
            let baseIva0 = null;
            let fechaEmision = null;
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, "text/xml");

            const factura = xmlDoc.querySelector("factura")
            const comp = xmlDoc.querySelector("numeroAutorizacion")
            if (factura) {
                const infoTributaria = factura.querySelector("infoTributaria")
                numeroAutorizacion = infoTributaria.querySelector("claveAcceso").textContent
                razonSocial = infoTributaria.querySelector("razonSocial").textContent
                establecimiento = infoTributaria.querySelector("estab").textContent
                puntoVenta = infoTributaria.querySelector("ptoEmi").textContent
                secuencial = infoTributaria.querySelector("secuencial").textContent
                numeroIdentificacionFiscal = infoTributaria.querySelector("ruc").textContent
                const infoFactura = factura.querySelector("infoFactura")
                fechaEmision = infoFactura.querySelector("fechaEmision").textContent
                importeTotal = infoFactura.querySelector("importeTotal").textContent
                const dataIva = infoFactura.getElementsByTagName("totalImpuesto");
                const dataImpuestos = Array.from(dataIva);

                dataImpuestos.forEach((totalImpuesto) => {
                    const codigo =
                        totalImpuesto.querySelector("codigo").textContent;
                    const valor = totalImpuesto.querySelector("valor").textContent;
                    const codPorcentaje = totalImpuesto.querySelector("codigoPorcentaje").textContent
                    if (codigo == "2") {
                        if (codPorcentaje == "0") {
                            baseIva0 = totalImpuesto.querySelector("baseImponible").textContent
                        }
                        if (codPorcentaje == "2" || codPorcentaje == "4") {
                            baseIva12 = totalImpuesto.querySelector("baseImponible").textContent
                            valorIva12 = totalImpuesto.querySelector("valor").textContent
                        }
                    }
                    if (codigo == "3") {
                        valorICE = valor;
                    }
                });
            } else {
                numeroAutorizacion =
                    xmlDoc.getElementsByTagName("numeroAutorizacion")[0]
                        .textContent;
                let comprobanteXmlString =
                    xmlDoc.getElementsByTagName("comprobante")[0].textContent;
                comprobanteXmlString = comprobanteXmlString.replace(/^\n+/, '')
                const comprobanteXmlDoc = parser.parseFromString(
                    comprobanteXmlString,
                    "text/xml"
                );

                const infoFactura =
                    comprobanteXmlDoc.getElementsByTagName("infoFactura")[0];

                fechaEmision = infoFactura.querySelector("fechaEmision").textContent
                razonSocial =
                    comprobanteXmlDoc.getElementsByTagName("razonSocial")[0]
                        .textContent;
                numeroIdentificacionFiscal =
                    comprobanteXmlDoc.getElementsByTagName("ruc")[0].textContent;
                establecimiento =
                    comprobanteXmlDoc.getElementsByTagName("estab")[0].textContent;
                puntoVenta =
                    comprobanteXmlDoc.getElementsByTagName("ptoEmi")[0].textContent;
                secuencial =
                    comprobanteXmlDoc.getElementsByTagName("secuencial")[0]
                        .textContent;
                importeTotal =
                    comprobanteXmlDoc.getElementsByTagName("importeTotal")[0]
                        .textContent;

                const iva12 =
                    comprobanteXmlDoc.getElementsByTagName("totalConImpuestos")[0];
                const dataIva = iva12.getElementsByTagName("totalImpuesto");
                const dataImpuestos = Array.from(dataIva);
                dataImpuestos.forEach((totalImpuesto) => {
                    const codigo =
                        totalImpuesto.querySelector("codigo").textContent;
                    const valor = totalImpuesto.querySelector("valor").textContent;
                    const codPorcentaje = totalImpuesto.querySelector("codigoPorcentaje").textContent
                    if (codigo == "2") {
                        if (codPorcentaje == "0") {
                            baseIva0 = totalImpuesto.querySelector("baseImponible").textContent
                        }
                        if (codPorcentaje == "2" || codPorcentaje == "4") {
                            baseIva12 = totalImpuesto.querySelector("baseImponible").textContent
                            valorIva12 = totalImpuesto.querySelector("valor").textContent
                        }
                    }
                    if (codigo == "3") {
                        valorICE = valor;
                    }
                });
            }

            let parts = fechaEmision.split("/")
            let fecha = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)
            fecha.setHours(fecha.getHours() + 5);
            this.movimiento.autorizacionTributaria = numeroAutorizacion;
            this.movimiento.razonSocial = razonSocial;
            this.movimiento.numeroIdentificacion = numeroIdentificacionFiscal;
            this.movimiento.establecimiento = establecimiento;
            this.movimiento.puntoVenta = puntoVenta;
            this.movimiento.secuencial = secuencial;
            this.movimiento.valorTotal = Number(importeTotal);
            this.movimiento.baseiva = Number(baseIva12);
            this.movimiento.totaliva = Number(valorIva12);
            this.movimiento.ice = Number(valorICE);
            this.movimiento.baseiva0 = baseIva0;
            this.movimiento.fechaFactura = fecha
            await this.validarDocumento();

            if (this.documentoRepetido) {
                this.cleanFile()
                //this.fileImages.clear()
            }
        } catch (error) {
            this.movimiento.autorizacionTributaria = null;
            this.movimiento.razonSocial = null;
            this.movimiento.numeroIdentificacion = null;
            this.movimiento.establecimiento = null;
            this.movimiento.puntoVenta = null;
            this.movimiento.secuencial = null;
            this.movimiento.valorTotal = null;
            this.movimiento.baseiva = null;
            this.movimiento.totaliva = null;
            this.movimiento.baseiva0 = null;
            this.movimiento.ice = null;
            this.movimiento.fechaFactura = null;
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: "El documento proporcionado no corresponde a una factura",
                life: this.properties.timeLifeMessages,
            });
        }
    }
    closeNewSupport() {
        const event = {
            type: "cancel",
        };
        this.supportForm.emit(event);
    }
    openComment() {
        this.createComment = true;
    }
    closeCommentForm(event) {
        this.createComment = event.createComment;
    }

    async isDocumentElectronico() {
        this.movimiento.autorizacionTributaria = null;
        this.movimiento.razonSocial = null;
        this.movimiento.numeroIdentificacion = null;
        this.movimiento.establecimiento = null;
        this.movimiento.puntoVenta = null;
        this.movimiento.secuencial = null;
        this.movimiento.valorTotal = null;
        this.movimiento.baseiva = null;
        this.movimiento.totaliva = null;
        this.movimiento.baseiva0 = null;
        this.movimiento.ice = null;
        this.movimiento.fechaFactura = null;
        this.movimiento.idCatalogoTipoDocumento = null;
        this.createDistribucion = false;
        if (this.documentoElectronico == true) {
            this.valorTotalEdit = true;
        }
        this.validarListaDocumentos();
        this.movimiento.adjunto = []
        this.files = []
        this.cleanFile()
        this.cleanFileImages()
    }
    async getCatalogList(idCatalog: any) {
        this.blocked = true;
        let catalogList: any[] = [];
        await this.securityServices
            .getCatalogsByFather(idCatalog)
            .then((resp) => {
                if (resp && resp.code === 200) {
                    resp.data.forEach((catalog) => {
                        let catalogo = new CatalogModel();
                        catalogo = catalog;
                        let selectItem = {
                            label: catalogo.nombre,
                            value: catalogo.idCatalogo,
                        };
                        if (catalogo.estado === this.properties.stateActive) {
                            catalogList.push(selectItem);
                        }
                    });
                    this.blocked = false;
                } else if (resp.description) {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: "Ocurrio un error al cargar los catalgos",
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
        return catalogList;
    }
    async newDistribution() {
        this.newCenterCost = true;
        this.centroCosto = new CentroCostoMovimientoModel();
    }

    async updateDistribucion(distribucion) {
        this.newCenterCost = true;
        this.centroCosto = distribucion;
        this.editing = true;
    }
    guardarDistribucion(distribucion) {

        this.submitDistribucion = true;
        if (distribucion.montoAsignado != null &&
            distribucion.idParametroErpCentroCosto != null &&
            distribucion.idParametroErpCuentaGasto != null && distribucion.montoAsignado > 0
        ) {
            this.initValores();
            this.submitDistribucion = false;

            let filterCuentaContable = this.listCuentaContable.filter(
                (x) => x.idParametroErp === distribucion.idParametroErpCuentaGasto
            );
            distribucion.cuenta = filterCuentaContable[0];
            let filterCentroCosto = this.listCentoCosto.filter(
                (x) => x.idParametroErp == distribucion.idParametroErpCentroCosto
            );
            distribucion.centro = filterCentroCosto[0];
            if (!this.editing) {
                distribucion.baseiva = distribucion.baseiva === null || !distribucion.baseiva ? Number(0.00).toFixed(2) : Number(Number(distribucion.baseiva).toFixed(2))
                distribucion.ice = distribucion.ice === null || !distribucion.ice ? Number(0.00).toFixed(2) : Number(distribucion.ice)
                distribucion.totaliva = distribucion.totaliva === null || !distribucion.totaliva ? Number(0.00).toFixed(2) : Number(distribucion.totaliva).toFixed(2)
                this.movimiento.centrosCosto.push(distribucion);
            }

            this.movimiento.centrosCosto.forEach((objeto) => {
                this.totalBaseIva0 += Number(objeto.baseiva0);
                this.totalBaseIva12 += Number(objeto.baseiva);
                this.totalIce += Number(objeto.ice);
                this.totalIva0 += Number(objeto.totaliva);
                this.totalFactura += Number(objeto.montoAsignado);
            });
            this.totalFactura = Number((this.totalFactura).toFixed(2))
            this.totalIva0 = Number((this.totalIva0).toFixed(2))
            this.totalIce = Number((this.totalIce).toFixed(2))
            this.totalBaseIva12 = Number((this.totalBaseIva12).toFixed(2))
            this.totalBaseIva0 = Number((this.totalBaseIva0).toFixed(2))

            if (this.totalFactura > this.movimiento.valorTotal ||
                this.totalBaseIva0 > this.movimiento.baseiva0 ||
                this.totalBaseIva12 > this.movimiento.baseiva ||
                this.totalIce > this.movimiento.ice ||
                this.totalIva0 > this.movimiento.totaliva
            ) {
                this.movimiento.centrosCosto =
                    this.movimiento.centrosCosto.filter(
                        (obj) => obj !== distribucion
                    );

                this.totalBaseIva0 -= Number(distribucion.baseiva0);
                this.totalBaseIva12 -= Number(distribucion.baseiva);
                this.totalIce -= Number(distribucion.ice);
                this.totalIva0 -= Number(distribucion.totaliva);
                this.totalFactura -= Number(distribucion.montoAsignado);
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: "No es posible guardar la distribución debido a que los valores no concuerdan con los totales en la factura",
                    life: this.properties.timeLifeMessages,
                });
            } else {
                this.newCenterCost = false;
                this.editing = false;
                this.errorValorFactura = false;
            }
        } else {
            this.submitDistribucion = true;
        }
    }
    closeNewCenterCost() {
        this.newCenterCost = false;
    }

    async getCentroCosto(idCatalogoCuentaCosto) {
        let centroList: any[] = [];
        this.blocked = true;
        await this.securityServices
            .getCentroCuentaGasto(idCatalogoCuentaCosto)
            .then((resp) => {
                if (resp && resp.code === 200) {
                    centroList = resp.data;
                    this.blocked = false;
                } else if (resp.description) {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: "Ocurrio un error al cargar los catalogos",
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
        return centroList;
    }
    validateFiels() {
        let validate = false
        if (!this.isValeComprobante) {
            if (this.movimiento.fechaFactura &&
                this.movimiento.valorTotal &&
                this.movimiento.autorizacionTributaria &&
                this.movimiento.secuencial &&
                this.movimiento.puntoVenta &&
                this.movimiento.establecimiento &&
                this.movimiento.idCatalogoGrupoDocumento &&
                this.movimiento.idCatalogoTipoDocumento &&
                this.movimiento.razonSocial &&
                this.movimiento.numeroIdentificacion
            ) {
                validate = true;
            }
        } else {
            if (this.movimiento.descripcionGasto && this.movimiento.fechaFactura && this.movimiento.idCatalogoGrupoDocumento &&
                this.movimiento.valorTotal) {
                validate = true;
            }
        }
        if (!validate) {
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: 'Aún faltan llenar algunos campos',
                life: this.properties.timeLifeMessages,
            });
        }
        return validate;
    }

    async saveMovimiento() {
        this.submitMovimiento = true
        this.movimiento.valorTotal = this.movimiento.valorTotal == null ? 0 : this.movimiento.valorTotal
        this.movimiento.totaliva = this.movimiento.totaliva == null ? 0 : this.movimiento.totaliva
        this.movimiento.ice = this.movimiento.ice == null ? 0 : this.movimiento.ice
        this.movimiento.baseiva = this.movimiento.baseiva == null ? 0 : this.movimiento.baseiva
        this.movimiento.baseiva0 = this.movimiento.baseiva0 == null ? 0 : this.movimiento.baseiva0
        let isValidate = this.validateFiels()
        if (isValidate) {
            let created = false
            if (this.documentoElectronico && this.files.length > 0 && this.filesImages.length > 0) {
                created = true
            }
            if (!this.documentoElectronico && this.filesImages.length > 0) {
                created = true
            }
            if (this.isValeComprobante) {
                created = true;
            }
            await this.validarDocumento();
            //console.log(this.documentoRepetido, "data");

            if (this.documentoRepetido) {
                created = false;
            }
            if (this.movimiento.centrosCosto.length == 0) {
                created = false
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: "Se debe ingresar al menos una distribución contable.",
                    life: this.properties.timeLifeMessages,
                });
            }
            this.totalFactura = Number((this.totalFactura).toFixed(2))
            this.totalIva0 = Number((this.totalIva0).toFixed(2))
            this.totalIce = Number((this.totalIce).toFixed(2))
            this.totalBaseIva12 = Number((this.totalBaseIva12).toFixed(2))
            this.totalBaseIva0 = Number((this.totalBaseIva0).toFixed(2))


            if (this.totalBaseIva0 != this.movimiento.baseiva0 ||
                this.totalBaseIva12 != this.movimiento.baseiva ||
                this.totalIce != this.movimiento.ice ||
                this.totalIva0 != this.movimiento.totaliva ||
                this.totalFactura != this.movimiento.valorTotal) {
                created = false
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: "No se puede registrar el movimiento porque los totales de la distribución contable no coinciden con los totales del documento de soporte",
                    life: 5000,
                });
            }



            if (created) {

                this.submitMovimiento = false
                this.movimiento.adjunto = this.files ? this.files.concat(this.filesImages) : null
                this.movimiento.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
                await this.securityServices
                    .createMovimientos(this.movimiento)
                    .then((resp) => {
                        if (resp.code === 200) {
                            this.messageService.add({
                                severity: "success",
                                summary: this.properties.successful,
                                detail: "Comprobante creado correctamente",
                                life: this.properties.timeLifeMessages,
                            });
                            const event = {
                                type: "reload",
                            };
                            this.supportForm.emit(event);
                        } else {
                            this.messageService.add({
                                severity: "error",
                                summary: this.properties.error,
                                detail: resp.description,
                                life: this.properties.timeLifeMessages,
                            });
                        }
                    })
                    .catch((err) => {
                        this.messageService.add({
                            severity: "error",
                            summary: this.properties.error,
                            detail: err.message,
                            life: this.properties.timeLifeMessages,
                        });
                    });
            }
        }

    }

    async updateMovimiento(movimiento) {
        movimiento.estado = this.properties.ESTADO_RECHAZADO
        movimiento.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        await this.securityServices
            .updateMovimientos(movimiento)
            .then((resp) => {
                if (resp.code === 200) {
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: "Comprobante creado correctamente",
                        life: this.properties.timeLifeMessages,
                    });
                    const event = {
                        type: "reload",
                    };
                    this.supportForm.emit(event);
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    eliminarDistribucion(distribucion) {
        this.movimiento.centrosCosto = this.movimiento.centrosCosto.filter(
            (obj) => obj !== distribucion
        );
        this.totalBaseIva0 -= Number(distribucion.baseiva0);
        this.totalBaseIva12 -= Number(distribucion.baseiva);
        this.totalIce -= Number(distribucion.ice);
        this.totalIva0 -= Number(distribucion.totaliva);
        this.totalFactura -= Number(distribucion.montoAsignado);
    }
    initValores() {
        this.totalBaseIva0 = 0;
        this.totalBaseIva12 = 0;
        this.totalIce = 0;
        this.totalIva0 = 0;
        this.totalFactura = 0;
    }
    validarCentros() {
        if (this.totalFactura > this.movimiento.valorTotal) {
            this.errorValorFactura = true;
        } else {
            this.errorValorFactura = false;
        }
    }
    viewDocument(ruta) {
        window.open(ruta, '_blank');
    }
    validateCreateDistribucion() {

        if (this.documentoElectronico) {
            if (this.files.length > 0 && this.filesImages.length > 0) {
                this.createDistribucion = true
            }
        } else {
            if (this.filesImages.length > 0 && this.movimiento.valorTotal != null && this.movimiento.valorTotal > 0) {
                this.createDistribucion = true;
            }
            if (this.movimiento.idCatalogoTipoDocumento !== this.properties.idCatalogoValeCajaChica && this.filesImages.length <= 0) {
                this.createDistribucion = false;
            }
            if (this.movimiento.idCatalogoTipoDocumento == this.properties.idCatalogoValeCajaChica && this.movimiento.valorTotal > 0) {
                this.createDistribucion = true;
            }
            else {
                if (this.movimiento.idCatalogoTipoDocumento !== this.properties.idCatalogoValeCajaChica && this.movimiento.valorTotal <= 0) {
                    this.createDistribucion = false;
                }
            }
        }


    }
    calcularValorTotal() {
        if (this.documentoElectronico) {
            this.movimiento.valorTotal = 0
            this.movimiento.totaliva = 0
            this.movimiento.totaliva = Number((this.movimiento.baseiva * 0.12).toFixed(2))
            this.movimiento.valorTotal = Number((this.movimiento.baseiva0 + this.movimiento.baseiva + this.movimiento.totaliva + this.movimiento.ice).toFixed(2))
        } else {
            this.movimiento.valorTotal = Number((this.movimiento.baseiva0 + this.movimiento.baseiva + this.movimiento.totaliva + this.movimiento.ice).toFixed(2))
        }
        if (this.movimiento.valorTotal > 0) {
            this.validateCreateDistribucion()
        }
        else {
            this.createDistribucion = false;
        }
    }

    calcularValorDistribucion(centro) {
        centro.montoAsignado = 0
        centro.montoAsignado = (Number(centro.baseiva) + Number(centro.baseiva0) + Number(centro.totaliva) + Number(centro.ice)).toFixed(2)
    }

    onChangeTipoDocumento() {
        this.validarDocumento()
        this.submitMovimiento = true;
        if (this.movimiento.idCatalogoTipoDocumento && this.movimiento.idCatalogoTipoDocumento !== this.properties.idCatalogoValeCajaChica && this.movimiento.idCatalogoTipoDocumento !== this.properties.idCatalogoComprobante) {
            this.isValeComprobante = false;
            this.validateCreateDistribucion()
        } else {
            this.isValeComprobante = true;
            //this.createDistribucion = true;
            this.validateCreateDistribucion()

        }
        if (this.movimiento.idCatalogoTipoDocumento === null) {
            this.createDistribucion = false;
        }
    }
    async validarListaDocumentos() {
        this.listDocumentos = await this.getCatalogList(
            this.properties.idCatalogoDocumento
        );
        this.movimiento.idCatalogoTipoDocumento = null;
        if (this.documentoElectronico) {
            this.listDocumentos = this.listDocumentos.filter(item => item.value === this.properties.idFacturaElectronica)
            this.movimiento.idCatalogoTipoDocumento = this.properties.idFacturaElectronica;
        } else {
            if (this.movimiento.emitidoEmpresa) {
                this.listDocumentos = this.listDocumentos.filter(item => this.properties.idsDocumentoEmpresa.includes(item.value))
            } else {
                this.listDocumentos = this.listDocumentos.filter(item => item.value !== this.properties.idFacturaElectronica)
            }
        }
        this.onChangeTipoDocumento()
    }

    async validarDocumento() {
        if (this.movimiento.idCatalogoTipoDocumento && this.movimiento.establecimiento && this.movimiento.puntoVenta && this.movimiento.secuencial && this.movimiento.numeroIdentificacion) {
            if (this.movimiento.idCatalogoTipoDocumento !== this.properties.idCatalogoValeCajaChica && this.movimiento.idCatalogoTipoDocumento !== this.properties.idCatalogoComprobante) {
                await this.securityServices.validateNumberDocumento(Number(this.movimiento.idCatalogoTipoDocumento), this.movimiento.establecimiento, this.movimiento.puntoVenta, this.movimiento.secuencial, this.movimiento.numeroIdentificacion).then((resp) => {
                    if (resp.code === 200) {
                        this.documentoRepetido = resp.data;
                        if (this.documentoRepetido) {
                            this.messageService.add({
                                severity: "error",
                                summary: this.properties.error,
                                detail: "El número de documento proporcionado ya ha sido registrado",
                                life: this.properties.timeLifeMessages,
                            });
                        }
                    } else {
                        this.messageService.add({
                            severity: "error",
                            summary: this.properties.error,
                            detail: resp.description,
                            life: this.properties.timeLifeMessages,
                        });
                    }
                })
                    .catch((err) => {
                        this.messageService.add({
                            severity: "error",
                            summary: this.properties.error,
                            detail: err.message,
                            life: this.properties.timeLifeMessages,
                        });
                    });
            } else {
                this.documentoRepetido = false;
            }

        }

    }
}
