export const PendingsConstants = {
   dashboard: 'Dasboard',
   requirements : {
    title : 'Pendientes por aprobar',
    subtitle : 'Este es tu dashboard, aquí están tus solicitudes por aprobar.',
    addButton : 'Nuevo requerimiento',
    search : 'Buscar',
    placehorders : {
        applicant : 'Puedes buscar por numero o nombre del solicitante',
        dateForm : 'Fecha desde',
        dateTo : 'Fecha hasta'
    },
    applicant : {
        label : 'Solicitante'
    },
    numberSoli : {
        label : 'Número de solicitud'
    },
    amountApprove : {
        label : 'Monto aprobación'
    },
    createDate : {
        label : 'Fecha de creación'
    },
    liquitationDate : {
        label : 'Fecha de liquidación'
    },
    fundType :{ 
        label : 'Tipo de fondo'
    },
    aprobateFor : {
        label : 'Aprobado por'
    },
    description : {
        label : 'Descripción'
    },
    status : {
        label : 'Estado'
    },
    actions : {
        label : 'Acciones'
    }
   }

}
