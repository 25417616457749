import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { UserModel } from 'src/app/models/user.model';
import { SecurityService } from 'src/app/service/securityservice';
import { AppBreadcrumbService } from 'src/app/utilities/app.breadcrumb.service';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { UsersFormComponent } from './users-form.component';
import { UsersListComponent } from './users-list.component';
import { Router } from '@angular/router';
import { PermisoModel } from 'src/app/models/permiso.model';
import { AuthorizationService } from 'src/app/common/auth.service';
import { CatalogModel } from 'src/app/models/catalog.model';
import { CommonService } from 'src/app/utilities/commonservice';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  providers: [MessageService]
})
export class UsersComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  deleteUsersDialog: boolean = false;
  users: UserModel[] = [];
  user: UserModel;
  selectedUsers: UserModel[];
  isNewUser: boolean;
  home = { icon: 'pi pi-arrow-left'};
  items: MenuItem[];
  isAssignRol: boolean = false;
  isEdit = false;
  permiso: PermisoModel;
  branches: CatalogModel[] = [];

  @ViewChild(UsersFormComponent) userFormComponent: UsersFormComponent;
  @ViewChild(UsersListComponent) userListComponent: UsersListComponent;

  constructor(private messageService: MessageService, private breadcrumbService: AppBreadcrumbService,
    private router: Router,
    private auth: AuthorizationService,
    private commonService: CommonService,
    private securityServices: SecurityService) {
    this.breadcrumbService.setItems([
      { label: this.securityConstants.security },
      { label: this.securityConstants.user.title, routerLink: ['/principal/user'] }
    ]);
  }

  ngOnInit(): void {
    this.items = [];
    this.permiso = this.auth.validatePermision(this.router);
    this.commonService.getCatalogList(this.properties.brachsCatalogId, (catalogs) => this.branches = catalogs);
  }

  newUser() { 
    this.selectedUsers = [];
    this.user = new UserModel();
    this.isNewUser = true;
  }

  deleteSelectedUsers() {
    this.deleteUsersDialog = true;
  }

  saveUser() {
    this.userFormComponent.saveUser();
  }

  cancelUser() {
    this.isNewUser = false;
  }

  userAccion(event: any) {
    if (event.type == 'M') {
      this.isNewUser = true;
      this.user = event.user;
      this.isEdit = event.isEdit
    } else if (event.type == 'AR') {
      this.items.push({ label: event.user.nombre + ' ' + event.user.apellido, id: event.user.idUsuario});
      this.items = this.items.slice();
      this.isAssignRol = true;
      this.user = event.user;
    }
    else {
      this.selectedUsers = event.users;
    }

  }

  confirmDeleteSelected() {
    let usersToDelete: any[] = [];
    this.selectedUsers.forEach(x => {
      usersToDelete.push(x.idUsuario);
    });
    const usersDelete = {
      idRoles: usersToDelete
    }
    this.deleteMassive(usersDelete);
  }

  deleteMassive(users: any) {
    this.securityServices.deleteUser(users).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.user.messages.deletes, life: this.properties.timeLifeMessages });
        this.reloadUsers();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.successful, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  reloadUsers() {
    this.deleteUsersDialog = false;
    setTimeout(() => {
      this.userListComponent.getUsers();
      this.userListComponent.selectedUsers = [];

    }, 3000);
  }

  onItemClick(event) {
    if (!event.item.id) {
      setTimeout(() => {
        this.items = [];
        this.isNewUser = false;
        this.userListComponent.isAssignRol = false;
        this.isAssignRol = false;
      }, 1);
    }
  }

}
