<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3">
            <div class="field col-12 md:col-10">
                <h2 style="color: white;">Menús</h2>
                <h6 style="color: white;">Aquí puedes gestionar tus menús</h6>
            </div>

        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-breadcrumb  *ngIf="items.length > 0" (onItemClick)="onItemClick($event)" [model]="items" [home]="home"></p-breadcrumb>
            <p-toolbar styleClass="mb-1">
                <ng-template pTemplate="left">
                    <h5></h5>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="isNewMenu" pButton pRipple id="saveMenu" icon="{{properties.guardar.icono}}"
                        label="{{properties.guardar.valor}}" class="p-button mr-2"
                        (click)="saveMenu()"></button>
                    <button *ngIf="isNewMenu" pButton pRipple id="cancelMenu" icon="{{properties.cancelar.icono}}"
                        label="{{properties.cancelar.valor}}" class="p-button-danger" (click)="cancelMenu()"></button>

                    <button *ngIf="!isNewMenu" pButton pRipple id="newMenu" icon="{{properties.nuevo.icono}}"
                        label="{{properties.nuevo.valor}}" class="mr-2 mb-2" (click)="newMenu()"></button>
                    <button *ngIf="!isNewMenu && selectedMenus && selectedMenus.length> 0" pButton pRipple
                        id="deleteMenu" icon="{{properties.eliminar.icono}}" label="{{properties.eliminar.valor}}"
                        class="p-button-danger mr-2 mb-2" (click)="deleteSelectedMenus()"></button>
                </ng-template>
            </p-toolbar>
<!-- 
            <div class="p-fluid p-formgrid grid" *ngIf="menuFather && menuFather.idMenu">
                <div class="field col-12 md:col-4">
                    <label htmlFor="name"><b>Gestionando menu: </b>{{menuFather.nombreMenu}}</label> 
                </div>
            </div> -->
            
            <app-menu-form *ngIf="isNewMenu" (menuAccion)="cancelMenu()" [menu]="menu" [isFather]="isFather" [isEdit]="isEdit" [idMenuPadre]="idMenuPadre"></app-menu-form>
            <app-menu-list *ngIf="!isNewMenu" (menuAccion)="menuAccion($event)" [menuFather]="menuFather" [isFather]="isFather" [isEdit]="isEdit" [items]="items" [idMenuPadre]="idMenuPadre"></app-menu-list>
        </div>

       

        <p-dialog [(visible)]="deleteMenusDialog" header="{{properties.confirmation}}" [modal]="true"
            [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>{{securityConstants.menu.confirmationMessages.deletes}}</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                    (click)="deleteMenusDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                    (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>