import { AuditoriaModel } from "./auditoria.model";

export class UserModel extends AuditoriaModel {
    idUsuario: number;
    idUsuarioCognito: string;
    nombre: string;
    apellido: string;
    email: string;
    telefono: string;
    estado: string;
    estadoMfa : string
    idCatalogoSucursal: number;
    estadoMfaAnterior: boolean;
    imagenFirma: string;
    nombreApellido: string;
    numeroDocumentoIdentidad:string;
    idEmpresa: string
    userEmpresa:any
    backupActivo: boolean
    idUsuarioBackup: number
    constructor() {
        super();
        this.idUsuario = null;
        this.idUsuarioCognito = null;
        this.nombre = null;
        this.apellido = null;
        this.email = null;
        this.telefono = null;
        this.estado = null;
        this.estadoMfaAnterior = false;
        this.nombreApellido = null;
        this.numeroDocumentoIdentidad = null;
        this.idEmpresa = null
        this.userEmpresa = {
            empresa:{
                idEmpresa: null
            }
        }
        this.idUsuarioBackup = null;
        this.backupActivo = null
    }
}