import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { MenuTreeModel } from 'src/app/models/menu.tree.model';
import { MenuModel } from 'src/app/models/menus.model';
import { RolModel } from 'src/app/models/roles.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/common/auth.service';
import { PermisoModel } from 'src/app/models/permiso.model';

@Component({
  selector: 'app-rol-list',
  templateUrl: './rol-list.component.html',
  providers: [MessageService]
})
export class RolListComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  rolDialog: boolean;
  deleteRolDialog: boolean = false;
  blockedRole: boolean = false;
  roles: RolModel[] = [];
  rol: RolModel;
  menus: MenuModel[] = [];
  selectedRoles: RolModel[];
  menusTree: TreeNode[];
  rowsPerPageOptions = [5, 10, 20];
  assignMenusRol: boolean = false;
  @Output() rolAccion = new EventEmitter<any>();
  cols: any[];
  selectedNodeMenus: TreeNode[];
  listTreeMenu: TreeNode[];
  permiso: PermisoModel;
  
  constructor(private messageService: MessageService, private securityServices: SecurityService, private router: Router, private auth: AuthorizationService) { }
  ngOnInit(): void {
    this.permiso = this.auth.validatePermision(this.router);
    this.getRoles();
    // this.getMenus();
    this.cols = [
      { field: "name", header: "Nombre Menú" },
      { field: "description", header: "Descripción" },
      { field: 'url', header: 'URL' }
    ];
  }

  getMenus(rol: any) {
    this.blockedRole = true;
    this.securityServices.getMenu().then(resp => {
      if (resp.code === 200) {
        this.menus = resp.data;
        this.listTreeMenu = this.buildMenus(resp.data);
        if (this.listTreeMenu.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: 'No se encontraron menus activos para agregar al rol: ' + rol.nombreRol, life: this.properties.timeLifeMessages });
        } else {
          this.findMenusByRol(rol);
        }
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
      this.blockedRole = false;
    }).catch(err => {
      this.blockedRole = true;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  findMenusByRol(rol: any) {
    this.selectedRoles = undefined;
    this.assignMenusRol = true;
    this.selectedNodeMenus = [];
    this.rol = rol;
    this.getMenusByRol();
    this.selectMenus();
  }

  confirmDelete() {
    this.deleteRolDialog = false;
    this.rol.estado = 'E';
    this.updateRol();
    this.rol = new RolModel();
  }

  deleteRol(rol: RolModel) {
    this.deleteRolDialog = true;
    this.rol = { ...rol };
  }

  editRol(rol: RolModel) {
    const rolEvent = {
      type: 'M',
      rol: rol
    };
    this.rolAccion.emit(rolEvent);

  }

  rolSelected() {
    const rolEvent = {
      type: 'S',
      roles: this.selectedRoles
    };
    this.rolAccion.emit(rolEvent);
  }

  selectMenus() {

    const rolEvent = {
      type: 'AS',
      assignMenu: this.assignMenusRol
    };
    this.rolAccion.emit(rolEvent);

  }

  cancelRolMenu() {
    this.assignMenusRol = false;
    const rolEvent = {
      type: 'RM',
      assignMenu: false
    };
    this.rolAccion.emit(rolEvent);
  }

  getRoles() {
    this.blockedRole = true;
    this.securityServices.getRoles().then(resp => {
      if (resp.code === 200) {
        this.roles = resp.data;
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
      this.blockedRole = false;
    }).catch(err => {
      this.blockedRole = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateRol() {
    this.rol.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.updateRol(this.rol).then(resp => {
      if (resp.code === 200) {
        this.getRoles();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.rol.messages.update, life: this.properties.timeLifeMessages });
      } else {
        this.getRoles();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateStateRol(rol: RolModel) {
    rol.estado = rol.estado === this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive;
    this.rol = rol;
    this.updateRol();
  }

  assignMenus(rol: RolModel) {
    this.getMenus(rol);
  }

  getMenusByRol() {
    this.securityServices.getMenusByRol(this.rol.idRol).then(resp => {
      if (resp.code === 200) {
        this.buildMenusByRol(resp.data);
      } else {
        this.getRoles();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  buildMenusByRol(data: any) {
    let listMenuRoles: MenuTreeModel[] = [];
    data.forEach(menu => {
      const menusSelect = this.menus.find(x => x.idMenu === menu.idMenu);
      if (menusSelect) {
        listMenuRoles.push(this.generateStructueSelect(menusSelect, menu));
        const menuEvaluate = {
          description: menusSelect.descripcion,
          id: menusSelect.idMenu,
          idParent: menusSelect.idMenuPadre,
          hasNew: menu.hasNew,
          hasUpdate: menu.hasUpdate,
          hasDelete: menu.hasDelete,
          name: menusSelect.nombreMenu,
          url: menusSelect.url
        }
        this.updateListTreeTableByRol(menuEvaluate);
      }

    });
    this.selectedNodeMenus = listMenuRoles;
  }

  generateStructueSelect(data: any, menuRol: any): MenuTreeModel {
    let newMenu: MenuTreeModel = new MenuTreeModel();
    // newMenu.children = this.findChildresMenus(data);
    newMenu.children = this.findChildresMenus(data, menuRol);
    newMenu.data.id = data.idMenu;
    newMenu.data.name = data.nombreMenu;
    newMenu.data.description = data.descripcion;
    newMenu.data.url = data.url;
    newMenu.data.idParent = data.idMenuPadre;
    newMenu.data.hasNew = menuRol.hasNew;
    newMenu.data.hasUpdate = menuRol.hasUpdate;
    newMenu.data.hasDelete = menuRol.hasDelete;
    newMenu.expanded = false
    newMenu.leaf = null
    newMenu.parent = this.findParentMenu(data, menuRol);
    // newMenu.partialSelected = null


    return newMenu;
  }

  findChildresMenus(data: any, menuRol: any) {
    let menusChildrens: MenuTreeModel[] = [];
    const menusSelect = this.menus.filter(x => x.idMenuPadre === data.idMenu);
    if (menusSelect.length !== 0) {
      menusSelect.forEach(menu => {
        menusChildrens.push(this.generateStructuOneLevel(menu, menuRol));
      });
    }
    return menusChildrens;
  }

  findParentMenu(data: any, menuRol: any): MenuTreeModel {
    let menusFather: MenuTreeModel = new MenuTreeModel();
    const menusSelect = this.menus.find(x => x.idMenu === data.idMenuPadre);
    if (menusSelect) {
      menusFather.children = [];
      menusFather.data.id = data.idMenu;
      menusFather.data.name = data.nombreMenu;
      menusFather.data.description = data.descripcion;
      menusFather.data.url = data.url;
      menusFather.data.idParent = data.idMenuPadre;
      menusFather.data.hasNew = false;
      menusFather.data.hasUpdate = false;
      menusFather.data.hasDelete = false;
      menusFather.expanded = false;
      menusFather.leaf = null;
      menusFather.parent = null
    }
    return menusFather;
  }

  generateStructuOneLevel(data: any, menuRol: any): MenuTreeModel {
    let newMenu: MenuTreeModel = new MenuTreeModel();
    newMenu.children = [];
    newMenu.data.id = data.idMenu;
    newMenu.data.name = data.nombreMenu;
    newMenu.data.description = data.descripcion;
    newMenu.data.url = data.url;
    newMenu.data.idParent = data.idMenuPadre;
    newMenu.data.hasNew = false;
    newMenu.data.hasUpdate = false;
    newMenu.data.hasDelete = false;
    newMenu.expanded = false;
    newMenu.leaf = null;
    newMenu.parent = null;

    return newMenu;
  }

  buildMenus(menu: MenuModel[]): MenuTreeModel[] {
    menu.sort((firstObject: MenuModel, secondObject: MenuModel) =>
    (firstObject.nombreMenu > secondObject.nombreMenu) ? 1 : -1);
    let tree = new MenuTreeModel();
    let list = [];
    let menuFathers = menu.filter(x => x.idMenuPadre === null);
    
    menuFathers.forEach(x => {
      tree = new MenuTreeModel();
      if (x.estado === this.properties.stateActive) {
        tree.data.id = x.idMenu;
        tree.data.name = x.nombreMenu;
        tree.data.description = x.descripcion;
        tree.data.url = x.url;
        tree.expanded = true;
        tree.data.idParent = x.idMenuPadre;
        tree.children = this.findchildren(x);
        list.push(tree);
      }

    });

    return list;
  }

  findchildren(menu: MenuModel): MenuTreeModel[] {
    let tree = new MenuTreeModel();
    let list = [];
    let menuFathers = this.menus.filter(x => x.idMenuPadre === menu.idMenu);
    if (menuFathers.length !== 0) {
      menuFathers.forEach(x => {
        if (x.estado === this.properties.stateActive) {
          tree = new MenuTreeModel();
          tree.data.id = x.idMenu;
          tree.data.name = x.nombreMenu;
          tree.data.description = x.descripcion;
          tree.data.url = x.url;
          tree.data.hasNew = false;
          tree.data.hasUpdate = false;
          tree.data.hasDelete = false;
          tree.data.idParent = x.idMenuPadre;
          tree.expanded = true;
          tree.children = this.findchildren(x);
          list.push(tree);
        }
      });
    }

    return list;

  }

  findTheadLevel(menu: MenuModel): MenuTreeModel[] {
    let tree = new MenuTreeModel();
    let list = [];
    let menuFathers = this.menus.filter(x => x.idMenuPadre === menu.idMenu);
    if (menuFathers.length !== 0) {
      menuFathers.forEach(x => {
        if (x.estado === this.properties.stateActive) {
          tree = new MenuTreeModel();
          tree.data.id = x.idMenu;
          tree.data.name = x.nombreMenu;
          tree.data.description = x.descripcion;
          tree.data.url = x.url;
          tree.data.idParent = x.idMenuPadre;
          tree.data.hasNew = false;
          tree.data.hasUpdate = false;
          tree.data.hasDelete = false;
          tree.expanded = true;
          tree.children = this.findchildren(x);
          list.push(tree);
        }
      });
    }

    return list;

  }

  saveRolMenu() {
    let menusNode: any = this.selectedNodeMenus;
    let idMenus: any[] = [];
    if (menusNode && menusNode.length !== 0) {
      menusNode.forEach(element => {
        idMenus.push({ idMenu: element.data.id, nuevo: element.data.hasNew, edicion: element.data.hasUpdate, eliminacion : element.data.hasDelete });
        if (element.parent && element.parent.data) {
          const menuFather = element.parent.data;
          idMenus.push({ idMenu: menuFather.id, nuevo: menuFather.hasNew, edicion:  menuFather.hasUpdate, eliminacion: menuFather.hasDelete });
        }
      });
      let bodyRolMenu = {
        idRol: this.rol.idRol,
        idMenus: this.filteMenuFather(idMenus),
      }
      this.createMenuByRol(bodyRolMenu);
    } else {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: 'Seleccione almenos un menú para asignar al rol.', life: this.properties.timeLifeMessages });
    }

  }

  filteMenuFather(data: any): any {
    let idMenus: any[] = [];
    data.forEach(menu => {
      if (menu.idMenu) {
        const rolMenu = idMenus.find(x => x.idMenu === menu.idMenu);
        if (!rolMenu) {
          idMenus.push(menu);
        }
      }
    });
    return idMenus
  }

  createMenuByRol(data: any) {
    data.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa 
    this.securityServices.createMenuByRol(data).then(resp => {
      if (resp.code == 200) {
        this.assignMenusRol = false;
        const rolEvent = {
          type: 'RM',
          assignMenu: false
        };
        this.rolAccion.emit(rolEvent);
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.rol.messages.menuRoles, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateListSelectMenu(data: any, operacion: string) {
    let objetUpdate: any = this.updateListTreeTable(data, operacion);
    let findSelectedMenu: boolean = false;
    setTimeout(() => {
      this.selectedNodeMenus.forEach(x => {
        if (x.data.id == data.id && !findSelectedMenu) {
          x.data.hasNew = objetUpdate.hasNew;
          x.data.hasUpdate = objetUpdate.hasUpdate;
          x.data.hasDelete = objetUpdate.hasDelete;
          findSelectedMenu = true;
        }

      })
    }, 10);
  }


  updateListTreeTable(data: any, operacion: string): any {
    let findInTree: boolean = false;
    let menuUpdate: any;
    do {
      this.listTreeMenu.forEach(x => {
        if (x.children.length !== 0 && !findInTree) {
          x.children.forEach(children => {
            if (children.data.id === data.id && !findInTree) {
              if (operacion === 'N') {
                children.data.hasNew = !children.data.hasNew;
              } else if (operacion === 'U') {
                children.data.hasUpdate = !children.data.hasUpdate;
              } else {
                children.data.hasDelete = !children.data.hasDelete;
              }
              menuUpdate = children.data;
              findInTree = true;
            } else {
              if (children.children.length !== 0 && !findInTree) {
                let nieto = children.children;
                nieto.forEach(nietoLevel => {
                  if (nietoLevel.data.id === data.id && !findInTree) {
                    if (operacion === 'N') {
                      nietoLevel.data.hasNew = !nietoLevel.data.hasNew;
                    } else if (operacion === 'U') {
                      nietoLevel.data.hasUpdate = !nietoLevel.data.hasUpdate;
                    } else {
                      nietoLevel.data.hasDelete = !nietoLevel.data.hasDelete;
                    }
                    menuUpdate = nietoLevel.data;
                    findInTree = true;
                  } else {
                    if (nietoLevel.children.length !== 0 && !findInTree) {
                      let lastLevel = nietoLevel.children;
                      lastLevel.forEach(element => {
                        if (element.data.id === data.id && !findInTree) {
                          if (operacion === 'N') {
                            element.data.hasNew = !element.data.hasNew;
                          } else if (operacion === 'U') {
                            element.data.hasUpdate = !element.data.hasUpdate;
                          } else {
                            element.data.hasDelete = !element.data.hasDelete;
                          }
                          menuUpdate = element.data;
                          findInTree = true;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }

      });
    } while (findInTree = false);
    return menuUpdate;
  }


  updateListTreeTableByRol(data: any): any {
    let findInTree: boolean = false;
    let menuUpdate: any;
    do {
      this.listTreeMenu.forEach(x => {
        if (x.children.length !== 0 && !findInTree) {
          x.children.forEach(children => {
            if (children.data.id === data.id && !findInTree) {
              children.data.hasNew = data.hasNew;
              children.data.hasUpdate = data.hasUpdate;
              children.data.hasDelete = data.hasDelete;
              menuUpdate = children.data;
              findInTree = true;
            } else {
              if (children.children.length !== 0 && !findInTree) {
                let nieto = children.children;
                nieto.forEach(nietoLevel => {
                  if (nietoLevel.data.id === data.id && !findInTree) {
                    nietoLevel.data.hasNew = data.hasNew;
                    nietoLevel.data.hasUpdate = data.hasUpdate;
                    nietoLevel.data.hasDelete = data.hasDelete;
                    menuUpdate = nietoLevel.data;
                    findInTree = true;
                  } else {
                    if (nietoLevel.children.length !== 0 && !findInTree) {
                      let lastLevel = nietoLevel.children;
                      lastLevel.forEach(element => {
                        if (element.data.id === data.id && !findInTree) {
                          element.data.hasNew = data.hasNew;
                          element.data.hasUpdate = data.hasUpdate;
                          element.data.hasDelete = data.hasDelete;
                          menuUpdate = element.data;
                          findInTree = true;
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        }

      });
    } while (findInTree = false);
    return menuUpdate;
  }

}