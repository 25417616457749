<p-dialog [(visible)]="viewNewFund" [modal]="true" [responsive]="true" [showHeader]="false"
[breakpoints]="{'960px': '90vw'}" [style]="{width: '65%', minHeight:'580px'}" [minY]="100" position="right"
[contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">    
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-4 px-3 pb-2">
                <div class="col-11 md:col-11">
                    <span style="color: white; font-size: 25px;"><b>{{fundTitle}} </b></span><br><br>
                    <span style="color: white;">Aquí estan tus fondos para su gestión</span>

                </div>
                <div class="col-1 md:col-1" style="text-align: end;">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                    class="p-button-rounded p-button-outlined" (click)="closeNewFund()"></button>
                </div>
            </div>
        </div>
        <p-toast></p-toast>

        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid mx-2">
                <div class="col-12 md:col-8">
                    <span class="title-screen">{{fundConstants.newFund.title}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-times" iconPos="left" class="cancel-button" label="{{fundConstants.newFund.cancelButton}}"
                    (click)="closeNewFund()" ></button>
                    
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-save" (click)="crearFondo()" iconPos="left" class="new-button" label="{{fundConstants.newFund.saveButton}}"
                    ></button>
                </div>
            </div>
                
        </div>
        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid mx-2">
                <div class="col-12 md:col-4">
                    <label htmlFor="typeFund">{{fundConstants.newFund.fundName.label}}</label>
                    <input type="text" pInputText id="fundName" required autofocus maxlength="100"  [(ngModel)]="fondo.nombreFondo"/>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !fondo.nombreFondo">{{fundConstants.newFund.fundName.require}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="typeFund">{{fundConstants.newFund.typeFund.label}}</label>
                    <p-dropdown  [options]="listTypeFund" optionLabel="nombre" optionValue="idCatalogo" [(ngModel)]="fondo.idCatalogoTipoFondo"  emptyFilterMessage="No existen registros" emptyMessage="No existen registros" filter="true"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !fondo.idCatalogoTipoFondo">{{fundConstants.newFund.typeFund.require}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="typeFund">{{fundConstants.newFund.custodianUser.label}}</label>
                    <p-dropdown  [options]="listRolUser" optionLabel="nombreCompleto" optionValue="idUsuario" [(ngModel)]="fondo.idUsuarioCustodio"  emptyFilterMessage="No existen registros" emptyMessage="No existen registros" filter="true"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !fondo.idUsuarioCustodio">{{fundConstants.newFund.custodianUser.require}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="dateCreation">{{fundConstants.newFund.dateCreation.label}}</label>
                    <p-calendar [(ngModel)]="fondo.fechaCreacion" dateFormat="dd/mm/yy" [disabled]="true"></p-calendar>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !fondo.fechaCreacion">{{fundConstants.newFund.dateCreation.require}}</small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="ammountFund">{{fundConstants.newFund.ammountFund.label}}</label>
                    <p-inputNumber [(ngModel)]="fondo.valorFondo" inputid="valorFondo" mode="currency" currency="USD" locale="en-US"
                         min="0"></p-inputNumber>
                    <small class="ng-dirty ng-invalid"
                    *ngIf="submitted && !fondo.valorFondo">{{fundConstants.newFund.ammountFund.require}}</small>
                    <small class="ng-dirty ng-invalid"
                    *ngIf="submitted && fondo.valorFondo < 0" >{{fundConstants.newFund.ammountFund.minimo}} </small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="percentajeReposition">{{fundConstants.newFund.percentajeReposition.label}}</label>
                    <input type="number" min="1" max="100" pInputText id="percentajeReposition" required autofocus maxlength="100" [(ngModel)]="fondo.porcentajeReposicionFondo"/>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !fondo.porcentajeReposicionFondo">{{fundConstants.newFund.percentajeReposition.require}}</small>
                        <small class="ng-dirty ng-invalid"
                    *ngIf="submitted && (fondo.porcentajeReposicionFondo < 0 || fondo.porcentajeReposicionFondo > 100)" >{{fundConstants.newFund.percentajeReposition.minimo}} </small>
                </div>
                <div class="col-12 md:col-4">
                    <label htmlFor="amountApprove">{{fundConstants.newFund.amountApprove.label}}</label>
                    <p-inputNumber [(ngModel)]="fondo.montoAprobacion" inputid="montoAprobacion" mode="currency" currency="USD" locale="en-US"
                         min="1"></p-inputNumber>
                    <small>{{fundConstants.newFund.smallText}}</small><br>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !fondo.montoAprobacion">{{fundConstants.newFund.amountApprove.require}}</small>
                    <small class="ng-dirty ng-invalid"
                    *ngIf="submitted && fondo.montoAprobacion < 0">{{fundConstants.newFund.amountApprove.minimo}} </small>
                    <small class="ng-dirty ng-invalid"
                    *ngIf="submitted && fondo.montoAprobacion > fondo.valorFondo">{{fundConstants.newFund.amountApprove.valorFondo}} </small>          
                </div>
                <div class="field col-12 md:col-4">
                    <label htmlFor="state">{{fundConstants.newFund.status.label}}</label><br>
                    <p-toggleButton [(ngModel)]="state" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
                        [style]="{'width': '10em'}"></p-toggleButton>
                </div>

            </div>
        </div>
      
    </div>
</p-dialog>