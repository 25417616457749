import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders, HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { UrlServices } from './url.services';
import { RefreshTokenModel } from '../models/refreshModel';
import { AuthorizationService } from './auth.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    public static tokenTest = undefined;
    refreshTokenUserModel: RefreshTokenModel;
    idTimeOut: any;
    private unprotectedResources: string[] = [];
    private unprotectedExternoResources: string[] = [];
    urlLogin = 'login';

    constructor(
        public auth: AuthorizationService,
        private http: HttpClient,
        private urlServices: UrlServices,
        private router: Router) {
        this.refreshTokenUserModel = new RefreshTokenModel();

        this.unprotectedResources = [
            urlServices.urlLogin,
            'assets/demo/data/products.json',
            urlServices.urlConfirm,
            urlServices.urlReset,
            urlServices.urlGetToken,
            urlServices.urlInfoLogued,
            urlServices.urlRefreshToken,
            urlServices.urlEmpresasList
        ];

        this.unprotectedExternoResources = [
            'amazonaws.com'
        ];
    }

    /**
     * Valida la lista de ambientes de seguridad con los ambientes sin seguridad
     * @param req Peticion HTTP
     */
    getScopesForEndpoint(req) {
    
        const endpoint = req.url;
        // if user specified list of unprotectedResources, no need to send token to these endpoints, return null.
        if (this.unprotectedResources.length > 0) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.unprotectedResources.length; i++) {
                if (endpoint.includes(this.unprotectedResources[i]) /*&& req.method === 'POST'*/) {
                    return true;
                }
            }
        }
        return null;
    }

    /**
     * Valida la lista de ambientes de seguridad con los ambientes sin seguridad
     * @param req Peticion HTTP
     */
    getScopesExterno(req) {
        const endpoint = req.url;
        // if user specified list of unprotectedResources, no need to send token to these endpoints, return null.
        if (this.unprotectedExternoResources.length > 0) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.unprotectedExternoResources.length; i++) {
                if (endpoint.includes(this.unprotectedExternoResources[i]) /*&& req.method === 'POST'*/) {
                    return true;
                }
            }
        }
        return false;
    }



    /**
     * Intercepta las peticiones Http para agregar seguridad
     * @param req Request interceptado
     * @param next Objeto con siguiente acción a procesar
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const scopes = this.getScopesForEndpoint(req);
        const scopesExterno = this.getScopesExterno(req);
       
        if (!!this.auth.getCurrentUser() && scopes == null) {
            const token = this.auth.getCurrentUser().accessToken;
            const helper = new JwtHelperService();
            const isTokenExpired = helper.isTokenExpired(token);
            if (isTokenExpired === true) {                
                return from(this.handle401Error(req, next));
            } else {
                const tokenStored = { token: { idtoken: token } };
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${tokenStored.token.idtoken}`,
                    }
                });
                return next.handle(req);
            }
        } else if (!this.auth.getCurrentUser() && scopes == null) {
            this.router.navigate(['login']);
            return null;
        } else if (scopesExterno === true) {
            return next.handle(req);
        } else {
            const tokenBasic = { token: { idtoken: 'Basic ' + btoa(this.generateToken()) } };
            req = req.clone({
                setHeaders: {
                    Authorization: `${tokenBasic.token.idtoken}`,
                }
            });
            return next.handle(req);
        }
    }
    /**
     * Genera un token para gestionar token
     */
    private generateToken() {
        const miliseconds = Date.now();
        return atob(environment.authToken) + miliseconds.toString();
    }

    private async handle401Error(req: HttpRequest<any>, next: HttpHandler, ) {
        const newRequest = await this.refreshAccessToken();
        const refreshToken = this.auth.getCurrentUser().refreshToken;
        this.saveToken(newRequest, refreshToken);
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${newRequest.data.accessToken}`,
            }
        });
        return next.handle(req).toPromise();
    }
  
    private refreshAccessToken(): Promise<any> {
        const refreshToken = this.auth.getCurrentUser().refreshToken;

        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json');
        this.refreshTokenUserModel.refreshToken = refreshToken;

        return this.http.put(this.urlServices.urlRefreshToken, this.refreshTokenUserModel, {
            params: new HttpParams(),
            withCredentials: false,
            headers: headers,
            observe: 'body',
            reportProgress: false
        }).toPromise();
    }

    async sleep(delay) {
        await new Promise(resolve => setTimeout(resolve, delay));
    }
    /**
     * Obtiene los datoss de token
     * @param token Token obtenido
     * @param refreshToken Refresh token
     * @returns Devuelve los datos de token
     */
    saveToken(token, refreshToken) {
        if (token.code === 200) {
            const acceso = token;
            TokenInterceptor.tokenTest = acceso.data.idToken;
            // this.auth.clearUser();
            const userData = this.auth.getCurrentUser();
            userData.accessToken = acceso.data.accessToken;
            userData.refreshToken  = refreshToken;
            userData.idToken =  acceso.data.idToken;
            
            this.auth.setCurrentUser(userData);
        } else {
            this.auth.clearUser();
            this.router.navigate(['login']);
            // return null;
        }
    }
}
