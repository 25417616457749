<p-dialog [(visible)]="viewTonage" [modal]="true" [responsive]="true" [showHeader]="false"
[breakpoints]="{'960px': '90vw'}" [style]="{width: '85%', minHeight:'620px'}" [minY]="100" position="right"
[contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-4 px-3 pb-2">
                <div class="col-11 md:col-11">
                    <span style="color: white; font-size: 25px;"><b>{{fundConstants.tonnage.grandTitle}} de {{singleFondo?.nombreFondo}}  </b></span><br><br>
                    <span style="color: white;">{{fundConstants.tonnage.subtitle}}</span>
                </div>
                <div class="col-1 md:col-1" style="text-align: end;">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                    class="p-button-rounded p-button-outlined" (click)="closeTonage()"></button>
                </div>
            </div>
        </div>
        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid px-2">
                <div class="col-12 md:col-10">
                    <span class="title-screen">{{fundConstants.tonnage.title}}</span>
                </div>
                <div  class="col-12 md:col-2" style="text-align: right;">
                    <button *ngIf="visibleButtons && onlyArqueo" pButton pRipple icon="pi pi-plus" iconPos="left" class="new-button" label="{{fundConstants.tonnage.addButton}}"
                    (click)="openNewTonage()"></button>
                </div>
            </div>
            <p-blockUI [blocked]="blocked"></p-blockUI>
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12 md:col-4">
                    <div class="p-inputgroup">
                        <span class="p-float-label p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="text" id="search1" class="inputSearch" pInputText
                                placeholder="{{fundConstants.placehorders.applicantArqueo}}" [(ngModel)]="busqueda"
                                (keyup)="searchData($event)" />
                            <label for="search1" style="font-size: 13px !important;">{{fundConstants.search}}</label>
                        </span>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-calendar [(ngModel)]="fechaInicio" dateFormat="dd-mm-yy" placeholder="día-mes-año"
                                class="calendarData" (onSelect)="selectFechas()" (keyup)="selectFechas()"></p-calendar>
                            <label for="search1"
                                style="font-size: 13px !important;">{{fundConstants.placehorders.dateForm}}</label>
                        </span>
                    </div>
                    <small class="ng-dirty ng-invalid" *ngIf="selectFechaInit && !fechaInicio">El formato de fecha debe ser
                        día-mes-año Ejem. 25-12-2024</small>
                </div>
    
                <div class="field col-12 md:col-4">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-calendar [(ngModel)]="fechaFin" dateFormat="dd-mm-yy" placeholder="día-mes-año"
                                class="calendarData" (onSelect)="selectFechas()" (keyup)="selectFechas()"></p-calendar>
                            <label style="font-size: 13px !important;"
                                for="search1">{{fundConstants.placehorders.dateTo}}</label>
                        </span>
                    </div>
                    <small class="ng-dirty ng-invalid" *ngIf="selectFechaFin && !fechaFin">El formato de fecha debe ser
                        día-mes-año Ejem. 25-12-2024</small>
                </div>
            </div>
            
                <div class="px-2">
                    <p-table #dt [value]="listArqueos" responsiveLayout="stack" [rows]="properties.paginationRange"
                [globalFilterFields]="['nombre','valor','estado']"  styleClass="p-datatable-products" [paginator]="true"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedRequirement"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
                selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0"></h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="fecha">{{fundConstants.tonnage.dateCreation.label}}<p-sortIcon field="fecha">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="usuarioArqueo.nombre">{{fundConstants.tonnage.managementFor.label}}<p-sortIcon field="usuarioArqueo.nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="totalArqueo">{{fundConstants.tonnage.valueMoney.label}}<p-sortIcon field="totalArqueo"></p-sortIcon>
                        </th>
                        <th pSortableColumn="totalDocumentos">{{fundConstants.tonnage.valueDocuments.label}}<p-sortIcon field="totalDocumentos"></p-sortIcon>
                        </th>
                        <th pSortableColumn="observaciones">{{fundConstants.tonnage.remarks.label}}<p-sortIcon field="observaciones"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estado">Estado<p-sortIcon field="estado"></p-sortIcon>
                        </th>
            
                        <th>{{properties.accions}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-arqueo>
                    <tr> 
                        <td><span class="p-column-title">{{fundConstants.tonnage.dateCreation.label}}</span><span>{{arqueo.fecha | date: 'dd-MM-yyyy'}}</span></td>
                        <td><span class="p-column-title">{{fundConstants.tonnage.managementFor.label}}</span>{{arqueo.usuarioArqueo?.nombre}} {{arqueo.usuarioArqueo?.apellido}}</td>
                        <td><span class="p-column-title">{{fundConstants.tonnage.valueMoney.label}}</span>{{arqueo.totalArqueo}}</td>
                        <td><span class="p-column-title">{{fundConstants.tonnage.valueDocuments.label}}</span>{{arqueo.totalDocumentos}}</td>
                        <td><span class="p-column-title">{{fundConstants.tonnage.remarks.label}}</span>{{arqueo.observaciones}}</td>
                        <td><span class="p-column-title">Estado</span>
                            <span *ngIf="arqueo.estado == 'A'">Aprobado</span>
                            <span *ngIf="arqueo.estado == 'C'">Creado</span></td>

                        <td style="width:14%; min-width:10rem;">
                            <span class="p-column-title">{{properties.accions}}</span>
                            <div class="flex">
                                <button *ngIf="!visibleButtons && arqueo.estado == 'C'" pButton pRipple icon="pi pi-check"
                                class="p-button-rounded p-button-text button-view-action" title="Aprobar arqueo" (click)="aprobacionArqueo(arqueo)"
                                ></button>
                                <button *ngIf="!visibleButtons && arqueo.estado == 'C'" pButton pRipple icon="pi pi-times"
                                class="p-button-rounded p-button-text button-view-action" title="Rechazar arqueo" (click)="rechazoArqueoData(arqueo)"
                                ></button>
                                <button pButton pRipple icon="pi pi-eye"
                                class="p-button-rounded p-button-text button-view-action" title="Visualizar arqueo" (click)="editArqueo(isEdit = false, arqueo)"
                                ></button>
                                <!--  <button pButton pRipple icon="pi pi-pencil" *ngIf="visibleButtons"
                                class="p-button-rounded p-button-text button-view-action"
                                (click)="editArqueo(isEdit = true, arqueo)" title="Actualizar arqueo"
                                ></button>
                                <button pButton pRipple icon="pi pi-trash" *ngIf="visibleButtons"
                                class="p-button-rounded p-button-text button-view-action" (click)="eliminarArqueo(arqueo)"
                                ></button> -->
                                
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
                </div>
                
        </div>
    </div>
    <p-dialog [(visible)]="aprobarArqueo" header="{{properties.confirmation}}" [modal]="true"
        [style]="{width:'450px'}">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>¿Esta seguro de aprobar el arqueo?</span>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                (click)="aprobarArqueo = false"></button>
            <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                (click)="confirmAprobacion()"></button>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="rechazoArqueo" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'450px', height:'335px'}">
    <div class="flex align-items-center ">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de rechazar el arqueo ?</span>
    </div><br>
    <div class="align-items-center">
        <div class="field col-12 md:col-12">
            <label htmlFor="fundType">Seleccione el motivo del rechazo del arqueo</label>
            <p-dropdown id="newFor" [options]="listRejectRequest" optionLabel="label" optionValue="value"
                [(ngModel)]="arqueo.idCatalogoMotivoRechazo" emptyFilterMessage="No existen registros"
                emptyMessage="No existen registros" filter="true"
                placeholder="Seleccione un motivo"></p-dropdown><br>
            <small class="ng-dirty ng-invalid"
                *ngIf="submitted && !arqueo.idCatalogoMotivoRechazo">El motivo del rechazo es requerido</small>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="rechazoArqueo = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="rejectArqueo()"></button>
    </ng-template>
</p-dialog>

    <app-new-tonnage *ngIf="viewNewTonage" [viewNewTonage] = "viewNewTonage" (newTonageForm) = "closeNewTonage($event)" [arqueo] = "arqueo" [fondo] = "singleFondo" [isEdit] = "isEdit" [isSumary]="false"></app-new-tonnage>
</p-dialog>
