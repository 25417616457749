<p-dialog [(visible)]="newSupport" [modal]="true" [responsive]="true" [showHeader]="false"
    [breakpoints]="{'960px': '90vw'}" [style]="{width: '85%', height:'100%'}" [minY]="95" position="right"
    [contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
    transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
    <p-toast></p-toast>
    <p-blockUI [blocked]="blocked"></p-blockUI>

    <div class="grid mt-4">
        <div class="col-12">
            <div class="p-fluid p-formgrid grid px-3 py-2">
                <div class="col-12 md:col-8">
                    <span class="title-screen">{{supportConstants.newSupport.tittle}}</span>
                </div>
                <div class="col-0 md:col-2" *ngIf="isView">

                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple class="cancel-button" label="{{supportConstants.newSupport.cancelButton}}"
                        (click)="closeNewSupport()" *ngIf="!isView"></button>
                    <button pButton pRipple class="cancel-button" label="Cerrar" (click)="closeNewSupport()"
                        *ngIf="isView"></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple class="new-button" label="{{supportConstants.newSupport.saveButton}}"
                        *ngIf="!isView" (click)="saveMovimiento()"></button>
                </div>
            </div>
            <hr>
        </div>
        <div class="col-12">
            <div class="p-fluid p-formgrid grid m-3 p-2 card-border">
                <div *ngIf="!isView" class="field col-12 md:col-6">
                    <div class="card-border p-2 m-2">
                        <div class="p-2">
                            <p-inputSwitch [(ngModel)]="documentoElectronico"
                                (click)="isDocumentElectronico()" [disabled]="movimiento.idMovimiento"></p-inputSwitch>
                            <label class="label-bold"> {{supportConstants.newSupport.electronicDocument}}</label>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-6">
                    <div class="card-border p-2 m-2">
                        <div class="p-2">
                            <p-inputSwitch [(ngModel)]="movimiento.emitidoEmpresa" (click)="validarListaDocumentos()" [disabled]="movimiento.idMovimiento" ></p-inputSwitch>
                            <label class="label-bold"> {{supportConstants.newSupport.bussinessDocument}}</label>
                        </div>
                    </div>
                </div>
                <div class="field col-12" [ngClass]="
                { 'md:col-12': !isView && documentoElectronico == false,
                  'md:col-6': !isView && documentoElectronico == true,
                }" *ngIf="!isView">
                    <h4>{{supportConstants.newSupport.pdf}}</h4>
                    <p-fileUpload #fileImages [customUpload]="true" (uploadHandler)="onUploadImages($event)" (onRemove)="cleanFileImages()"
                        [multiple]="false" [auto]="true" maxFileSize="2000000" [chooseLabel]=""
                        invalidFileSizeMessageDetail="Tamaño máximo permitido 2MG"
                        invalidFileSizeMessageSummary="Error " accept="image/*,application/pdf" [cancelLabel]=""
                        [uploadLabel]="" chooseIcon="pi pi-cloud-upload" required>
                        <ng-template pTemplate="toolbar">
                            <div class="field col-12 md:col-12 pt-4 text-center text-sm">
                                Click aquí para cargar su archivo
                            </div>
                        </ng-template>
                    </p-fileUpload>
                    <small class="ng-dirty ng-invalid"
                    *ngIf="submitMovimiento && filesImages.length == 0 && !isValeComprobante">
                    {{supportConstants.newSupport.filesImages.required}}
                    </small>
                </div>
                <div class="field col-12 md:col-6" *ngIf="!isView && documentoElectronico == true">
                    <h4>{{supportConstants.newSupport.xml}}</h4>
                    <p-fileUpload #fileXml [customUpload]="true" (uploadHandler)="onUpload($event)" (onRemove)="cleanFile()"
                        [multiple]="false" [auto]="true" maxFileSize="2000000" [chooseLabel]=""
                        invalidFileSizeMessageDetail="Tamaño máximo permitido 2MG"
                        invalidFileSizeMessageSummary="Error " accept="text/xml" [cancelLabel]=""
                        [uploadLabel]="" chooseIcon="pi pi-cloud-upload" required>
                        <ng-template pTemplate="toolbar">
                            <div class="field col-12 md:col-12 pt-4 text-center text-sm">
                                Click aquí para cargar su archivo
                            </div>
                        </ng-template>
                    </p-fileUpload>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && files.length == 0 && !isValeComprobante">
                        {{supportConstants.newSupport.files.required}}
                        </small>
                </div>
                <div *ngIf="isView" class=" col-12 md:col-12">
                    <h4 style="color: #667085;">Archivos adjuntos</h4>
                </div>

                <div *ngIf="isView" class=" col-12 md:col-12">
                    <div *ngFor="let item of movimiento.documentos" class="p-fluid p-formgrid grid card-border p-2 my-2">
                        <div class=" col-10 md:col-9 ">
                            <label>{{item.nombreArchivo}}</label><br>
                            <p-progressBar value='100' styleClass="custom-progress-bar"
                                [showValue]="false"></p-progressBar>
                        </div>
                        <div class=" col-1 md:col-1 mt-4">
                            100%
                        </div>
                        <div class="col-12 md:col-2">
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-eye"
                                    class="p-button-rounded p-button-text button-view-action" (click)="viewDocument(item.ruta);"> &nbsp; &nbsp; Ver
                                    documento</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-12">
            <div class="p-fluid p-formgrid grid m-3 p-2 card-border">
                <div class="col-12 md:col-6">
                    <label
                        htmlFor="taxIdentificationNumber">{{supportConstants.newSupport.taxIdentificationNumber.label}}</label>
                    <input type="text" pInputText id="taxIdentificationNumber"
                        [(ngModel)]="movimiento.numeroIdentificacion" required maxlength="13"
                        [disabled]="documentoElectronico" #idenFiscal="ngModel" pattern="[0-9]*" (keyup)="validarDocumento()"/>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.numeroIdentificacion && !isValeComprobante">
                        {{supportConstants.newSupport.taxIdentificationNumber.required}}
                        </small>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="idenFiscal.errors?.pattern">
                        El número de identificación fiscal debe contener solo números.
                        </small>
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="socialReason">{{supportConstants.newSupport.socialReason.label}}</label>
                    <input type="text" pInputText id="socialReason" required [(ngModel)]="movimiento.razonSocial"
                        maxlength="100" [disabled]="documentoElectronico" />
                        <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.razonSocial && !isValeComprobante">
                        {{supportConstants.newSupport.socialReason.required}}
                        </small>
                </div>
                <div class="col-12 md:col-3">
                    <label htmlFor="typeDocument">{{supportConstants.newSupport.typeDocument.label}}</label>
                    <p-dropdown placeholder="Seleccione tipo de documento" [options]="listDocumentos" required
                    optionLabel="label" optionValue="value" 
                        [(ngModel)]="movimiento.idCatalogoTipoDocumento" [disabled]="isView" (onChange)="onChangeTipoDocumento()"></p-dropdown>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.idCatalogoTipoDocumento">
                        {{supportConstants.newSupport.typeDocument.required}}
                        </small>

                </div>
                <div class="col-12 md:col-3">
                    <label htmlFor="groupDocument">{{supportConstants.newSupport.groupDocument.label}}</label>
                    <p-dropdown placeholder="Seleccione un grupo de documento" [options]="listGrupos" required
                    optionLabel="label" optionValue="value" 
                        [(ngModel)]="movimiento.idCatalogoGrupoDocumento" [disabled]="isView"></p-dropdown>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.idCatalogoGrupoDocumento && !isValeComprobante">
                        {{supportConstants.newSupport.groupDocument.required}}
                        </small>
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="nameDocument">{{supportConstants.newSupport.nameDocument.label}}</label>
                    <div class="p-formgrid grid">
                        <div class="col-12 md:col-4">
                            <input type="text" pInputText id="nameDocument" required maxlength="3" placeholder="Estab."
                                [(ngModel)]="movimiento.establecimiento" [disabled]="documentoElectronico" pattern="[0-9]*" #establecimiento="ngModel" (keyup)="validarDocumento()"/>
                                <small class="ng-dirty ng-invalid"
                                *ngIf="submitMovimiento && !movimiento.establecimiento && !isValeComprobante">
                                {{supportConstants.newSupport.establecimiento.required}}
                                </small>
                                <small class="ng-dirty ng-invalid"
                                *ngIf="establecimiento.errors?.pattern">
                                El establecimiento debe contener solo números.
                                </small>          
                        </div>
                        <div class="col-12 md:col-4">
                            <input type="text" pInputText id="name" required maxlength="3" placeholder="Pto venta"
                                [(ngModel)]="movimiento.puntoVenta" [disabled]="documentoElectronico" pattern="[0-9]*" #ptoVenta="ngModel" (keyup)="validarDocumento()" />
                                <small class="ng-dirty ng-invalid"
                                *ngIf="submitMovimiento && !movimiento.puntoVenta && !isValeComprobante">
                                {{supportConstants.newSupport.puntoVenta.required}}
                                </small>
                                <small class="ng-dirty ng-invalid"
                                *ngIf="ptoVenta.errors?.pattern">
                                El punto de venta debe contener solo números.
                                </small>
                        </div>
                        <div class="col-12 md:col-4">
                            <input type="text" pInputText id="name" required maxlength="9" placeholder="Secuencial" 
                                [(ngModel)]="movimiento.secuencial" [disabled]="documentoElectronico" pattern="[0-9]*" #secuencial="ngModel" (keyup)="validarDocumento()" />
                                <small class="ng-dirty ng-invalid"
                                *ngIf="submitMovimiento && !movimiento.secuencial && !isValeComprobante" >
                                {{supportConstants.newSupport.secuencial.required}}
                                </small>
                                <small class="ng-dirty ng-invalid"
                                *ngIf="secuencial.errors?.pattern">
                                El secuencial debe contener solo números.
                                </small>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 md:col-9">
                    <label htmlFor="name">{{supportConstants.newSupport.nroAutorization.label}}</label>
                    <input type="text" pInputText id="name" required maxlength="49"
                        [(ngModel)]="movimiento.autorizacionTributaria" [disabled]="documentoElectronico" pattern="[0-9]*" #nroAutorizacion="ngModel"/>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.autorizacionTributaria && !isValeComprobante">
                        {{supportConstants.newSupport.nroAutorization.required}}
                        </small>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="nroAutorizacion.errors?.pattern">
                        El número de autorización debe contener solo números.
                        </small>
                </div>
                <div class="col-12 md:col-3">
                    <label htmlFor="name">{{supportConstants.newSupport.dateFactura.label}}</label>
                    <p-calendar [(ngModel)]="movimiento.fechaFactura" dateFormat="dd/mm/yy"
                        [disabled]="documentoElectronico"  [maxDate]="maxDate"></p-calendar>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.fechaFactura">
                        {{supportConstants.newSupport.dateFactura.required}}
                        </small>
                </div>
                <div class="col-12 md:col-2">
                    <label htmlFor="name">{{supportConstants.newSupport.baseIva.label}}</label>
                    <p-inputNumber [(ngModel)]="movimiento.baseiva0" min="0" inputId="movimientoBaseIva0" mode="currency" currency="USD" locale="en-US"
                    [disabled]="documentoElectronico" (input)="calcularValorTotal()" (keyup)="calcularValorTotal()" autocomplete="off">
                    ></p-inputNumber>
                </div>
                <div class="col-12 md:col-2">
                    <label htmlFor="name">{{supportConstants.newSupport.baseIva12.label}}</label>
                    <p-inputNumber [(ngModel)]="movimiento.baseiva" min="0" inputId="movimientoBaseIva" mode="currency" currency="USD" locale="en-US"
                    [disabled]="documentoElectronico" (input)="calcularValorTotal()" (keyup)="calcularValorTotal()" autocomplete="off"></p-inputNumber> 
                </div>
                <div class="col-12 md:col-2">
                    <label htmlFor="name">{{supportConstants.newSupport.ice.label}}</label>
                    <p-inputNumber [(ngModel)]="movimiento.ice" min="0" inputId="movimientoIce" mode="currency" currency="USD" locale="en-US"
                    [disabled]="documentoElectronico" (input)="calcularValorTotal()" (keyup)="calcularValorTotal()" autocomplete="off"></p-inputNumber> 
                </div>
                <div class="col-12 md:col-3">
                    <label htmlFor="name">{{supportConstants.newSupport.totalIva.label}}</label>
                    <p-inputNumber [disabled] = "documentoElectronico" [(ngModel)]="movimiento.totaliva" min="0" inputId="movimientototalIva" mode="currency" currency="USD" locale="en-US"
                    (keyup)="calcularValorTotal()" autocomplete="off"></p-inputNumber> 
                </div>
                <div class="col-12 md:col-3">
                    <label htmlFor="name">{{supportConstants.newSupport.totalBill.label}}</label>
                    <p-inputNumber [(ngModel)]="movimiento.valorTotal" min="0" inputId="movimientoValorTotal" mode="currency" currency="USD" locale="en-US"
                    [disabled]="true" (keyup)="validarCentros()" [disabled]="true" autocomplete="off"></p-inputNumber> 
                    <small class="ng-dirty ng-invalid" *ngIf="errorValorFactura">El valor de la factura es inferior al
                        total de las distribuciones.</small>
                        <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.valorTotal">
                        {{supportConstants.newSupport.totalBill.required}}
                        </small>
                </div>
                <div class="col-12 md:col-12">
                    <label htmlFor="name">Descripción</label>
                    <textarea [disabled]="isView" rows="5" cols="30" maxlength="500" pInputTextarea [(ngModel)]="movimiento.descripcionGasto" ></textarea>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitMovimiento && !movimiento.descripcionGasto && isValeComprobante">
                        Descripción es requerida
                        </small>
                </div>
                
            </div>
        </div>
        <div class="col-12 mb-5" >
            <div class="p-fluid p-formgrid grid p-3">
                <div class="col-12 md:col-10">
                    <span class="title-screen">{{supportConstants.newSupport.subtittle2}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button *ngIf="!isView" pButton pRipple icon="pi pi-plus"
                        class="comment-button" label="{{supportConstants.newSupport.addButton}}"
                        (click)="newDistribution()" [disabled]="!createDistribucion" ></button>
                </div>

                <div class="col-12 md:col-12">
                    <p-table #dt [value]="movimiento.centrosCosto" responsiveLayout="stack"
                        [rows]="properties.paginationRange" [globalFilterFields]="['nombre','valor','estado']"
                        styleClass="p-datatable-products" [rowsPerPageOptions]="[10,20,30]"
                        [showCurrentPageReport]="true" [loading]="blocked"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                        <ng-template pTemplate="caption">
                            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                <h5 class="m-0"></h5>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>

                                <th pSortableColumn="nombre">{{supportConstants.newSupport.centerCost.label}}<p-sortIcon
                                        field="nombre">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="abreviacion">
                                    {{supportConstants.newSupport.expenseAccount.label}}<p-sortIcon
                                        field="valor"></p-sortIcon>
                                </th>
                                <th pSortableColumn="estado">{{supportConstants.newSupport.baseIva.label}}<p-sortIcon
                                        field="estado">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="estado">{{supportConstants.newSupport.baseIva12.label}}<p-sortIcon
                                        field="estado">
                                    </p-sortIcon>
                                <th pSortableColumn="estado">{{supportConstants.newSupport.ice.label}}<p-sortIcon
                                        field="estado">
                                    </p-sortIcon>
                                <th pSortableColumn="estado">{{supportConstants.newSupport.totalIva.label}}<p-sortIcon
                                        field="estado">
                                    </p-sortIcon>
                                <th pSortableColumn="estado">{{supportConstants.newSupport.totalBill.label}}<p-sortIcon
                                        field="estado">
                                    </p-sortIcon>
                                </th>

                                <th>{{properties.accions}}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-support>
                            <tr>

                                <td><span
                                        class="p-column-title">{{supportConstants.newSupport.centerCost.label}}</span><span>{{
                                        support.centro?.nombreErp }}</span></td>
                                <td><span
                                        class="p-column-title">{{supportConstants.newSupport.expenseAccount.label}}</span>{{support.cuenta?.nombreErp}}
                                </td>
                                <td><span
                                        class="p-column-title">{{supportConstants.newSupport.baseIva.label}}</span>{{(support.baseiva0 ? support.baseiva0 : "0.00") | currency: 'USD' }}
                                </td>
                                <td><span
                                        class="p-column-title">{{supportConstants.newSupport.baseIva12.label}}</span>{{(support.baseiva ? support.baseiva : "0.00") | currency: 'USD'}}
                                </td>
                                <td><span
                                        class="p-column-title">{{supportConstants.newSupport.ice.label}}</span>{{(support.ice ? support.ice : "0.00") | currency: 'USD'}}
                                </td>
                                <td><span
                                        class="p-column-title">{{supportConstants.newSupport.totalIva.label}}</span>{{(support.totaliva ? support.totaliva : "0.00") | currency: 'USD'}}
                                </td>
                                <td><span
                                        class="p-column-title">{{supportConstants.newSupport.totalBill.label}}</span>{{(support.montoAsignado ? support.montoAsignado : 0.00) | currency: 'USD'}}
                                </td>

                                <td   style="width:14%; min-width:10rem;">
                                    <span class="p-column-title">{{properties.accions}}</span>
                                    <div class="flex">
                                        <button *ngIf="!isView" pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-text button-view-action"
                                            (click)="updateDistribucion(support)"></button>
                                        <button *ngIf="!isView" pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-text button-view-action"
                                            (click)="eliminarDistribucion(support)"></button>

                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr style="border: 1px solid black;">
                                <td colspan="2">Totales</td>
                                <td><b>{{(totalBaseIva0 ? totalBaseIva0 : "0.00") | currency: 'USD'}}</b></td>
                                <td><b>{{(totalBaseIva12 ? totalBaseIva12 : "0.00") | currency: 'USD'}}</b></td>
                                <td><b>{{(totalIce ? totalIce : "0.00") | currency: 'USD'}}</b></td>
                                    <td><b>{{(totalIva0 ? totalIva0 : "0.00" ) | currency: 'USD'}}</b></td>
                                <td><b>{{(totalFactura ? totalFactura : 0.00 )| currency: 'USD'}}</b></td>
                                <td></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>


        </div>
    </div>

</p-dialog>

<p-dialog [(visible)]="newCenterCost" [modal]="true" [responsive]="true" [showHeader]="false"
    [breakpoints]="{'960px': '90vw'}" [style]="{width: '70%', minHeight:'650px'}" [minY]="100" position="right"
    [contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
    transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-4 px-3 pb-2">
                <div class="col-11 md:col-11">
                    <span style="color: white; font-size: 30px;"><b>Gestión de distribución</b></span><br><br>
                </div>
                <div class="col-1 md:col-1" style="text-align: end;">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                        class="p-button-rounded p-button-outlined" (click)="closeNewCenterCost()"></button>
                </div>
            </div>
        </div>
        <p-toast></p-toast>

        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid mx-2">
                <div class="col-12 md:col-8">
                    <span class="title-screen"></span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-times" iconPos="left" class="cancel-button" label="Cancelar"
                        (click)="closeNewCenterCost()"></button>

                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-save" iconPos="left" class="new-button" label="Guardar"
                        (click)="guardarDistribucion(centroCosto)"></button>
                </div>
            </div>

        </div>
        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid mx-2">
                <div class="col-12 md:col-6">
                    <label
                        htmlFor="taxIdentificationNumber">{{supportConstants.newCenterCost.erpCentroCosto.label}}</label>
                    <p-dropdown placeholder="Seleccione un centro de costo" [options]="listCentoCosto"
                        optionLabel="nombre" optionValue="idParametroErp" [showClear]="true"
                        [(ngModel)]="centroCosto.idParametroErpCentroCosto" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitDistribucion && !centroCosto.idParametroErpCentroCosto">Centro de costo es
                        requerido</small>
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="socialReason">{{supportConstants.newCenterCost.erpCuentaGast.label}}</label>
                    <p-dropdown placeholder="Seleccione una cuenta" optionLabel="nombre" optionValue="idParametroErp"
                        [showClear]="true" [(ngModel)]="centroCosto.idParametroErpCuentaGasto"
                        [options]="listCuentaContable" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitDistribucion && !centroCosto.idParametroErpCuentaGasto">Cuenta gasto es
                        requerido</small>
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="nameDocument">{{supportConstants.newCenterCost.baseiva0.label}}</label>
                    <p-inputNumber [(ngModel)]="centroCosto.baseiva0" min="0" mode="currency" currency="USD" locale="en-US"
                    (keyup)="calcularValorDistribucion(centroCosto)" ></p-inputNumber>
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="name">{{supportConstants.newCenterCost.baseiva12.label}}</label>
                    <p-inputNumber [(ngModel)]="centroCosto.baseiva" min="0" mode="currency" currency="USD" locale="en-US"
                    (keyup)="calcularValorDistribucion(centroCosto)" ></p-inputNumber>
                    
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="name">{{supportConstants.newCenterCost.ice.label}}</label>
                    <p-inputNumber [(ngModel)]="centroCosto.ice" min="0"  mode="currency" currency="USD" locale="en-US"
                    (keyup)="calcularValorDistribucion(centroCosto)" ></p-inputNumber>
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="name">{{supportConstants.newCenterCost.totalIva.label}}</label>
                    <p-inputNumber [(ngModel)]="centroCosto.totaliva" min="0" mode="currency" currency="USD" locale="en-US"
                    (keyup)="calcularValorDistribucion(centroCosto)"></p-inputNumber>
                </div>
                <div class="col-12 md:col-6">
                    <label htmlFor="name">{{supportConstants.newCenterCost.montoAsignado.label}}</label>
                    <p-inputNumber [(ngModel)]="centroCosto.montoAsignado" min="0" mode="currency" currency="USD" locale="en-US"
                    ></p-inputNumber>
                    <small class="ng-dirty ng-invalid" *ngIf="submitDistribucion && !centroCosto.montoAsignado">Monto asignado es requerido</small>
                </div>
            </div>
        </div>

    </div>
</p-dialog>