<p-toast></p-toast>
<div class="p-3">
    <div class="p-fluid p-formgrid grid">
        <div class="field col-12 md:col-12">
            <div class="p-inputgroup">
                <span class="p-float-label p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" id="search1" class="inputSearch" pInputText placeholder="{{pendingsConstants.requirements.placehorders.applicant}}">
                    <label for="search1">{{pendingsConstants.requirements.search}}</label>
                </span>
            </div>
        </div>
    </div>


    <p-table #dt [value]="requirements" responsiveLayout="stack" [rows]="properties.paginationRange"
    [globalFilterFields]="['nombre','valor','estado']" [paginator]="true" styleClass="p-datatable-products"
    [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedRequirement"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
    selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
    <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0"></h5>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>

            <th pSortableColumn="nombre">{{pendingsConstants.requirements.applicant.label}}<p-sortIcon field="nombre">
                </p-sortIcon>
            </th>
            <th pSortableColumn="valor">{{pendingsConstants.requirements.numberSoli.label}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="abreviacion">{{pendingsConstants.requirements.amountApprove.label}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="valorIngles">{{pendingsConstants.requirements.createDate.label}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="estado">{{pendingsConstants.requirements.fundType.label}}<p-sortIcon field="estado">
                </p-sortIcon>
            </th>
            <th pSortableColumn="estado">{{pendingsConstants.requirements.aprobateFor.label}}<p-sortIcon field="estado">
            </p-sortIcon>
        </th>
            <th pSortableColumn="estado">{{pendingsConstants.requirements.description.label}} <p-sortIcon field="estado">
            </p-sortIcon>
            </th>
            <th pSortableColumn="estado">{{pendingsConstants.requirements.status.label}}<p-sortIcon field="estado">
            </p-sortIcon>
            </th>
            <th>{{properties.accions}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-requirement>
        <tr>
       
            <td> <span class="p-column-title">{{pendingsConstants.requirements.applicant.label}}</span>
                <div class="flex">
                    <div>
                        <img src="assets/demo/images/avatar/amyelsner.png"width="32" style="vertical-align: middle" />
                    </div>
                    <div class="ml-1">
                        <span><b>{{ requirement.solicitante }} </b></span><br>
                        <span>edtg17@hotmail.com</span>
                    </div>
                </div>
                
            </td>
            <td><span class="p-column-title">{{pendingsConstants.requirements.numberSoli.label}}</span>{{requirement.nroSolicitud}}</td>
            <td><span class="p-column-title">{{pendingsConstants.requirements.amountApprove.label}}</span>{{requirement.montoSolicitado}}</td>
            <td><span class="p-column-title">{{pendingsConstants.requirements.createDate.label}}</span>{{requirement.fechaCreacion}}</td>
            <td><span class="p-column-title">{{pendingsConstants.requirements.fundType.label}}</span>{{requirement.tipoFondo}}</td>
            <td><span class="p-column-title">{{pendingsConstants.requirements.aprobateFor.label}}</span>{{requirement.aprobadoPor}}</td>
            <td><span class="p-column-title">{{pendingsConstants.requirements.description.label}}</span>{{requirement.descripcion}}</td>
            <td>
                
                <span class="p-column-title">{{pendingsConstants.requirements.status.label}}</span>
                <button pButton pRipple
                    class=" p-button-text "
                    >{{requirement.estado}}</button>
                
             </td>
            <td style="width:14%; min-width:10rem;">
                <span class="p-column-title">{{properties.accions}}</span>
                <div class="flex">
                    <button pButton pRipple icon="pi pi-eye"
                            class="p-button-rounded p-button-text button-view-action"
                    ></button>
                    <button pButton pRipple icon="pi pi-comment"
                    class="p-button-rounded p-button-text button-view-action"
                    (click)="openComment()" ></button>
                    <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-text button-view-action"
                    ></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
</div>

<app-comments
[createComment] = "createComment"
(closeComment)="closeCommentForm($event)"> 
</app-comments>