import { AuditoriaModel } from "./auditoria.model";

export class PermisoModel extends AuditoriaModel {
    permisoLectura ?: boolean;
    permisoNuevo ?: boolean;
    permisoEdicion ?: boolean;
    constructor() {
        super();
        this.permisoLectura = true;
        this.permisoNuevo = false;
        this.permisoEdicion = false;
    }
}
