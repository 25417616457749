import { AuditoriaModel } from "./auditoria.model";

export class MenuModel extends AuditoriaModel {
    idMenu?: number;
    nombreMenu?: string;
    descripcion?: string;
    estado?: string
    url: string;
    icono: string;
    idMenuPadre: number;
    orden: number;
    visible: boolean;
    idEmpresa?: number

    constructor() {
        super();
        this.idMenu = null;
        this.nombreMenu = null;
        this.descripcion = null;
        this.url = null;
        this.icono = null;
        this.idMenuPadre = null;
        this.estado = null;
        this.orden = null;
        this.visible = true;
        this.idEmpresa = null
    }
}
