<p-dialog [(visible)]="isNewRequirement" [modal]="true" [responsive]="true" [showHeader]="false"
    [breakpoints]="{'960px': '90vw'}" [style]="{width: '85%', minHeight:'620px'}" [minY]="100" position="right"
    [contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
    transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
    <p-blockUI [blocked]="blocked"></p-blockUI>
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-3 px-3">

                <!-- titulos datos solicitud efectivo -->
                <div class="col-10 md:col-11" *ngIf="!isDataSoli && !isApproveCash">
                    <h2 style="color: white;">{{dashboardConstants.cashRequirement.title}} </h2>
                    <span style="color: white;">{{dashboardConstants.cashRequirement.subtitle}}</span>
                </div>

                <!-- Estado Solicitado - Desde Dashborad - campo isDataSoli -->
                <div class="col-10 md:col-11" *ngIf="isDataSoli">
                    <h2 style="color: white;">$ {{requirement.valorSolicitado | number: '1.2-2'}}</h2>
                    <span style="color: white;">{{dashboardConstants.cashRequirement.subtitleDataSoli}}</span>
                </div>
                <!-- titulos requerimientos por aprobar -->
                <div class="col-10 md:col-11" *ngIf="isApproveCash">
                    <h2 style="color: white;">Aprobaciones de efectivo</h2>
                    <span style="color: white;">{{dashboardConstants.cashRequirement.subtitleDataSoli}}</span>
                </div>

                <div class="col-2 md:col-1" style="text-align: end;">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                        class="p-button-rounded p-button-outlined" (click)="closeNewRequirement()"></button>
                </div>
            </div>
        </div>
        <p-toast></p-toast>
        <div class="col-12">
            <div class="p-fluid p-formgrid grid p-3" *ngIf="!isDataSoli && !isApproveCash">
                <div class="col-12 md:col-6">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.leftTitle}}</span>
                </div>
                <div class="col-0 md:col-2" style="text-align: right;" *ngIf="isView">

                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button *ngIf="requirement.idSolicitud" pButton pRipple icon="pi pi-comment" class="comment-button"
                        label="{{dashboardConstants.cashRequirement.commentButton}}" (click)="openComment()"></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-times" class="cancel-button" (click)="close()"
                        label="{{dashboardConstants.cashRequirement.cancelButton}}"></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;" *ngIf="!isView">
                    <button pButton pRipple icon="pi pi-send" class="new-button" (click)="crearSolicitud()"
                        label="{{dashboardConstants.cashRequirement.sendButton}}"></button>
                </div>

            </div>
            <!-- para datos de la soloicitud Botones para entregar valor o rechazar  -->
            <div class="p-fluid p-formgrid grid p-3" *ngIf="isDataSoli">
                <div class="col-12" [ngClass]="{ 
                'md:col-8': requirement.estado == properties.ESTADO_SOLI_ENTREGADA,
                'md:col-6': requirement.estado == properties.ESTADO_SOLI_POR_SOLICITADA}">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.leftTitle}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-comment" class="comment-button"
                        label="{{dashboardConstants.cashRequirement.commentButton}}" (click)="openComment()"></button>
                </div>
                <div *ngIf="requirement.estado == properties.ESTADO_SOLI_POR_SOLICITADA" class="col-12 md:col-2"
                    style="text-align: right;">
                    <button pButton pRipple icon="pi pi-times" class="cancel-button"
                        label="{{dashboardConstants.cashRequirement.rejectButton}}"
                        (click)="rejectRequirement(requirement)"></button>
                </div>
                <div *ngIf="requirement.estado == properties.ESTADO_SOLI_ENTREGADA" class="col-12 md:col-2"
                    style="text-align: right;">
                    <button pButton pRipple icon="pi pi-check" class="new-button"
                        label="{{dashboardConstants.cashRequirement.receiveValue}}"
                        (click)="receiveDocuments(requirement)"></button>
                </div>
                <div *ngIf="requirement.estado == properties.ESTADO_SOLI_POR_SOLICITADA" class="col-12 md:col-2"
                    style="text-align: right;">
                    <button pButton pRipple icon="pi pi-check" class="new-button"
                        label="{{dashboardConstants.cashRequirement.deliveredValue}}"
                        (click)="delivedRequirement(requirement)"></button>
                </div>

            </div>
            <!-- INIT Botones aprobacines -->
            <div class="p-fluid p-formgrid grid p-3" *ngIf="isApproveCash">
                <div class="col-12 md:col-6">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.leftTitle}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-comment" class="comment-button"
                        label="{{dashboardConstants.cashRequirement.commentButton}}" (click)="openComment()"></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-times" class="cancel-button"
                        label="{{dashboardConstants.cashRequirement.rejectRequirementButton}}"
                        (click)="rejectRequirement(requirement)"></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-check" class="new-button"
                        label="{{dashboardConstants.cashRequirement.approveRequirementButton}}"
                        (click)="approveRequirement(requirement)"></button>
                </div>

            </div>
            <!-- END Botones aprobacines -->
        </div>
        <div class="col-12 px-3 pt-2">
            <div class="p-fluid p-formgrid grid">
                <div class="col-12 md:col-3">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="nameApplicant" class="inputSearch" pInputText
                                [(ngModel)]="dataSolicitante.nombre" readonly>
                            <label
                                for="nameApplicant">{{dashboardConstants.cashRequirement.nameApplicant.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="nroIdentification" class="inputSearch" pInputText
                                [(ngModel)]="dataSolicitante.numeroDocumentoIdentidad" readonly>
                            <label
                                for="nroIdentification">{{dashboardConstants.cashRequirement.nroIdentification.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="search1" class="inputSearch" pInputText
                                [(ngModel)]="dataSolicitante.email" readonly>
                            <label for="search1">{{dashboardConstants.cashRequirement.email.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-calendar [(ngModel)]="requirement.fechaSolicitud" dateFormat="dd-mm-yy"
                                class="calendarData" [disabled]="true"></p-calendar>
                            <label for="search1">{{dashboardConstants.cashRequirement.dateSoli.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-calendar dateFormat="dd-mm-yy" class="calendarData"
                                [(ngModel)]="requirement.fechaEsperadaLiquidacion" [disabled]="true"></p-calendar>
                            <label for="search1">{{dashboardConstants.cashRequirement.dateLiquidation.label}}</label>
                        </span>
                    </div>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.fechaEsperadaLiquidacion">{{dashboardConstants.cashRequirement.dateLiquidation.required}}</small>
                </div>
            </div>
            <hr style="border-color: #E11F1D;" class="mx-3">

        </div>
        <div class="col-12 px-3 pt-2">
            <div class="p-fluid p-formgrid grid px-3">
                <div class="col-12 md:col-12">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.dateApplicnt}}</span>
                </div>
                <div class="field col-12 md:col-6" *ngIf="requirement.idSolicitud">
                    <label htmlFor="nroSolicitud">{{dashboardConstants.cashRequirement.nroSolicitud.label}}</label>
                    <input type="text" pInputText id="nroSolicitud" required autofocus maxlength="100"
                        [(ngModel)]="requirement.idSolicitud" readonly />
                </div>
                <div class="field col-12 md:col-6">
                    <label
                        htmlFor="valueRequeriment">{{dashboardConstants.cashRequirement.valueRequeriment.label}}</label>
                    <p-inputNumber [(ngModel)]="requirement.valorSolicitado" inputid="valueRequeriment" mode="currency"
                        currency="USD" locale="en-US" [disabled]="view" min="0"></p-inputNumber>
                    <small>Valor en dólares (USD)</small><br>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.valorSolicitado">{{dashboardConstants.cashRequirement.valueRequeriment.required}}</small><br>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && requirement.valorSolicitado < 0 ">{{dashboardConstants.cashRequirement.valueRequeriment.requiredMin}}</small>

                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="fundType">{{dashboardConstants.cashRequirement.fundType.label}}</label>
                    <p-dropdown id="newFor" [options]="listTypeRequest" optionLabel="label" optionValue="value"
                        [(ngModel)]="requirement.idCatalogoTipoSolicitud" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true" placeholder="Seleccione un tipo de Solicitud"
                        [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idCatalogoTipoSolicitud">{{dashboardConstants.cashRequirement.fundType.required}}</small>
                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="fundName">{{dashboardConstants.cashRequirement.fundName.label}}</label>
                    <p-dropdown id="newFor" [options]="fondos" optionLabel="label" optionValue="value"
                        [(ngModel)]="requirement.idFondo" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true" placeholder="Seleccione un fondo"
                        [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idFondo">{{dashboardConstants.cashRequirement.fundName.required}}</small>
                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="fundReason">{{dashboardConstants.cashRequirement.fundReason.label}}</label>
                    <p-dropdown id="newFor" [options]="listReasonRequest" optionLabel="label" optionValue="value"
                        [(ngModel)]="requirement.idCatalogoMotivoSolicitud" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true" placeholder="Seleccione un motivo"
                        [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idCatalogoMotivoSolicitud">{{dashboardConstants.cashRequirement.fundReason.required}}</small>
                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="aplicatArea">{{dashboardConstants.cashRequirement.aplicatArea.label}}</label>
                    <p-dropdown id="newFor" [options]="listSolicitantArea" optionLabel="nombreErp"
                        optionValue="idParametroErp" [(ngModel)]="requirement.idCatalogoAreaSolicitante"
                        emptyFilterMessage="No existen registros" emptyMessage="No existen registros" filter="true"
                        placeholder="Seleccione un área" [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idCatalogoAreaSolicitante">{{dashboardConstants.cashRequirement.aplicatArea.required}}</small>
                </div>
            </div>
            <hr style="border-color: #E11F1D;" class="mx-3">
        </div>
        <div class="col-12 px-3 pt-2">
            <div class="p-fluid p-formgrid grid px-3">
                <div class="col-12 md:col-12">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.description}}</span>
                </div>
                <div class="field col-12 md:col-12">
                    <label htmlFor="name">{{dashboardConstants.cashRequirement.descriptionSoli.label}}</label>
                    <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="requirement.observaciones"
                        [disabled]="view"></textarea>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="rechazoRequerimiento" header="{{properties.confirmation}}" [modal]="true"
        [style]="{width:'450px', minHeight:'469px'}">
        
        <div class="grid">
            <div class="p-fluid p-formgrid grid px-3">
                <div class="col-12 md:col-12">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                        <span>¿Está seguro de rechazar el requerimiento ?</span>
                    </div>

                </div>
                <div class="col-12 md:col-12">
                <label>Seleccione el motivo del rechazo del requerimiento</label><br><br>
                <p-dropdown id="newFor" [options]="listRejectRequest" optionLabel="label" optionValue="value"
                    [(ngModel)]="requirement.idCatalogoMotivoRechazo" emptyFilterMessage="No existen registros"
                    emptyMessage="No existen registros" filter="true"
                    placeholder="Seleccione un motivo"></p-dropdown><br>
                <small class="ng-dirty ng-invalid" *ngIf="submitted && !requirement.idCatalogoMotivoRechazo">El
                    motivo del rechazo es requerido</small>
                </div>
            </div>
           
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                (click)="rechazoRequerimiento = false"></button>
            <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                (click)="rejectReq()"></button>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="aprobarRequerimiento" header="{{properties.confirmation}}" [modal]="true"
        [style]="{width:'450px'}">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>¿Está seguro de aprobar el requerimiento ?</span>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                (click)="aprobarRequerimiento = false"></button>
            <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                (click)="approveReq()"></button>
        </ng-template>
    </p-dialog>
</p-dialog>
<p-dialog [(visible)]="envioRequerimiento" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de entregar el valor del requerimiento?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="envioRequerimiento = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="delivedReq()"></button>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="recibirDocumento" header="{{properties.confirmation}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de recibir los documentos de soporte del requerimiento?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="recibirDocumento = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="receiveReq()"></button>
    </ng-template>
</p-dialog>

<app-comments *ngIf="createComment" [createComment]="createComment" [singleSolicitud]="singleSolicitud"
    (closeComment)="closeCommentForm($event)">
</app-comments>