<p-toast></p-toast>
<div class="p-fluid p-formgrid grid">
    <div class="field col-12 md:col-4">
        <label htmlFor="name">{{securityConstants.rol.name.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="name" [(ngModel)]="rol.nombreRol" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !rol.nombreRol}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !rol.nombreRol">{{securityConstants.rol.name.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="description">{{securityConstants.rol.description.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="description" [(ngModel)]="rol.descripcion" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !rol.descripcion}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !rol.descripcion">{{securityConstants.rol.description.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="state">{{securityConstants.rol.status.field}}</label><br>
        <p-toggleButton [(ngModel)]="state" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div>
</div>