import { AuditoriaModel } from "../auditoria.model";
import { Properties } from 'src/app/utilities/properties';
const properties = new Properties();
export class FondoModel extends AuditoriaModel {
    idFondo : number;
    idUsuarioCustodio : number;
    idCatalogoTipoFondo : number;
    fechaCreacion : any;
    valorFondo : number;
    porcentajeReposicionFondo : number;
    montoAprobacion : number;
    estado : string;
    nombreFondo: string;
    idEmpresa?: number
    custodio?: {}

    constructor() {
        super();
        this.idFondo = null;
        this.idUsuarioCustodio = null;
        this.idCatalogoTipoFondo = properties.idCatalogoFondoCajaChica;
        this.fechaCreacion = new Date();
        this.valorFondo = null;
        this.porcentajeReposicionFondo = null;
        this.montoAprobacion = null;
        this.estado = null;
        this.nombreFondo = null;
        this.idEmpresa = null
        this.custodio = {}

    }
}
