import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import { AuthorizationService } from './common/auth.service';


@Component({
    
    selector: 'app-menu',
    template: `
    <ul class="layout-menu">
    <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
    </ul>

    
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppComponent, private auth: AuthorizationService) {}

    ngOnInit() {
        const usuarioLogin = this.auth.getCurrentUser();
       
        let menusVisibles = this.auth.getCurrentMenu() ? this.auth.getCurrentMenu() : []; 
        menusVisibles = menusVisibles.filter(men => men.visible || men.visible === null || men.visible === undefined);

        menusVisibles.forEach(menu => {
            menu.items = menu.items.filter(men => men.visible || men.visible === null || men.visible === undefined);
        });
        const menus = [{
            label: '',
            icon: '',
            items: this.auth.getCurrentMenu()
        }];

        this.model = menus;        
    }

}
