import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';

import { PermisoModel } from 'src/app/models/permiso.model';
import { Router } from '@angular/router';
import { FundConstants } from '../fund.constants';
import { ArqueoModel } from 'src/app/models/caja-chica/arqueo.model';
import { FondoModel } from 'src/app/models/caja-chica/fondo.model';
import { SecurityService } from 'src/app/service/securityservice';
import { MessageService } from 'primeng/api';
import { CatalogModel } from 'src/app/models/catalog.model';
import { Utils } from 'src/app/utilities/utils';

@Component({
  selector: 'app-tonnage',
  templateUrl: './tonnage.component.html',
})
export class TonnageComponent implements OnInit {

  public readonly fundConstants = FundConstants;
  properties: Properties = new Properties();
  permiso: PermisoModel;
  datosSolicitud :any = {};
  soportesLiquidacion:any[] = [];
  blockedRequirement :boolean = false;
  resumen : boolean = true;
  viewNewTonage : boolean = false;
  @Input() viewTonage : boolean;
  @Input() singleFondo : any;
  @Output() tonageForm = new EventEmitter<any>();
  arqueo: ArqueoModel = new ArqueoModel();
  blocked : boolean = false;
  listArqueos : ArqueoModel[] = [];
  isEdit : boolean = false;
  aprobarArqueo : boolean = false;
  @Input() visibleButtons:boolean
  listRejectRequest: CatalogModel[] = [];
  rechazoArqueo: boolean = false
  submitted : boolean = false
  @Input() onlyArqueo : boolean;
  busqueda: any = null;
  fechaInicio: any = null;
  fechaFin: any = null;
  selectFechaFin : boolean = false
  selectFechaInit : boolean = false

  constructor(private router : Router, 
    private securityService : SecurityService, 
    private messageService: MessageService,
    private util: Utils
    ) {

  }
  /**
   * Inicio de pantalla
   */
  async ngOnInit() {
    await this.getArqueo();
    this.listRejectRequest = await this.getCatalogList(
      this.properties.idCatalogoRechazoSolicitud
  );
  }

  closeTonage(){
    const event = {
      type : 'close'
    }
    this.tonageForm.emit(event);
  }

  openNewTonage(){
    this.arqueo = new ArqueoModel();
    this.viewNewTonage = true;
    this.isEdit = true;
  }

  closeNewTonage(event){
    if(event.type === 'close'){
      this.viewNewTonage = false;
    }
    if(event.type === 'reload'){
      this.viewNewTonage = false;
      this.getArqueo();
    }
  }

  async getArqueo(){
    this.blocked = true;
    await this.securityService.getArqueos(this.singleFondo.idFondo, this.busqueda, this.fechaInicio, this.fechaFin).then(resp => {
      this.blocked = false;
      if (resp.code === 200) {
        this.listArqueos = resp.data;
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blocked = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  editArqueo(isEdit, arqueo : ArqueoModel){
    this.arqueo = arqueo;
    if(this.arqueo?.documentos.length > 0){

      window.open(this.arqueo?.documentos[0].ruta, '_blank');
    }else{
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: "No existe documento de respaldo", life: this.properties.timeLifeMessages });
    } 
  }
  aprobacionArqueo(arqueo){
    this.aprobarArqueo = true;
    this.arqueo = arqueo
    let now = new Date()
    now.setHours(now.getHours() - 5)
    this.arqueo.fechaAceptacion = now
   
  }
  rechazoArqueoData(arqueo){
    this.submitted = true
    this.rechazoArqueo = true;
    this.arqueo = arqueo
    let now = new Date()
    now.setHours(now.getHours() - 5)
    this.arqueo.fechaRechazo = now
  }
  async selectFechas(){
    this.selectFechaInit = true
    this.selectFechaFin = true
    if(this.fechaInicio > this.fechaFin && this.fechaFin != null){
        this.messageService.add({
            severity: "info",
            summary: this.properties.info,
            detail: "La fecha hasta no puede ser inferior a la inicial",
            life: this.properties.timeLifeMessages,
        }); 
    }else{
        if(this.fechaFin != null &&  this.fechaInicio != null){
            this.selectFechaFin = true
            this.selectFechaInit = true
            const fechaInicio = this.fechaInicio
            const fechaFin = this.fechaFin
            this.fechaInicio = this.util.transformDate(this.fechaInicio)
            this.fechaFin = this.util.transformDate(this.fechaFin)
            await this.getArqueo()
            this.fechaInicio = new Date(fechaInicio)
            this.fechaFin = new Date(fechaFin) 
        }
        
    }
}
async searchData(value) {
  if(this.fechaInicio != null && this.fechaFin !=null){
      const fechaInicio = this.fechaInicio
          const fechaFin = this.fechaFin
          this.fechaInicio = this.util.transformDate(this.fechaInicio)
          this.fechaFin = this.util.transformDate(this.fechaFin)
          await this.getArqueo()
          this.fechaInicio = new Date(fechaInicio)
          this.fechaFin = new Date(fechaFin) 
  }else{
      await this.getArqueo();
  }
  
}
  confirmAprobacion(){
    this.arqueo.estado = this.properties.ESTADO_SOLI_APROBADO;
    this.securityService.updateArqueo(this.arqueo).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({severity: "success",summary: this.properties.successful, detail: "Arqueo aprobado correctamente",life: this.properties.timeLifeMessages,
      });
      this.aprobarArqueo = false;
      this.getArqueo();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blocked = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  rejectArqueo(){
    this.arqueo.estado = this.properties.ESTADO_ARQUEO_RECHAZADO;
    this.securityService.updateArqueo(this.arqueo).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({severity: "success",summary: this.properties.successful, detail: "Arqueo aprobado correctamente",life: this.properties.timeLifeMessages,
      });
      this.aprobarArqueo = false;
      this.getArqueo();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blocked = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  async getCatalogList(idCatalog: any) {
    let catalogList: any[] = [];
    await this.securityService
        .getCatalogsByFather(idCatalog)
        .then((resp) => {
            if (resp && resp.code === 200) {
                resp.data.forEach((catalog) => {
                    let catalogo = new CatalogModel();
                    catalogo = catalog;
                    let selectItem = {
                        label: catalogo.nombre,
                        value: catalogo.idCatalogo,
                    };
                    if (catalogo.estado === this.properties.stateActive) {
                        catalogList.push(selectItem);
                    }
                });
            } else if (resp.description) {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: resp.description,
                    life: this.properties.timeLifeMessages,
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: "Ocurrio un error al cargar los catalgos",
                    life: this.properties.timeLifeMessages,
                });
            }
        })
        .catch((err) => {
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: err.message,
                life: this.properties.timeLifeMessages,
            });
        });
    return catalogList;
}
}