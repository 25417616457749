export const SupportConstants = {
    supportSettlement : {
        nroSolicitud :  'Número de la solicitud',
        totalSolicitado : 'Total solicitado',
        totalSpend : 'Total gastado',
        differenceSettled : 'Diferencia por liquidar',
        title : 'Soportes de la liquidación',
        addButton : 'Nuevo soporte',
        settleRequest : 'Liquidar solicitud',
        reasonRequest : {
            label : 'Motivo de la solicitud',
            required : 'Motivo de la solicitud es requerida'
        },
        dateCreate : {
            label : 'Fecha de creación',
            required : 'Fecha de la creación es requrida'
        },
        fundType : {
            label : 'Tipo de fondo',
            required : 'Tipo de fondo es requerido'
        },
        description : {
            label : 'Descripción'
        },

        nroIdentification : 'Número de identificación fiscal',
        socialReason : 'Razón social',
        invoiceDate : 'Fecha factura',
        subtotal : 'Subtotal',
        iva : 'iva',
        total : 'Total'
    },

    newSupport : {
        tittle : 'Documento de soporte',
        cancelButton : 'Cancelar',
        saveButton : 'Guardar soporte',
        electronicDocument : 'Documento electrónico',
        bussinessDocument : 'Documento a nombre de la empresa',
        pdf : 'Pdf o imagen',
        xml : 'XML',
        subtitle : 'Archivos adjuntos',
        namePdf : 'Nombre del pdf',
        nameExel : 'Nombre Excel',
        subtittle2: 'Distribución contable',
        addButton : 'Nueva distribución',
        taxIdentificationNumber : {
            label :'Número de identificación fiscal',
            required : 'Número de identificación fiscal es requerido'
        },
        socialReason : {
            label : 'Razón social',
            required : 'Razón social es requerido'
        },
        typeDocument : {
            label : 'Tipo de documento',
            required : 'Tipo de documento es requerido'
        },
        groupDocument : {
            label : 'Grupo de documento',
            required : 'Grupo de documento es requrido'
        },
        nameDocument : {
            label : 'Número de documento'
        },
        secuencial : {
            required : 'Número secuencial es requerido'
        },
        establecimiento : {
            required : 'Número de establecimiento es requerido'
        },
        puntoVenta : {
            required : 'Número de punto de venta es requerido'
        },
        nroAutorization : {
            label : 'Número de autorización',
            required : 'Número de autorización es requerido'
        },
        baseIva : {
            label : 'Base IVA 0%',
            required : 'Base IVA 0% es requerido'
        },
        filesImages:{
            required:"Archivo pdf o imagen es requerido"
        },
        files:{
            required:"Archivo xml es requerido"
        },
        baseIva12 : {
            label : 'Base IVA',
            required : 'Base IVA 12% es requrido'
        },
        ice : {
            label : 'ICE',
            required : 'ICE es requerido'
        },
        totalIva : {
            label :'Total IVA',
            required :'Total IVA  es requerido'
        },
        totalBill : {
            label : 'Total factura',
            required : 'Total factura es requerido'
        },
        centerCost : {
            label : 'Centro de costo',
            required: 'Centro de costo es requerido'

        },
        descriptionCenterCost : {
            label : 'Descripción de Centro de Costo'
        },
        expenseAccount : {
            label : 'Cuenta Gasto'
        },
        descriptionExpenseAccount : {
            label : 'Descripción Cuenta Gasto'
        },
        dateFactura:{
            label: 'Fecha factura',
            required : 'Fecha factura es requerido'
        }
    },
    newCenterCost:{
        title: "Crear distribución",
        erpCentroCosto: {
            label: "Centro de costo",
            required: "Centro de costo requerido"
        },
        erpCuentaGast: {
            label: "Cuenta gasto",
            required: "Cuenta gasto requerida"
        },
        baseiva0:{
            label: "Base IVA 0%",
            required: "Base IVA 0 requerido"
        },
        baseiva12: {
            label: "Base IVA",
            required: "Base IVA requerido"
        },
        ice: {
            label: "ICE",
            required: "ICE requerido"
        },
        totalIva:{
            label: "Total IVA",
            required:"Total IVA requerido"
        },
        montoAsignado: {
            label:"Monto asignado",
            required: "Monto asignado requerido"
        }
        
    },
    approveReposition : {
        grandTitle : 'Aprobación de reposición',
        totalSolicitado : 'Total solicitado',
        valueReposition : 'Valor a reponer',
        totalFund : 'Total de fondo',
        title : 'Resumen de la liquidación',
        title2: 'Soportes de liquidación',
        showSummary : 'Mostrar resumen',
        hideSummary : 'Ocultar resumen',
        reasonRequest : {
            label : 'Motivo de la solicitud',
            required : 'Motivo de la solicitud es requerida'
        },
        dateCreate : {
            label : 'Fecha de creación',
            required : 'Fecha de la creación es requrida'
        },
        solicitudType : {
            label : 'Tipo de solicitud',
            required : 'Tipo de fondo es requerido'
        },
        description : {
            label : 'Descripción'
        },
        subtotal : 'Subtotal',
        iva : 'Iva',
        estado: 'Estado',
        codigo: 'Código SAP',
        total : 'Total',
        nroSolicitud :  {
            label : 'Número de solicitud'
        },
        groupDocument : {
            label : 'Grupo de documento'
        },
        typeDocument : {
            label : 'Tipo de documento'
        },
        fiscalNumberIdentification : {
            label : 'Número de Identificación Fiscal'
        },
        dateInvoice : {
            label : 'Fecha Factura'
        },
     
    },
}
