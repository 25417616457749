import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UserModel } from 'src/app/models/user.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { Utils } from 'src/app/utilities/utils';
import { SecurityConstants } from '../security.constants';
import { CatalogModel } from 'src/app/models/catalog.model';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/common/auth.service';
import { PermisoModel } from 'src/app/models/permiso.model';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  providers: [MessageService]
})
export class UsersFormComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  @Input() user: UserModel;
  @Input() branches: CatalogModel[] = [];
  submitted: boolean = false;
  state: boolean = false;
  @Output() userAccion = new EventEmitter<any>();
  isAssignRol: boolean = false;
  @Input() isEdit = false;
  stateMfa = false;
  imageSrc: string;
  permiso: PermisoModel;
  files = [];
  empresas = []
  private oldStateMfa = false;
  private firstLoad = true;
  nombreValido:boolean = true
  apellidoValido:boolean = true
  cedulaCorrecta = true
  listRolUser:any

  constructor(private messageService: MessageService, 
    private router: Router,
    private auth: AuthorizationService,
    private securityServices: SecurityService, private utils: Utils) {

  }
  async ngOnInit() {
    await this.getUsers()
    this.permiso = this.auth.validatePermision(this.router);
    if (this.user.idUsuario) {
      this.user.idEmpresa = this.user.userEmpresa?.empresa?.idEmpresa
      this.state = this.user.estado == this.properties.stateActive ? true : false;
      this.stateMfa =  this.user.estadoMfa === 'A';
      this.oldStateMfa = this.stateMfa;
    }
    this.getEmpresas()
    //this.getRolesByUser();
  }

  saveUser() { 
    try {
      this.submitted = true;
      if (this.user.nombre && this.user.apellido 
        && this.user.email && this.user.numeroDocumentoIdentidad
        && this.validateEmail() && this.validarTelefono(this.user.telefono) && 
        this.validarCedula() &&
        this.validateNombre() && this.validateApellido()) {
        this.user.estado = this.state ? this.properties.stateActive : this.properties.stateInactive;
        if (this.user.idUsuario) {
          this.updateUser();
        } else {
          this.user.idUsuarioActualizacion = this.user.email
          this.createUser();
        }
      }
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err, life: this.properties.timeLifeMessages });
    }
  }

  sendCodeUser() {
    this.securityServices.getEmailVerification(this.user.idUsuarioCognito).then(resp => {
      if (resp.code === 0) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: 'Mensaje enviado correctamente.', life: this.properties.timeLifeMessages });
      } else {  
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      console.log(err);
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err, life: this.properties.timeLifeMessages });
    });
  }

  createUser() {
    this.user.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.createUser(this.user).then(resp => {
      if (resp.code === 200) {
        this.goToUsers();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.user.messages.create, life: this.properties.timeLifeMessages });
      } else {
        if(resp.data){
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.data, life: this.properties.timeLifeMessages });
        }else{
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });

        }
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateUser() {
    this.user.telefono = this.user.telefono ? this.user.telefono : ' '
    this.securityServices.updateUser(this.user).then(resp => {
      if (resp.code === 200) {
        this.goToUsers();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.user.messages.update, life: this.properties.timeLifeMessages });
      } else {
        if(resp.data){
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.data, life: this.properties.timeLifeMessages });
        }else{
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });

        }
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  goToUsers() {
    setTimeout(() => {
      this.user = new UserModel();
      this.userAccion.emit();
    }, 2000);

  }


  cancelUser() {
    this.userAccion.emit();
  }

  validateEmail(): boolean{
    let isValid: boolean = true;
    if(!this.utils.esEmailValido(this.user.email)){
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.properties.invalidEmail, life: this.properties.timeLifeMessages });
      isValid = false
    }
    return isValid;
  }

  validateNombre(){
    const EMAIL_REGEX = /^[\p{L}\sáéíóúÁÉÍÓÚüÜ]+$/u;
  
        if (this.user.nombre != null && this.user.nombre.match(EMAIL_REGEX)) {
            this.nombreValido = true;
        }else{
          this.nombreValido = false;
        }

        return this.nombreValido
  }
  validateApellido(){
    const EMAIL_REGEX = /^[\p{L}\sáéíóúÁÉÍÓÚüÜ]+$/u;
        if (this.user.apellido != null && this.user.apellido.match(EMAIL_REGEX)) {
            this.apellidoValido = true;
        }else{
          this.apellidoValido = false;
        }
        return this.apellidoValido
  }

  onUpload(event) {
    this.firstLoad = false;
    if (event.files.length > 1) {
      event.files.shift();
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageSrc = e.target.result;
    }
    reader.readAsDataURL(event.files[0]);
  }

  validarTelefono(phone) {
    phone = phone ? phone : null
    let statusPhoneUser = false;
    if(phone != null && phone.length > 1){
      
      
      if (/^\+[1-9][0-9]{0,24}$/gm.test(phone)) {
        statusPhoneUser = true;
    }
    }else{
      statusPhoneUser = true;
    }
    
    
    return statusPhoneUser;
  }

  async getEmpresas() {
    
    
    await this.securityServices.getEmpresas().then(resp => {
      if (resp.code === 200) {
        this.empresas = resp.data;
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  validarCedula() {
    if(this.user.numeroDocumentoIdentidad != null){
    if(this.user.numeroDocumentoIdentidad == '9999999999'){
        return this.cedulaCorrecta
    }
    if (this.user.numeroDocumentoIdentidad.length == 10) {
        let coefValCedula = [2, 1, 2, 1, 2, 1, 2, 1, 2];
        let verificador = parseInt(this.user.numeroDocumentoIdentidad.substring(9, 10));
        let suma: number = 0;
        let digito: number = 0;
        for (let i = 0; i < this.user.numeroDocumentoIdentidad.length - 1; i++) {
            digito =
                parseInt(this.user.numeroDocumentoIdentidad.substring(i, i + 1)) * coefValCedula[i];
            suma +=
                parseInt((digito % 10) + "") +
                parseInt(digito / 10 + "");
        }
        suma = Math.round(suma);
        if (
            Math.round(suma % 10) == 0 &&
            Math.round(suma % 10) == verificador
        ) {
            this.cedulaCorrecta = true;
        } else if (10 - Math.round(suma % 10) == verificador) {
            this.cedulaCorrecta = true;
        } else {
            this.cedulaCorrecta = false;
        }

    } else {
        this.cedulaCorrecta = false;
    }
  }
    return this.cedulaCorrecta;
  
}

getRolesByUser() {
  this.securityServices.getUsersByRol(this.properties.idRolCustodio).then(resp => {         
      this.listRolUser = resp           
      for (const user of this.listRolUser) {
          user.nombreCompleto = user.usuario.nombre + ' ' + user.usuario.apellido
      }
      if (this.listRolUser.length === 0) {
        this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
      }
    
  }).catch(err => {
    this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
  });
}
async getUsers() {
  await this.securityServices.getUsers().then(resp => {
   
    if (resp.code === 200) {      
      this.listRolUser = resp.data;      
      if (this.listRolUser.length === 0) {
        this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
      }else{
      this.listRolUser = this.listRolUser.map(objeto =>{
        if(objeto.estado == 'A'){
          objeto.nombreCompleto = objeto.nombre + ' ' + objeto.apellido
          return objeto
        }
      })
      }
    } else {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
    }
  }).catch(err => {
    this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
  });
}
}
