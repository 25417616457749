<div class="layout-topbar shadow-4">
    <div class="layout-topbar-left" style="background-color: white;">
        <a class="layout-topbar-logo layout-topbar-logo-desktop" >
            <img id="app-logo" src="assets/layout/images/caja_chica_2.png" alt="ultima-layout" style="height: 10rem">
        </a>

        <a class="layout-topbar-logo-mobile">
            <img id="app-logo" src="assets/layout/images/caja_chica_1.png" alt="ultima-layout" style="height: 5rem">
        </a>
        
        <a class="layout-topbar-logo-mobile" (click)="appMain.onMenuButtonClick($event)"  pRipple style="margin-left: 70%; background-color: white !important; border:none !important; box-shadow: none !important;">
            <i class="pi pi-bars" style="font-size: 1.5rem; color: #000;"></i>
        </a>
       

        <!-- <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a> -->
    </div>

   
</div>
