<div class="pages-body accessdenied-page flex flex-column">
    <div class="topbar p-3 flex justify-content-between flex-row align-items-center">
        <div class="topbar-left ml-3 flex" routerLink="/">
            <div class="logo">
                <img id="app-logo" src="assets/layout/images/logo-moderna.png" alt="Moderna S.A" style="height: 5rem">
            </div>
        </div>
        <div class="topbar-right mr-3 flex">
            <button pButton pRipple type="button" label="{{securityConstants.login.inputA}}" (click)="logout()"
                    class="p-button-text p-button-plain topbar-button"></button>
        </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>{{securityConstants.notFound.accessNotAllowed}}</h2>
            </div>
            <div class="card mt-3 px-6">
                <img src="assets/layout/images/pages/error.png" alt=""/>
            </div>
            <div class="pages-detail pb-6">{{ securityConstants.notFound.resourceNotFound }}</div>
            <button pButton pRipple type="button" label="{{ securityConstants.notFound.back }}" (click)="logout()" class="p-button-text"></button>
        </div>
    </div>
</div>
