export const FundConstants = {
    grandTitle : 'Gestión de fondos',
    title : '',
    addButton : 'Nuevo fondo',
    search : 'Buscar',
    placehorders : {
        applicant : 'Puedes buscar por nombre o valor de fondo, nombre custodio',
        dateForm : 'Fecha desde',
        dateTo : 'Fecha hasta',
        applicantArqueo: 'Puedes buscar por usuario creación, valor documentos, saldo disponible'
    },
    fundName : {
        label : 'Nombre del fondo'
    },
    nameCustodian: {
        label : 'Nombre del custodio'
    },
    dateCreation :  {
        label : 'Fecha de creación'
    },
    percentageReposition : {
        label : 'Porcentaje reposición'
    },
    fundValue : {
        label : 'Valor de fondo'
    },
    amountApproved : {
        label : 'Monto aprobación'
    },
    accions : 'Acciones',

    tonnage : {
        grandTitle : 'Arqueo ',
        title : '',
        subtitle : 'Aquí puedes realizar el arqueo de este fondo.',
        addButton : 'Nuevo arqueo',
        dateCreation : {
            label : 'Fecha creación'
        },
        managementFor : {
            label : 'Creado por'
        },
        valueMoney : {
            label : 'Saldo disponible'
        },
        valueDocuments : {
            label : 'Valor en documentos'
        },
        remarks : {
            label : 'Observaciones'
        }
    },
    newTonnage : {
        grandTitle : 'Arqueo ',
        title : 'Acciones',
        subtitle : 'Aquí puedes realizar el arqueo de este fondo.',
        saveButton : 'Guardar arqueo',
        cancelButton : 'Cancelar arqueo',
        addDenomination : 'Ingresar denominación',
        viewDenomination : 'Visualizar denominaciones',
        smallText : 'Valor en dólares (USD)',
        fundName : {
            label : 'Nombre del fondo'
        },

        dateCreation : {
            label : 'Fecha creación',
            required : 'Fecha creación es requerida.'
        },
        managementFor : {
            label : 'Creado por',
            required : 'Creado por es requerido'

        },
        ammountFund : {
            label : 'Monto del fondo',
            required : 'Monto del fondo es requerido'
        },
        valueMoney : {
            label : 'Saldo disponible',
            required : 'Saldo disponible es requerido'
        },
        valueDocuments : {
            label : 'Valor en documentos',
            required : 'Valor en documentos es requerido'
        },
        valueEntregados : {
            label : 'Valor requerimientos entregados',
            required : 'Valor requerimientos entregados es requerido.'
        },
        valueLiquidadas : {
            label : 'Valor liquidaciones aprobadas',
            required : 'Valor liquidaciones aprobadas es requerido.'
        },
        porcentajeUso: {
            label : 'Porcentaje utilizado (%)',
            required : 'Porcentaje utilizado es requerido.'
        },
        valueTonnageMoney : {
            label : 'Valor arqueado en dinero',
            required : 'Valor arqueado en dinero es requerido'

        },
        valueTonnageDocuments : {
            label : 'Valor arqueado en documentos',
            required : 'Valor arqueado en documentos es requerido'
        },
        remarks : {
            label : 'Observaciones',
            subtitle : 'Observaciones de tu arqueo (Opcional)'
        },

        messages : {
            create : 'Arqueo creado correctamente',
            update : 'Arqueo actualizado correctamente',
            delete : 'Arqueo eliminado correctamente',
        }
    },
    updateFund:{
        grandTitle : 'Actualización de fondo',
        title : '',
        subtitle : 'Aquí puedes actualizar un fondo.',
    },
    newFund : {
        grandTitle : 'Nuevo fondo ',
        title : '',
        subtitle : 'Aquí puedes crear un fondo.',
        saveButton : 'Guardar fondo',
        cancelButton : 'Cancelar fondo',
        addDenomination : 'Ingresar denominación',
        smallText : 'Valor en dólares (USD)',
        typeFund : {
            label : 'Tipo de fondo',
            require: "Tipo de fondo requerido."

        },
        custodianUser : {
            label : 'Usuario custodio',
            require: "Usuario custodio requerido."

        },

        dateCreation : {
            label : 'Fecha creación',
            require: "Fecha creación requerida."

        },
        managementFor : {
            label : 'Creado por'
        },
        ammountFund : {
            label : 'Valor del fondo',
            require: "Valor de fondo requerido.",
            minimo: "El valor del fondo no puede ser menor que 1."
        },
        percentajeReposition : {
            label : 'Porcentaje reposición de fondo',
            require: "Porcentaje de reposición requerido.",
            minimo: "El porcentaje de reposición debe estar comprendido en el rango de 1 a 100."

        },
        amountApprove : {
            label : 'Monto aprobación',
            require: "Monto aprobación requerido",
            minimo: "El monto aprobación no puede ser menor que 1.",
            valorFondo :"El monto aprobación no puede ser mayor que el valor del fondo."

        },
        status : {
            label : 'Estado'
        },
        fundName : {
            label : 'Nombre del fondo',
            require: "Nombre del fondo requerido."

        },
        messages:{
            create:"Fondo creado correctamente",
            update:"Fondo actualizado correctamente.",
            delete:"Fondo eliminado correctamente.",
        }

    },
    denominacion:{
        messages: {
            update: "Denominación actualizada correctamente",
            delete: "Denominación eliminada correctamente",
            create: "Denominación creada correctamente"
        }
    }
}
