import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CatalogModel } from 'src/app/models/catalog.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { Table } from 'primeng/table';
import { PermisoModel } from 'src/app/models/permiso.model';
import { AuthorizationService } from 'src/app/common/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  providers: [MessageService],
})
export class CatalogListComponent implements OnInit, OnChanges {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  catalogDialog: boolean;
  deleteCatalogDialog: boolean = false;
  blockedCatalog: boolean = false;
  catalogs: CatalogModel[] = [];
  catalog = new CatalogModel();
  @Input()
  catalogPadre = new CatalogModel();
  selectedCatalogs: CatalogModel[];
  INpibusqueda: string;
  rowsPerPageOptions = [5, 10, 20];
  @Output() catalogAccion = new EventEmitter<any>();
  @ViewChild('dt') dt : Table; 
  @ViewChild('busqueda') busqueda : ElementRef; 
  permiso: PermisoModel;
  constructor(private messageService: MessageService, private securityServices: SecurityService, private auth: AuthorizationService,private router: Router) {
  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.permiso = this.auth.validatePermision(this.router);
    this.catalog = new CatalogModel();
    if (!this.catalogPadre) {
      this.getCatalogs();
    }
  }

  /**
   * Presenta ventana de confirmación de eliminación
   */
  confirmDelete() {
    this.deleteCatalogDialog = false;
    this.catalogs = this.catalogs.filter(val => val.idCatalogo !== this.catalog.idCatalogo);
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.securityConstants.catalog.messages.delete, life: this.properties.timeLifeMessages });
    this.catalog = new CatalogModel();
  }
  /**
   * Seecciona un catalogo para su eliminación
   * @param catalog Catalogo seleccionado
   */
  deleteCatalog(catalog: CatalogModel) {
    this.deleteCatalogDialog = true;
    this.catalog = { ...catalog };
  }
  /**
   * Selecciona un catálogo para su edición
   * @param catalog Catalogo seleccionado
   */
  editCatalog(catalog: CatalogModel) {
    const catalogEvent = {
      type: 'M',
      catalog: catalog
    };
    this.catalogAccion.emit(catalogEvent);
  }
  /**
   * Selecciona un catalogo para mostrar sus hijos
   * @param catalog Catalogo seleccionado
   */
  selectChildens(catalog: CatalogModel) {
    const catalogEvent = {
      type: 'H',
      catalog: catalog
    };
    this.catalogAccion.emit(catalogEvent);
    setTimeout(() => {
      this.dt.clear();
      this.busqueda.nativeElement.value = '';
      
    }, 100);
  }
  /**
   * Se encarga de consultar los catalgos hijos
   * @param catalog Catalogo seleccionado
   */
  getChilds(catalog: CatalogModel) {
    this.catalogs = [];
    this.catalogPadre = catalog;
    this.blockedCatalog = true;
    this.securityServices.getCatalogsByFather(catalog.idCatalogo).then(resp => {
      if (resp.code === 200) {
        this.catalogs = resp.data;
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
      this.blockedCatalog = false;
    }).catch(err => {
      this.blockedCatalog = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Actualiza los datos de un catalogo en el sistema
   * @param catalog Datos del catalogo
   */
  updateCatalog(catalog: CatalogModel) {
   
      catalog.estado = catalog.estado === this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive;
      this.securityServices.updateCatalog(catalog).then(resp => {
        if (resp.code === 200) {
          this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.catalog.messages.update, life: this.properties.timeLifeMessages });
        } else {
          catalog.estado = catalog.estado === this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive;
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
        }
      }).catch(err => {
        catalog.estado = catalog.estado === this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive;
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
      });
    
  }

  /**
   * Selecciona un catálogo en pantalla para edición
   * @param event Evento click
   */
  catalogsSelected(event: any) {
    const catalogEvent = {
      type: 'S',
      catalogs: this.selectedCatalogs
    };
    this.catalogAccion.emit(catalogEvent);
  }
  /**
   * Obtiene la lista de catalogos del sistema
   */
  getCatalogs() {
    this.catalogs = [];
    this.blockedCatalog = true;
    this.securityServices.getCatalogs().then(resp => {
      this.blockedCatalog = false;
      if (resp.code === 200) {
        this.catalogs = resp.data;
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blockedCatalog = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  /**
   * Devuelve el catalogo desde link breadcrums para obtener los hijos
   * @param catalogo Catalogo
   * @returns Devuelve el catalogo Padre
   */
  getCatalogoFromThree(catalogo) {

    if (!catalogo) {
      return undefined;
    }
    if (!catalogo.hijo) {
      return catalogo;
    }

    return this.getCatalogoFromThree(catalogo.catalogo);
  }

  /**
   * Detect all input changesS
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.catalogPadre) {
      if (!changes.catalogPadre.currentValue || !changes.catalogPadre.currentValue.idCatalogo) {
        this.getCatalogs();
      } else {
        const idCatalogo = changes.catalogPadre.currentValue.idCatalogo ? changes.catalogPadre.currentValue.idCatalogo : undefined;
        // if (idCatalogo !== this.catalogPadre.idCatalogo) {
          const catalogFilter = new CatalogModel();
          catalogFilter.idCatalogo = idCatalogo;
          if (catalogFilter.idCatalogo) {
            this.getChilds(catalogFilter);
          }
        // }
      }
    }
  }
}
