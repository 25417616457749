import { AuditoriaModel } from "../auditoria.model";

export class CentroCostoMovimientoModel extends AuditoriaModel {

    idCentroCostoAnexo : number
    idParametroErpCentroCosto : number
    idMovimiento : number
    idParametroErpCuentaGasto : number
    idEmpresa? : number
    baseiva0 : number
    baseiva : number
    ice : number
    totaliva : number
    montoAsignado : number
    estado : string
    
    constructor() {
        super();
        this.idParametroErpCentroCosto = null
        this.idCentroCostoAnexo = null
        this.idMovimiento = null
        this.idParametroErpCuentaGasto = null
        this.idEmpresa = null
        this.baseiva0 = null
        this.baseiva = null
        this.ice = null
        this.totaliva = null
        this.montoAsignado = null
        this.estado = null
    }
}
