//Esto es para QAs
/*
export const environment = {
  production: false,
  urlBackend: 'https://cajachicaqas.azurewebsites.net/api',
  admin: 'jgjerry01@gmail.com',
  authToken: 'QklDSVMtQklYMjAyM0RFU19Vc2VyOkJJQ0lTLUJJWDIwMjNERVNfUGFzc3dvcmQ6',
  urlFrontend:"https://kind-mud-0e24d8e0f.4.azurestaticapps.net",
  idClient: "MTA2YjMwNWYtZDY0Zi00Mjc1LTg3YjEtYmQ1Mzc5ZTQ0ZDgy",
  urlLogin:"https://login.microsoftonline.com/",
  tenanId: "ZDcwNjBiY2MtYmIyNi00NzA4LWE5MWQtODgzNjJhODZhZTM1",
  type:"prod",
  codeEnv: "cfIHC9m0gG_F4iGPVtFRoR5ml7RssqLHnYvnwTwUe1KGAzFu9fjF7w=="
};
*/
//Esto es para Local
/*
export const environment = {
  production: false,
  urlBackend: 'https://cajachicaqas.azurewebsites.net/api',
  admin: 'jgjerry01@gmail.com',
  authToken: 'QklDSVMtQklYMjAyM0RFU19Vc2VyOkJJQ0lTLUJJWDIwMjNERVNfUGFzc3dvcmQ6',
  urlFrontend:"http://localhost:4200/",
  idClient: "MTA2YjMwNWYtZDY0Zi00Mjc1LTg3YjEtYmQ1Mzc5ZTQ0ZDgy",
  urlLogin:"https://login.microsoftonline.com/",
  tenanId: "ZDcwNjBiY2MtYmIyNi00NzA4LWE5MWQtODgzNjJhODZhZTM1",
  type:"local",
  codeEnv: "cfIHC9m0gG_F4iGPVtFRoR5ml7RssqLHnYvnwTwUe1KGAzFu9fjF7w=="
};
*/
//Esto es para Produccion

export const environment = {
  production: false,
  urlBackend: 'https://cajachicaprd.azurewebsites.net/api',
  admin: 'jgjerry01@gmail.com',
  authToken: 'QklDSVMtQklYMjAyM0RFU19Vc2VyOkJJQ0lTLUJJWDIwMjNERVNfUGFzc3dvcmQ6',
  urlFrontend:"https://cajachica.modernaalimentos.com.ec",
  idClient: "MTA2YjMwNWYtZDY0Zi00Mjc1LTg3YjEtYmQ1Mzc5ZTQ0ZDgy",
  urlLogin:"https://login.microsoftonline.com/",
  tenanId: "ZDcwNjBiY2MtYmIyNi00NzA4LWE5MWQtODgzNjJhODZhZTM1",
  type:"prod",
  codeEnv: "9Dj2-WS3OkNGJBexHthKeAscdB9NzI4-ETkwi6BoevdhAzFuzOxVbg=="
};