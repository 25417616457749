import { AuditoriaModel } from "../auditoria.model";

export class ComentarioModel extends AuditoriaModel {

    idComentario : number;
    idUsuario : number;
    idSolicitud : number;
    idMovimiento : number;
    comentario : string;
    fechaComentario : any;
    estado : string;
    idEmpresa?: number

    constructor() {
        super();
        this.idComentario = null;
        this.idUsuario = null;
        this.idSolicitud = null;
        this.idMovimiento = null;
        this.comentario = null;
        this.fechaComentario = null;
        this.estado = null;
        this.idEmpresa = null
    }
}
