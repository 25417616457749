<p-dialog [(visible)]="viewSupportSettle" [modal]="true" [responsive]="true" [showHeader]="false"
    [breakpoints]="{'960px': '90vw'}" [style]="{width: '80%', minHeight:'620px'}" [minY]="100" position="right"
    [contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
    transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-5 px-3 pb-3 align-items-center">         
                <div class="col-12 md:col-2">
                    <div style="color: white; margin-top: 6px;">{{supportConstants.supportSettlement.nroSolicitud}}</div>
                    <div style="color: white; font-size: 25px; margin-top: 6px;"><b>#{{requirement.idSolicitud}} </b></div>
                </div>
                <div class="col-12 md:col-3">
                    <div style="color: white;margin-top: 6px;">{{requirement.solicitante?.email}}</div>
                    <div style="color: white; font-size: 25px; margin-top: 6px;" >{{requirement.solicitante?.nombre}} {{requirement.solicitante?.apellido}}</div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="search1" class="inputSearch" pInputText [(ngModel)]="requirement.valorSolicitado" style="font-size: 24px; font-weight: 600;" readonly>
                            <label for="search1">{{supportConstants.supportSettlement.totalSolicitado}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="search1" class="inputSearch" pInputText [(ngModel)]="requirement.valorGastado" style="font-size: 24px; font-weight: 600;" readonly>
                            <label for="search1">{{supportConstants.supportSettlement.totalSpend}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="search1" class="inputSearch" pInputText [(ngModel)]="diferenciaLiquidar" style="font-size: 24px; font-weight: 600;" readonly>
                            <label for="search1">{{supportConstants.supportSettlement.differenceSettled}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-2 md:col-1" style="text-align: end">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                    class="p-button-rounded p-button-outlined" (click)="closeViewSupportSettle()"></button>
                </div>

            </div>
        </div>
        <p-toast></p-toast>
        <div class="col-12 pt-5">
            <div class="p-fluid p-formgrid grid mx-3 card align-items-center" >
                <div class="col-12 md:col-2">
                    <label><b>{{supportConstants.supportSettlement.reasonRequest.label}}</b></label><br><br>
                    <span class="mt-1">{{requirement.motivoSolicitud?.nombre}}</span>
                </div>
                <div class="col-12 md:col-2">
                    <label><b>{{supportConstants.supportSettlement.dateCreate.label}}</b></label><br><br>
                    <span>{{requirement.fechaSolicitud | date: "dd-MM-yyyy"}}</span>
                </div>
                <div class="col-12 md:col-2">
                    <label><b>{{supportConstants.supportSettlement.fundType.label}}</b></label><br><br>
                    <span>{{requirement.tipoSolicitud?.nombre}}</span>
                </div>
                <div class="col-12 md:col-12" style="border-top: solid 1px #EAECF0; height: 0px;"> 
                </div>
    
                <div class="col-12 md:col-12">
                    <label><b>{{supportConstants.supportSettlement.description.label}}</b></label><br><br>
                    <span>{{requirement.observaciones}}</span>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="p-fluid p-formgrid grid p-3">
                <div class="col-12 md:col-8">
                    <span class="title-screen">{{supportConstants.supportSettlement.title}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button *ngIf="vizualizarSoportes == false" pButton pRipple icon="pi pi-plus" class="comment-button" label="{{supportConstants.supportSettlement.addButton}}" (click)="openNewSupport(isView = false, null)"
                    ></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button *ngIf="vizualizarSoportes == false" pButton pRipple  class="new-button" label="{{supportConstants.supportSettlement.settleRequest}}" [disabled]="isData"
                    (click)="confirmarLiquidacion()"></button>
                </div>
                <div class="col-12 md:col-12">
                    <p-table #dt [value]="movimientos" responsiveLayout="stack" [rows]="properties.paginationRange"
                [globalFilterFields]="['nombre','valor','estado']" [paginator]="true" styleClass="p-datatable-products"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedRequirement"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
                selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0"></h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="nombre">{{supportConstants.supportSettlement.nroIdentification}}<p-sortIcon field="nombre">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="valor">{{supportConstants.supportSettlement.socialReason}}<p-sortIcon field="valor"></p-sortIcon>
                        </th>
                        <th pSortableColumn="abreviacion">{{supportConstants.supportSettlement.invoiceDate}}<p-sortIcon field="valor"></p-sortIcon>
                        </th>
                        <th pSortableColumn="valorIngles">Base Iva 0<p-sortIcon field="valor"></p-sortIcon>
                        </th>
                        <th pSortableColumn="valorIngles">Base Iva<p-sortIcon field="valor"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estado">Iva<p-sortIcon field="estado">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="estado">Ice<p-sortIcon field="estado">
                        </p-sortIcon>
                    </th>
                        <th pSortableColumn="estado">{{supportConstants.supportSettlement.total}}<p-sortIcon field="estado">
                        </p-sortIcon>
                    </th>
            
                        <th>{{properties.accions}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-movimiento>
                    <tr>
                
                        <td><span class="p-column-title">{{supportConstants.supportSettlement.nroIdentification}}</span><span>{{ movimiento.numeroIdentificacion }}</span></td>
                        <td><span class="p-column-title">{{supportConstants.supportSettlement.socialReason}}</span>{{movimiento.razonSocial}}</td>
                        <td><span class="p-column-title">{{supportConstants.supportSettlement.invoiceDte}}</span>{{(movimiento.fechaFactura ?? 0)  | date:'dd-MM-yyyy'}}</td>
                        <td><span class="p-column-title">Base Iva 0</span>{{(movimiento.baseiva0 ?? 0) | currency: 'USD'}}</td>
                        <td><span class="p-column-title">Base Iva</span>{{(movimiento.baseiva ?? 0) | currency: 'USD'}}</td>
                        <td><span class="p-column-title">Iva</span>{{(movimiento.totaliva ?? 0) | currency: 'USD'}}</td>
                        <td><span class="p-column-title">Ice</span>{{(movimiento.ice ?? 0) | currency: 'USD'}}</td>
                        <td><span class="p-column-title">{{supportConstants.supportSettlement.total}}</span>{{(movimiento.valorTotal ?? 0) | currency: 'USD'}}</td>
                        <td style="width:14%; min-width:10rem;">
                            <span class="p-column-title">{{properties.accions}}</span>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-eye"
                                        class="p-button-rounded p-button-text button-view-action" (click)="openNewSupport(isView = true, movimiento)"
                                ></button>
                                <button pButton pRipple icon="pi pi-comment"
                                class="p-button-rounded p-button-text button-view-action"
                                (click)="openComment(movimiento)"></button>
                                <button *ngIf="vizualizarSoportes == false" pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-text button-view-action"
                               (click)="confirmDeleteSoporte(movimiento)" ></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                </p-table>
            </div>
                
         </div>
        </div>

       
    </div>
    <p-dialog [(visible)]="showLiquidar" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'450px'}">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>¿Esta seguro de liquidar esta solicitud?</span>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                (click)="showLiquidar = false"></button>
            <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                (click)="liquidarSolicitud()"></button>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="showEliminar" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'450px'}">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>¿Esta seguro de eliminar el soporte?</span>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                (click)="showLiquidar = false"></button>
            <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                (click)="updateMovimiento()"></button>
        </ng-template>
    </p-dialog>

</p-dialog>
<app-new-support *ngIf="newSupport" [isView] = "isView"
 [newSupport] = "newSupport"
 [requirement] = "requirement"
 [movimiento] = "movimiento"
 [diferenciaLiquidar]="diferenciaLiquidar"
 (supportForm) = "closeSupport($event)"
 ></app-new-support>
 
 <app-comments *ngIf="createComment" 
 [createComment]="createComment" 
 (closeComment)="closeCommentForm($event)" 
 [movimiento] = "movimiento">
</app-comments>