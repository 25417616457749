<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL}" [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme" (click)="updateBackendDate()">

    <app-topbar></app-topbar>
    
    <!--app-rightmenu></app-rightmenu-->

    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <app-inline-menu *ngIf="app.inlineMenuPosition=='top' || app.inlineMenuPosition=='both'" key="top"></app-inline-menu>
            <app-menu style="height: 80%;"></app-menu>
            <div style="height: 20%; position: fixed; bottom: 30px; left: 0; width: 100%; padding: 10px 20px; border-radius: 5px; text-decoration: none; ">
                <hr>
                <div class="flex">
                    <div class="ml-1" style="overflow: hidden;">
                        <span style="color: #101828"><b>{{usuario}}</b></span><br>
                        <span style="color: #667085; font-size:11px; overflow: hidden; text-overflow: ellipsis;">{{correo}}</span>
                    </div>
                    <div>
                        <button (click)="logout()" pButton pRipple icon="pi pi-sign-out" class="p-button-rounded p-button-text button-view-action" ></button>
                    </div>
                </div>
            </div>
            <app-inline-menu *ngIf="app.inlineMenuPosition=='bottom' || app.inlineMenuPosition=='both'" key="bottom"></app-inline-menu>
        </div>
    </div>
    
    <div class="layout-main">
        

        <div class="layout-content">
            <p-toast position="top-left" key="generalMessage"></p-toast>
            <block-ui>
                <router-outlet></router-outlet>
            </block-ui>
        </div>

    </div>

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>

</div>
