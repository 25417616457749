import { Component, OnInit } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';
import { PermisoModel } from 'src/app/models/permiso.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FundConstants } from './fund.constants';
import { SecurityService } from 'src/app/service/securityservice';
import { MessageService } from 'primeng/api';
import { AuthorizationService } from 'src/app/common/auth.service';
import { FondoModel } from 'src/app/models/caja-chica/fondo.model';
import { Utils } from "src/app/utilities/utils";
import { ArqueoModel } from 'src/app/models/caja-chica/arqueo.model';


@Component({
  selector: 'app-fund-management',
  templateUrl: './fund-management.component.html',
  providers: [MessageService],
})
export class FundManagementComponent implements OnInit {

  public readonly fundConstants = FundConstants;
  properties: Properties = new Properties();
  permiso: PermisoModel;
  requirement : any;
  datosSolicitud :any = {};
  soportesLiquidacion:any[] = [];
  fondos:FondoModel[] = [];
  blocked :boolean = false;
  resumen : boolean = true;
  action : any;
  visibleButtons : boolean = false;
  viewTonage : boolean = false;
  viewNewFund : boolean = false;
  fondo: FondoModel
  singleFondo : FondoModel = new FondoModel();
  deleteFondo: boolean = false
  titulo: string = undefined;
  onlyArqueo : boolean = false;
  subtitle : string = undefined;
  busqueda: any = null;
  fechaInicio: any = null;
  fechaFin: any = null;
  selectFechaFin : boolean = false
  selectFechaInit : boolean = false
  viewNewTonage : boolean = false;
  arqueo: ArqueoModel = new ArqueoModel();
  isSumary: boolean = false

  constructor(
    private router : Router, 
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private securityServices: SecurityService,
    private auth: AuthorizationService,
    private util: Utils) {

  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.busqueda = null
      this.fechaFin = null
      this.fechaInicio = null
      this.action = params['type'];
      this.changeValues();
      this.getFondos();
    });
  }
  changeValues(){
    switch (this.action) {
      case 'fund': 
        this.titulo = "Gestión de fondos"
        this.visibleButtons = true;
        this.onlyArqueo = false;
        this.subtitle = "Aquí se encuentra la lista de fondo(s)"
        break;

      case 'arqueo':
        this.titulo = "Mis arqueos"
        this.visibleButtons = false;
        this.onlyArqueo = true;
        this.subtitle = "Aquí se encuentra la lista del arqueo de tu(s) fondo(s)"

        break;

      case 'arqueoCaja':
        this.titulo = "Arqueo de caja"
        this.visibleButtons = true;
        this.onlyArqueo = true;
        this.subtitle = "Aquí se encuentra la lista del arqueo de fondo(s)"

        break;

      default :

        break;
    }
  }

  newSupport(){
    this.router.navigate(['principal/newSupport'])
  }
  goTonnage(fondo: FondoModel){
   this.singleFondo = fondo; 
   this.viewTonage = true;
  }
  closeTonage(event){
    if(event.type === 'close'){
      this.viewTonage = false;
    }
  }
  newFund(){
    this.viewNewFund = true;
    this.fondo = new FondoModel()
  }
  updateFund(fondo){
    this.viewNewFund = true;
    this.fondo = fondo
  }
  closeNewFund(event){
    if(event.type === 'close'){
      this.viewNewFund = false;
    }
    if(event.type === 'new'){
      this.viewNewFund = false;
      this.reloadFondos();
    }
  }
  async getFondos(){  
    let idCustodio = this.action == 'arqueo' ? this.auth.getCurrentUser().userData.idUsuario: undefined
    await this.securityServices.getFondo(null, idCustodio, null, this.properties.stateActive,null, this.busqueda, this.fechaInicio, this.fechaFin).then(resp => {
      this.blocked = false;
      if (resp.code === 200) {
        this.fondos = resp.data;  
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blocked = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  reloadFondos() {
    setTimeout(() => {
      this.getFondos();
    }, 1000);
  }
  async searchData(value) {
    if(this.fechaInicio != null && this.fechaFin !=null){
        const fechaInicio = this.fechaInicio
            const fechaFin = this.fechaFin
            this.fechaInicio = this.util.transformDate(this.fechaInicio)
            this.fechaFin = this.util.transformDate(this.fechaFin)
            await this.getFondos()
            this.fechaInicio = new Date(fechaInicio)
            this.fechaFin = new Date(fechaFin) 
    }else{
        await this.getFondos();
    }
    
  }
  confirmDelete(){
      this.fondo.estado = this.properties.stateInactive;
      this.securityServices
          .updateFondo(this.fondo)
          .then((resp) => {
              if (resp.code === 200) {
               this.deleteFondo = false;

                  this.getFondos();
                  this.messageService.add({
                      severity: "success",
                      summary: this.properties.successful,
                      detail: this.fundConstants.newFund.messages.delete,
                      life: this.properties.timeLifeMessages,
                  });
              } else {
                  this.messageService.add({
                      severity: "error",
                      summary: this.properties.error,
                      detail: resp.description,
                      life: this.properties.timeLifeMessages,
                  });
              }
          })
          .catch((err) => {
              this.messageService.add({
                  severity: "error",
                  summary: this.properties.error,
                  detail: err.message,
                  life: this.properties.timeLifeMessages,
              });
          });
  
  }
  eliminarFondo(fondo){
    this.deleteFondo = true;
    this.fondo = fondo
  }
  async selectFechas(){
    this.selectFechaInit = true
    this.selectFechaFin = true
    if(this.fechaInicio > this.fechaFin && this.fechaFin != null){
        this.messageService.add({
            severity: "info",
            summary: this.properties.info,
            detail: "La fecha hasta no puede ser inferior a la inicial",
            life: this.properties.timeLifeMessages,
        }); 
    }else{
        if(this.fechaFin != null &&  this.fechaInicio != null){
            this.selectFechaFin = true
            this.selectFechaInit = true
            const fechaInicio = this.fechaInicio
            const fechaFin = this.fechaFin
            this.fechaInicio = this.util.transformDate(this.fechaInicio)
            this.fechaFin = this.util.transformDate(this.fechaFin)
            await this.getFondos()
            this.fechaInicio = new Date(fechaInicio)
            this.fechaFin = new Date(fechaFin) 
        }
        
    }
}
  openResumen(fondo){
    this.singleFondo = fondo; 
    this.viewNewTonage = true;
    this.arqueo = new ArqueoModel();
    this.isSumary = true
  }
  closeNewTonage(event){
    this.viewNewTonage = false;
  }
}