<div class="grid">
    <div class="col-12">
        <p-toast></p-toast>
        <div class="card">
            
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple id="saveperfil" icon="{{properties.guardar.icono}}"
                        label="{{properties.guardar.valor}}" class="p-button-success mr-2"
                        (click)="saveUser()"></button>
                    
                </ng-template>
            </p-toolbar>
            <h5>{{securityConstants.perfil.title}}</h5>
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12 md:col-4">
                    <label htmlFor="name">{{securityConstants.user.name.field}} <span class="ng-invalid">*</span></label>
                    <input type="text" pInputText id="name" [(ngModel)]="user.nombres" required autofocus
                        [ngClass]="{'ng-invalid ng-dirty' : !user.nombres}" />
                    <small class="ng-dirty ng-invalid"
                        *ngIf="!user.nombres">{{securityConstants.user.name.requiered}}</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label htmlFor="lastName">{{securityConstants.user.lastName.field}} <span class="ng-invalid">*</span></label>
                    <input type="text" pInputText id="lastName" [(ngModel)]="user.apellidos" required autofocus
                        [ngClass]="{'ng-invalid ng-dirty' : !user.apellidos}" />
                    <small class="ng-dirty ng-invalid"
                        *ngIf="!user.apellidos">{{securityConstants.user.lastName.requiered}}</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label htmlFor="email">{{securityConstants.user.email.field}} <span class="ng-invalid">*</span></label>
                    <input type="email"  pInputText id="email" [(ngModel)]="user.email" disabled />
                </div>
                <div class="field col-12 md:col-4">
                    <label htmlFor="phone">{{securityConstants.user.phone.field}} <span class="ng-invalid">*</span></label>
                    <input type="text" pInputText id="phone" [(ngModel)]="user.telefono" required autofocus
                        [ngClass]="{'ng-invalid ng-dirty' : !user.telefono}" />
                    <small class="ng-dirty ng-invalid"
                        *ngIf="!user.telefono">{{securityConstants.user.phone.requiered}}</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label htmlFor="sucursal">{{securityConstants.user.branches.field}} <span class="ng-invalid">*</span></label>
                    <input type="text" pInputText id="sucursal" value="{{user.sucursal?.nombre}}" readonly />
                </div>
            </div>
        </div>
        <div class="card">
            <h5>{{securityConstants.user.multifactor.field}}</h5>
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12 md:col-4">
                    <div>
                        <div class="field-checkbox">
                            <p-inputSwitch [(ngModel)]="mfaStatus" [disabled]="imagenQR || activarCodigo" (click)="generateMfa()"></p-inputSwitch>
                            <label *ngIf="mfaStatus"> &nbsp;{{securityConstants.perfil.messages.activeMfa}}</label>
                            <label *ngIf="!mfaStatus"> &nbsp;{{securityConstants.perfil.messages.inactiveMfa}}</label>
                        </div>
                    </div>
                    <div class="text-center">
                        <img src="{{imagenQR}}" />
                    </div>
                   
                    <div style="margin-top: 10px;">
                        <label htmlFor="codigoOTP" *ngIf="imagenQR || activarCodigo" >
                            {{securityConstants.perfil.messages.mfaNew}}
                        </label>
                        <input  pInputText *ngIf="imagenQR || activarCodigo" id="codigoMfaId" autocomplete="off" type="text" maxlength="6" style="margin-top: 10px;"
                            name="codigoOTP" placeholder="MFA" [(ngModel)]="codigoMfa" (keypress)="commonService.onlyNumbers($event)">
                    </div>
                    <div  class="text-center m-4" style="margin-top: 10px;">
                        <button pButton pRipple type="button" *ngIf="imagenQR" (click)="validateMfa()" class="p-button-success mr-2" label="{{ securityConstants.perfil.messages.validate}}" >
                            
                        </button>
                        <button pButton pRipple type="button" *ngIf="activarCodigo"  (click)="inactivarMfa()" class="p-button-success mr-2" label="{{ securityConstants.perfil.messages.disable}}">
                            
                        </button>
                    </div>
                </div>
               
            </div>
        </div>



    </div>
</div>
