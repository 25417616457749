import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { SecurityConstants } from "../../security/security.constants";
import { Properties } from "src/app/utilities/properties";
import { DashboardConstants } from "../dashboard.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityService } from "src/app/service/securityservice";
import { AuthorizationService } from "src/app/common/auth.service";
import { SolicitudModel } from "src/app/models/caja-chica/solitud.model";
import { Utils } from "src/app/utilities/utils";
@Component({
    selector: "app-dashboard-list",
    templateUrl: "./dashboard-list.component.html",
    providers: [MessageService],
})
export class DashboardListComponent implements OnInit {
    public readonly dashboardConstants = DashboardConstants;
    properties: Properties = new Properties();
    requirements: any[] = [];
    blockedRequirement: boolean = false;
    @Output() requirementForm = new EventEmitter<any>();
    @Input() action: any;
    @Input() createRequeriment: any;
    createComment: boolean = false;
    blocked: boolean;
    viewSettleRequest: boolean = false;
    visibleButton: boolean = false;
    tittle: string = "";
    submitted: boolean = false;
    requirement: SolicitudModel;
    deleteRequerimiento = false;
    singleSolicitud: SolicitudModel = new SolicitudModel();
    busqueda: any = null;
    fechaInicio: any = null;
    fechaFin: any = null;
    recibirRequerimiento: boolean = false
    recibirEfectivoD: boolean = false
    selectFechaFin: boolean = false
    selectFechaInit: boolean = false
    selectedProduct!: any;
    viewSupportSettle: boolean = false
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private messageService: MessageService,
        private securityServices: SecurityService,
        private auth: AuthorizationService,
        private util: Utils
    ) { }
    /**
     * Inicio de pantalla
     */
    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.busqueda = null
            this.fechaFin = null
            this.fechaInicio = null
            this.action = params["type"];
            this.changeValues();
            this.getRequerimientos();


        });

    }

    changeValues() {
        switch (this.action) {
            case "requirements":
                this.tittle = "Mis requerimientos";
                this.visibleButton = true;
                this.viewSettleRequest = false;
                break;

            case "requirementsApprove":
                this.tittle = "Requerimientos por aprobar";
                this.visibleButton = false;
                this.viewSettleRequest = false;

                break;

            case "cashReplenishment":
                this.tittle = "Solicitudes de reposición";
                this.visibleButton = false;
                this.viewSettleRequest = false;
                break;

            default:
                this.tittle = "Solicitudes de fondos";
                this.visibleButton = false;
                this.viewSettleRequest = false;
                break;
        }
    }
    openViewRequirement(value, requirement, isView?: any) {
        const event = {
            type: "open",
            accion: value,
            requirement: requirement,
            isView: isView
        };
        this.requirementForm.emit(event);
    }
    openComment(solicitud) {
        this.createComment = true;
        this.singleSolicitud = solicitud;
    }
    closeCommentForm(event) {
        this.createComment = event.createComment;
    }
    async getRequerimientos() {
        this.blocked = true;
        let filterEstados = this.properties.ESTADO_SOLI_POR_SOLICITADA + "," + this.properties.ESTADO_SOLI_ENTREGADA;
        let idSolicitante =
            this.action == "requirements"
                ? this.auth.getCurrentUser().userData.idUsuario
                : undefined;

        let idCustodio =
            this.action == "requirementsApprove" || this.action == "cashReplenishment"
                ? this.auth.getCurrentUser().userData.idUsuario
                : undefined;
        let idTipoSolicitud = undefined;
        if (this.action == "requirements") {
            filterEstados = this.properties.ESTADO_SOLI_RECIBIDO + "," +
                this.properties.ESTADO_SOLI_POR_APROBAR + "," +
                this.properties.ESTADO_SOLI_REGISTRADA + "," +
                this.properties.ESTADO_SOLI_ANULADA + "," +
                this.properties.ESTADO_SOLI_APROBADO + "," +
                this.properties.ESTADO_SOLI_POR_SOLICITADA + "," +
                this.properties.ESTADO_SOLI_POR_APROBAR + "," +
                this.properties.ESTADO_SOLI_LIQUIDADA + "," +
                this.properties.ESTADO_SOLI_ANULADA + "," +
                this.properties.ESTADO_SOLI_APROBADO + "," +
                this.properties.ESTADO_SOLI_EN_PROCESO + "," +
                this.properties.ESTADO_SOLI_ENTREGADA + "," +
                this.properties.ESTADO_SOLI_POR_LIQUIDAR + "," +
                this.properties.ESTADO_SOLI_RECIBO_ENTREGADO + "," +
                this.properties.ESTADO_SOLI_ERROR + "," +
                this.properties.ESTADO_SOLI_PENDIENTE_RPA + "," +
                this.properties.ESTADO_SOLI_NO_APROBADO

            idTipoSolicitud = this.properties.idTipoSolicitudReembolso;
        }
        if (this.action == "cashReplenishment") {
            filterEstados = this.properties.ESTADO_SOLI_RECIBIDO + "," +
                this.properties.ESTADO_SOLI_POR_APROBAR + "," +
                this.properties.ESTADO_SOLI_REGISTRADA + "," +
                this.properties.ESTADO_SOLI_ANULADA + "," +
                this.properties.ESTADO_SOLI_APROBADO
            idTipoSolicitud = this.properties.idTipoSolicitudReposicion;
        }
        if (this.action == "requirementsApprove") {
            filterEstados = this.properties.ESTADO_SOLI_POR_APROBAR;
            idTipoSolicitud = this.properties.idTipoSolicitudReembolso;
        }
        let usuarioCustodio = undefined
        if (this.action == "dashboard") {

            usuarioCustodio = this.auth.getCurrentUser().userData.idUsuario
            idTipoSolicitud = this.properties.idTipoSolicitudReembolso;
            filterEstados = this.properties.ESTADO_SOLI_RECIBIDO + "," +
                this.properties.ESTADO_SOLI_POR_APROBAR + "," +
                this.properties.ESTADO_SOLI_REGISTRADA + "," +
                this.properties.ESTADO_SOLI_ANULADA + "," +
                this.properties.ESTADO_SOLI_APROBADO + "," +
                this.properties.ESTADO_SOLI_POR_SOLICITADA + "," +
                this.properties.ESTADO_SOLI_POR_APROBAR + "," +
                this.properties.ESTADO_SOLI_LIQUIDADA + "," +
                this.properties.ESTADO_SOLI_ANULADA + "," +
                this.properties.ESTADO_SOLI_APROBADO + "," +
                this.properties.ESTADO_SOLI_EN_PROCESO + "," +
                this.properties.ESTADO_SOLI_ENTREGADA + "," +
                this.properties.ESTADO_SOLI_POR_LIQUIDAR + "," +
                this.properties.ESTADO_SOLI_RECIBO_ENTREGADO + "," +
                this.properties.ESTADO_SOLI_ERROR + "," +
                this.properties.ESTADO_SOLI_PENDIENTE_RPA + "," +
                this.properties.ESTADO_SOLI_NO_APROBADO
        }
        console.log(filterEstados)
        this.securityServices
            .getSolicitudes(
                idSolicitante,
                filterEstados,
                idCustodio,
                idTipoSolicitud,
                this.busqueda,
                this.fechaInicio,
                this.fechaFin,
                usuarioCustodio
            )
            .then((resp) => {
                this.blocked = false;
                if (resp.code === 200) {
                    this.requirements = resp.data;

                    if (this.action == "requirementsApprove") {
                        this.requirements = this.requirements.filter((res) => res.aprobador[0].idUsuarioAprobador === this.auth.getCurrentUser().userData.idUsuario)
                    }
                    for (let i = 0; i < this.requirements.length; i++) {
                        let days = this.diferenciaEnDias(new Date(), this.requirements[i].fechaEsperadaLiquidacion)
                        this.requirements[i].countDays = days;
                    }
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    diferenciaEnDias(fecha1, fecha2) {
        const fechaInicio = new Date(fecha1);
        const fechaFin = new Date(fecha2);
        const diferenciaMs = fechaFin.getTime() - fechaInicio.getTime();
        const dias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24));

        return dias;
    }
    async updateSolicitud() {
        this.requirement.estado = "I";
        this.requirement.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityServices
            .updateSolicitud(this.requirement)
            .then((resp) => {
                if (resp.code === 200) {
                    this.getRequerimientos();
                    this.submitted = false;
                    this.deleteRequerimiento = false;
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: "Requerimiento eliminado correctamente",
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    deleteReq(requerimiento) {
        this.requirement = requerimiento;
        this.deleteRequerimiento = true;
    }

    async searchData(value) {
        if (this.fechaInicio != null && this.fechaFin != null) {
            const fechaInicio = this.fechaInicio
            const fechaFin = this.fechaFin
            this.fechaInicio = this.util.transformDate(this.fechaInicio)
            this.fechaFin = this.util.transformDate(this.fechaFin)
            await this.getRequerimientos()
            this.fechaInicio = new Date(fechaInicio)
            this.fechaFin = new Date(fechaFin)
        } else {
            await this.getRequerimientos();
        }

    }

    async selectFechas() {

        this.selectFechaInit = true
        this.selectFechaFin = true
        if (this.fechaInicio > this.fechaFin && this.fechaFin != null) {
            this.messageService.add({
                severity: "info",
                summary: this.properties.info,
                detail: "La fecha hasta no puede ser inferior a la inicial",
                life: this.properties.timeLifeMessages,
            });
        } else {
            if (this.fechaFin != null && this.fechaInicio != null) {
                this.selectFechaFin = true
                this.selectFechaInit = true
                const fechaInicio = this.fechaInicio
                const fechaFin = this.fechaFin
                this.fechaInicio = this.util.transformDate(this.fechaInicio)
                this.fechaFin = this.util.transformDate(this.fechaFin)
                await this.getRequerimientos()
                this.fechaInicio = new Date(fechaInicio)
                this.fechaFin = new Date(fechaFin)
            }

        }
    }

    confirmarRecibirSolicitud(requerimiento) {
        this.requirement = requerimiento;
        this.recibirRequerimiento = true;
    }

    confirmarRecibirEfectivo(requerimiento) {
        this.requirement = requerimiento;
        this.recibirEfectivoD = true;
    }

    recibirDineroEfectivo() {
        this.requirement.estado = "R";
        this.requirement.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityServices
            .updateSolicitud(this.requirement)
            .then((resp) => {
                if (resp.code === 200) {
                    this.getRequerimientos();
                    this.submitted = false;
                    this.recibirEfectivoD = false;
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: "Efectivo recibido correctamente",
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    recibirSolicitud() {
        this.requirement.estado = "R";
        this.requirement.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityServices
            .updateSolicitud(this.requirement)
            .then((resp) => {
                if (resp.code === 200) {
                    this.getRequerimientos();
                    this.submitted = false;
                    this.recibirRequerimiento = false;
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: "Requerimiento recibido correctamente",
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    onRowSelect(event: any) {
        let requirement = event.data
        if (
            this.action !== 'requirementsApprove' &&
            this.action !== 'requirements' &&
            (requirement.estado == this.properties.ESTADO_SOLI_POR_SOLICITADA || requirement.estado == this.properties.ESTADO_SOLI_ENTREGADA) &&
            this.action !== 'cashReplenishment'
        ) {
            this.openViewRequirement('datosSolicitante', event.data)
        }
        if (requirement.estado == this.properties.ESTADO_SOLI_RECIBO_ENTREGADO &&
            this.action !== 'requirementsApprove' &&
            this.action !== 'requirements' &&
            this.action !== 'cashReplenishment') {
            this.openViewRequirement('datosSolicitanteLiquidacion', requirement)
        }
        if (this.action === 'requirementsApprove' && requirement.estado == this.properties.ESTADO_SOLI_POR_APROBAR) {
            this.openViewRequirement('requirementsApprove', requirement)
        }
        if (this.action === 'cashReplenishment' && requirement.estado == this.properties.ESTADO_SOLI_POR_APROBAR) {
            this.openViewRequirement('reposicionCaja', requirement, false)
        }

    }

    onRowUnselect(event: any) {
        let requirement = event.data
        if (
            this.action !== 'requirementsApprove' &&
            this.action !== 'requirements' &&
            requirement.estado == 'S' &&
            this.action !== 'cashReplenishment'
        ) {
            this.openViewRequirement('datosSolicitante', event.data)
        }
    }
}
