import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';

import { PermisoModel } from 'src/app/models/permiso.model';
import { SupportConstants } from '../../support-settlement/support.constants';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/service/securityservice';
import { MessageService } from 'primeng/api';
import { DashboardConstants } from "../dashboard.constants";

@Component({
  selector: 'app-approve-reposition',
  templateUrl: './approve-reposition.component.html',
})
export class ApproveRepositionComponent implements OnInit {

  public readonly supportConstants = SupportConstants;
  public readonly dashboardConstants = DashboardConstants;

  properties: Properties = new Properties();
  deleteCelulaDialog: boolean = false;
  isNewRequirement: boolean;
  permiso: PermisoModel;
  @Input() requirement: any;
  blockedRequirement: boolean = false;
  resumen: boolean = true;
  @Input() isCashReplenishment: boolean;
  @Output() approveRepositionForm = new EventEmitter<any>();
  valorReponer: string;
  valorTotal: string;
  createComment: boolean = false
  movimientos: any[];
  sumMoviminetos: any[];
  totalValorReponer: number = 0;
  aprobar: boolean = false;
  rechazar: boolean = false;
  createCommentMovimiento: boolean = false;
  movimiento: any;
  solicitud: any;
  @Input() isView: boolean
  newSupport: boolean = false;
  isViewSupport: boolean = false;
  busqueda: string
  constructor(private router: Router, private securityService: SecurityService, private messageService: MessageService) {

  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.valorReponer = '$ ' + (this.totalValorReponer).toFixed(2);
    this.valorTotal = this.requirement.fondo.valorFondo ? '$ ' + this.requirement.fondo.valorFondo : '$ ';

    this.getMovimientos();
    this.getMovimientosGroup();
  }

  showResumen() {
    this.resumen = !this.resumen
  }
  closeApproveReposition() {
    const event = {
      type: "return",
    };
    this.approveRepositionForm.emit(event);
  }
  openComment(requirement, movimiento) {
    this.solicitud = requirement;
    this.createComment = true
    this.movimiento = movimiento;
  }

  closeCommentForm(event) {
    this.createComment = event.createComment
    this.movimiento = {}
  }

  async searchData(value) {
    const filterEstados = "I";
    this.securityService.getMovimientosIdSolicitudRepo(filterEstados, this.requirement.idSolicitud, this.busqueda).then((resp) => {
      if (resp.code === 200) {
        this.movimientos = resp.data;
        for (const movimiento of this.movimientos) {
          movimiento.estado = "I";
        }
      }
      else {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: resp.description,
          life: this.properties.timeLifeMessages,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: err.message,
          life: this.properties.timeLifeMessages,
        });
      });

  }
  getMovimientos() {
    const filterEstados = "I";

    this.securityService.getMovimientosIdSolicitudRepo(filterEstados, this.requirement.idSolicitud).then((resp) => {
      if (resp.code === 200) {
        this.movimientos = resp.data;
        
        for (const movimiento of this.movimientos) {
          movimiento.estado = "I";
        }
      }
      else {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: resp.description,
          life: this.properties.timeLifeMessages,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: err.message,
          life: this.properties.timeLifeMessages,
        });
      });
  }
  getMovimientosGroup() {
    const filterEstados = "I";
    this.securityService.getSumMovimientosSolicitudesRepo(filterEstados, this.requirement.idSolicitud).then((resp) => {
      if (resp.code === 200) {
        this.sumMoviminetos = resp.data;

      }
      else {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: resp.description,
          life: this.properties.timeLifeMessages,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: err.message,
          life: this.properties.timeLifeMessages,
        });
      });
  }

  async changeStatus(movimiento: any, variable: any) {
    if (variable === 'aceptar') {
      this.movimientos.forEach(movi => {
        if (movi.idSolicitud == movimiento.idSolicitud) {
          movi.estado = "A"
        }
      });
    }
    if (variable === 'rechazar') {
      this.movimientos.forEach(movi => {
        if (movi.idSolicitud == movimiento.idSolicitud) {
          movi.estado = "R"
        }
      });
    }
    if (variable === 'nulo') {
      this.movimientos.forEach(movi => {
        if (movi.idSolicitud == movimiento.idSolicitud) {
          movi.estado = "I"
        }
      });
    }
    this.reCalcular();

  }

  reCalcular() {
    this.totalValorReponer = 0
    this.movimientos.forEach(sumMovi => {
      if (sumMovi.estado === 'A') {
        this.totalValorReponer = this.totalValorReponer + Number(sumMovi.valorTotal);
      }
    });
    this.valorReponer = '$ ' + (this.totalValorReponer).toFixed(2);
  }

  aprobarReposicion() {
    let movimientos = {
      movimientos: this.movimientos
    }
    if (this.totalValorReponer > 0) {
      this.securityService.aprobarReposicion(movimientos).then((resp) => {
        if (resp.code === 200) {
          this.messageService.add({
            severity: "success",
            summary: this.properties.successful,
            detail: "Aprobación realizada con éxito",
            life: this.properties.timeLifeMessages,
          });
          const event = {
            type: "return",
          };
          this.approveRepositionForm.emit(event);
        }
        else {
          this.messageService.add({
            severity: "error",
            summary: this.properties.error,
            detail: resp.description,
            life: this.properties.timeLifeMessages,
          });
        }
      })
        .catch((err) => {
          this.messageService.add({
            severity: "error",
            summary: this.properties.error,
            detail: err.message,
            life: this.properties.timeLifeMessages,
          });
        });
    } else {
      this.messageService.add({
        severity: "error",
        summary: this.properties.error,
        detail: "El valor a reponer debe ser mayor a 0, apruebe al menos un soporte de liquidación.",
        life: this.properties.timeLifeMessages,
      });
    }

  }

  rechazarReposicion() {
    let bodyRequest = {
      idSolicitudPadre: this.requirement.idSolicitud
    }
    this.securityService.rechazarReposicion(bodyRequest).then((resp) => {
      if (resp.code === 200) {
        this.messageService.add({
          severity: "success",
          summary: this.properties.successful,
          detail: "Aprobación rechazada con éxito",
          life: this.properties.timeLifeMessages,
        });
        const event = {
          type: "return",
        };
        this.approveRepositionForm.emit(event);
      }
      else {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: resp.description,
          life: this.properties.timeLifeMessages,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: err.message,
          life: this.properties.timeLifeMessages,
        });
      });
  }
  dialogAprobar() {
    this.aprobar = true;

  }
  dialogoRechazar() {
    this.rechazar = true;
  }

  aprobarTodas() {
    for (const movimiento of this.movimientos) {
      movimiento.estado = 'A';
    }
    this.reCalcular();
  }
  rechazarTodas() {
    for (const movimiento of this.movimientos) {
      movimiento.estado = 'R';
    }
    this.reCalcular();
  }
  viewSupport(movimiento) {
    this.movimiento = movimiento;
    this.newSupport = true;
    this.isViewSupport = true;
  }
  editSupport(movimiento) {
    this.movimiento = movimiento;
    this.newSupport = true;
    this.isViewSupport = false;

  }
  closeSupport(event) {
    if (event.type === "cancel") {
      this.newSupport = false;
    }
    if (event.type === "reload") {
      this.newSupport = false;
      this.getMovimientos()
    }
  }
}