<p-dialog [(visible)]="viewNewapproverConfiguration" [modal]="true" [responsive]="true" [showHeader]="false"
    [breakpoints]="{'960px': '90vw'}" [style]="{width: '65%', minHeight:'580px'}" [minY]="100" position="right"
    [contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
    transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
    <p-toast></p-toast>
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-4 px-3 pb-2">
                <div class="col-11 md:col-11">
                    <span style="color: white; font-size: 25px;"><b>{{approverConstants.newApprove.title}}
                        </b></span><br><br>
                    <span style="color: white;">{{approverConstants.newApprove.subtitle}}</span>

                </div>
                <div class="col-1 md:col-1" style="text-align: end;">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                        class="p-button-rounded p-button-outlined" (click)="closeNewConfiguration()"></button>
                </div>
            </div>
        </div>

        <div class="col-12 p-3">
            <div class="p-fluid p-formgrid grid mx-2">
                <div class="col-12 md:col-8">
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-times" iconPos="left" class="cancel-button" label="Cancelar"
                        (click)="closeNewConfiguration()"></button>

                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-save" (click)="validate()" iconPos="left" class="new-button"
                        label="Guardar"></button>
                </div>
            </div>
            <div class="col-12">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12 md:col-12 flex">
                        <div>
                            <label htmlFor="aprobadorFinal">{{approverConstants.aprobadorFinal.label}}
                                &nbsp;&nbsp;</label>
                        </div>
                        <div>
                            <p-inputSwitch [(ngModel)]="aprobacion.aprobadorFinal" (click)="isAprobadorFinal()" ></p-inputSwitch>

                        </div>
                    </div>
                    <br>

                    <div class="field col-12 md:col-4">
                        <label htmlFor="users">{{approverConstants.usuarioAprobador.label}}</label>
                        <p-dropdown id="users" [options]="users" optionLabel="nombreApellido" optionValue="idUsuario"
                            [(ngModel)]="aprobacion.idUsuarioAprobador" emptyFilterMessage="No existen registros"
                            emptyMessage="No existen registros" filter="true"></p-dropdown>
                        <small class="ng-dirty ng-invalid"
                            *ngIf="submitted && !aprobacion.idUsuarioAprobador">{{approverConstants.usuarioAprobador.required}}</small>
                    </div>
                    <div class="field col-12 md:col-4" *ngIf="!aprobacion.aprobadorFinal">
                        <label htmlFor="users">{{approverConstants.aprobadorSuperior.label}}</label>
                        <p-dropdown id="users" [options]="users" optionLabel="nombreApellido" optionValue="idUsuario"
                            [(ngModel)]="aprobacion.idConfiguracionAprobadorSuperior"
                            emptyFilterMessage="No existen registros" emptyMessage="No existen registros"
                            filter="true"></p-dropdown>
                        <!-- <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !aprobacion.idConfiguracionAprobadorSuperior">{{approverConstants.aprobadorSuperior.required}}</small> -->
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="users">{{approverConstants.tipoAprobacion.label}}</label>
                        <p-dropdown id="users" [options]="listTipoAprobacion" optionLabel="label" optionValue="value"
                            [(ngModel)]="aprobacion.idCatalogoTipoAprobacion" emptyFilterMessage="No existen registros"
                            emptyMessage="No existen registros" filter="true"></p-dropdown>
                        <small class="ng-dirty ng-invalid"
                            *ngIf="submitted && !aprobacion.idCatalogoTipoAprobacion">{{approverConstants.tipoAprobacion.required}}</small>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="users">{{approverConstants.tipoFondo.label}}</label>
                        <p-dropdown id="users" [options]="listTipoFondo" optionLabel="label" optionValue="value"
                            [(ngModel)]="aprobacion.idCatalogoTipoFondo" emptyFilterMessage="No existen registros"
                            emptyMessage="No existen registros" filter="true"></p-dropdown>
                        <small class="ng-dirty ng-invalid"
                            *ngIf="submitted && !aprobacion.idCatalogoTipoFondo">{{approverConstants.tipoFondo.required}}</small>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="ammountFund">{{approverConstants.nivel.label}}</label>
                        <input type="number" min="1" pInputText id="nivel" [(ngModel)]="aprobacion.nivel" />
                        <small class="ng-dirty ng-invalid"
                            *ngIf="submitted && !aprobacion.nivel">{{approverConstants.nivel.required}}</small>
                    </div>
                    <div class="field col-12 md:col-4">
                        <label htmlFor="fundName">{{approverConstants.fondo.label}}</label>
                        <p-dropdown id="newFor" [options]="fondos" optionLabel="label" optionValue="value"
                            [(ngModel)]="aprobacion.idFondo" emptyFilterMessage="No existen registros"
                            emptyMessage="No existen registros" filter="true"
                            placeholder="Seleccione un fondo"></p-dropdown>
                        <small class="ng-dirty ng-invalid"
                            *ngIf="submitted && !aprobacion.idFondo">{{approverConstants.fondo.required}}</small>
                    </div>
                    <div class="col-12 md:col-12">
                        <label htmlFor="ammountFund">{{approverConstants.descripcion.label}}</label>
                        <textarea type="text" pInputText id="descripcion" rows="5"
                            [(ngModel)]="aprobacion.descripcion"></textarea>
                    </div>
                </div>
            </div>
        </div>


    </div>
</p-dialog>