<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3 pb-3">
            <div class="col-12 md:col-12">
                <h2 style="color: white;">{{approverConstants.grandTitle}}</h2>
                <span style="color: white;">Aquí se encuentra la lista de configuraciones para aprobadores de fondo(s)</span>
            </div>
        </div>
    </div>
    <p-toast></p-toast>

    <div class="col-12 p-3">
        <div class="p-fluid p-formgrid grid pt-2 px-2">
            <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                    <span class="p-float-label p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input type="text" id="search1" class="inputSearch" pInputText
                            placeholder="Puedes buscar por nombre o apellido del aprobador."[(ngModel)]="aprobador" (keyup)="searchData()" />
                        <label for="search1" style="font-size: 13px !important;">Nombre del aprobador</label>
                    </span>
                </div>
            </div>
            <div class="col-12 md:col-4">   
                <span class="p-float-label" style="background-color: white; border-radius: 15px;">    
                    <p-dropdown class="custom-dropdown" filter="true" emptyFilterMessage="No existen registros"
                     [showClear]="true" placeholder="Puedes buscar por el fondo." [options]="fondos" optionLabel="label" optionValue="value" inputId="float-label"
                      [style]="{'height': '4.25rem', 'background-color': 'white', 'border-radius': '7px'}" [(ngModel)]="idFondo" (onChange)="searchData()"></p-dropdown>
                    <label for="float-label" style="font-size: 13px !important;">Selecione el fondo</label>          
                </span>       
            </div>
            <div class="col-0 md:col-2">
                
            </div>
            <div class="col-12 md:col-2" style="text-align: right;">
                <button pButton pRipple icon="pi pi-plus" iconPos="left" class="new-button" label="{{approverConstants.addButton}}"
                 (click)="newapproverConfiguration()"></button>
            </div>
        </div>
            <div class="px-2">
                <p-table #dt [value]="aprobaciones" responsiveLayout="stack" [rows]="properties.paginationRange"
            [globalFilterFields]="['nombre','valor','estado']"  styleClass="p-datatable-products" [paginator]="true"
            [rowsPerPageOptions]="[10,20,30,100]" [showCurrentPageReport]="true" [loading]="blocked"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
            selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
            <ng-template pTemplate="caption">
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <h5 class="m-0"></h5>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="idFondo">{{approverConstants.fondo.label}}<p-sortIcon field="idFondo">
                    </p-sortIcon>
                    </th>
                    <th pSortableColumn="aprobador.nombre">{{approverConstants.usuarioAprobador.label}}<p-sortIcon field="aprobador.nombre">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="tipoAprobacion.nombre">{{approverConstants.tipoAprobacion.label}}<p-sortIcon field="tipoAprobacion.nombre"></p-sortIcon>
                    </th>
                    <th pSortableColumn="tipoFondo.nombre">{{approverConstants.tipoFondo.label}}<p-sortIcon field="tipoFondo.nombre"></p-sortIcon>
                    </th>
                    <th pSortableColumn="nivel">{{approverConstants.nivel.label}}<p-sortIcon field="nivel"></p-sortIcon>
                    </th>
                    <th pSortableColumn="descripcion">{{approverConstants.descripcion.label}}<p-sortIcon field="descripcion"></p-sortIcon>
                    </th>
                    <th pSortableColumn="aprobadorFinal">{{approverConstants.aprobadorFinal.label}}<p-sortIcon field="aprobadorFinal"></p-sortIcon>
                    </th>
                    <th pSortableColumn="aprobadorSuperior.nombre">{{approverConstants.aprobadorSuperior.label}}<p-sortIcon field="aprobadorSuperior.nombre"></p-sortIcon>
                    </th>
        
                    <th>{{properties.accions}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-aprobacion>
                <tr> 
                    <td><span class="p-column-title">{{approverConstants.fondo.label}}</span>{{aprobacion.fondo?.nombreFondo}}</td>
                    <td><span class="p-column-title">{{approverConstants.usuarioAprobador.label}}</span><span>{{aprobacion.aprobador?.nombre}} {{aprobacion.aprobador?.apellido}}</span></td>
                    <td><span class="p-column-title">{{approverConstants.tipoAprobacion.label}}</span>{{aprobacion.tipoAprobacion?.nombre}}</td>
                    <td><span class="p-column-title">{{approverConstants.tipoFondo.label}}</span>{{aprobacion.tipoFondo?.nombre}}</td>
                    <td><span class="p-column-title">{{approverConstants.nivel.label}}</span>{{aprobacion.nivel}}</td>
                    <td><span class="p-column-title">{{approverConstants.descripcion.label}}</span>{{aprobacion.descripcion}}</td>
                    <td *ngIf="aprobacion.aprobadorFinal == true"><span class="p-column-title">{{approverConstants.aprobadorFinal.label}}</span>SI</td>
                    <td *ngIf="aprobacion.aprobadorFinal == false"><span class="p-column-title">{{approverConstants.aprobadorFinal.label}}</span>NO</td>
                    <td><span class="p-column-title">{{approverConstants.aprobadorSuperior.label}}</span>{{aprobacion.aprobadorSuperior?.nombre}} {{aprobacion.aprobadorSuperior?.apellido}}</td>
                    <td style="width:14%; min-width:10rem;">
                        <span class="p-column-title">{{properties.accions}}</span>
                        <div class="flex">
                            <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-text button-view-action" (click)="updateapproverConfiguration(aprobacion)"
                            ></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
            </div>
            
    </div>
    
</div>
<app-new-approver 
*ngIf="viewNewapproverConfiguration" 
[viewNewapproverConfiguration] = "viewNewapproverConfiguration"
(newApproverForm) = "closeNewapproverConfiguration($event)"
[aprobacion] = "aprobacion"
[fondos] = "fondos"
></app-new-approver>
