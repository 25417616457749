import { MsalModule, MSAL_INSTANCE, MsalInterceptor, MsalService, MsalGuard } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';


const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Triden/') > -1

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: atob(environment.idClient),
      authority: environment.urlLogin+atob(environment.tenanId),
      redirectUri: environment.urlFrontend,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  });
}