import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MenuModel } from 'src/app/models/menus.model';
import { FeriadoModel } from 'src/app/models/feriado.model';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { SecurityService } from 'src/app/service/securityservice';
import { PermisoModel } from 'src/app/models/permiso.model';
import { AuthorizationService } from 'src/app/common/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feriado-list',
  templateUrl: './feriado-list.component.html',
  providers: [MessageService]
})
export class FeriadoListComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  bicycleDialog: boolean;
  deleteFeriadoDialog: boolean = false;
  blockedFeriado: boolean = false;
  feriados: FeriadoModel[] = [];
  feriado: FeriadoModel;
  menus: MenuModel[] = [];
  selectedFeriados: FeriadoModel[];
  rowsPerPageOptions = [5, 10, 20];
  public lastFeriado:number;
  permiso: PermisoModel;
  @Output() feriadoAccion = new EventEmitter<any>();
  deleteFeriado : boolean = false;
  constructor(private messageService: MessageService, private securityService: SecurityService, private auth: AuthorizationService, private router: Router) { }
  ngOnInit(): void {
    this.permiso = this.auth.validatePermision(this.router);
    this.getFeriados();    
  }

  

  bicycleSelected() {
    const bicycleEvent = {
      type: 'S',
      bicycles: this.selectedFeriados
    };
    this.feriadoAccion.emit(bicycleEvent);
  }

  editStatus(feriado: FeriadoModel) {
    if (this.permiso.permisoEdicion) {
      feriado.estado = feriado.estado == this.properties.stateActive ? this.properties.stateInactive :this.properties.stateActive
        this.feriado = feriado;
        this.updateFeriado();
    }
  }
  getFeriados() {
    this.blockedFeriado = true;
    this.securityService.getFeriados().then(resp => {
      if (resp.code === 200) {        
        this.feriados = resp.data;        
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
      this.blockedFeriado = false;
    }).catch(err => {
      this.blockedFeriado = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  editFeriado(feriado: FeriadoModel) {
    const feriadoEvent = {
      type: 'M',
      feriado: feriado,
      update : true
    };
    this.feriadoAccion.emit(feriadoEvent);

  }
  updateFeriado() {
    this.securityService.updateFeriado(this.feriado).then(resp => {
      if (resp.code === 200) {
        this.getFeriados();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: 'Feriado actualizado correctamente', life: this.properties.timeLifeMessages });
      } else {
        this.getFeriados();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
   }

   trashFeriado(){
    this.securityService.updateFeriado(this.feriado).then(resp => {
      if (resp.code === 200) {
        this.deleteFeriado = false;
        this.getFeriados();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: 'Feriado eliminado correctamente', life: this.properties.timeLifeMessages });
      } else {
        this.getFeriados();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
   }
   confirmTrash(feriado){
    this.deleteFeriado = true;
    this.feriado = feriado
    this.feriado.estado = this.properties.stateDelete;
   }
}