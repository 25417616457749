<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3 pb-3">
            <div class="col-12 md:col-12">
                <h2 style="color: white; ">{{documentsConstants.title}}</h2>
                <h6 style="color: white;">{{documentsConstants.subtitle}}</h6>
            </div>
        </div>

    </div>
    <p-toast></p-toast>

    <div class="col-12 p-3">
        <div class="p-fluid p-formgrid grid px-2">
            <div class="col-12 md:col-12">
                <span class="title-screen">{{documentsConstants.acctions}}</span>
            </div>
            <div class="field col-12 md:col-6">
                <div class="p-inputgroup">
                    <span class="p-float-label p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input type="text" id="search1" class="inputSearch" pInputText
                            placeholder="{{documentsConstants.requirements.placehorders.applicant}}"
                            [(ngModel)]="busqueda" (keyup)="searchData($event)">
                        <label for="search1">{{documentsConstants.requirements.search}}</label>
                    </span>
                </div>
            </div>



        </div>
        <div class="px-2" >
            <p-table #dt [value]="soportesLiquidacion" responsiveLayout="stack" [rows]="properties.paginationRange"
                [globalFilterFields]="['nombre','valor','estado']" [paginator]="true" styleClass="p-datatable-products"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="!cargado"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0"></h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="solicitud.idSolicitud">
                            {{supportConstants.approveReposition.nroSolicitud.label}}<p-sortIcon
                                field="solicitud.idSolicitud"></p-sortIcon>
                        </th>
                        <th pSortableColumn="grupoMovimiento.nombre">
                            {{supportConstants.approveReposition.groupDocument.label}}<p-sortIcon
                                field="grupoMovimiento.nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="tipoDocumento.nombre">
                            {{supportConstants.approveReposition.typeDocument.label}}<p-sortIcon
                                field="tipoDocumento.nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="numeroIdentificacion">
                            Número de documento<p-sortIcon field="numeroIdentificacion"></p-sortIcon>
                        </th>
                        <th pSortableColumn="fechaFactura">
                            {{supportConstants.approveReposition.dateInvoice.label}}<p-sortIcon
                                field="fechaFactura"></p-sortIcon>
                        </th>
                        <th pSortableColumn="baseiva">Base Iva 0<p-sortIcon field="baseiva"></p-sortIcon>
                        </th>
                        <th pSortableColumn="baseiva">Base Iva<p-sortIcon field="baseiva"></p-sortIcon>
                        </th>

                        <th pSortableColumn="totaliva">{{supportConstants.approveReposition.iva}}<p-sortIcon
                                field="totaliva">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="baseiva">Ice<p-sortIcon field="baseiva"></p-sortIcon>
                        </th>
                        <th pSortableColumn="valorTotal">{{supportConstants.approveReposition.total}}<p-sortIcon
                                field="valorTotal">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="codigoSap">{{supportConstants.approveReposition.codigo}}<p-sortIcon
                                field="codigoSap">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="estado">{{supportConstants.approveReposition.estado}}<p-sortIcon
                                field="estado">
                            </p-sortIcon>
                        </th>
                        <th>{{properties.accions}}</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-movimiento>
                    <tr>

                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.nroIdentification}}</span><span>{{
                                movimiento?.solicitud?.idSolicitud }}</span></td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.groupDocument.label}}</span>{{movimiento.grupoMovimiento?.nombre}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.typeDocument.label}}</span>{{movimiento.tipoDocumento?.nombre}}
                        </td>
                        <td><span class="p-column-title">Número de documento</span>{{movimiento.numeroDocumento}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.dateInvoice.label}}</span>{{movimiento.fechaFactura
                            | date : "dd-MM-yyyy" }}</td>
                        <td><span class="p-column-title">Base iva 0</span>{{(movimiento.baseiva0 ?? 0) | currency:
                            'USD'}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.subtotal}}</span>{{(movimiento.baseiva
                            ?? 0) | currency: 'USD'}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.iva}}</span>{{(movimiento.totaliva
                            ?? 0) | currency: 'USD'}}
                        </td>
                        <td><span class="p-column-title">Ice</span>{{(movimiento.ice ?? 0) | currency: 'USD'}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.total}}</span>{{(movimiento.valorTotal
                            ?? 0) | currency: 'USD'}}
                        </td>
                        <td><span class="p-column-title">{{supportConstants.approveReposition.codigo}}</span>{{movimiento.codigoRegistroSap !== null ? movimiento.codigoRegistroSap : "--"}}
                        </td>
                        <td>
                            <span class="p-column-title">{{supportConstants.approveReposition.estado}}</span>
                            <div>
                                <span *ngIf="movimiento?.estado == 'I'">INGRESADO</span>
                                <span *ngIf="movimiento?.estado == 'R'">ELIMINADO</span>
                                <span *ngIf="movimiento?.estado == 'A'">APROBADO</span>
                                <span *ngIf="movimiento?.estado == 'X'">RECHAZADO</span>
                            </div>
                        </td>
                        <td style="width:14%; min-width:10rem;">
                            <span class="p-column-title">{{properties.accions}}</span>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-eye"
                                    class="p-button-rounded p-button-text button-view-action"
                                    (click)="viewDocumentSupport(movimiento)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>
<app-new-support *ngIf="newSupport" [isView]=true [newSupport]="newSupport" (supportForm)="closeSupport($event)"
    [isDocuments]="isDocuments" [idSolicitud]="idSolicitud" [movimiento]="movimiento"></app-new-support>