import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermisoModel } from '../models/permiso.model';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    constructor(private router: Router) { }

    currentUserCch = 'currentUserCch';
    currentMenuCch = 'currentMenuCch';
    currentDbMenu = 'currentDbMenu';
    currentDbMenuCelulas = 'currentDbMenuCelulas';
    currentUserSign = 'currentUserSign';
    clickCounterCch = 'clickCounterCch';
    currentEmpresa = 'currentEmpresa';

    setCurrentEmpresa(currentEmpresa: any) {
        localStorage.setItem(this.currentEmpresa, JSON.stringify(currentEmpresa));
    }
    setCurrentUser(currentUserCch: any) {
        localStorage.setItem(this.currentUserCch, JSON.stringify(currentUserCch));
    }
    setCurrentUserSign(currentUserSign: any) {
        localStorage.setItem(this.currentUserSign, JSON.stringify(currentUserSign));
    }

    setCurrentMenu(currentMenuCch: any) {
        //Ordenamiento de menu
        currentMenuCch.sort((firstObject: any, secondObject: any) =>
    	(firstObject.orden > secondObject.orden) ? 1 : -1);
        const menuList = this.armarMenu(currentMenuCch);
        localStorage.setItem(this.currentDbMenu, JSON.stringify(currentMenuCch));
        localStorage.setItem(this.currentMenuCch, JSON.stringify(menuList));
    }

    
    getCurrentUser() {
        try {
            return JSON.parse(localStorage.getItem(this.currentUserCch));
        } catch(err ){
            return undefined;
        }
    }
    getCurrentEmpresa() {
        try {
            return JSON.parse(localStorage.getItem(this.currentEmpresa));
        } catch(err ){
            return undefined;
        }
    }

    getCurrentMenu() {
        try {
            return JSON.parse(localStorage.getItem(this.currentMenuCch));
        } catch(err ){
            return undefined;
        }
    }
    getCurrentMenuDb() {
        try {
            return JSON.parse(localStorage.getItem(this.currentDbMenu));
        } catch(err ){
            return undefined;
        }
    }
    getCurrentCelulasDb() {
        try {
            return JSON.parse(localStorage.getItem(this.currentDbMenuCelulas));
        } catch(err ){
            return undefined;
        }
    }
    getCurrentUserSign() {
        try {
            const docuJson = JSON.parse(localStorage.getItem(this.currentUserSign));
            const fechaAnter = new Date(docuJson.expiredIn);
            const currentTime = new Date();
            if (currentTime >= fechaAnter) {
                return undefined;
            }
            return docuJson
        } catch(err ){
            return undefined;
        }
    }
    /**
    * Se guarda el usuario actual
    */
    clearUser() {
        localStorage.removeItem(this.currentUserCch);
    }

     /**
    * Se guarda el usuario actual
    */
    logOut() {
        localStorage.clear();
        this.router.navigate(['login']);
    }

    /**
     * Se realiza servicio para organizar los menús
     */
    armarMenu(menuList: any[]) {
        const map = new Map();
        menuList.forEach(parent => {
            if (!map.get(parent.idMenu)) {
                map.set(parent.idMenu, parent);
            } else {
                const rept =  map.get(parent.idMenu);
                if (parent.lectura === 'I') {
                    rept.lectura = parent.lectura;
                }
            }
        });
        menuList = Array.from(map.values());
        let parentMenus = menuList.filter(menu => !menu.idMenuPadre);

        const finalParents = parentMenus.map(menu => {
            return {
                label: menu.nombreMenu,
                icon: 'fa ' + menu.icono,
                idMenu: menu.idMenu,
                items: undefined,
                visible: menu.visible
            }
        });
        finalParents.forEach(parent => {
            const children = menuList.filter(menu => menu.idMenuPadre === parent.idMenu);
            parent.items = children.map(menu => {
                return {
                    label: menu.nombreMenu,
                    icon: 'pi ' + menu.icono,
                    idMenu: menu.idMenu,
                    visible: menu.visible,
                    routerLink: [menu.url]
                }
            });
        });
        finalParents.forEach(parent => {
            parent.items.forEach(level3 => {
                const children = menuList.filter(menu => menu.idMenuPadre === level3.idMenu);
                level3.screens = children.map(menu => {
                    return {
                        label: menu.nombreMenu,
                        icon: 'pi ' + menu.icono,
                        idMenu: menu.idMenu,
                        visible: menu.visible,
                        routerLink: [menu.url]
                    }
                });
            });
        });
        return finalParents;
    }

    validatePermision(router: Router): PermisoModel {
        try{
            const permisos = this.getCurrentMenuDb();
            const currentScreenList = permisos.filter(permiso => permiso.url && router.url.startsWith(permiso.url));
            const currentScreenEdicion = currentScreenList.filter(permiso => permiso.edicion === true);
            const currentScreenNuevo = currentScreenList.filter(permiso => permiso.edicion === true);

            const permisoResult = new PermisoModel();
            permisoResult.permisoEdicion = currentScreenEdicion.length > 0;
            permisoResult.permisoNuevo = currentScreenNuevo.length > 0;
            return permisoResult;
        } catch (err) {
            const permisoResult = new PermisoModel();
            permisoResult.permisoEdicion = true;
            permisoResult.permisoNuevo = true;
            return permisoResult;
        }

    }

    getclickCounterCch() {
        try {
            return JSON.parse(localStorage.getItem(this.clickCounterCch));
        } catch(err ){
            return undefined;
        }
    }
    setclickCounterCch(clickCounterCch: any) {
        localStorage.setItem(this.clickCounterCch, JSON.stringify(clickCounterCch));
    }

    isAutomaticBackedActive() {
        const timestam = new Date(this.getclickCounterCch().lastDate);
        const fechaActual = new Date();
        fechaActual.setMinutes(fechaActual.getMinutes() - 10);
        return timestam.getTime() > fechaActual.getTime();
    }

    getCurrentRolesDb() {
        try {
            const rolesMemory = JSON.parse(localStorage.getItem(this.currentDbMenuCelulas));
            const roles = rolesMemory.map(rol => rol.idRol);
            let dumpRoles = roles.filter((element, index) => {
                return roles.indexOf(element) === index;
            });
            return dumpRoles;
        } catch(err ){
            return undefined;
        }
    }

}