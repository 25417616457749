<p-toast></p-toast>

<p-table *ngIf="!isAssignRol" #dtUser [value]="users" responsiveLayout="stack" [rows]="properties.paginationRange" [loading]="blockedUser"
    [globalFilterFields]="['nombre','apellido','email','telefono','estado']" [paginator]="true" styleClass="p-datatable-products"
    [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [(selection)]="selectedUsers"
    selectionMode="multiple" [rowHover]="true" dataKey="idUsuario">
    <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0"></h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dtUser.filterGlobal($event.target.value, 'contains')"
                    placeholder="Buscar..." />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            
            <th pSortableColumn="nombre">{{securityConstants.user.name.field}} <p-sortIcon field="nombre">
                </p-sortIcon>
            </th>
            <th pSortableColumn="apellido">{{securityConstants.user.lastName.field}}<p-sortIcon field="apellido">
                </p-sortIcon>
            </th>
            <th pSortableColumn="email">{{securityConstants.user.email.field}} <p-sortIcon field="email">
                </p-sortIcon>
            </th>
            <th pSortableColumn="telefono">{{securityConstants.user.phone.field}}<p-sortIcon field="telefono">
                </p-sortIcon>
            </th>
            <th pSortableColumn="estado">{{securityConstants.user.status.field}} <p-sortIcon field="estado">
                </p-sortIcon>
            </th>
            <th pSortableColumn="empresa">{{securityConstants.user.empresa.field}} <p-sortIcon field="empresa">
                </p-sortIcon>
            </th>
            <th>{{properties.accions}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
        <tr>
            
            <td><span class="p-column-title">{{securityConstants.user.name.field}}</span>{{user.nombre}}</td>
            <td><span class="p-column-title">{{securityConstants.user.lastName.field}}</span>{{user.apellido}}</td>
            <td><span class="p-column-title">{{securityConstants.user.email.field}}</span>{{user.email}}</td>
            <td><span class="p-column-title">{{securityConstants.user.phone.field}}</span>{{user.telefono}}</td>
            <td>
                <span class="p-column-title">{{securityConstants.user.status.field}}</span>
                <span *ngIf="user.estado == properties.stateActive" 
                    [class]="'state-badge state-A'" (click)="updateUser(user)"> {{properties.active}}</span>
                <span *ngIf="user.estado == properties.stateInactive"
                    [class]="'state-badge state-I'" (click)="updateUser(user)"> {{properties.inactive}}</span>
                <span *ngIf="user.estado == properties.stateDelete" [class]="'state-badge state-E'">
                    {{properties.delete}}</span>
            </td>
            <td><span class="p-column-title">{{securityConstants.user.empresa.field}}</span>{{user.userEmpresa.empresa.nombreEmpresa}}</td>
            <td style="width:14%; min-width:10rem;">
                <span class="p-column-title">{{properties.accions}}</span>
                <div class="flex">
                    <button pButton pRipple icon="pi pi-pencil"
                        class="p-button-rounded p-button-text button-view-action mr-2"
                        (click)="editUser(user)"></button>
                    <button pButton pRipple icon="pi pi-bars"
                        class="p-button-rounded p-button-text button-view-action mr-2" title=" {{ securityConstants.login.messages.assignRoles }}"
                        (click)="assignRoles(user)"></button>
                    <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        (click)="deleteUser(user)"></button> -->
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
<div *ngIf="isAssignRol">
    <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
            <label htmlFor="assignRol">Asignacion de roles a usuario: <b>{{user.nombre}} {{user.apellido}}</b></label>
        </ng-template>

        <ng-template pTemplate="right">
            <button *ngIf="isNewRolMenu" pButton pRipple id="saveUser" icon="{{properties.guardar.icono}}"
                label="{{properties.guardar.valor}}" class="new-button mr-2" (click)="saveRolUser()"></button>
            <button *ngIf="isNewRolMenu" pButton pRipple id="cancelUser" icon="{{properties.cancelar.icono}}"
                label="{{properties.cancelar.valor}}" class="p-button-danger" (click)="cancelRolUser()"></button>

            <button *ngIf="!isNewRolMenu" pButton pRipple id="newUser" icon="{{properties.nuevo.icono}}"
                label="{{properties.nuevo.valor}}" class="mr-2 mb-2" (click)="newUserRol()"></button>
            
        </ng-template>
    </p-toolbar>

    <div *ngIf="isNewRolMenu" class="p-fluid p-formgrid grid">
        <div class="field col-12 md:col-4">
            <label htmlFor="lastName">{{securityConstants.rolUsuario.roles.field}} <span
                    class="ng-invalid">*</span></label>
            <p-dropdown [options]="listRoles" id="listRoles" [(ngModel)]="selectedRoles" placeholder="Seleccione un rol" appendTo="body" 
                required autofocus optionLabel="label" [showClear]="true">
            </p-dropdown>
            <small class="ng-dirty ng-invalid"
                *ngIf="submitted && !selectedRoles">{{securityConstants.rolUsuario.roles.requiered}}</small>
        </div>

        <div class="field col-12 md:col-4">
            <label htmlFor="state">{{securityConstants.rolUsuario.status.field}}</label><br>
            <p-toggleButton [(ngModel)]="stateRolUser" onLabel="{{properties.active}}"
                offLabel="{{properties.inactive}}" [style]="{'width': '10em'}"></p-toggleButton>
        </div>
    </div>

    <p-table *ngIf="!isNewRolMenu" #dt [value]="listRolUser" responsiveLayout="scroll" [rows]="properties.paginationRange"
        [globalFilterFields]="['celula','rol','estado']" [paginator]="true" styleClass="p-datatable-products"
        [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedUser"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
        [(selection)]="selectedRolUsers" selectionMode="multiple" [rowHover]="true" dataKey="idCelula">
        <ng-template pTemplate="caption">
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 class="m-0"></h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Buscar..." />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="rol">Rol<p-sortIcon field="rol">
                    </p-sortIcon>
                </th>

                <th pSortableColumn="estado">{{securityConstants.user.status.field}} <p-sortIcon field="estado">
                    </p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rolUser>
            <tr>
                <td>{{rolUser.rol}}</td>

                <td style="padding: 0.75rem !important;">
                    <span *ngIf="rolUser.estado == properties.stateActive" 
                        [class]="'state-badge state-A'" (click)="updateStateRolUser(rolUser)" > {{properties.active}}</span>
                    <span *ngIf="rolUser.estado == properties.stateInactive" 
                        [class]="'state-badge state-I'" (click)="updateStateRolUser(rolUser)"> {{properties.inactive}}</span>
                    <span *ngIf="rolUser.estado == properties.stateDelete" [class]="'state-badge state-E'">
                        {{properties.delete}}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="deleteUserDialog" header="{{properties.confirmation}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="user">{{securityConstants.user.confirmationMessages.delete}}<b>{{user.nombre}}</b>?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="deleteUserDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="confirmDelete()"></button>
    </ng-template>
</p-dialog>