<p-toast></p-toast>
<div class="p-fluid p-formgrid grid pt-4">
    <div class="field col-12 md:col-4 px-2 py-0">
        <label htmlFor="description">Fecha Festivo</label>
        <p-calendar id="fechaFeriado" [(ngModel)]="feriado.fechaFeriado" required autofocus
        [ngClass]="{'ng-invalid ng-dirty' : submitted && !feriado.fechaFeriado}" dateFormat="yy/mm/dd" ></p-calendar>
        <small class="ng-dirty ng-invalid"
        *ngIf="!feriado.fechaFeriado">El formato de fecha debe ser año/mes/dia Ejem. 2024/12/25</small>
        
    </div>
    <!-- <div class="field col-12 md:col-4 px-2 py-0">
        <label htmlFor="state">{{securityConstants.celula.status.field}}</label><br>
        <p-toggleButton [(ngModel)]="state" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div> -->
</div>