import { AuditoriaModel } from "./auditoria.model";

export class FeriadoModel extends AuditoriaModel {
    fechaFeriado: Date;
    idFeriado?: number;
    estado?: string;
    constructor() {
        super();
        this.fechaFeriado = undefined;
        this.idFeriado = null;
    }
}