import { Component, OnInit} from '@angular/core';
import { MessageService } from 'primeng/api';
import { SecurityConstants } from '../../security/security.constants';
import { Properties } from 'src/app/utilities/properties';
import { PendingsConstants } from '../pendings.constants';


@Component({
  selector: 'app-pendings-approval-list',
  templateUrl: './pendings-approval-list.component.html',
  providers: [MessageService],
})
export class PendingsApprovalListComponent implements OnInit {
  public readonly pendingsConstants = PendingsConstants;
  properties: Properties = new Properties();
  requirements:any[] = [];
  requirement:any;
  blockedRequirement:boolean = false;
  createComment : boolean = false
  constructor() {
  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.requirements = [ 
      {
        solicitante : 'David Tayan',
        nroSolicitud : '12312',
        montoSolicitado : '12312',
        fechaCreacion : '12/12/2023',
        fechaLiquidacion : '12/12/2024',
        tipoFondo : 'Caja chica',
        aprobadoPor : 'David Tayan',
        descripcion : 'Descripción',
        estado : 'Solicitado'
      }
    ]
  }
  openComment(){
    this.createComment = true
  }
  closeCommentForm(event){
    this.createComment = event.createComment
  }
}
