<p-dialog
    [(visible)]="createComment"
    [modal]="true"
    [responsive]="true"
    [showHeader]="false"
    [breakpoints]="{ '960px': '90vw' }"
    [style]="{ width: '50%', minHeight: '400px' }"
    [minY]="100"
    position="center"
    [contentStyle]="{ padding: 0 }"
    position="center"
    styleClass="p-cdialog-right"
    [closeOnEscape]="false"
    transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)"
>
    <div class="grid">
        <div class="col-12">
            <div class="p-fluid p-formgrid grid pt-4 px-3 pb-1">
                <div class="col-11 md:col-11">
                    <span class="title-dialog">Comentarios</span
                    ><br /><br />
                </div>
                <div class="col-1 md:col-1" style="text-align: end">
                    <button
                        pButton
                        pRipple
                        type="button"
                        style="color: #000"
                        icon="pi pi-times"
                        class="p-button-rounded p-button-text button-view-action"
                        (click)="closeCommentForm()"
                    ></button>
                </div>
            </div>
        </div>
    </div>
    <span class="label-form">Ingresa tu comentario</span>
    <div class="col-12">
    <div class="col-12 text-comments">
        <div class="p-fluid p-formgrid grid  px-3 pb-2">
            <div class="col-12 md:col-12">
                <textarea type="text" pInputText id="descripcion" rows="6" style="border: none;" [(ngModel)]="comentario.comentario"></textarea>
                <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !comentario.comentario">El comentario es requerido</small>
            </div>
            
        </div>
        <div class="col-12 md:col-4" style="text-align: left;" >
            <button pButton pRipple  class="new-button" label="Enviar Comentario" (click)="createComentarios()"
            ></button>
        </div>
    </div>
</div>
    <hr>
    <div class="col-12" *ngFor="let comentario of listComentarios">
        <div class="col-12 md:col-12 comment-list">
            <span class="name-comment">{{comentario.usuario?.nombre}} {{comentario.usuario?.apellido}}</span><br>
            <span class="text-comment">{{comentario.fechaComentario | date: 'dd-MM-yyyy'}}</span>
            <hr>
            <p class="text-comment-data">{{comentario.comentario}}</p>
        </div>        
    </div>
</p-dialog>
