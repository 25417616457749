export const DashboardConstants = {
   dashboard: 'Dasboard',
   requirements : {
    title : 'Tus requerimientos',
    subtitle : 'Aquí están las solicitudes de tu fondo(s)',
    addButton : 'Nuevo requerimiento',
    search : 'Buscar',
    placehorders : {
        applicant : 'Puedes buscar por número, nombre del solicitante o código SAP',
        dateForm : 'Fecha desde',
        dateTo : 'Fecha hasta',
        reposition: 'Puedes buscar por solicitud, grupo o tipo de documento'
    },
    fundName:{
        label: "Fondo"
    },
    applicant : {
        label : 'Solicitante'
    },
    numberSoli : {
        label : 'Número de solicitud'
    },
    amountRequested : {
        label : 'Monto solicitado'
    },
    createDate : {
        label : 'Fecha de creación'
    },
    liquitationDate : {
        label : 'Fecha esperada de liquidación'
    },
    fundType :{ 
        label : 'Tipo de Solicitud'
    },
    requirementType:{ 
        label : 'Tipo de Solicitud'
    },
    aprobateFor : {
        label : 'Aprobado por'
    },
    description : {
        label : 'Descripción'
    },
    status : {
        label : 'Estado'
    },
    actions : {
        label : ''
    }
   },
   cashRequirement : {
    title: 'Requerimiento de efectivo',
    subtitle : 'Aquí puedes solicitar tu requerimiento.',
    subtitleDataSoli : 'Aquí puedes gestionar tu requerimiento.',
    sendButton : 'Enviar requerimiento',
    cancelButton : 'Cerrar formulario',
    commentButton : 'Comentarios',
    leftTitle : 'Datos del solicitante',
    dateApplicnt: 'Datos del requerimiento',
    description : 'Descripción',
    rejectButton : 'Rechazar solicitud',
    deliveredValue : 'Valor entregado',
    liquidButton : 'Liquidar solicitud',
    receiveValue: "Recibir Documentos",
    rejectRequirementButton : 'Rechazar requerimiento',
    approveRequirementButton : 'Aprobar requerimiento',
    placehorders : {
        applicant : 'Nombre del solicitante',
        nroIdentification : 'Número de identificación',
        email : 'Correo electrónico',
        dateSoli : 'Fecha de solicitud',
        dateLiquidation : 'Fecha esperada de liquidación'
        },
    
        nameApplicant : {
            label : 'Nombre del solicitante',
            required : 'Nombre del solicitante es requerido.'
        },
        nroIdentification : {
            label : 'Número de identificación',
            required : 'Número de identificación es requerido.'
        },
        email : {
            label : 'Correo electrónico',
            required : 'Correo electrónico es requerido.'
        },
        dateSoli :  {
            label : 'Fecha de solicitud',
            required : 'Fecha de solicitud es requerida.'
        },
        dateLiquidation : {
            label : 'Fecha de liquidación',
            required : 'Fecha de liquidación es requerida.'
        },
        nroSolicitud : {
            label : 'Número de solicitud',
        },
        valueRequeriment : {
            label : 'Valor solicitado',
            required: 'Valor solicitado es requerido',
            requiredMin: "Valor solicitado debe ser superior a 0."

        },
        fundType : {
            label : 'Tipo de solicitud',
            required : 'Tipo de solicitud es requerido.'
        },
        requirementType: {
            label : 'Tipo de solicitud',
            required : 'Tipo de solicitud es requerido.'
        },
        fundName : {
            label : 'Nombre de fondo',
            required :  'Nombre de fondo es requerido.'
        },
        fundReason : {
            label : 'Motivo de la solicitud',
            required : 'Motivo de la solicitud.'
        },
        aplicatArea: {
            label : 'Área de solicitante',
            required : 'Área de solicitante es requerida.'
        },
        descriptionSoli : {
            label : 'Detalle de tu requerimiento (Opcional)',
            
        }
   },
   comments:{
    title:{
        label: "Comentarios"
    },
    
   },
   newRequeriment:{
    messages:{
        create:"Solicitud creada correctamente",
        update:"Solicitud actualizada correctamente"

    }
   }
}
