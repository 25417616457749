import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthorizationService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthorizationService) {

    }

    publicUrls = [
        '/principal/perfil'
    ]
    /**
     * Se ejecuta en cada llamada a una ruta de Angular para validar seguridad de la Aplicación Volrisk
     * @param route Objeto Route de la ruta generada
     * @param state Estado del Ruteo contiene la URL invocada
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // Sacar del local storage
        const isAuthenticated = !!this.auth.getCurrentUser() ? true : false;
        if (!isAuthenticated) {
            if (state.url) {
                this.router.navigate(['login']);
            }
            return true
        } else if (this.validatePermission(route, state)){
            return true;
        } else {
            this.router.navigate(['noauth']);
            return false;
        }
    }
    validatePermission(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let permission = this.publicUrls.includes(state.url) || state.url.startsWith(this.publicUrls[0]);
        if (permission) {
            return permission;
        }
        const menuParents = this.auth.getCurrentMenu();
        menuParents.forEach(parent => {
            parent.items.forEach(menu => {
                if (!permission && state.url.indexOf(menu.routerLink[0]) > -1){
                    permission = true;
                }
            });
        });
        return permission;

    }
}
