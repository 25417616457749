import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { CatalogModel } from 'src/app/models/catalog.model';
import { AppBreadcrumbService } from 'src/app/utilities/app.breadcrumb.service';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { CatalogFormComponent } from './catalog-form.component';
import { SecurityService } from 'src/app/service/securityservice';
import { CatalogListComponent } from './catalog-list.component';
import { PermisoModel } from 'src/app/models/permiso.model';
import { AuthorizationService } from 'src/app/common/auth.service';
@Component({
  selector: 'app-catalog-ecc',
  templateUrl: './catalog.component.html',
  providers: [MessageService]
})
export class CatalogComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  deleteCatalogsDialog: boolean = false;
  catalogs: CatalogModel[] = [];
  catalog = new CatalogModel();
  @Input()
  catalogPadre = new CatalogModel();
  selectedCatalogs: CatalogModel[];
  items: MenuItem[];
  home = {icon: 'pi pi-arrow-left', routerLink: ''};
  isNewCatalog: boolean;
  permiso: PermisoModel;
  @ViewChild(CatalogFormComponent) catalogFormComponent: CatalogFormComponent;
  @ViewChild(CatalogListComponent) catalogListComponent: CatalogListComponent;

  constructor(private router: Router, private messageService: MessageService, 
    private breadcrumbService: AppBreadcrumbService, 
    private auth: AuthorizationService,
    private securityServices: SecurityService) {
    this.breadcrumbService.setItems([
      { label: this.securityConstants.security },
      { label: this.securityConstants.catalog.title, routerLink: ['/principal/catalog'] }
    ]);
  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.permiso = this.auth.validatePermision(this.router);
    this.items = [ ];
  }
  /**
   * Presenta la pantalla lista de catagos hijos del catalogo sobre el cual se hizo click en el breadcrumb
   * @param event click en breadcrumb
   */
  onItemClick(event) {
    this.isNewCatalog = false
    this.catalogPadre = undefined
    const index = this.items.indexOf(event.item);
    if (index  < 0) {
      this.items = [];
      this.catalogPadre = undefined; 
    } else {
      this.items.splice(index +  1, this.items.length);
      this.catalogPadre = event.item.iconStyle; 
    }
    this.catalog = this.catalogPadre; 

  }
  /**
   * Presenta formulario para nuevo catalogo
   */
  newCatalog() {
    this.selectedCatalogs = [];
    this.catalog = new CatalogModel();
    this.isNewCatalog = true;
  }
  /**
   * Presenta ppoup de confirmación de eliminación
   */
  deleteSelectedCatalogs() {
    this.deleteCatalogsDialog = true;
  }
  /**
   * Guarda los datos de los catalogos
   */
  saveCatalog() {
    this.catalogFormComponent.saveCatalog();
  }
  /**
   * Cancela la creación de un catalogo
   */
  cancelCatalog() {
    this.isNewCatalog = false;
  }
  /**
   * Evento que se dispara por las emisiones de los componentes hijos
   * @param event Evento de pantallas hijas
   */
  catalogAccion(event: any) {
    if (event.type == 'M') {
      this.isNewCatalog = true;
      this.catalog = event.catalog;
    } else if (event.type == 'H') {
      this.catalog = event.catalog;
      this.catalogPadre = event.catalog;
      this.items.push({label: this.catalogPadre.nombre, id: this.catalogPadre.idCatalogo.toString(), iconStyle: this.catalogPadre});
      this.items = this.items.slice();
    } else {
      this.selectedCatalogs = event.catalogs;
    }

  }
  /**
   * Elimina los catálogos del sistema
   * @param catalogos catalogos eleccionados
   */
  deleteMassive(catalogos: any) {
    this.securityServices.deleteCatalog(catalogos).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.catalog.messages.deletes, life: this.properties.timeLifeMessages });
        this.reloadListCatalogs();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.successful, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Confirma la eliminación de los catalogos seleccionados
   */
  confirmDeleteSelected() {
    
    let catalogsToDelete: any[] = [];
    this.selectedCatalogs.forEach(x => {
      catalogsToDelete.push(x.idCatalogo)
    });
    const catalogDelete = {
      idCatalogos: catalogsToDelete
    }
    this.deleteMassive(catalogDelete);
  }

  /**
   * Detect all input changesS
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
  }
  /**
   * Lee la lista de catalogos luego de una acción en pantalla con un timeout para ver mensajess
   */
  reloadListCatalogs() {
    this.deleteCatalogsDialog = false;
    setTimeout(() => {
      this.catalogListComponent.selectedCatalogs = [];
      if (this.catalogPadre && this.catalogPadre.idCatalogo) {
        this.catalogListComponent.getChilds(this.catalogPadre);
      } else {
        this.catalogListComponent.getCatalogs();
      }
    }, 3000);

  }
}
