import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FeriadoModel } from 'src/app/models/feriado.model';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { FeriadoFormComponent } from './feriado-form.component';
import { FeriadoListComponent } from './feriado-list.component';
import { PermisoModel } from 'src/app/models/permiso.model';
import { AuthorizationService } from 'src/app/common/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-feriado',
	templateUrl: './feriado.component.html',
	providers: [MessageService]
})
export class FeriadoComponent implements OnInit {

	public readonly securityConstants = SecurityConstants;
	properties: Properties = new Properties();
	deleteFeriadosDialog: boolean = false;
	feriados: FeriadoModel[] = [];
	feriado: FeriadoModel;
	selectedFeriados: FeriadoModel[];
	isNewFeriado: boolean;
	lastFeriado: number;
	isUpdate = false;
	permiso: PermisoModel;
	@ViewChild(FeriadoFormComponent) feriadoFormComponent: FeriadoFormComponent;
	@ViewChild(FeriadoListComponent) feriadoListComponent: FeriadoListComponent;

	constructor(private auth: AuthorizationService, private router: Router) {
	}

	ngOnInit(): void {
		this.permiso = this.auth.validatePermision(this.router);
	}

	newFeriado() {
		this.selectedFeriados = [];
		this.isNewFeriado = true;
		this.feriado = new FeriadoModel();
	}

	saveFeriado() {
		this.feriadoFormComponent.saveFeriado();
	}

	cancelFeriado() {
		this.isNewFeriado = false;
	}

	feriadoAccion(event: any) {
		if (event.type == 'M') {
			this.isNewFeriado = true;
			this.feriado = event.feriado;	
			this.isUpdate = event.update
		} else if (event.type == 'D') {
			this.feriadoFormComponent.updateFeriado();
			this.feriado = event.feriado;
		} else {
			this.selectedFeriados = event.feriados;
		}

	}

	reloadListFeriados() {
		this.deleteFeriadosDialog = false;
		setTimeout(() => {
			this.feriadoListComponent.getFeriados();
			this.feriadoListComponent.selectedFeriados = [];

		}, 1800);

	}
}