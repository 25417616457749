import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Properties } from "src/app/utilities/properties";
import { PermisoModel } from "src/app/models/permiso.model";
import { SupportConstants } from "../support.constants";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { AuthorizationService } from "src/app/common/auth.service";
import { SecurityService } from "src/app/service/securityservice";
import { Utils } from "src/app/utilities/utils";
import { SolicitudModel } from "src/app/models/caja-chica/solitud.model";
import { MovimientoComprobanteModel } from "src/app/models/caja-chica/movimientoComprobante.model";

@Component({
    selector: "app-support-settlement",
    templateUrl: "./support-settlement.component.html",
    providers: [MessageService],
})
export class SupportSettlementComponent implements OnInit {
    public readonly supportConstants = SupportConstants;
    properties: Properties = new Properties();
    permiso: PermisoModel;
    datosSolicitud: any = {};
    soportesLiquidacion: any[] = [];
    blockedRequirement: boolean = false;
    newSupport: boolean = false;
    isView: boolean = false;
    createComment: boolean = false;
    @Input() viewSupportSettle: boolean;
    @Input() requirement: any;
    @Input() vizualizarSoportes: boolean
    @Output() supportSettleForm = new EventEmitter<any>();
    movimientos: any = []
    isData: boolean = true
    movimiento : any
    totalGastado: any = 0
    diferenciaLiquidar: any = 0
    showLiquidar:boolean = false
    showEliminar: boolean = false
    constructor(
        private router: Router,
        private messageService: MessageService,
        private auth: AuthorizationService,
        private securityServices: SecurityService,
        private utils: Utils
    ) {}
    /**
     * Inicio de pantalla
     */
    async ngOnInit() {
       await this.getMovimientos();
       this.isData = this.movimientos.length > 0 ? false : true
    }

    viewSupport() {
        this.router.navigate(["principal/newSupport/view"]);
    }
    closeViewSupportSettle() {
        const event = {
            type: "return",
        };
        this.supportSettleForm.emit(event);
    }
    openNewSupport(isView, movimiento) {
        if(movimiento == null){
            this.movimiento = new MovimientoComprobanteModel()
        }else{
            this.movimiento = movimiento
        }
        this.newSupport = true;
        this.isView = isView;
        
    }
    closeSupport(event) {
        if (event.type === "cancel") {
            this.newSupport = false;
        }
        if (event.type === "reload") {
            this.newSupport = false;
            this.getMovimientos()
            this.isData = this.movimientos.length > 0 ? false : true

        }
    }
    openComment(movimiento) {
        this.movimiento = movimiento        
        this.createComment = true;
    }
    closeCommentForm(event) {
        this.createComment = event.createComment;
    }
    async getMovimientos() {
        this.blockedRequirement = true;
        let estado = ''
        if(this.requirement.estado == this.properties.ESTADO_SOLI_NO_APROBADO){
            estado = this.properties.ESTADO_RECHAZADO
        }else{
            estado = this.requirement.estado == this.properties.ESTADO_SOLI_RECIBIDO || this.requirement.estado == this.properties.ESTADO_SOLI_APROBADO || this.requirement.estado == this.properties.ESTADO_SOLI_REGISTRADA ? this.properties.ESTADO_SOLI_APROBADO : this.properties.ESTADO_INGRESADO
        }
        await this.securityServices
            .getMovimientos(this.requirement.idSolicitud, null, estado)
            .then((resp) => {
                this.blockedRequirement = false;
                if (resp.code === 200) {
                    this.movimientos = resp.data;
                    this.requirement.valorGastado = 0
                    this.diferenciaLiquidar = 0
                    this.movimientos.forEach((objeto) => {
                        this.requirement.valorGastado += objeto.valorTotal
                        objeto.fechaFactura = new Date(objeto.fechaFactura)
                        objeto.fechaFactura = (objeto.fechaFactura).setDate((objeto.fechaFactura).getDate() + 1)
                    });
                    this.diferenciaLiquidar = (this.requirement.valorSolicitado - this.requirement.valorGastado).toFixed(2)
                    this.requirement.valorGastado = (this.requirement.valorGastado).toFixed(2)
                    this.isData = this.movimientos.length > 0 ? false : true
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blockedRequirement = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
     liquidarSolicitud() {
        this.securityServices
            .liquidarSolicitud(this.requirement)
            .then((resp) => {
                if (resp.code === 200) {
                    this.showLiquidar = false
                    this.viewSupportSettle = false
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: "Solicitud liquidada con éxito.",
                        life: this.properties.timeLifeMessages,
                    });
                    const event = {
                        type: "reload",
                    };
                    this.supportSettleForm.emit(event);
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    confirmarLiquidacion(){
        this.showLiquidar = true;
    }
    confirmDeleteSoporte(movimiento){
        this.showEliminar = true
        this.movimiento = movimiento
    }
    async updateMovimiento() {
        this.movimiento.estado = this.properties.ESTADO_RECHAZADO
        this.movimiento.fechaFactura = this.movimiento.fechaFactura ? new Date(this.movimiento.fechaFactura): ""
        await this.securityServices
            .updateMovimientos(this.movimiento)
            .then((resp) => {
                if (resp.code === 200) {
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: "Comprobante eliminado correctamente",
                        life: this.properties.timeLifeMessages,
                    });
                    this.showEliminar = false
                    this.getMovimientos()
                    
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
}
