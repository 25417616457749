import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UrlServices {
    code = '?code=SDt0GWn6mDV2lKEYLEyReArB8IvRYPcwWRm-qI_2AGCHAzFuNYfBlA=='
    urlApiBackend = environment.urlBackend;
    securityContext = this.urlApiBackend;
    //Menú
    urlMenuCreate = this.urlApiBackend + '/MenuCreate';
    urlMenuUpdate = this.urlApiBackend + '/MenuUpdate';
    urlMenuList = this.urlApiBackend + '/MenuList';  
    urlMenuByRol = this.urlApiBackend + '/RoleMenuList'; 
    urlAsignarMenuRole = this.urlApiBackend + '/AsignarMenuRole'
    //Catalogo
    urlCatalogoList = this.urlApiBackend + '/CatalogList';
    urlCatalogoCreate = this.urlApiBackend + '/CatalogCreate';
    urlCatalogoUpdate = this.urlApiBackend + '/CatalogUpdate';
    getCatalogsByFather = this.securityContext + '/CatalogByFather';
    getCatalogsByFathers = this.securityContext+ '/CatalogByFathers'
    //User
    urlUserList = this.securityContext + '/UserList';
    urlUserCreate = this.securityContext + '/UserCreate';
    urlUserUpdate = this.securityContext + '/UserUpdate';
    //Roles
    urlRoleList = this.securityContext + '/RoleList';
    urlRoleCreate = this.securityContext + '/RoleCreate';
    urlRoleUpdate = this.securityContext + '/RoleUpdate';
    //RolesUsuarios
    urlRoleUserList = this.securityContext + '/RoleUserList';
    urlRoleUserCreate = this.securityContext + '/RoleUserCreate'
    urleRoleUserUpdate = this.securityContext + '/RoleUserUpdate'
    //Feriados
    urlFeriadoList = this.securityContext + '/FeriadosList'
    urlFeriadoCreate = this.securityContext + '/FeriadosCreate'
    urlFeriadoUpdate = this.securityContext + '/FeriadosUpdate'
    urlCountFeriados = this.securityContext + '/FeriadosCount'

    //Token
    urlGetToken = this.securityContext + '/GetToken'
    urlRefreshToken = this.securityContext + '/RefreshToken?code='+environment.codeEnv

    //Solicitudes
    getSolicitudesList = this.securityContext + '/SolicitudList'
    urlSolicitudCreate = this.securityContext + '/SolicitudCreate'
    urlSolicitudUpdate = this.securityContext + '/SolicitudUpdate'
    urlSolicitudLiquidar = this.securityContext + '/SolicitudLiquidar'

    //Fondo
    urlFondoList = this.securityContext + '/FondoList'
    urlFondoCreate = this.securityContext + '/FondoCreate'
    urlFondoUpdate = this.securityContext + '/FondoUpdate'

    //Arqueo
    getArqueoList = this.securityContext + '/ArqueoList';
    urlArqueoCrete = this.securityContext + '/ArqueoCreate';
    urlArqueoUpdate = this.securityContext + '/ArqueoUpdate'
    urlValorDocSolicitudes = this.securityContext + '/ValorDocumentosSolicitudes'
    urlValorDocMovimientos = this.securityContext + '/ValorDocumentosMovimientos'
    urlFindOneValesProvisionales = this.securityContext +'/FindOneValesProvisionales'
    urlFindOneComprobantes = this.securityContext + '/FindOneComprobantes'
    
    //Comentarios
    getComentarios = this.securityContext + '/ComentarioList'
    urlCreateComentarios = this.securityContext + '/ComentarioCreate'
    urlUpdateComentarios = this.securityContext + '/ComentarioUpdate'

    //Denominaciones
    urlDenominacionCreate = this.securityContext + '/DenominacionCreate'
    urlDenominacionUpdate = this.securityContext + '/DenominacionUpdate'
    urlDenominacionList = this.securityContext + '/DenominacionList'

    //Reposicion Caja
    getMovimientosIdSolicitudRepo = this.securityContext + '/MovimientoComprobanteFilter'
    getMovimientosIdSolicitudRepoSum = this.securityContext + '/MovimientoComprobanteSum'
    urlAprobarReposicion = this.securityContext + '/MovimientoComprobanteAprobar'
    urlRechazarReposicion = this.securityContext + '/MovimientoComprobanteRechazar'

    //Movimientos
    getMovimientos = this.securityContext + '/MovimientoComprobanteList'

    getConfiguracionAprobador = this.securityContext + '/ConfiguracionAprobadorList'
    urlCreateConfiguracionAprobador = this.securityContext + '/ConfiguracionAprobadorCreate'
    urlUpdateConfiguracionAprobador = this.securityContext + '/ConfiguracionAprobadorUpdate'
    //Movimientos
    urlMovimientoComprobanteList = this.securityContext + '/MovimientoComprobanteList'
    urlMovimientoComprobanteCreate  = this.securityContext + '/MovimientoComprobanteCreate'
    urlMovimientoComprobanteUpdate = this.securityContext + '/MovimientoComprobanteUpdate'
    validateNumeroDocumento = this.securityContext + '/ValidateNumberDocument'
    //CentroCostoMovimiento
    urlCentroCostoMovimientoList = this.securityContext + '/CentroCostoMovimientoList'
    urlCentroCostoCuentaGastoList = this.securityContext + '/CentroCostoCuentaGastoList'


    //UsersByRole
    urlUserByRole = this.securityContext + '/UserListByRole';
    
    //Empresas
    urlEmpresasList =  this.securityContext + '/EmpresaList'

    pathDelete = '/delete'
    urlMenu = this.securityContext + '/MenuList';
    urlUsers = this.securityContext + '/UserList';
    urlReset = this.securityContext + '/clave';
    urlLogin = this.urlUsers + '/login';
    urlConfirm = this.urlUsers + '/confirm';
    urlRoles = this.securityContext + '/RoleList';
    urlRoleUser = this.securityContext + '/rolUser';
    urlSignedUrl = this.securityContext + '/aws/signedUrl';

    urlcelula = this.securityContext + '/celula';
    urlCatalog = this.securityContext + '/CatalogList';
    
    getAeropuertos = this.securityContext + '/aeropuerto';
    urlInfoLogued = this.securityContext + '/GetInfoUser';
    urlMfa = this.securityContext + '/mfa';
    urlDecodeToken = this.urlApiBackend + '/DecodeToken';
}
