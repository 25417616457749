<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3 pb-3">
            <div class="col-12 md:col-12">
                <h2 style="color: white;">{{titulo}}</h2>
                <h6 style="color: white;">{{subtitle}}</h6>
            </div>
        </div>
    </div>
    <p-toast></p-toast>

    <div class="col-12 p-3">
        <div class="p-fluid p-formgrid grid pt-2 px-2">
            <div class="col-12 md:col-10">
                <span class="title-screen">{{fundConstants.title}}</span>
            </div>
            <div class="col-12 md:col-2" style="text-align: right;">
                <button pButton pRipple icon="pi pi-plus" iconPos="left" class="new-button"
                    label="{{fundConstants.addButton}}" *ngIf="action === 'fund'" (click)="newFund()"></button>
            </div>
            
        </div><br>
        <div class="p-fluid p-formgrid grid">
            <div class="field col-12 md:col-4">
                <div class="p-inputgroup">
                    <span class="p-float-label p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input type="text" id="search1" class="inputSearch" pInputText
                            placeholder="{{fundConstants.placehorders.applicant}}" [(ngModel)]="busqueda"
                            (keyup)="searchData($event)" />
                        <label for="search1" style="font-size: 13px !important;">{{fundConstants.search}}</label>
                    </span>
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="fechaInicio" dateFormat="dd-mm-yy" placeholder="día-mes-año"
                            class="calendarData" (onSelect)="selectFechas()" (keyup)="selectFechas()"></p-calendar>
                        <label for="search1"
                            style="font-size: 13px !important;">{{fundConstants.placehorders.dateForm}}</label>
                    </span>
                </div>
                <small class="ng-dirty ng-invalid" *ngIf="selectFechaInit && !fechaInicio">El formato de fecha debe ser
                    día/mes/año Ejem. 25-12-2024</small>
            </div>

            <div class="field col-12 md:col-4">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="fechaFin" dateFormat="dd-mm-yy" placeholder="día-mes-año"
                            class="calendarData" (onSelect)="selectFechas()" (keyup)="selectFechas()"></p-calendar>
                        <label style="font-size: 13px !important;"
                            for="search1">{{fundConstants.placehorders.dateTo}}</label>
                    </span>
                </div>
                <small class="ng-dirty ng-invalid" *ngIf="selectFechaFin && !fechaFin">El formato de fecha debe ser
                    día/mes/año Ejem. 25-12-2024</small>
            </div>
        </div>
        <div class="px-2">
            <p-table #dt [value]="fondos" responsiveLayout="stack" [rows]="properties.paginationRange"
                [globalFilterFields]="['nombre','valor','estado']" styleClass="p-datatable-products" [paginator]="true"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blocked"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0"></h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="nombreFondo">{{fundConstants.fundName.label}}<p-sortIcon
                                field="nombreFondo">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="custodio.nombre">{{fundConstants.nameCustodian.label}}<p-sortIcon
                                field="custodio.nombre">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="fechaCreacion">{{fundConstants.dateCreation.label}}<p-sortIcon
                                field="fechaCreacion"></p-sortIcon>
                        </th>
                        <th pSortableColumn="porcentajeReposicionFondo">
                            {{fundConstants.percentageReposition.label}}<p-sortIcon
                                field="porcentajeReposicionFondo"></p-sortIcon>
                        </th>
                        <th pSortableColumn="valorFondo">{{fundConstants.fundValue.label}}<p-sortIcon
                                field="valorFondo"></p-sortIcon>
                        </th>
                        <th pSortableColumn="montoAprobacion">{{fundConstants.amountApproved.label}}<p-sortIcon
                                field="montoAprobacion"></p-sortIcon>
                        </th>

                        <th>{{properties.accions}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fondo>
                    <tr>
                        <td><span
                                class="p-column-title">{{fundConstants.fundName.label}}</span><span>{{fondo.nombreFondo}}</span>
                        </td>
                        <td><span
                                class="p-column-title">{{fundConstants.nameCustodian.label}}</span><span>{{fondo.custodio?.nombre}}
                                {{fondo.custodio?.apellido}}</span></td>
                        <td><span
                                class="p-column-title">{{fundConstants.dateCreation.label}}</span>{{fondo.fechaCreacion
                            | date: 'dd-MM-yyyy'}}</td>
                        <td><span
                                class="p-column-title">{{fundConstants.percentageReposition.label}}</span>{{fondo.porcentajeReposicionFondo}}
                        </td>
                        <td><span class="p-column-title">{{fundConstants.fundValue.label}}</span>{{fondo.valorFondo | currency: 'USD'}}
                        </td>
                        <td><span
                                class="p-column-title">{{fundConstants.amountApproved.label}}</span>{{fondo.montoAprobacion | currency: 'USD'}}
                        </td>
                        <td style="width:14%; min-width:10rem;">
                            <span class="p-column-title">{{properties.accions}}</span>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-database"
                                    class="p-button-rounded p-button-text button-view-action" (click)="goTonnage(fondo)"
                                    title="Listado de arqueos"
                                    *ngIf="action === 'arqueo' || action === 'arqueoCaja'"></button>

                                <button pButton pRipple icon="pi pi-eye"
                                    class="p-button-rounded p-button-text button-view-action" (click)="openResumen(fondo)"
                                    title="Resumen de fondo"
                                    *ngIf="action === 'arqueo'"></button>

                                <button pButton pRipple icon="pi pi-pencil" *ngIf="visibleButtons && !onlyArqueo"
                                    class="p-button-rounded p-button-text button-view-action"
                                    (click)="updateFund(fondo)" title="Actualizar fondo"></button>
                                <!-- <button pButton pRipple icon="pi pi-trash" *ngIf="visibleButtons && fondo.eliminar"
                            class="p-button-rounded p-button-text button-view-action" (click)="eliminarFondo(fondo)" title="Eliminar fondo"
                            ></button> -->
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

    </div>
    <app-tonnage *ngIf="viewTonage" [viewTonage]="viewTonage" (tonageForm)="closeTonage($event)"
        [singleFondo]="singleFondo" [visibleButtons]="visibleButtons" [onlyArqueo]="onlyArqueo"></app-tonnage>
    <app-new-fund *ngIf="viewNewFund" [viewNewFund]="viewNewFund" [fondo]="fondo"
        (newFundForm)="closeNewFund($event)"></app-new-fund>
    <app-new-tonnage *ngIf="viewNewTonage" [viewNewTonage] = "viewNewTonage" 
    (newTonageForm) = "closeNewTonage($event)" [fondo] = "singleFondo" [isEdit] = "false" [isSumary]="isSumary"
    [arqueo] = "arqueo"></app-new-tonnage>

</div>
<p-dialog [(visible)]="deleteFondo" header="{{properties.confirmation}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Esta seguro de eliminar este fondo?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="deleteFondo = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="confirmDelete()"></button>
    </ng-template>
</p-dialog>
