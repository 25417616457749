import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MenuModel } from 'src/app/models/menus.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { AuthorizationService } from 'src/app/common/auth.service';

@Component({
  selector: 'app-menu-form',
  templateUrl: './menu-form.component.html',
  providers: [MessageService, ConfirmationService],
})
export class MenuFormComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  @Input() menu = new MenuModel();
  @Input() isFather = new MenuModel();
  @Input() isEdit = new MenuModel();
  @Input() idMenuPadre: number;
  submitted: boolean = false;
  state: boolean = false;
  @Output() menuAccion = new EventEmitter<any>();
  isMandatoryUrl: boolean = false;

  constructor(private messageService: MessageService, private securityServices: SecurityService,  private auth: AuthorizationService) { }

  ngOnInit(): void {
    this.isMandatoryUrl = false;
    if (this.isEdit) {
      this.state = this.menu.estado == 'A' ? true : false;
    } else if (this.isFather) {
      let idFather = this.menu.idMenu
      this.menu = new MenuModel();
      this.menu.idMenuPadre = idFather;
      this.isMandatoryUrl = true;
    } else if (this.menu.idMenu) {
       this.state = this.menu.estado == 'A' ? true : false;     
    }
    if (this.menu && this.menu.idMenuPadre) {
      this.isMandatoryUrl = true;
    } 
  }

  saveMenu() {
    try {
      this.submitted = true;
      if (this.menu.nombreMenu && this.menu.orden && this.menu.descripcion && this.menu.icono && ((this.isMandatoryUrl && this.menu.url) || !this.isMandatoryUrl)){
          this.menu.estado = this.state ? 'A' : 'I';
          this.menu.idMenuPadre = this.idMenuPadre ? this.idMenuPadre : null;
          if (this.menu.idMenu) {
            this.menu.idMenu = Number.parseInt(this.menu.idMenu.toString());
           this.updateMenu();
          } else {
           this.createMenu();
          }
      
        
      }
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err, life: this.properties.timeLifeMessages });
    }
  }

  cancelMenu() {
    this.menuAccion.emit();
  }

  createMenu() {
    this.menu.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.menu.idUsuarioActualizacion = this.auth.getCurrentUser().userData.email
    this.securityServices.createMenu(this.menu).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.menu.messages.create, life: this.properties.timeLifeMessages });
        this.goToMenus();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateMenu() {
    this.menu.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.updateMenu(this.menu).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail:  this.securityConstants.menu.messages.update, life: this.properties.timeLifeMessages });
       
        this.goToMenus();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  goToMenus() {
    setTimeout(() => {
      this.menu = new MenuModel();
      this.menuAccion.emit();
    }, 3000);
  }

}
