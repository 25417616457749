<p-dialog [(visible)]="isCashReplenishment" [modal]="true" [responsive]="true" [showHeader]="false"
    [breakpoints]="{'960px': '90vw'}" [style]="{width: '80%', minHeight:'620px'}" [minY]="100" position="right"
    [contentStyle]="{padding:0}" position="right" styleClass="p-cdialog-right" [closeOnEscape]="false"
    transitionOptions="550ms cubic-bezier(0, 0, 0.2, 1)">
    <div class="grid">
        <div class="height-bar col-12">
            <div class="p-fluid p-formgrid grid pt-3 px-3 pb-2">
                <div class="col-11 md:col-11">
                    <span
                        style="color: white; font-size: 25px;"><b>{{supportConstants.approveReposition.grandTitle}}</b></span>
                </div>
                <div class="col-1 md:col-1" style="text-align: end;">
                    <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                        class="p-button-rounded p-button-outlined" (click)="closeApproveReposition()"></button>
                </div>

                <div class="col-12 md:col-3">
                    <span style="color: white;">{{supportConstants.approveReposition.nroSolicitud.label}}</span><br>
                    <span style="color: white; font-size: 25px;"><b>{{requirement.idSolicitud}} </b></span>
                </div>
                <div class="col-12 md:col-3">
                    <span style="color: white;">{{requirement.solicitante?.email}}</span><br>
                    <span style="color: white; font-size: 25px;">{{requirement.solicitante.nombre}}</span>
                </div>
                <div class="col-0 md:col-1">

                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="search1" class="inputSearch" pInputText [(ngModel)]="valorReponer"
                                style="font-size: 24px; font-weight: 600; color: #E11F1D;" mode="currency"
                                currency="USD" locale="en-US" readonly>

                            <label for="search1"
                                style="color: #E11F1D !important;">{{supportConstants.approveReposition.valueReposition}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="search1" class="inputSearch" pInputText [(ngModel)]="valorTotal"
                                style="font-size: 24px; font-weight: 600;" readonly>
                            <label for="search1">{{supportConstants.approveReposition.totalFund}}</label>
                        </span>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12 pt-4">
            <div class="p-fluid p-formgrid grid mx-3 card">
                <div class="col-12 md:col-3">
                    <span><b>{{supportConstants.approveReposition.reasonRequest.label}}</b></span><br>
                    <span>{{requirement.motivoSolicitud?.nombre}}</span>
                </div>
                <div class="col-12 md:col-3">
                    <span><b>{{supportConstants.approveReposition.dateCreate.label}}</b></span><br>
                    <span>{{requirement.fechaSolicitud | date : "dd-MM-yyyy"}}</span>
                </div>
                <div class="col-12 md:col-3">
                    <span><b>{{supportConstants.approveReposition.solicitudType.label}}</b></span><br>
                    <span>{{requirement.tipoSolicitud?.nombre}}</span>
                </div>
                <div class="col-12 md:col-12">
                    <hr>
                </div>

            </div>
        </div>
        <div class="col-12 p-4">
            <div class="p-fluid p-formgrid grid">
                <div class="col-12 md:col-10">
                    <span class="title-screen">{{supportConstants.approveReposition.title}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-arrow-up" iconPos="right" class="comment-button"
                        label="{{supportConstants.approveReposition.hideSummary}}" (click)="showResumen()"
                        *ngIf="resumen"></button>
                    <button pButton pRipple icon="pi pi-arrow-down" iconPos="right" class="comment-button"
                        label="{{supportConstants.approveReposition.showSummary}}" (click)="showResumen()"
                        *ngIf="!resumen"></button>
                </div>
            </div>
            <div *ngIf="resumen">
                <p-table #dt [value]="sumMoviminetos" responsiveLayout="stack" [rows]="properties.paginationRange"
                    [globalFilterFields]="['nombre','valor','estado']" styleClass="p-datatable-products"
                    [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedRequirement"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                    selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                    <ng-template pTemplate="caption">
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0"></h5>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>

                            <th pSortableColumn="nombre">
                                Cuenta<p-sortIcon field="nombre">
                                </p-sortIcon>
                            </th>
                            <th pSortableColumn="valor">Base Iva 0<p-sortIcon field="valor"></p-sortIcon>
                            </th>
                            <th pSortableColumn="abreviacion">Base Iva<p-sortIcon field="valor"></p-sortIcon>
                            </th>
                            <th pSortableColumn="abreviacion">Iva<p-sortIcon field="valor"></p-sortIcon>
                            </th>
                            <th pSortableColumn="abreviacion">Ice<p-sortIcon field="valor"></p-sortIcon>
                            </th>
                            <th pSortableColumn="valorIngles">{{supportConstants.approveReposition.total}}<p-sortIcon
                                    field="valor"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-sumMovimineto>
                        <tr>
                            <td><span class="p-column-title">Cuenta</span><span>{{ sumMovimineto.cuenta }}</span></td>
                            <td><span class="p-column-title">Base Iva 0</span>{{(sumMovimineto.baseiva0 ?? 0) |
                                currency: 'USD'}}</td>
                            <td><span class="p-column-title">Base Iva</span>{{(sumMovimineto.baseiva ?? 0) | currency:
                                'USD'}}</td>
                            <td><span class="p-column-title">Iva</span>{{(sumMovimineto.totalIva ?? 0) | currency:
                                'USD'}}</td>
                            <td><span class="p-column-title">Ice</span>{{(sumMovimineto.ice ?? 0) | currency: 'USD'}}
                            </td>
                            <td><span class="p-column-title">{{(supportConstants.approveReposition.total ??
                                    0)}}</span>{{sumMovimineto.valorTotal | currency: 'USD'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

        </div>
        <div class="col-12 pr-4 pl-4">
            <div class="p-fluid p-formgrid grid">
                <div class="col-12 md:col-7">
                    <span class="title-screen">{{supportConstants.approveReposition.title2}}</span>
                </div>
            </div>
        </div>
        <div class="col-12 p-4">
            <div class="p-fluid p-formgrid grid">

                <div class="field col-12 md:col-7">
                    <div class="p-inputgroup">
                        <span class="p-float-label p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="text" id="search1" class="inputSearch-repo" pInputText
                                placeholder="{{dashboardConstants.requirements.placehorders.reposition}}"
                                [(ngModel)]="busqueda" (keyup)="searchData($event)" />
                            <label for="search1"
                                style="font-size: 13px !important;">{{dashboardConstants.requirements.search}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-1" style="text-align: end; ">
                    <div>
                        <button pButton pRipple icon="pi pi-comment" (click)="openComment(requirement,null)"
                            class=" p-button-text" style="border: 1px solid #27272D;" *ngIf="!isView"></button>
                    </div>

                </div>
                <div class="col-12 md:col-2" style="text-align: left;">
                    <button pButton pRipple icon="pi pi-times" iconPos="right" class="cancel-button"
                        label="Rechazar reposición" (click)="dialogoRechazar()" *ngIf="!isView"></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: left;">
                    <button pButton pRipple icon="pi pi-check" iconPos="right" class="new-button"
                        label="Aprobar reposición " (click)="dialogAprobar()" *ngIf="!isView"></button>
                </div>
            </div>

            <p-table #dt [value]="movimientos" responsiveLayout="stack" [rows]="properties.paginationRange"
                [globalFilterFields]="['nombre','valor','estado']" [paginator]="true" styleClass="p-datatable-products"
                [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedRequirement"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                selectionMode="multiple" [rowHover]="true" dataKey="idRquirement">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0"></h5>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="nombre">
                            {{supportConstants.approveReposition.nroSolicitud.label}}<p-sortIcon
                                field="nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombre">
                            {{supportConstants.approveReposition.groupDocument.label}}<p-sortIcon
                                field="nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="nombre">
                            {{supportConstants.approveReposition.typeDocument.label}}<p-sortIcon
                                field="nombre"></p-sortIcon>
                        </th>
                        <th pSortableColumn="valor">
                            {{supportConstants.approveReposition.fiscalNumberIdentification.label}}<p-sortIcon
                                field="valor"></p-sortIcon>
                        </th>
                        <th pSortableColumn="abreviacion">
                            {{supportConstants.approveReposition.dateInvoice.label}}<p-sortIcon
                                field="valor"></p-sortIcon>
                        </th>
                        <th pSortableColumn="estado">Base Iva 0<p-sortIcon field="estado">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="valorIngles">Base Iva<p-sortIcon field="valor"></p-sortIcon>
                        </th>

                        <th pSortableColumn="estado">{{supportConstants.approveReposition.iva}}<p-sortIcon
                                field="estado">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="estado">Ice<p-sortIcon field="estado">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="estado">{{supportConstants.approveReposition.total}}<p-sortIcon
                                field="estado">
                            </p-sortIcon>
                        </th>
                        <th>{{properties.accions}}</th>
                        <th>
                            <button pButton pRipple icon="pi pi-check" class="p-button-rounded p-button-text "
                                style="background-color: white !important; color: #E11F1D;"
                                (click)="aprobarTodas()"></button>
                        </th>
                        <th>
                            <button pButton pRipple icon="pi pi-times" class="p-button-rounded p-button-text"
                                style="background-color: white !important; color: #E11F1D;"
                                (click)="rechazarTodas()"></button>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-movimiento>
                    <tr>

                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.nroIdentification}}</span><span>{{
                                movimiento?.solicitud?.idSolicitud }}</span></td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.groupDocument.label}}</span>{{movimiento.grupoMovimiento?.nombre}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.typeDocument.label}}</span>{{movimiento.tipoDocumento?.nombre}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.fiscalNumberIdentification.label}}</span>{{movimiento.numeroIdentificacion}}
                        </td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.dateInvoice.label}}</span>{{movimiento.fechaFactura
                            | date : "dd-MM-yyyy" }}</td>
                        <td><span class="p-column-title">Base Iva 0</span>{{(movimiento.baseiva0 ?? 0) | currency:
                            'USD'}}</td>
                        <td><span class="p-column-title">Base Iva</span>{{(movimiento.baseiva ?? 0) | currency: 'USD'}}
                        </td>
                        <td><span class="p-column-title">Iva</span>{{(movimiento.totaliva ?? 0) | currency: 'USD'}}</td>
                        <td><span class="p-column-title">Ice</span>{{(movimiento.ice ?? 0 ) | currency: 'USD'}}</td>
                        <td><span
                                class="p-column-title">{{supportConstants.approveReposition.total}}</span>{{(movimiento.valorTotal
                            ?? 0) | currency: 'USD'}}</td>

                        <td style="width:14%; min-width:10rem;">
                            <span class="p-column-title">{{properties.accions}}</span>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-eye"
                                    class="p-button-rounded p-button-text button-view-action"
                                    (click)="viewSupport(movimiento)"></button>
                                <button pButton pRipple icon="pi pi-comment"
                                    class="p-button-rounded p-button-text button-view-action"
                                    (click)="openComment(null,movimiento)"></button>
                            </div>
                        </td>
                        <td>
                            <button pButton pRipple icon="pi pi-circle"
                                *ngIf="movimiento.estado === 'I' || movimiento.estado === 'R' "
                                class="p-button-rounded p-button-text" style="color: #0052A1;"
                                (click)="changeStatus(movimiento, acction = 'aceptar')"></button>
                            <button pButton pRipple icon="pi pi-check" *ngIf="movimiento.estado === 'A'"
                                class="p-button-rounded p-button-text" style="color: #0052A1;"
                                (click)="changeStatus(movimiento,acction = 'nulo')"></button>

                        </td>
                        <td>
                            <button pButton pRipple icon="pi pi-circle"
                                *ngIf="movimiento.estado === 'I' || movimiento.estado === 'A'"
                                class="p-button-rounded p-button-text"
                                (click)="changeStatus(movimiento, acction = 'rechazar')"></button>
                            <button pButton pRipple icon="pi pi-times" *ngIf="movimiento.estado === 'R'"
                                class="p-button-rounded p-button-text" style="color: #0052A1;"
                                (click)="changeStatus(movimiento, acction = 'nulo')"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <app-new-support *ngIf="newSupport" [isView]="isViewSupport" [newSupport]="newSupport" [requirement]="requirement"
        [movimiento]="movimiento" (supportForm)="closeSupport($event)"></app-new-support>
</p-dialog>

<p-dialog [(visible)]="aprobar" header="{{properties.confirmation}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de aprobar la reposición?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="aprobar = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="aprobarReposicion()"></button>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="rechazar" header="Al rechazar la reposición, se liberarán todos requerimientos asociados."
    [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de recharzar la reposción?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="rechazar = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="rechazarReposicion()"></button>
    </ng-template>
</p-dialog>

<app-comments *ngIf="createComment" [createComment]="createComment" (closeComment)="closeCommentForm($event)"
    [singleSolicitud]="solicitud" [movimiento]="movimiento">

</app-comments>