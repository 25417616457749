<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3">
            <div class="field col-12 md:col-10">
                <h2 style="color: white;">Catálogos</h2>
                <h6 style="color: white;">Aquí puedes gestionar tus catálogos</h6>
            </div>

        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-breadcrumb  *ngIf="items.length > 0" (onItemClick)="onItemClick($event)" [model]="items" [home]="home"></p-breadcrumb>
            <p-toolbar styleClass="mb-1">
                <ng-template pTemplate="left">
                    <h5></h5>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="isNewCatalog" pButton pRipple id="saveCatalog" icon="{{properties.guardar.icono}}"
                        label="{{properties.guardar.valor}}" class="new-button mr-2"
                        (click)="saveCatalog()"></button>
                    <button *ngIf="isNewCatalog" pButton pRipple id="cancelCatalog" icon="{{properties.cancelar.icono}}"
                        label="{{properties.cancelar.valor}}" class="p-button-danger" (click)="cancelCatalog()"></button>

                    <button *ngIf="!isNewCatalog" pButton pRipple id="newCatalog" icon="{{properties.nuevo.icono}}"
                        label="{{properties.nuevo.valor}}" class="new-button mr-2 mb-2" (click)="newCatalog()"></button>
                    <button *ngIf="!isNewCatalog && selectedCatalogs && selectedCatalogs.length> 0 " pButton pRipple
                        id="deleteCatalog" icon="{{properties.eliminar.icono}}" label="{{properties.eliminar.valor}}"
                        class="p-button-danger mr-2 mb-2" (click)="deleteSelectedCatalogs()"></button>
                </ng-template>
            </p-toolbar>
            
            <app-catalog-form *ngIf="isNewCatalog" (catalogAccion)="cancelCatalog()" [catalog]="catalog" [catalogPadre]="catalogPadre"></app-catalog-form>
            <app-catalog-list *ngIf="!isNewCatalog" (catalogAccion)="catalogAccion($event)"  [catalogPadre]="catalogPadre"></app-catalog-list>
        </div>


        <p-dialog [(visible)]="deleteCatalogsDialog" header="{{properties.confirmation}}" [modal]="true"
            [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>{{securityConstants.catalog.confirmationMessages.deletes}}</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                    (click)="deleteCatalogsDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                    (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>