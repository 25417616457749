import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/service/securityservice';
import { AuthorizationService } from '../../../common/auth.service';
import { CommonService, MESSAGE_TYPE } from '../../../utilities/commonservice';
import { SecurityConstants } from '../security.constants';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GenericService } from 'src/app/common/genericservice';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import * as Msal from 'msal';
import * as msal from "@azure/msal-browser";
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Properties } from 'src/app/utilities/properties';

var selfG;
@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ["./app.login.component.scss"],
  providers: [MessageService]
})

export class AppLoginComponent implements OnInit, OnDestroy {
  isUserLogged: boolean = false;
  properties: Properties = new Properties();
  public readonly securityConstants = SecurityConstants;
  private readonly _destroy = new Subject<void>();
  constructor(
    private router: Router,
    private securityServices: SecurityService,
    private authService: AuthorizationService,
    private genericService: GenericService,
    private commonService: CommonService,
    private auth: AuthorizationService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadService: MsalBroadcastService,
    private msalAuthService: MsalService,
    private messageService: MessageService
  ) {

  }
  username: string;
  passWd: string;
  tieneMFA = false;
  poolData: any;
  codigoMFA: string;
  @ViewChild('txtMfa') txtMfa: ElementRef;
  usertype: string = '';
  codeToken = ''
  codeVerifier = ''
  notFoundUser = false
  empresas = []
  submitted = false
  idEmpresa = null
  async ngOnInit(): Promise<void> {
    this.idEmpresa = this.properties.idEmpresa
    if (this.auth.getCurrentUser()) {
      this.router.navigate(['/principal/dashboard/requirements']);
    }
    //this.getEmpresas()
    await this.msalAuthService.instance.initialize()

    const url = window.location.href;
    const fragment = url.split('#')[1];
    const params = new URLSearchParams(fragment);
    const authorizationCode = params.get('code');
    if (authorizationCode) {
      const keys = Object.keys(sessionStorage);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const valor = key.split('.');
        if (valor[3] === 'params') {
          this.codeToken = sessionStorage.getItem(key);
          break;
        }
      }
      if (!this.auth.getCurrentUser()) {
        const decodedToken = await JSON.parse(atob(this.codeToken));
        this.codeVerifier = decodedToken.codeVerifier
        const bodyToken = {
          code: authorizationCode,
          codeVerifier: this.codeVerifier,
          type: environment.type,
          idEmpresa: this.auth.getCurrentEmpresa()
        }
        const user = await this.securityServices.getToken(bodyToken)
        if (user.code == 500) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: user.description, life: this.properties.timeLifeMessages });
          setTimeout(async () => {
            await this.logout();
          }, 3000);
          this.router.navigate(['/']);
        } else {
          this.authService.setCurrentUser(user);
          await this.getInfoUser(user.userData.idUsuario)
        }
      } else {
        this.router.navigate(['/principal/dashboard/requirements']);
      }
    }
    this.usertype = 'Solicitante'
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  reset() {
    this.router.navigate(['/reenvio']);
  }

  async logout() {
    localStorage.clear();
    sessionStorage.clear();
    const msalConfig = {
      auth: {
        clientId: atob(environment.idClient),
        authority: environment.urlLogin + atob(environment.tenanId),
        redirectUri: environment.urlFrontend,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    }
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    await msalInstance.initialize()
    msalInstance.logout({
      postLogoutRedirectUri: environment.urlFrontend + '/login',
    })
  }
  async login() {
    if (!this.auth.getCurrentUser()) {
      sessionStorage.clear();
      if (this.idEmpresa != null) {
        this.auth.setCurrentEmpresa(this.idEmpresa)
        this.msalAuthService.loginRedirect();
      } else {
        this.submitted = true
      }
    } else {
      this.router.navigate(['/principal/dashboard/requirements']);
    }


  }

  /**
   * Obtiene los datos del usuario conectado
   */
  async getInfoUser(idUsuario) {
    this.genericService.manageBlockUI(true);
    this.securityServices.getInfoUser(idUsuario).then(respInfo => {
      this.genericService.manageBlockUI(false);
      this.authService.setCurrentMenu(respInfo.data.menus);
      if (respInfo.code === 200) {
        this.router.navigate(['/principal/dashboard/requirements']);
      } else {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
        this.commonService.addMessage(MESSAGE_TYPE.ERROR, respInfo.description);
      }
    }).catch(err => {
      console.log(err);
      this.genericService.manageBlockUI(false);
      this.commonService.addMessage(MESSAGE_TYPE.ERROR, this.securityConstants.login.messages.error);
    });
  }
  async getEmpresas() {
    await this.securityServices.getEmpresas().then(resp => {
      if (resp.code === 200) {
        this.empresas = resp.data;
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
}
