export const DocumentsConstants = {
    title : "Consulta de documentos",
    subtitle : "Consulta los documentos de soporte",
    acctions : '',
    nameDocument : {
        label : 'Número del documento'
    },
    numberIdentificator : {
        label : 'Número de identificación fiscal'
    },
    socialReason : {
        label : 'Razón social'
    },
    numberAutorization : {
        label : 'Número de autorización'
    },
    numberSAP:{
        label :'Número de SAP'
    },
    requirements : {
        search : 'Buscar',
        placehorders : {
            applicant : 'Puedes buscar por número de documento, número de solicitud o código SAP',
            dateForm : 'Fecha desde',
            dateTo : 'Fecha hasta'
        }
    }

 }