import { Component } from '@angular/core';
import { SecurityConstants } from '../security.constants';
import { environment } from 'src/environments/environment';
import * as msal from "@azure/msal-browser";
@Component({
  selector: 'app-notfound',
  templateUrl: './app.notfound.component.html',
})
export class AppNotfoundComponent {
  public readonly securityConstants = SecurityConstants;

  async logout(){
    localStorage.clear();
    sessionStorage.clear();
    const msalConfig = {
        auth: {
          clientId: atob(environment.idClient),
          authority: environment.urlLogin+atob(environment.tenanId),
          redirectUri: environment.urlFrontend, 
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    await msalInstance.initialize()
    msalInstance.logout({
        postLogoutRedirectUri: environment.urlFrontend+'/login',
    })
}
}
