import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Configuration } from './configuration';
import { Response } from './Response';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

export enum METHODS {
    POST = 'post',
    GET = 'get',
    UPDATE = 'update',
    PUT = 'put',
    DELETE = 'delete',
    FORMDATA = 'formData',
    FILE = 'file'
}


@Injectable({providedIn: 'root'})
export class GenericService {

    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    @BlockUI() blockUI: NgBlockUI;
    constructor(private _http: HttpClient) {
    }

    public manageBlockUI(bandera: boolean) {
        if (bandera) {
            this.blockUI.start();
        } else {
            this.blockUI.stop();
        }
    }

    /**
     * Ete metodo construye genericamente las llamadas a servicios http segun el las necesidades para el proyecto cnm
     * donde & methodoHttpType &
     *
     * @param methodoHttpType Metodo a ser enviado al servidor GET, POST, UPDATE, DELETE
     * @param context contexto del servicio parte del servidor
     * @param body En el caso de POST datos de envio
     * @param parameterRequired parametros requeridos de la llamada
     * @param additionalParameters  parametros adicionales de llamada
     */
    public genericCallServices(methodoHttpType, context: string, body: any, parameterRequired: string, additionalParameters: any):
        Promise<Response> {
        let contextoTypeEnd = context;
        if (parameterRequired) {
            contextoTypeEnd = contextoTypeEnd + `/${parameterRequired}`;
        }
        return this.switchMethodHttp(methodoHttpType, contextoTypeEnd, body, additionalParameters);
    }

    public switchMethodHttp(methodoHttpType, contextoTypeEnd, body, additionalParameters, observe = false,  reportProgress = false) {
        let queryParameters = new HttpParams();
        if (additionalParameters) {
            additionalParameters.forEach((value: string, key: string) => {
                queryParameters = queryParameters.append(key, value);
            });
        }

        let headers = this.defaultHeaders;
        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        // to determine the Content-Type header
        const consumes: string[] = ['application/json' ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);

        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (methodoHttpType === METHODS.GET || methodoHttpType === METHODS.DELETE) {
            
            return this._http[methodoHttpType](contextoTypeEnd, {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }).toPromise().then(resp => {
                this.blockUI.stop();
                return Promise.resolve(resp);
            }).catch(this.handleError());
        } else if (methodoHttpType === METHODS.POST || methodoHttpType === METHODS.PUT) {
            this.blockUI.start();
            if (body) {
                Object.keys(body).forEach(key => !body[key] && body[key] !== undefined  && body[key] !== 0  && body[key] !== false && delete body[key]);
            }
            return this._http[methodoHttpType](contextoTypeEnd, body, {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }).toPromise().then(resp => {
                    this.blockUI.stop();
                    return Promise.resolve(resp);
            }).catch(this.handleError());
        } else if (methodoHttpType === METHODS.FORMDATA) {
            this.blockUI.start('');
            return this._http.put(contextoTypeEnd, body).toPromise().then(resp => {
                this.blockUI.stop();
                return Promise.resolve(resp);
            }).catch(this.handleError()
            );
        } else if (methodoHttpType === METHODS.FILE) {
            this.blockUI.start('');
            const httpOptions = {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                responseType: 'blob' as 'json'
            };
            return this._http.get<any>(contextoTypeEnd, httpOptions).toPromise().then(resp => {
                this.blockUI.stop();
                return Promise.resolve(resp);
            }).catch(this.handleError());
        }
    }
    /**
     * Devuelve los datos en caso de error
     * @param res Respuesta con los datos
     */
    showError(res) {
        this.blockUI.stop();
        const result = res.data;
        return result;
    }

    /**
     * Maneja el error en el proyecto
     */
    private handleError() {
        return (res: any) => {
            let errMessage: any;
            try {
                this.blockUI.stop()
                if (res.status === 412) {
                    errMessage = { status: res.status, message: res.text() };
                } else if (res.status === 401) {

                } else if (res.status === 0 && res.statusText === 'Unknown Error') {
                    res.message = 'Problemas de conectividad, verifique su conexión a Internet';
                    errMessage = res.json();
                } else {
                    errMessage = res.json();
                }
            } catch (err) {
                this.blockUI.stop();
                throw res;
            }
            this.blockUI.stop();
            const error: any = new Error(errMessage);
            error.timestamp = Date.now();
            throw error;
        };
    }

}