import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthorizationService } from 'src/app/common/auth.service';
import { SecurityService } from 'src/app/service/securityservice';
import { CommonService } from 'src/app/utilities/commonservice';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { Utils } from 'src/app/utilities/utils';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html'
})
export class PerfilComponent implements OnInit {
  user: any;
  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  imagenQR: string;
  codigoMfa: string;
  mfaStatus: boolean;
  gmailStatus: boolean;
  docusignStatus: boolean;
  paramCode = this.router.snapshot.paramMap.get('code');
  activarCodigo = false;
  gmail = "GMAIL-";
  docuSign = "DOCUSIGN-";
  constructor(
    private auth: AuthorizationService,
    private router: ActivatedRoute,
    private utils: Utils,
    public commonService: CommonService,
    private messageService: MessageService,
    private securityService: SecurityService) {

  }

  ngOnInit(): void {
    this.getSucursal();
    this.user = this.auth.getCurrentUser().userData;
      if (this.paramCode && this.paramCode.startsWith(this.gmail) ) {
        this.paramCode = this.paramCode.replace(this.gmail, '');
      } else if (this.paramCode && this.paramCode.startsWith(this.docuSign) ) {
        this.paramCode = this.paramCode.replace(this.docuSign, '');
      }
      this.mfaStatus = !!(this.user.mfaStatus || this.user.mfaStatus === this.properties.stateActive);
  }

  getSucursal() {
    this.securityService.getCatalogs
  }

  saveUser() {
    this.user.idUsuario = this.user.userId;
    this.securityService.updateUser(this.user).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.perfil.messages.update, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      console.log(err)
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err, life: this.properties.timeLifeMessages });
    })
  }

   /**
   * Se encarga de activar o inactivar el código MFA
   */
    generateMfa () {  
      if (!this.mfaStatus) {
        this.activarCodigo = true;
      } else {
        this.securityService.mfaGenerateQR().then(resp => {
          if (resp.code === 200) {
            this.imagenQR = resp.data.qr;
          } else {
            this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
          }
        }).catch(err => {
          console.log(err)
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.perfil.messages.errorQR, life: this.properties.timeLifeMessages });
        });
      }
    }
    /**
     * Se encarga de activar o inactivar el código MFA
     */
    validateMfa() {
      if (this.codigoMfa) {
        this.activarCodigo = false;
        this.securityService.validateMfa(this.codigoMfa).then(resp => {
          if (resp.code === 200) {
            this.mfaStatus = true;
            this.codigoMfa = undefined;
            this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.perfil.messages.mfaActive, life: this.properties.timeLifeMessages });
            this.imagenQR = undefined;
            const userAux = this.auth.getCurrentUser();
            userAux.userData.mfaStatus = this.properties.stateActive;
            this.auth.setCurrentUser(userAux);
          } else {
            this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.perfil.messages.codeNotValid, life: this.properties.timeLifeMessages });
          }
        }, err => {
          console.log(err)
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.perfil.messages.erroValidation, life: this.properties.timeLifeMessages });
        });
      } else {
        document.getElementById('codigoMfaId').focus();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.perfil.messages.emptyCode, life: this.properties.timeLifeMessages });
      }
    }
    /**
     * Inactiva el doble factor MFA
     */
    inactivarMfa() {
      if (this.codigoMfa) {
        this.securityService.disableMfa(this.codigoMfa).then(resp => {
          if (resp.code === 200) {
            this.mfaStatus = false;
            this.activarCodigo = false;
            this.codigoMfa = undefined;
            this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.perfil.messages.mfaInactive, life: this.properties.timeLifeMessages });
            const userAux = this.auth.getCurrentUser();
            userAux.userData.mfaStatus = this.properties.stateInactive;
            this.auth.setCurrentUser(userAux);
          } else {
            this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.perfil.messages.codeNotValid, life: this.properties.timeLifeMessages });
          }
        }, err => {
          console.log(err);
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.perfil.messages.erroValidation, life: this.properties.timeLifeMessages });
        });
      } else {
        document.getElementById('codigoMfaId').focus();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.perfil.messages.emptyCode, life: this.properties.timeLifeMessages });
      }
    }

} 
