<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3">
            <div class="field col-12 md:col-10">
                <h2 style="color: white;">Roles</h2>
                <h6 style="color: white;">Aquí puedes gestionar tus roles</h6>
            </div>

        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-1">
                <ng-template pTemplate="left">
                    <h5></h5>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="isNewRol && !assignMenus" pButton pRipple id="saveRol" icon="{{properties.guardar.icono}}"
                        label="{{properties.guardar.valor}}" class="new-button mr-2" (click)="saveRol()"></button>
                    <button *ngIf="isNewRol" pButton pRipple id="cancelRol" icon="{{properties.cancelar.icono}}"
                        label="{{properties.cancelar.valor}}" class="p-button-danger" (click)="cancelRol()"></button>

                    <button *ngIf="!isNewRol && !assignMenus" pButton pRipple id="newRol" icon="{{properties.nuevo.icono}}"
                        label="{{properties.nuevo.valor}}" class="mr-2 mb-2" (click)="newRol()"></button>
                    <button *ngIf="!isNewRol && !assignMenus && selectedRoles && selectedRoles.length> 0 " pButton pRipple id="deleteRol"
                        icon="{{properties.eliminar.icono}}" label="{{properties.eliminar.valor}}"
                        class="p-button-danger mr-2 mb-2" (click)="deleteSelectedRoles()"></button>
                </ng-template>
            </p-toolbar>
           
            <app-rol-form *ngIf="isNewRol" (rolAccion)="cancelRol()" [rol]="rol"></app-rol-form>
            <app-rol-list *ngIf="!isNewRol" (rolAccion)="rolAccion($event)"></app-rol-list>
        </div>


        <p-dialog [(visible)]="deleteRolesDialog" header="{{properties.confirmation}}" [modal]="true"
            [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>{{securityConstants.rol.confirmationMessages.deletes}}</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                    (click)="deleteRolesDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                    (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>

    </div>
</div>