import { AuditoriaModel } from "../auditoria.model";
import { AdjuntoModel } from "./adjunto.model";
import { CentroCostoMovimientoModel } from "./centroCostoMovimiento.model";
import { Properties } from 'src/app/utilities/properties';
const properties = new Properties();
export class MovimientoComprobanteModel extends AuditoriaModel {
    
    idMovimiento : number;
    idCatalogoTipoMovimiento : number;
    idSolicitud : number;
    fechaMovimiento : Date;
    numeroIdentificacion : string;
    razonSocial : string;
    autorizacionTributaria : string;
    numeroDocumento : string;
    fechaFactura : Date;
    descripcionGasto : string;
    baseiva0 : number;
    baseiva : number;
    ice : number;
    totaliva : number;
    valorTotal : number;
    estado : string;
    subtotal: number;
    secuencial:string;
    puntoVenta: string;
    establecimiento:string;
    idCatalogoGrupoDocumento: number
    idCatalogoTipoDocumento: number
    adjunto: any
    centrosCosto: CentroCostoMovimientoModel[]
    emitidoEmpresa: boolean
    documentos: AdjuntoModel[]
    idEmpresa?: number

    constructor() {
        super();
        this.idMovimiento = null;
        this.idCatalogoTipoMovimiento = null;
        this.idSolicitud = null;
        this.fechaMovimiento = null;
        this.numeroIdentificacion = null;
        this.razonSocial = null;
        this.autorizacionTributaria = null;
        this.numeroDocumento = null;
        this.fechaFactura = null;
        this.descripcionGasto = null;
        this.baseiva0 = null;
        this.baseiva = null;
        this.ice = null;
        this.totaliva = null;
        this.valorTotal = null;
        this.estado = null;
        this.secuencial = null;
        this.puntoVenta = null;
        this.establecimiento = null;
        this.idCatalogoGrupoDocumento = properties.idCatalogoGrupoBien
        this.idCatalogoTipoDocumento = null
        this.adjunto = []
        this.centrosCosto = []
        this.emitidoEmpresa = false
        this.documentos = []
        this.idEmpresa = null
    }
}
