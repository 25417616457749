import { AuditoriaModel } from "./auditoria.model";
export class CatalogModel extends AuditoriaModel {
    idCatalogo?: number;
    nombre: string;
    valor: string;
    valorIngles: string;
    orden: number;
    estado: string
    abreviacion: string;
    abreviacionIngles: string;
    idCatalogoPadre: number;
    idCatalogoRelacionado: number;
    value:number
    idEmpresa: number

    constructor() {
        super();
        this.idCatalogo = null;
        this.idCatalogoPadre = null;
        this.valor = null;
        this.orden = null;
        this.estado = null;
        this.abreviacion = null;
        this.valorIngles = null;
        this.abreviacionIngles = null;
        this.idUsuarioActualizacion = null;
        this.value = null
        this.idEmpresa = null

    }
}
