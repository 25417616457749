import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Properties } from "src/app/utilities/properties";

import { PermisoModel } from "src/app/models/permiso.model";
import { Router } from "@angular/router";
import { FundConstants } from "../fund.constants";
import { ArqueoModel } from "src/app/models/caja-chica/arqueo.model";
import { SecurityService } from "src/app/service/securityservice";
import { MessageService } from "primeng/api";
import { FondoModel } from "src/app/models/caja-chica/fondo.model";
import { AuthorizationService } from "src/app/common/auth.service";
import { UserModel } from "src/app/models/user.model";
import { Utils } from "src/app/utilities/utils";
import { DenominacionModel } from "src/app/models/caja-chica/denominacion.model";
import { log } from "console";
import { CatalogModel } from "src/app/models/catalog.model";

@Component({
    selector: "app-new-tonnage",
    templateUrl: "./new-tonnage.component.html",
})
export class NewTonnageComponent implements OnInit {
    public readonly fundConstants = FundConstants;
    properties: Properties = new Properties();
    permiso: PermisoModel;
    datosSolicitud: any = {};
    soportesLiquidacion: any[] = [];
    blockedRequirement: boolean = false;
    resumen: boolean = true;
    visible: boolean = false;
    @Input() viewNewTonage: boolean;
    @Input() isSumary : boolean
    @Output() newTonageForm = new EventEmitter<any>();
    @Input() arqueo: ArqueoModel;
    @Input() fondo: FondoModel;
    usuario: UserModel = new UserModel();
    blocked: boolean = false;
    @Input() isEdit: boolean;
    submitted: boolean;
    valorDocMovimientos: any;
    valorDocSolicitudes: any;
    valorDocumentos: any;
    valorDinero: any;
    denominacion: DenominacionModel;
    totalDenominacion = 0;
    denominacionTipo:boolean = true
    botonLabel: string
    listDenominaciones: CatalogModel[] = [];
    valesProvisionales : any[] = [];
    comprobantesGastos:any[] = [];
    blockedLiquidacion : boolean = true;
    blockedVale : boolean = true;

    constructor(
        private router: Router,
        private securityService: SecurityService,
        private auth: AuthorizationService,
        private messageService: MessageService,
        private utils: Utils
    ) {}
    /**
     * Inicio de pantalla
     */
    async ngOnInit() {
        this.botonLabel = this.arqueo.idArqueo ? this.fundConstants.newTonnage.viewDenomination : this.fundConstants.newTonnage.addDenomination
        this.arqueo.denominaciones.forEach((objeto) => {
            objeto.denominacion = Number(objeto.denominacion)
        });
        if(!this.isEdit){
            this.arqueo.denominaciones.forEach((objeto) => {
                objeto.isTypeView = true
            });
        }
        
        this.usuario = this.auth.getCurrentUser().userData;
        this.arqueo.fecha = this.arqueo.idArqueo
            ? new Date(this.arqueo.fecha)
            : new Date();
        await this.valorDocumentosMovimientos();
        await this.valorDocumentosSolicitudes();
        await this.getComprobantes()
        await this.getValesProvisionales()
        this.valorDocumentos =
            (this.valorDocSolicitudes ? Number(this.valorDocSolicitudes) : 0) +
            (this.valorDocMovimientos ? Number(this.valorDocMovimientos) : 0);
        this.valorDinero = this.fondo.valorFondo - this.valorDocumentos;
        await this.getCatalog()
        this.arqueo.porcentajeUso = Number( (100 - Number(((this.valorDinero * 100) / this.fondo.valorFondo).toFixed(2))).toFixed(2))
        this.arqueo.valorRequerimientosAprobadas = this.valorDocMovimientos
        this.arqueo.valorRequerimientosEntregadas = this.valorDocSolicitudes
    }

    showDialog() {
        this.visible = true;
    }
    closeNewTonage() {
        const event = {
            type: "close",
        };
        this.newTonageForm.emit(event);
    }

    async getCatalog(){
        await this.securityService.getCatalogsByFather(this.properties.idCatalogoDenominaciones).then(resp => {
          if (resp.code === 200) {
            this.listDenominaciones = resp.data;  
            if (resp.data.length === 0) {
              this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
            }
          } else {
            this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
          }
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
        });
      }
      
    createArqueo() {
        this.blocked = true;
        this.arqueo.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityService
            .createArqueo(this.arqueo)
            .then((resp) => {
                if (resp.code === 200) {
                    this.blocked = false;
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: this.fundConstants.newTonnage.messages.create,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
                const event = {
                    type: "reload",
                };
                this.newTonageForm.emit(event);
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    updateArqueo() {
        this.blocked = true;
        this.arqueo.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityService
            .updateArqueo(this.arqueo)
            .then((resp) => {
                if (resp.code === 200) {
                    this.blocked = false;
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: this.fundConstants.newTonnage.messages.update,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.blocked = false;
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    sendArqueo() {
        this.submitted = true;
        if (
            this.arqueo.fecha &&
            this.usuario.idUsuario &&
            this.arqueo.totalArqueo &&
            this.arqueo.fecha
        ) {
            this.arqueo.idFondo = this.fondo.idFondo;
            //this.arqueo.fecha = this.utils.transformDate(this.arqueo.fecha);
            this.arqueo.estado = this.properties.ESTADO_ARQUEO_CREADO;
            this.arqueo.idUsuarioArqueo = this.usuario.idUsuario;
            if (this.arqueo.idArqueo) {
                this.updateArqueo();
            } else {
                this.createArqueo();
            }
            
        }
    }
    async valorDocumentosMovimientos() {
        this.blocked = true;
        let filterEstados = "L,E,P,A";
        let filterEstadosMovimientos = "A,I,PE";

        await this.securityService
            .valorDocumentosMovimientos(
                filterEstados,
                this.fondo.idFondo,
                this.properties.idTipoSolicitudReembolso,
                filterEstadosMovimientos
            )
            .then((resp) => {
                this.blocked = false;
                if (resp.code === 200) {
                    this.valorDocMovimientos = resp.data;
                    this.valorDocMovimientos =
                        this.valorDocMovimientos[0].valorTotal;
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    async valorDocumentosSolicitudes() {
        this.blocked = true;
        let filterEstados = this.properties.ESTADO_SOLI_RECIBO_ENTREGADO + "," + this.properties.ESTADO_SOLI_ENTREGADA;
        await this.securityService
            .valorDocumentosSolicitudes(
                filterEstados,
                this.fondo.idFondo,
                this.properties.idTipoSolicitudReembolso
            )
            .then((resp) => {
                this.blocked = false;
                if (resp.code === 200) {
                    this.valorDocSolicitudes = resp.data;
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    async agregarDenominacion() {
        this.denominacion = new DenominacionModel();
        this.arqueo.denominaciones.push(this.denominacion);
    }
    async deleteDenominacion(item) {
        this.arqueo.totalArqueo = 0;
        if (this.arqueo.idArqueo) {
            item.estado = this.properties.stateInactive
          await this.updateDenominacion(item)  
            
        } 
        this.arqueo.denominaciones = this.arqueo.denominaciones.filter(
            (obj) => obj !== item
        );
        this.arqueo.denominaciones.forEach((objeto) => {
            if (objeto.valor !== null) {
                this.arqueo.totalArqueo += Number(objeto.valor);
            }
        });
    }
    async guardarDenominacion(item) {
        this.arqueo.totalArqueo = 0;
        if (this.arqueo.idArqueo && item.idDenominacion) { 
          await this.updateDenominacion(item)  
        } 
        if(this.arqueo.idArqueo && item.idDenominacion == null){
            item.idArqueo = this.arqueo.idArqueo
            await this.createDenominacion(item)
        }
        this.arqueo.denominaciones.forEach((objeto) => {
            if (objeto.valor !== null) {
                this.arqueo.totalArqueo += Number(objeto.valor);
            }
        });
    }
    async validarDenominacion(cadena, item){
        let valorSelect = await this.listDenominaciones.filter(
            (x) => x.idCatalogo == item.denominacion
        );
        let value = valorSelect[0].valor
        item.isTypeView = Number(value) > 0 ? true : false
        item.valor = item.isTypeView == true ? (Number(value) * Number(item.cantidad)).toFixed(2) : item.valor 
        item.cantidad = item.isTypeView == false ? 1 : item.cantidad
        this.arqueo.totalArqueo = 0;
        let valueRepet = false
        let cont = 0
        let denominaciones = this.arqueo.denominaciones
        for (const iterator of denominaciones) {
            if (iterator.denominacion == item.denominacion) {
                cont +=1
            }
            if(cont == 2){
                valueRepet = true
                break
                
            }
        }
        if(valueRepet){
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: "La denominación seleccionada ya se encuentra en la lista.",
                life: this.properties.timeLifeMessages,
            });
            await this.deleteDenominacion(item)
        }else{
            this.arqueo.denominaciones.forEach((objeto) => {
                if (objeto.valor !== null) {
                    this.arqueo.totalArqueo += Number(objeto.valor);
                }
                
            });
        }
    }
    enviarValor(item){
        let valorSelect = this.listDenominaciones.filter(
            (x) => x.idCatalogo == item.denominacion
        );
        
        let value = valorSelect[0].valor
        if(item.isTypeView){
            item.valor = (Number(value) * Number(item.cantidad)).toFixed(2)
        }
        this.arqueo.totalArqueo = 0;
        this.arqueo.denominaciones.forEach((objeto) => {
            if (objeto.valor !== null) {
                this.arqueo.totalArqueo += Number(objeto.valor);
            }
        });
    }
    calcularValorFinal(){
        this.arqueo.totalArqueo = 0;
        this.arqueo.denominaciones.forEach((objeto) => {
            if (objeto.valor !== null) {
                this.arqueo.totalArqueo += Number(objeto.valor);
            }
        });
    }

    async createDenominacion(item) {
        item.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        await this.securityService
            .createDenominacion(item)
            .then((resp) => {
                if (resp.code === 200) {
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: this.fundConstants.denominacion.messages.create,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    async updateDenominacion(item) {
        item.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        await this.securityService
            .updateDenominacion(item)
            .then((resp) => {
                if (resp.code === 200) {
                    this.messageService.add({
                        severity: "success",
                        summary: this.properties.successful,
                        detail: this.fundConstants.denominacion.messages.update,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    async getValesProvisionales(){
        await this.securityService
            .findOneValesProvisionales(this.fondo.idFondo)
            .then((resp) => {
                if (resp.code === 200) {
                    this.valesProvisionales = resp.data?.solicitudes ?? [];
                    this.blockedVale = false
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    async getComprobantes(){
        await this.securityService
            .findOneComprobantes(this.fondo.idFondo)
            .then((resp) => {
                if (resp.code === 200) {
                    this.comprobantesGastos = this.obtenerMovimientos(resp.data?.solicitudes);
                    this.blockedLiquidacion = false
                    
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.blocked = false;
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    obtenerMovimientos(solicitudes) {
        if(solicitudes && solicitudes.length > 0){
            return solicitudes.reduce((acumulador, solicitud) => {
                return acumulador.concat(solicitud.movimientos);
              }, []);
        }
        return []
        
      }


}