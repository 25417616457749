export class Properties {
    active: string;
    inactive: string;
    delete: string;
    accions: string;
    atras: Boton;
    nuevo: Boton;
    editar: Boton;
    aceptar: Boton;
    enviar: Boton;
    eliminar: Boton;
    registrar: Boton;
    descargar: Boton;
    imprimir: Boton;
    guardar: Boton;
    reasignar: Boton;
    cancelar: Boton;
    cerrar: Boton;
    buscar: Boton;
    rechazar: Boton;
    yes: string;
    no: string;
    confirmation: string;
    successful: string;
    timeLifeMessages: number = 3000;
    warning: string;
    error: string;
    warn: string;
    info: string;
    noRecords: string;
    stateActive: string;
    stateInactive: string;
    stateAprobado :string;
    
    viable: string;
    stateDelete: string;
    invalidEmail: string;
    paginationRange: number;
    
    
    brachsCatalogId: number;
    read: string;

    es: any;

    stateYes: string;
    stateYesOnly: string;
    stateNo: string
    all: string;

    visible = 'Visible';
    noVisible = 'No Visible';
    idCatalogoTipoFondo: number = 8
    idCatalogoTipoSolicitud: number = 10
    idCatalogoMotivoSolicitud: number = 12
    idCatalogoAreaSolicitante: number = 11
    idTipoSolicitudReembolso: number = 16
    idTipoSolicitudReposicion: number = 17
    idCatalogoFechaLiquidacion: number = 20
    idCatalogoTipoAprobacion: number = 2
    idCatalogoGrupo: number = 21
    idCatalogoDocumento: number = 26
    idCatalogoCuentaContable: number = 33
    idCatalogoCentroCosto: number = 34
    idRolCustodio: number = 5
    idRolAdministradorN: number = 21
    idRolAdministradorS: number = 22
    idRolArqueoCaja: number = 8
    idCatalogoRechazoSolicitud: number = 13
    idCatalogoFondoCajaChica: number = 14
    idCatalogoGrupoBien: number = 38
    idEmpresa: number = 1000
    idCatalogoArea: number = 35
    idCatalogoDenominaciones: number = 46
    ESTADO_SOLI_REGISTRADA : string = 'E'
    ESTADO_SOLI_ENTREGADA : string = 'T'
    ESTADO_SOLI_EN_PROCESO : string = 'P'
    ESTADO_SOLI_ANULADA : string ='N'
    ESTADO_SOLI_LIQUIDADA : string = 'L'
    ESTADO_SOLI_POR_APROBAR : string = 'B'
    ESTADO_SOLI_POR_SOLICITADA : string = 'S'
    ESTADO_SOLI_RECIBIDO : string = 'R'

    ESTADO_SOLI_ERROR : string = 'OE'
    ESTADO_SOLI_PENDIENTE_RPA : string = 'PE'
    ESTADO_SOLI_NO_APROBADO : string = 'NA'

    ESTADO_INGRESADO: string = 'I'
    ESTADO_RECHAZADO: string = 'X'
    ESTADO_SOLI_APROBADO: string = 'A'
    ESTADO_ARQUEO_CREADO: string = 'C'
    ESTADO_SOLI_POR_LIQUIDAR: string = 'PL'
    ESTADO_ARQUEO_RECHAZADO: string = "X"
    idCatalogoValeCajaChica: number = 31;
    idCatalogoComprobante : number = 30;
    idsDocumentoEmpresa : any[] = [ 41, 29];
    ESTADO_SOLI_RECIBO_ENTREGADO: string = 'RE'
    idFacturaElectronica: number = 28

    constructor() {
        // Botones generales
        this.nuevo = new Boton('Agregar', 'fa fa-fw fa-plus-circle', '', 'Agregar');
        this.editar = new Boton('Editar', 'fa fa-fw fa-pencil', '', 'Editar');
        this.aceptar = new Boton('Aceptar', 'fa fa-fw fa-check-circle', 'buttonAction', 'Aceptar');
        this.guardar = new Boton('Guardar', 'fa fa-fw fa-save', 'buttonAction', 'Guardar');
        this.reasignar = new Boton('Reasignar', 'fa fa-fw fa-save', 'buttonAction', 'Reasignar');
        this.enviar = new Boton('Enviar', 'fa fa-fw fa-save', 'buttonAction', 'Enviar');
        this.eliminar = new Boton('Eliminar', 'fa fa-fw fa-trash', '', 'Eliminar');
        this.cancelar = new Boton('Cancelar', 'fa fa-fw fa-times', '', 'Cancelar');
        this.cerrar = new Boton('Cerrar', 'fa fa-fw fa-close', '', 'Cerrar');
        this.atras = new Boton('Atrás', 'fa fa-fw fa-arrow-circle-left', '', 'Atrás');
        this.buscar = new Boton('Buscar', 'fa fa-fw fa-search-plus', '', '');
        this.rechazar = new Boton('Rechazar', 'fa fa-fw fa-check-circle', 'buttonAction', 'Rechazar');
        this.active = 'Activo';
        this.inactive = 'Inactivo';
        this.delete = 'Eliminado';
        this.viable = 'Viable';
        this.all = 'Todos'
        this.accions = 'Acciones'
        this.yes = 'Si';
        this.no = 'No';
        this.stateYes = 'SI';
        this.stateYesOnly = 'S';
        this.stateNo = 'NO';
        this.confirmation = 'Confirmar'
        this.successful = 'Exito';
        this.info = 'Información';
        this.warning = 'Alerta';
        this.error = 'Error';
        this.warn = 'Advertecia';
        this.stateActive = 'A';
        this.stateInactive = 'I';
        this.stateDelete = 'E';
        this.noRecords = 'No se encontraron registros.'
        this.invalidEmail = 'Correo no valido'
        this.paginationRange = 10;
        this.stateAprobado = 'A';

        this.es = {
            firstDayOfWeek: 1,
            dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
            dayNamesShort: ['Dom', 'Lun', 'Mart', 'Mier', 'Juev', 'Vier', 'Sáb'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
                'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            today: 'Hoy',
            clear: 'Limpiar'
        };
    }


}

class Boton {
    valor: string;
    icono: string;
    pclass: string;
    ptitulo: string;
    constructor(valor: string, icono: string, pclass: string, ptitulo: string) {
        this.valor = valor;
        this.icono = icono;
        this.pclass = pclass;
        this.ptitulo = ptitulo;
    }

}
