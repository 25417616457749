import { AuditoriaModel } from "../auditoria.model";

export class DenominacionModel extends AuditoriaModel {
    idDenominacion : number;
    idArqueo : number;
    cantidad : number;
    valor : number;
    estado : string;
    denominacion:number
    isTypeView: boolean
    idEmpresa?: number
 
    constructor() {
        super();
        this.idDenominacion = null;
        this.idArqueo = null;
        this.cantidad = null;
        this.valor = null;
        this.estado = null;
        this.denominacion = null
        this.isTypeView = false
        this.idEmpresa = null
    }
}
