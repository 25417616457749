import { AuditoriaModel } from "./auditoria.model";

export class RolModel extends AuditoriaModel {
    idRol: number;
    nombreRol: string;
    descripcion: string;
    estado: string;
    idEmpresa?: number
    constructor() {
        super();
        this.idRol = null;
        this.nombreRol = null;
        this.descripcion = null;
        this.estado = null;
        this.idEmpresa = null
    }
}

