import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { RolModel } from 'src/app/models/roles.model';
import { SecurityService } from 'src/app/service/securityservice';
import { AppBreadcrumbService } from 'src/app/utilities/app.breadcrumb.service';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { RolformComponent } from './rol-form.component';
import { RolListComponent } from './rol-list.component';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/common/auth.service';
import { PermisoModel } from 'src/app/models/permiso.model';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  providers: [MessageService]
})
export class RolesComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  deleteRolesDialog: boolean = false;
  roles: RolModel[] = [];
  rol: RolModel;
  selectedRoles: RolModel[];
  assignMenus: boolean = false;
  isNewRol: boolean;
  @ViewChild(RolformComponent) rolFormComponent: RolformComponent;
  @ViewChild(RolListComponent) rolListComponent: RolListComponent;
  permiso: PermisoModel;

  constructor(private messageService: MessageService,
    private breadcrumbService: AppBreadcrumbService, private securityServices: SecurityService, private router: Router,
    private auth: AuthorizationService) {
    this.breadcrumbService.setItems([
      { label: this.securityConstants.security },
      { label: this.securityConstants.rol.title, routerLink: ['/pricipal/roles'] }
    ]);
  }

  ngOnInit(): void {
  this.permiso = this.auth.validatePermision(this.router);
  }

  newRol() {
    this.selectedRoles = [];
    this.isNewRol = true;
    this.rol = new RolModel();
  }

  deleteSelectedRoles() {
    this.deleteRolesDialog = true;
  }

  saveRol() {
    this.rolFormComponent.saveRol();
  }

  cancelRol() {
    this.isNewRol = false;
  }

  rolAccion(event: any) {
    if (event.type == 'M') {
      this.isNewRol = true;
      this.rol = event.rol;
    } else if (event.type == 'D') {
      this.rolFormComponent.updateRol();
      this.rol = event.rol;
    } else if (event.type == 'AS') {
      this.assignMenus = event.assignMenu;
      this.selectedRoles = undefined;
    } else if (event.type == 'RM') {
      this.assignMenus = event.assignMenu;
      this.isNewRol = false;
      this.selectedRoles = undefined
    }
    else {
      this.selectedRoles = event.roles;
    }

  }

  confirmDeleteSelected() {
    let rolesToDelete: any[] = [];
    this.selectedRoles.forEach(x => {
      rolesToDelete.push(x.idRol)
    });
    const rolesDelete = {
      idRoles: rolesToDelete
    }
    this.deleteMassive(rolesDelete);
  }

  deleteMassive(roles: any) {
    this.securityServices.deleteRol(roles).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.rol.messages.deleteRoles, life: this.properties.timeLifeMessages });
        this.reloadListRoles();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.successful, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  reloadListRoles() {
    this.deleteRolesDialog = false;
    setTimeout(() => {
      this.rolListComponent.getRoles();
      this.rolListComponent.selectedRoles = [];

    }, 3000);

  }
}