<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-3 px-3">

            <!-- titulos datos solicitud liquidacion -->
            <div class="col-10 md:col-11">
                <h2 style="color: white;">$ {{requirement.valorSolicitado | number: '1.2-2'}}</h2>
                <span style="color: white;">{{dashboardConstants.cashRequirement.subtitleDataSoli}}</span>
            </div>

            <div class="col-2 md:col-1" style="text-align: end;">
                <button pButton pRipple type="button" style="color: #ffffff;" icon="pi pi-times"
                    class="p-button-rounded p-button-outlined" (click)="closeNewRequirement()"></button>
            </div>
        </div>
    </div>
    <p-toast></p-toast>
    <div style="background-color: #ffffff;">
        <div class="col-12">
            <!-- para datos de la solicitud  liquidación-->
            <div class="p-fluid p-formgrid grid p-3">
                <div class="col-12 md:col-6">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.leftTitle}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <!-- <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{dashboardConstants.cashRequirement.rejectButton}}"
                    (click)="rejectRequirement(requirement)"></button> -->
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-comment" class="comment-button"
                        label="{{dashboardConstants.cashRequirement.commentButton}}" (click)="openComment()"></button>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;">
                    <button pButton pRipple icon="pi pi-check" class="new-button"
                        label="{{dashboardConstants.cashRequirement.liquidButton}}"
                        (click)="openSupportSettle()"></button>
                </div>

            </div>
        </div>
        <div class="col-12 px-3 pt-2">
            <div class="p-fluid p-formgrid grid">
                <div class="col-12 md:col-3">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="nameApplicant" class="inputSearch" pInputText
                                [(ngModel)]="requirement.solicitante.nombreCompleto" readonly>
                            <label
                                for="nameApplicant">{{dashboardConstants.cashRequirement.nameApplicant.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="nroIdentification" class="inputSearch" pInputText
                                [(ngModel)]="requirement.solicitante.numeroDocumentoIdentidad" readonly>
                            <label
                                for="nroIdentification">{{dashboardConstants.cashRequirement.nroIdentification.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-3">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input type="text" id="search1" class="inputSearch" pInputText
                                [(ngModel)]="requirement.solicitante.email" readonly>
                            <label for="search1">{{dashboardConstants.cashRequirement.email.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-calendar [(ngModel)]="requirement.fechaSolicitud" dateFormat="dd/mm/yy"
                                class="calendarData" [disabled]="view"></p-calendar>
                            <label for="search1">{{dashboardConstants.cashRequirement.dateSoli.label}}</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-2">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-calendar dateFormat="dd/mm/yy" class="calendarData"
                                [(ngModel)]="requirement.fechaEsperadaLiquidacion" [disabled]="view"></p-calendar>
                            <label for="search1">{{dashboardConstants.cashRequirement.dateLiquidation.label}}</label>
                        </span>
                    </div>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.fechaEsperadaLiquidacion">{{dashboardConstants.cashRequirement.dateLiquidation.required}}</small>
                </div>
            </div>
            <hr style="border-color: #E11F1D;" class="mx-3">

        </div>
        <div class="col-12 px-3 pt-2">
            <div class="p-fluid p-formgrid grid px-3">
                <div class="col-12 md:col-12">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.dateApplicnt}}</span>
                </div>
                <div class="field col-12 md:col-6" *ngIf="requirement.idSolicitud">
                    <label htmlFor="nroSolicitud">{{dashboardConstants.cashRequirement.nroSolicitud.label}}</label>
                    <input type="text" pInputText id="nroSolicitud" required maxlength="100"
                        [(ngModel)]="requirement.idSolicitud" readonly />
                </div>
                <div class="field col-12 md:col-6">
                    <label
                        htmlFor="valueRequeriment">{{dashboardConstants.cashRequirement.valueRequeriment.label}}</label>
                    <p-inputNumber [(ngModel)]="requirement.valorSolicitado" inputid="valueRequeriment" mode="currency"
                        currency="USD" locale="en-US" [disabled]="view"></p-inputNumber>
                    <small>Valor en dólares (USD)</small><br>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.valorSolicitado">{{dashboardConstants.cashRequirement.valueRequeriment.required}}</small><br>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && requirement.valorSolicitado < 0 ">{{dashboardConstants.cashRequirement.valueRequeriment.requiredMin}}</small>

                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="fundType">{{dashboardConstants.cashRequirement.fundType.label}}</label>
                    <p-dropdown id="newFor" [options]="listTypeRequest" optionLabel="label" optionValue="value"
                        [(ngModel)]="requirement.idCatalogoTipoSolicitud" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true" placeholder="Seleccione un tipo de Solicitud"
                        [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idCatalogoTipoSolicitud">{{dashboardConstants.cashRequirement.fundType.required}}</small>
                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="fundName">{{dashboardConstants.cashRequirement.fundName.label}}</label>
                    <p-dropdown id="newFor" [options]="fondos" optionLabel="label" optionValue="value"
                        [(ngModel)]="requirement.idFondo" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true" placeholder="Seleccione un fondo"
                        [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idFondo">{{dashboardConstants.cashRequirement.fundName.required}}</small>
                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="fundReason">{{dashboardConstants.cashRequirement.fundReason.label}}</label>
                    <p-dropdown id="newFor" [options]="listReasonRequest" optionLabel="label" optionValue="value"
                        [(ngModel)]="requirement.idCatalogoMotivoSolicitud" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true" placeholder="Seleccione un motivo"
                        [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idCatalogoMotivoSolicitud">{{dashboardConstants.cashRequirement.fundReason.required}}</small>
                </div>
                <div class="field col-12 md:col-3">
                    <label htmlFor="aplicatArea">{{dashboardConstants.cashRequirement.aplicatArea.label}}</label>
                    <p-dropdown id="newFor" [options]="listSolicitantArea" optionLabel="nombreErp" optionValue="idParametroErp"
                        [(ngModel)]="requirement.idCatalogoAreaSolicitante" emptyFilterMessage="No existen registros"
                        emptyMessage="No existen registros" filter="true" placeholder="Seleccione un área"
                        [disabled]="view"></p-dropdown>
                    <small class="ng-dirty ng-invalid"
                        *ngIf="submitted && !requirement.idCatalogoAreaSolicitante">{{dashboardConstants.cashRequirement.aplicatArea.required}}</small>
                </div>
            </div>
            <hr style="border-color: #E11F1D;" class="mx-3">
        </div>
        <div class="col-12 px-3 pt-2">
            <div class="p-fluid p-formgrid grid px-3">
                <div class="col-12 md:col-12">
                    <span class="title-screen">{{dashboardConstants.cashRequirement.description}}</span>
                </div>
                <div class="field col-12 md:col-12">
                    <label htmlFor="name">{{dashboardConstants.cashRequirement.descriptionSoli.label}}</label>
                    <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="requirement.observaciones"
                        [disabled]="view"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>

<app-support-settlement *ngIf="viewSupportSettle" [viewSupportSettle]="viewSupportSettle" [vizualizarSoportes]="vizualizarSoportes" [requirement]="requirement"
    (supportSettleForm)="closeSupportSettle($event)"></app-support-settlement>
<app-comments *ngIf="createComment" [createComment]="createComment" [singleSolicitud]="singleSolicitud"
    (closeComment)="closeCommentForm($event)">
</app-comments>
<p-dialog [(visible)]="rechazoRequerimiento" header="{{properties.confirmation}}" [modal]="true"
    [style]="{width:'400px', minHeight:'479px'}">
    <div class="flex align-items-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>¿Está seguro de rechazar el requerimiento ?</span>

    </div><br>
    <div class="align-items-center">
        <div class="field col-12 md:col-12">
            <label htmlFor="fundType">Seleccione el motivo del rechazo del requerimiento</label>
            <p-dropdown id="newFor" [options]="listRejectRequest" optionLabel="label" optionValue="value"
                [(ngModel)]="requirement.idCatalogoMotivoRechazo" emptyFilterMessage="No existen registros"
                emptyMessage="No existen registros" filter="true" placeholder="Seleccione un motivo"></p-dropdown><br>
            <small class="ng-dirty ng-invalid" *ngIf="submitted && !requirement.idCatalogoMotivoRechazo">El motivo del
                rechazo es requerido</small>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="rechazoRequerimiento = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="rejectReq()"></button>
    </ng-template>
</p-dialog>