import { Component, OnInit } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';

import { PermisoModel } from 'src/app/models/permiso.model';
import { PendingsConstants } from '../pendings.constants';
@Component({
  selector: 'app-pendings-approval',
  templateUrl: './pendings-approval.component.html',
})
export class PendingsApprovalComponent implements OnInit {

  public readonly pendingsConstants = PendingsConstants;
  properties: Properties = new Properties();
  deleteCelulaDialog: boolean = false;
  isNewRequirement: boolean;
  permiso: PermisoModel;
  requirement : any;
  constructor() {

  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    

  }
  celulaAccion(event: any) {
    if (event.type == 'M') {
      this.isNewRequirement = true;
    } 
  }
  cancelcelula() {
    this.isNewRequirement = false;
  }
}