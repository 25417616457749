<p-toast></p-toast>
<p-table #dt [value]="menus" responsiveLayout="stack"
    [globalFilterFields]="['nombreMenu','descripcion','estado']" [rows]="properties.paginationRange" [paginator]="true"
    [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" styleClass="p-datatable-products"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [(selection)]="selectedMenus"
    selectionMode="multiple" [rowHover]="true" dataKey="idMenu" [loading]="blockedMenu">
    <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0"></h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Buscar..." />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
           
            <th pSortableColumn="nombreMenu">{{securityConstants.menu.name.field}} <p-sortIcon field="nombreMenu">
                </p-sortIcon>
            </th>
            
            <th pSortableColumn="icono">{{securityConstants.menu.icono.field}} <p-sortIcon field="icono">
                </p-sortIcon>
            </th>
            <th pSortableColumn="url">{{securityConstants.menu.url.field}}<p-sortIcon field="url"></p-sortIcon>
            </th>
            <th pSortableColumn="orden">{{securityConstants.menu.orden.field}}<p-sortIcon field="orden"></p-sortIcon>
            </th>
            <th pSortableColumn="visible">{{securityConstants.menu.visible.field}}<p-sortIcon
                field="visible"></p-sortIcon>
            <th pSortableColumn="estado" style="text-align:center;">{{securityConstants.menu.status.field}} <p-sortIcon
                    field="estado">
                </p-sortIcon>
            </th>
            <th>{{properties.accions}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-menu>
        <tr>
           
            <td><span class="p-column-title">{{securityConstants.menu.name.field}}</span>{{menu.nombreMenu}}</td>
            
            <td><span class="p-column-title">{{securityConstants.menu.icono.field}}</span>{{menu.icono}}</td>
            <td><span class="p-column-title">{{securityConstants.menu.url.field}}</span>{{menu.url}}</td>
            <td><span class="p-column-title">{{securityConstants.menu.orden.field}}</span>{{menu.orden}}</td>
            <td><span class="p-column-title">{{securityConstants.menu.visible.field}}</span>
                <span *ngIf="menu.visible || menu.visible === null">SI</span>
                <span *ngIf="menu.visible === false">NO</span>
            </td>
            <td class="text-center">
                <span class="p-column-title text-left">{{securityConstants.menu.status.field}}</span>
                <span *ngIf="menu.estado == properties.stateActive"
                    [class]="'state-badge state-A'" (click)="updateStateMenu(menu)">
                    {{properties.active}}</span>
                <span *ngIf="menu.estado == properties.stateInactive"
                    [class]="'state-badge state-I'" (click)="updateStateMenu(menu)">
                    {{properties.inactive}}</span>
                <span *ngIf="menu.estado == properties.stateDelete" [class]="'state-badge state-E'">
                    {{properties.delete}}</span>
                <!-- <span *ngIf="menu.estado == properties.stateDelete" [class]="'state-badge state-E'">
                    {{properties.delete}}</span> -->
            </td>
            <td style="width:14%; min-width:10rem;">
                <span class="p-column-title">{{properties.accions}}</span>
                <div class="flex">
                    <button pButton pRipple icon="pi pi-pencil"
                        class="p-button-rounded p-button-text button-view-action mr-2" title="Editar"
                        (click)="editMenu(menu)"></button>
                    <button pButton pRipple icon="pi pi-bars" *ngIf="addSubLevels"
                        class="p-button-rounded p-button-text button-view-action mr-2" title="Agregar submenu"
                        (click)="viewMenus(menu)"></button>
                    <!-- <button pButton pRipple icon="pi pi-trash"
                        class="p-button-rounded p-button-outlined p-button-danger mr-2" title="Eliminar"
                        (click)="deleteMenu(menu)"></button> -->
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>


<p-dialog [(visible)]="deleteMenuDialog" header="{{properties.confirmation}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="menu">{{securityConstants.menu.confirmationMessages.delete}}<b>{{menu.nombreMenu}}</b>?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="deleteMenuDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="confirmDelete()"></button>
    </ng-template>
</p-dialog>