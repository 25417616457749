import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { RolModel } from 'src/app/models/roles.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { AuthorizationService } from 'src/app/common/auth.service';


@Component({
  selector: 'app-rol-form',
  templateUrl: './rol-form.component.html',
  providers: [MessageService]
})
export class RolformComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  @Input() rol = new RolModel();
  submitted: boolean = false;
  state: boolean = false;
  @Output() rolAccion = new EventEmitter<any>();
  constructor(private messageService: MessageService, private securityServices: SecurityService, private auth:AuthorizationService) { }

  ngOnInit(): void {
    if (this.rol.idRol) {
      this.state = this.rol.estado == this.properties.stateActive ? true : false;
    }
  }

  saveRol() {
    try {
      this.submitted = true;
      if (this.rol.nombreRol && this.rol.descripcion) {
        this.rol.estado = this.state ? this.properties.stateActive : this.properties.stateInactive;;
        if (this.rol.idRol) {
          this.rol.idRol = Number.parseInt(this.rol.idRol.toString());
          this.updateRol();
        } else {
          this.createRol();
        }
      }
    } catch (err) {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err, life: this.properties.timeLifeMessages });
    }

  }

  createRol() {
    this.rol.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.createRol(this.rol).then(resp => {
      if (resp.code == 200) {
        this.goToRoles();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.rol.messages.create, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateRol() {
    this.rol.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.updateRol(this.rol).then(resp => {
      if (resp.code === 200) {
        this.goToRoles();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.rol.messages.update, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  cancelRol() {
    this.rol = new RolModel();
    this.rolAccion.emit();
  }

  goToRoles() {

    setTimeout(() => {
      this.rol = new RolModel();
      this.rolAccion.emit();
    }, 3000);

  }

  confirmDelete() {
  }


}
