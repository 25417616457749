import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Properties } from "src/app/utilities/properties";
import { PermisoModel } from "src/app/models/permiso.model";
import { Router } from "@angular/router";
import { SecurityService } from "src/app/service/securityservice";
import { MessageService } from "primeng/api";
import { CatalogModel } from "src/app/models/catalog.model";
import { AuthorizationService } from "src/app/common/auth.service";
import { ApproverConfigurationConstants } from "../approver-configuration.constants";
import { UserModel } from "src/app/models/user.model";

@Component({
    selector: "app-new-approver",
    templateUrl: "./new-approver.component.html",
    providers: [MessageService],
})
export class NewApproverComponent implements OnInit {
  public readonly approverConstants = ApproverConfigurationConstants;
    properties: Properties = new Properties();
    @Input() viewNewapproverConfiguration : boolean
    @Output() newApproverForm = new EventEmitter<any>();
    @Input() aprobacion: any;
    users : UserModel[] = [];
    submitted:boolean = false;
    listTipoFondo : CatalogModel[] = [];
    listTipoAprobacion : CatalogModel[] = [];
    isValidate: boolean = false;
    @Input() fondos:any

    constructor(
        private router: Router,
        private messageService: MessageService,
        private securityServices: SecurityService,
        private auth: AuthorizationService
    ) {}
    /**
     * Inicio de pantalla
     */
    async ngOnInit() {
       this.aprobacion = this.aprobacion ? this.aprobacion : {};
       this.aprobacion.idEmpresa =  this.auth.getCurrentUser().userData.idEmpresa
       this.getUsers();
       this.listTipoFondo = await this.getCatalogList(this.properties.idCatalogoTipoFondo)
       this.listTipoAprobacion = await this.getCatalogList(this.properties.idCatalogoTipoSolicitud)
        
    }
    closeNewConfiguration(){
        const event = {
            type: "close",
        };
        this.newApproverForm.emit(event);
    }

    crearConfiguracion(){
        this.aprobacion.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityServices.createConfiguracionAprobador(this.aprobacion).then(resp => {
            if (resp.code === 200) {
                this.messageService.add({
                    severity: "success",
                    summary: this.properties.successful,
                    detail: 'Configuración del aprobador creada con éxito',
                    life: this.properties.timeLifeMessages,
                });

                setTimeout(() => {
                    const event = {
                        type: "reload",
                    };
                    this.newApproverForm.emit(event);
                }, 1500);
               
            } else {
                this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
              }
            }).catch(err => {
              this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
            });
    }
    actualizarConfiguracion(){
        this.aprobacion.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityServices.updateConfiguracionAprobador(this.aprobacion).then(resp => {
            if (resp.code === 200) {
                this.messageService.add({
                    severity: "success",
                    summary: this.properties.successful,
                    detail: 'Configuración del aprobador actualizada con éxito',
                    life: this.properties.timeLifeMessages,
                });
                
                setTimeout(() => {
                    const event = {
                        type: "reload",
                    };
                    this.newApproverForm.emit(event);
                }, 1500);
                
            } else {
                this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
              }
            }).catch(err => {
              this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
            });
    }

    guardarConfiguracion(){
        this.aprobacion.aprobadorFinal = this.aprobacion.aprobadorFinal ? this.aprobacion.aprobadorFinal : false        
        if(this.aprobacion.idConfiguracionAprobador){
            this.actualizarConfiguracion();
        }else{
            this.crearConfiguracion();
        }
    }
    validate(){
        this.submitted = true;
        if (this.submitted && this.aprobacion.idUsuarioAprobador && this.aprobacion.idCatalogoTipoAprobacion
            && this.aprobacion.idCatalogoTipoFondo && this.aprobacion.nivel && this.aprobacion.idFondo) {
            this.isValidate = true;
            this.guardarConfiguracion();
        }else{
            this.isValidate = false;
        }
    }
    isAprobadorFinal(){
        if(this.aprobacion.aprobadorFinal){
            this.aprobacion.idConfiguracionAprobadorSuperior = null
        }
    }
    getUsers(){
        this.securityServices.getUsers().then(resp => {
            if (resp.code === 200) {
              this.users = resp.data;
              for (const user of this.users) {
                user.nombreApellido = user.nombre + ' ' + user.apellido
              }              
              if (resp.data.length === 0) {
                this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
              }
            } else {
              this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
            }
          }).catch(err => {
            this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
          });
    }

    async getCatalogList(idCatalog: any) {
      let catalogList: any[] = [];
      await this.securityServices
          .getCatalogsByFather(idCatalog)
          .then((resp) => {
              if (resp && resp.code === 200) {
                  resp.data.forEach((catalog) => {
                      let catalogo = new CatalogModel();
                      catalogo = catalog;
                      let selectItem = {
                          label: catalogo.nombre,
                          value: catalogo.idCatalogo,
                      };
                      if (catalogo.estado === this.properties.stateActive) {
                          catalogList.push(selectItem);
                      }
                  });
              } else if (resp.description) {
                  this.messageService.add({
                      severity: "error",
                      summary: this.properties.error,
                      detail: resp.description,
                      life: this.properties.timeLifeMessages,
                  });
              } else {
                  this.messageService.add({
                      severity: "error",
                      summary: this.properties.error,
                      detail: "Ocurrio un error al cargar los catalgos",
                      life: this.properties.timeLifeMessages,
                  });
              }
          })
          .catch((err) => {
              this.messageService.add({
                  severity: "error",
                  summary: this.properties.error,
                  detail: err.message,
                  life: this.properties.timeLifeMessages,
              });
          });
      return catalogList;
    }
}
