import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { MenuModel } from 'src/app/models/menus.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { PermisoModel } from 'src/app/models/permiso.model';
import { AuthorizationService } from 'src/app/common/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  providers: [MessageService],
})
export class MenuListomponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  menuDialog: boolean;
  deleteMenuDialog: boolean = false;
  blockedMenu: boolean = false;
  allMenus: MenuModel[] = [];
  menus: MenuModel[] = [];
  menu = new MenuModel();
  selectedMenus: MenuModel[];
  rowsPerPageOptions = [5, 10, 20];
  @Input() menuFather = new MenuModel();
  @Output() menuAccion = new EventEmitter<any>();
  @Input() isFather = new MenuModel();
  @Input() items: MenuItem[];
  addSubLevels = true;
  @Input() idMenuPadre: number;
  permiso: PermisoModel;
  constructor(private messageService: MessageService, private securityServices: SecurityService, private router: Router, private auth: AuthorizationService) {
  }
  ngOnInit(): void {
    this.permiso = this.auth.validatePermision(this.router);
    this.menu = new MenuModel();
    if (this.menuFather && this.menuFather.idMenu) {
      this.getMenus(this.idMenuPadre);
    } else {
      this.getMenus(this.idMenuPadre);
    }

  }

  confirmDelete() {
    this.deleteMenuDialog = false;
    this.menu.estado = 'E';
    this.updateMenu();
    this.menu = new MenuModel();
  }

  deleteMenu(menu: MenuModel) {
    this.deleteMenuDialog = true;
    this.menu = { ...menu };
  }

  editMenu(menu: MenuModel) {
    const menuEvent = {
      type: 'M',
      menu: menu,
      isEdit: true
    };
    this.menuAccion.emit(menuEvent);
  }

  menuSelected() {
    const menuEvent = {
      type: 'S',
      menus: this.selectedMenus
    };
    this.menuAccion.emit(menuEvent);
  }

  getMenus(idFather?: number) {
    if(this.menuFather){
      idFather = this.menuFather.idMenu;
    }
    this.selectedMenus = [];
    this.blockedMenu = true;
    this.securityServices.getMenu().then(resp => {
      if (resp.code === 200) {
        this.allMenus = this.buildAllMenus(resp.data);
        this.menus = this.getMenusById(idFather ? idFather : null);
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
      this.blockedMenu = false;
    }).catch(err => {
      this.blockedMenu = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  getMenusById(id: number) {
    return this.allMenus.filter(x => x.idMenuPadre === id);
  }

  buildAllMenus(menus: any[]) {
    let menusResp: any[] = [];
    let menu = new MenuModel();
    menus.forEach(x => {
      menu = x;
      menusResp.push(menu);
    });
    return menusResp;
  }

  updateMenu() {
    this.menu.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.updateMenu(this.menu).then(resp => {
      if (resp.code === 200) {
        this.getMenusByFather();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.menu.messages.update, life: this.properties.timeLifeMessages });
      } else {
        this.getMenusByFather();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  getMenusByFather(){
    if(this.menu.idMenuPadre){
      this.getMenus(this.idMenuPadre);
    } else{
      this.getMenus(this.idMenuPadre);
    }
   
  }

  viewMenu(menu: MenuModel) {
    const menuEvent = {
      type: 'V',
      menu: menu
    };
    this.menuAccion.emit(menuEvent);
  }


  viewMenus(menu: MenuModel) {
    this.selectedMenus = [];
    this.menuSelected();
    this.menuFather = menu;
    const menus = this.allMenus.filter(x => x.idMenuPadre === menu.idMenu);
    this.menus = menus;
    this.viewMenu(menu);
    if(this.items.length >= 3){
      this.addSubLevels = false;
    } else {
      this.addSubLevels = true;
    }

  }



  updateStateMenu(menu: MenuModel) {
    menu.estado = menu.estado === this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive;
    this.menu = menu;
    this.updateMenu();
  }

  findMenu(idMenu: number): MenuModel{
    this.menu = this.allMenus.find(x=> x.idMenu === idMenu);
    return this.menu;
  }

}
