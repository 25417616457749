import { AuditoriaModel } from "../auditoria.model";
import { UserModel } from "../user.model";
export class SolicitudModel extends AuditoriaModel {
    idSolicitud : number;
    idCatalogoTipoSolicitud : number;
    idFondo : number;
    idUsuarioSolicitante : number;
    fechaSolicitud : any;
    fechaEsperadaLiquidacion : any;
    idCatalogoAreaSolicitante : number;
    valorSolicitado : number;
    idCatalogoMotivoSolicitud : number;
    idCatalogoMotivoRechazo : number;
    idSolicitudReposicion : number;
    valorGastado : number;
    observaciones : string;
    fechaLiquidacion : any;
    estado : string;
    solicitante: UserModel
    idEmpresa: number
    countDays: number
    constructor() {
        super();
        this.idSolicitud = null;
        this.idCatalogoTipoSolicitud = null;
        this.idFondo = null;
        this.idUsuarioSolicitante = null;
        this.fechaSolicitud = null;
        this.fechaEsperadaLiquidacion = null;
        this.idCatalogoAreaSolicitante = null;
        this.valorSolicitado = 0;
        this.idCatalogoMotivoSolicitud = null;
        this.idCatalogoMotivoRechazo = null;
        this.idSolicitudReposicion = null;
        this.valorGastado = null;
        this.observaciones = null;
        this.fechaLiquidacion = null;
        this.estado = null;
        this.idEmpresa = null
        this.countDays = null
    }
}
