<div class="pages-body login-page flex flex-column">
    <div class="p-3 flex justify-content-center flex-row align-items-center">
        <div class="flex" routerLink="/">
            <div class="logo">
                <img src="assets/layout/images/logo-moderna.png" style="height: 12rem;" alt=""/>
            </div>
        </div>
    </div>
    <p-toast></p-toast>
    <div class="align-self-center mt-auto-c mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>{{ securityConstants.login.restorePassword }}</h2>
            </div>

            <h4>{{ securityConstants.login.messages.enterEmail }}</h4>

            <div class="input-panel flex flex-column px-3">
                <div class="p-inputgroup mt-3 mb-6">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-lock"></i>
                    </span>
                    <span class="p-float-label">
                        <input type="email" id="email" pInputText [(ngModel)]="username" autofocus>
                        <label for="email">{{ securityConstants.login.email.field }}</label>
                    </span>
                </div>
            </div>

            <p-button class="login-button mb-6 px-3" label="{{ securityConstants.login.confirm }}" (click)="reenvio()"></p-button>

        </div>
    </div>
</div>
