
    <div class="p-fullscreen">
        <div class="p-fluid p-formgrid grid md:mx-5 md:mt-5 card p-0">
            <div class="col-12 md:col-4">
                <div class="left-top">
                    <div class="p-fluid p-formgrid grid pt-8 mx-3 md:mx-6">
                        <div class="col-12 md:col-12">
                            <span class="title-login">¿Que necesitas para hacerlo increíble?</span>
                        </div>
                    </div>
                </div>
                <div class="left-center"> 
                    <div class="pt-7">
                        <img src="assets/layout/images/moderna-logo.svg" style="height: 6rem;" alt=""/>
                    </div>
                </div>
                <div class="left-botton">
                    <div class="p-fluid p-formgrid grid pt-4 px-1">
                        <div class="col-0 sm:col-0 xs:col-0 md:col-12">
                            <p class="yellow-text">AVISO: ha accedido a un sistema propiedad de la EMPRESA MODERNA ALIMENTOS S.A. y QUALITY WINE & SPIRITS S.A.S. Necesita tener autorización antes de usarlo, estando estrictamente limitado al uso indicado en dicha autorización.</p>
                        </div>
                        <div class="col-12 sm:col-12 xs:col-12 md:col-12">
                            <p class="white-text">©2024 Moderna Alimentos S.A | Quality Wine & Spirits S.A.S.</p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-8">
                <p-toast></p-toast>
                <div class="align-items-center justify-content-center flex div-login" style="width: 100%; height: 100% !important;">
                    <div style="display: block;">
                        <span class="welcome-text">Bienvenido</span><br>
                        <span style="color: #656D76;"> <b>Inicia sesión aquí.</b></span><br><br>
                        <button pButton pRipple class="login-button mt-3 ml-8" label="INGRESAR" (click)="login()"
                    ></button>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
    
