<div class="grid" *ngIf="!viewSettleRequest">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3">
            <div class="field col-12 md:col-10">
                <h2 style="color: white;">{{tittle}}</h2>
                <h6 style="color: white;">{{dashboardConstants.requirements.subtitle}}</h6>
            </div>

        </div>
    </div>
    <p-toast></p-toast>
    <div class="col-12">
            <div class="p-fluid p-formgrid grid pt-3 px-3">
                <div class="col-12 md:col-10">
                    <span class="title-screen">{{dashboardConstants.requirements.actions.label}}</span>
                </div>
                <div class="col-12 md:col-2" style="text-align: right;" *ngIf="visibleButton">
                    <button pButton pRipple icon="pi pi-plus" class="new-button" label="{{dashboardConstants.requirements.addButton}}"
                     (click)="newRequirement()"></button>
                </div>
            </div>     
    </div>
    <div class="col-12">
        <app-dashboard-list 
        (requirementForm)="openViewRequirement($event)"
        [action] = "action"
        [createRequeriment] = "createRequeriment"
    ></app-dashboard-list>   
    </div>
    <app-cash-requirement *ngIf="isNewRequirement" 
        [isNewRequirement] = "isNewRequirement" 
        (requirementForm)="closeNewRequirement($event)" 
        [isView] = "isView"
        [isDataSoli] = "isDataSoli"
        [isDataSoliLiquidacion] = "isDataSoliLiquidacion"
        [isApproveCash] = "isApproveCash"
        [requirement] = requirement
    ></app-cash-requirement>

    <app-approve-reposition *ngIf="isCashReplenishment"
    [isCashReplenishment] = "isCashReplenishment"
    (approveRepositionForm)="closeApproveReposition($event)" 
    [requirement] = requirement
    [isView] = "isViewRepoCaja"
    > 
    </app-approve-reposition>

</div>
<app-settle-request *ngIf="viewSettleRequest"
 [idSolicitante] = "idSolicitante" 
 [requirement] = requirement
 (requirementForm)="closeNewRequirement($event)">
</app-settle-request>

<app-support-settlement *ngIf="viewSupportSettle" 
[viewSupportSettle]="viewSupportSettle" [vizualizarSoportes]="vizualizarSoportes" [requirement]="requirement"
    (supportSettleForm)="closeSupportSettle($event)">
</app-support-settlement>
