<p-toast></p-toast>
<div *ngIf="!isAssignRol" class="p-fluid p-formgrid grid">
    <div class="field col-12 md:col-4">
        <label htmlFor="name">{{securityConstants.user.name.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="name" [(ngModel)]="user.nombre" required 
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.nombre}" (blur)="validateNombre()"/>
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.nombre">{{securityConstants.user.name.requiered}}</small>
            <small class="ng-dirty ng-invalid"
            *ngIf="!nombreValido">{{securityConstants.user.nameValido.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="lastName">{{securityConstants.user.lastName.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="lastName" [(ngModel)]="user.apellido" required 
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.apellido}" (blur)="validateApellido()" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.apellido">{{securityConstants.user.lastName.requiered}}</small>
            <small class="ng-dirty ng-invalid"
            *ngIf="!apellidoValido">{{securityConstants.user.lastNameValido.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="email">{{securityConstants.user.email.field}} <span class="ng-invalid">*</span></label>
        <input type="email" (blur)='validateEmail()' pInputText
            id="email" [(ngModel)]="user.email" required 
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.email}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.email">{{securityConstants.user.email.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="phone">{{securityConstants.user.numeroIdentidad.field}} <span
                class="ng-invalid">*</span></label>
        <input type="text" pInputText id="numeroIdentidad" [(ngModel)]="user.numeroDocumentoIdentidad" required maxlength="10"
            autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.numeroDocumentoIdentidad}" (blur)="validarCedula()"/>
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.numeroDocumentoIdentidad">{{securityConstants.user.numeroIdentidad.requiered}}</small>
            <small class="ng-dirty ng-invalid"
            *ngIf="!cedulaCorrecta">{{securityConstants.user.numeroIdentidadIncorrecta.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="phone">{{securityConstants.user.phone.field}}</label>
        <input type="text" pInputText id="phone" [(ngModel)]="user.telefono" autofocus #phoneNg="ngModel"
            pattern="^\+[1-9][0-9]{0,24}$" [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.telefono}" maxlength="13"/>
        <small class="ng-dirty ng-invalid" *ngIf="submitted && phoneNg?.errors?.pattern">{{
            securityConstants.user.phone.pattern }}</small>
    </div>
    
    <div class="field col-12 md:col-4">
        <label htmlFor="state">{{securityConstants.user.status.field}}</label><br>
        <p-toggleButton [(ngModel)]="state" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="user.backupActivo">{{securityConstants.user.backupActivo.field}}</label><br>
        <p-toggleButton [(ngModel)]="user.backupActivo" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div>
    <div *ngIf="user.backupActivo" class="col-12 md:col-4">
        <label htmlFor="typeFund">{{securityConstants.user.backup.field}}</label>
        <p-dropdown  [options]="listRolUser" optionLabel="nombreCompleto" optionValue="idUsuario" [(ngModel)]="user.idUsuarioBackup"  emptyFilterMessage="No existen registros" emptyMessage="No existen registros" filter="true"></p-dropdown>
        <small class="ng-dirty ng-invalid"
            *ngIf="user.backupActivo && !user.idUsuarioBackup">{{securityConstants.user.backup.requiered}}</small>
    </div>
    

</div>

<div *ngIf="isAssignRol" class="p-fluid p-formgrid grid">
    asignar rol
    <div class="field col-12 md:col-4">
        <label htmlFor="name">{{securityConstants.user.name.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="name" [(ngModel)]="user.nombre" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.nombre}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.nombre">{{securityConstants.user.name.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="lastName">{{securityConstants.user.lastName.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="lastName" [(ngModel)]="user.apellido" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.apellido}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.apellido">{{securityConstants.user.lastName.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="email">{{securityConstants.user.email.field}} <span class="ng-invalid">*</span></label>
        <input type="email" (blur)='validateEmail()' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" pInputText
            id="email" [(ngModel)]="user.email" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.email}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.email">{{securityConstants.user.email.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="phone">{{securityConstants.user.phone.field}} <span class="ng-invalid">*</span></label>
        <input type="text" pInputText id="phone" [(ngModel)]="user.telefono" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.telefono}" maxlength="13"/>
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !user.telefono">{{securityConstants.user.phone.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="state">{{securityConstants.user.status.field}}</label><br>
        <p-toggleButton [(ngModel)]="state" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div>
</div>