import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CelulaModel } from 'src/app/models/celula.model';
import { RolModel } from 'src/app/models/roles.model';
import { UserModel } from 'src/app/models/user.model';
import { SecurityService } from 'src/app/service/securityservice';
import { Properties } from 'src/app/utilities/properties';
import { environment } from 'src/environments/environment';
import { SecurityConstants } from '../security.constants';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/common/auth.service';
import { PermisoModel } from 'src/app/models/permiso.model';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  providers: [MessageService]
})
export class UsersListComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  userDialog: boolean;
  deleteUserDialog: boolean = false;
  blockedUser: boolean = false;
  users: UserModel[] = [];
  user: UserModel;
  selectedUsers: UserModel[];
  rowsPerPageOptions = [5, 10, 20];
  @Output() userAccion = new EventEmitter<any>();
  isAssignRol: boolean = false;
  isNewRolMenu: boolean = false;
  listRoles: any[];
  listCelulas: any[];
  listAllRoles: any[];
  listAllCelulas: any[];
  selectedCelula: any;
  selectedRoles: any;
  stateRolUser: boolean = false;
  listRolUser: any;
  isCreateRolUser: boolean = false;
  submitted: boolean = false;
  permiso: PermisoModel;

  constructor(private messageService: MessageService, private securityServices: SecurityService, private router: Router,
    private auth: AuthorizationService) {
  }
  ngOnInit(): void {
    this.permiso = this.auth.validatePermision(this.router);
    this.getUsers();
    this.getRoles();
  }

  confirmDelete() {
    this.deleteUserDialog = false;
    this.user.estado = 'E';
    this.updateUsers();
    this.user = new UserModel();
  }

  deleteUser(user: UserModel) {
    this.deleteUserDialog = true;
    this.user = { ...user };
  }

  editUser(user: UserModel) {
      const userEvent = {
        type: 'M',
        user: user,
        isEdit: true
      };
      this.userAccion.emit(userEvent);
    

  }

  userSelected() {
    const userEvent = {
      type: 'S',
      users: this.selectedUsers
    };
    this.userAccion.emit(userEvent);
  }
  /**
   * Consutla los usuarios del sistema
   */
  getUsers() {
    this.blockedUser = true;
    this.securityServices.getUsers().then(resp => {
      this.blockedUser = false;
      if (resp.code === 200) {
        this.users = resp.data;
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blockedUser = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Actualiza los datos del usuario seleccionado
   * @param user Datos del usuario a almacenar
   */
  updateUsers() {
    this.securityServices.updateUser(this.user).then(resp => {
      if (resp.code === 200) {
        this.getUsers();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.user.messages.update, life: this.properties.timeLifeMessages });
      } else {
        this.getUsers();
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
  /**
   * Inactiva o Activa un usuario del sistemas
   * @param user Usuario a inactivar
   */
  updateStateUser(user: UserModel) {
   
      if (user.email === environment.admin) {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: this.securityConstants.user.messages.adminNoInactivate, life: this.properties.timeLifeMessages });
      } else {
        user.estado = user.estado === this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive;
        this.user = user;
        this.updateUsers();
      }
  }

  assignRoles(user: UserModel) {
    this.user = user;
    this.isAssignRol = true;
    this.isNewRolMenu = false;
    this.getRolesByUser(user.idUsuario)
    this.assignRolUser();
  }


  assignRolUser() {
    const userEvent = {
      type: 'AR',
      user: this.user
    };
    this.userAccion.emit(userEvent);
  }


  //Asignacion de roles a usuario

  getRoles() {
    this.securityServices.getRoles().then(resp => {
      if (resp.code === 200) {
        this.listAllRoles = resp.data;
        this.listRoles = this.buildRoles(resp.data);
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  buildRoles(roles: RolModel[]): any[] {
    const listRoles: any[] = [];
    roles.forEach(rol => {
      if (rol.estado === this.properties.stateActive) {
        listRoles.push({ value: rol.idRol, label: rol.nombreRol });
      }
    });
    return listRoles;
  }

  getRolesByUser(idUser: any) {
    this.blockedUser = true;
    this.securityServices.getRolesByUser(idUser).then(resp => {
      if (resp.code === 200) {
        this.listRolUser = this.buildListRolUser(resp.data);
        if (resp.data.length === 0) {
          this.messageService.add({ severity: 'info', summary: this.properties.info, detail: this.properties.noRecords, life: this.properties.timeLifeMessages });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
      this.blockedUser = false;
    }).catch(err => {
      this.blockedUser = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  buildListRolUser(data: any[]): any[] {
    const listRolUser: any[] = [];
    data.forEach(x => {
      listRolUser.push({
        rol: this.findRol(x.idRol), estado: x.estado,
        idRol: x.idRol
      });
    });
    return listRolUser;
  }

  

  findRol(idRol: any): any {
    const rol = this.listAllRoles.find(rol => rol.idRol == idRol);
    if (!rol) {
      return '';
    }
    return rol.nombreRol;
  }

  newUserRol() {
    this.submitted = false;
    this.isNewRolMenu = true;
    this.isCreateRolUser = true;
    this.selectedCelula = undefined;
    this.selectedRoles = undefined;
    this.listCelulas = [];
  }

  saveRolUser() {
    this.submitted = true;
    if (this.selectedRoles) {
      const body = {
        idUsuario: this.user.idUsuario,
        idRol: this.selectedRoles.value,
        estado: this.stateRolUser == true ? this.properties.stateActive : this.properties.stateInactive
      }
      this.createRolUser(body);


    }
  }

  createRolUser(data: any) {
    data.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.createRolUser(data).then(resp => {
      if (resp.code === 200) {
        this.goToRolUsers();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.user.messages.create, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateRolUser(data: any) {
    data.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices.updateRolUser(data).then(resp => {
      if (resp.code === 200) {
        this.getRolesByUser(this.user.idUsuario)
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.user.messages.update, life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  goToRolUsers() {
    this.isNewRolMenu = false;
    this.getRolesByUser(this.user.idUsuario)
  }

  updateStateRolUser(rolUser: any) {
      const bodyUpdate = {
        idUsuario: this.user.idUsuario,
        idRol: rolUser.idRol,
        estado: rolUser.estado == this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive
      }
      this.updateRolUser(bodyUpdate);
    
  }

  cancelRolUser(){
    this.isNewRolMenu = false;
  }
  updateUser(user) {
    user.idEmpresa = user.userEmpresa?.empresa?.idEmpresa
    user.estado = user.estado == this.properties.stateActive ? this.properties.stateInactive : this.properties.stateActive
    this.securityServices.updateUser(user).then(resp => {
      if (resp.code === 200) {
        this.getUsers();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.user.messages.update, life: this.properties.timeLifeMessages });
      } else {
        if(resp.data){
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.data, life: this.properties.timeLifeMessages });
        }else{
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });

        }
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }
}
