import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { MenuModel } from 'src/app/models/menus.model';
import { SecurityService } from 'src/app/service/securityservice';
import { AppBreadcrumbService } from 'src/app/utilities/app.breadcrumb.service';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { MenuFormComponent } from './menu-form.component';
import { MenuListomponent } from './menu-list.component';
import { PermisoModel } from 'src/app/models/permiso.model';
import { AuthorizationService } from 'src/app/common/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-ecc',
  templateUrl: './menu.component.html',
  providers: [MessageService]
})
export class MenuComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  deleteMenusDialog: boolean = false;
  menus: MenuModel[] = [];
  menu = new MenuModel();
  menuFather = new MenuModel();
  selectedMenus: MenuModel[];
  isNewMenu: boolean;
  home = { icon: 'pi pi-arrow-left' };
  items: MenuItem[];
  isFather: boolean = false;
  isEdit = false;
  idMenuPadre: number;
  permiso: PermisoModel;
  @ViewChild(MenuFormComponent) menuFormComponent: MenuFormComponent;
  @ViewChild(MenuListomponent) menuListComponent: MenuListomponent;

  constructor(private messageService: MessageService, private breadcrumbService: AppBreadcrumbService,
    private router: Router, private auth: AuthorizationService,
    private securityServices: SecurityService) {
    this.breadcrumbService.setItems([
      { label: this.securityConstants.security },
      { label: this.securityConstants.menu.title, routerLink: ['/principal/menu'] }
    ]);
  }

  ngOnInit(): void {
    this.permiso = this.auth.validatePermision(this.router);
    this.items = [];
  }

  newMenu() {
    this.selectedMenus = [];
    this.isNewMenu = true;
    if (this.isFather && this.isNewMenu) {
      let menuFather = this.menu.idMenu;
      this.menuFather = new MenuModel();
      this.menuFather.idMenuPadre = menuFather;
      this.menu = new MenuModel();
      this.menu = this.menuFather;
      this.idMenuPadre = menuFather;
    }
    if (!this.menu.idMenu) {
      this.menu = new MenuModel();
    }
  }

  deleteSelectedMenus() {
    this.deleteMenusDialog = true;
    this.validateLevelsMenu();
  }

  saveMenu() {
    this.menuFormComponent.saveMenu();
    this.validateLevelsMenu();
  }

  cancelMenu() {
    this.isNewMenu = false;
    this.menu = new MenuModel();
    this.getFatherMenu();
    setTimeout(() => {
      if (this.menuListComponent.menus.length == 0 && !this.isFather) {
        this.menuListComponent.getMenus(this.idMenuPadre);
      }
    }, 10);
    this.validateLevelsMenu();
  }


  getFatherMenu() {
    if (this.menuFormComponent && this.menuFormComponent.menu && this.idMenuPadre) {
      this.idMenuPadre = this.menuFormComponent.menu.idMenuPadre;
    }
  }

  menuAccion(event: any) {
    if (event.type == 'M') {
      this.isNewMenu = true;
      this.menu = event.menu;
      this.isEdit = event.isEdit;
      this.idMenuPadre = this.menu.idMenuPadre;
    } else if (event.type == 'V') {
      this.items.push({ label: event.menu.nombreMenu, id: event.menu.idMenu });
      this.items = this.items.slice();
      this.menuFather = event.menu;
      this.isFather = true;
      this.menu = this.menuFather;
    }
    else {
      this.selectedMenus = event.menus;
    }
    this.validateLevelsMenu();
  }

  confirmDeleteSelected() {
    let menusToDelete: any[] = [];
    this.selectedMenus.forEach(x => {
      menusToDelete.push(x.idMenu)
    });
    const menusDelete = {
      idMenus: menusToDelete
    }

    this.deleteMassive(menusDelete);
  }

  deleteMassive(menus: any) {
    this.securityServices.deleteMenu(menus).then(resp => {
      if (resp.code === 200) {
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: this.securityConstants.menu.messages.deletes, life: this.properties.timeLifeMessages });
        this.reloadListMenus();
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.successful, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  reloadListMenus() {
    this.deleteMenusDialog = false;
    this.selectedMenus = [];
    setTimeout(() => {
      this.getFatherMenu();
      if (this.menuFather && this.menuFather.idMenu) {
        this.menuListComponent.getMenus(this.idMenuPadre);
      } else {
        this.menuListComponent.getMenus(this.idMenuPadre);
      }

      this.menuListComponent.selectedMenus = [];
      this.validateLevelsMenu();
    }, 10);
  }

  onItemClick(event) {
    this.isNewMenu = false;
    const index = this.items.indexOf(event.item);
    if (!event.item.id) {
      setTimeout(() => {
        this.menuFather = undefined;
        this.menuListComponent.menuFather = undefined;
        this.menuListComponent.getMenus(null);
        this.items = [];
        this.menuFather = null;
        this.isFather = false
      }, 1);
    } else {
      this.items.splice(index + 1, this.items.length);
      this.menu.idMenu = event.item.id
      this.menuListComponent.getMenus(event.item.id);
      this.menu = this.menuListComponent.findMenu(event.item.id);
      if (!this.menu.idMenuPadre) {
        this.isFather = false;
      }
    }

    this.validateLevelsMenu();


  }

  validateLevelsMenu() {
    setTimeout(() => {
      if (this.items.length >= 3 && this.menuListComponent) {
        this.menuListComponent.addSubLevels = false;
      } else if (this.menuListComponent) {
        this.menuListComponent.addSubLevels = true;
      }
    }, 100);

  }


}
