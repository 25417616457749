import { TreeNode } from "primeng/api";
export class MenuTreeModel {
    data?: any;
    children?: TreeNode[];
    leaf?: boolean;
    expanded?: boolean;
    parent?:TreeNode


    constructor() {
        this.data = {};
        this.children = [];
        this.leaf = null;
        this.expanded = null;
        this.parent = null;
    }
}
