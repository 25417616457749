import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { EditorModule } from 'primeng/editor';

import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppRightMenuComponent } from './app.rightmenu.component';
import { AppTopBarComponent } from './app.topbar.component';

import { MenuService } from './app.menu.service';

import { AppLoginComponent } from './pages/security/login/app.login.component';
import { TokenInterceptor } from './common/token.interceptor';
import { GenericService } from './common/genericservice';
import { MessageService } from 'primeng/api';
import { AppBreadcrumbService } from './utilities/app.breadcrumb.service';
import { RolesComponent } from './pages/security/roles/roles.component';
import { MenuComponent } from './pages/security/menu/menu.component';
import { UsersComponent } from './pages/security/users/users.component';
import { UsersFormComponent } from './pages/security/users/users-form.component';
import { UsersListComponent } from './pages/security/users/users-list.component';
import { MenuFormComponent } from './pages/security/menu/menu-form.component';
import { MenuListomponent } from './pages/security/menu/menu-list.component';
import { RolformComponent } from './pages/security/roles/rol-form.component';
import { RolListComponent } from './pages/security/roles/rol-list.component';
import { Utils } from './utilities/utils';
import { CatalogComponent } from './pages/security/catalog/catalog.component';
import { CatalogFormComponent } from './pages/security/catalog/catalog-form.component';
import { CatalogListComponent } from './pages/security/catalog/catalog-list.component';
import { BlockUIModule } from 'primeng/blockui';
import { BlockUIModule as BlockUIModuleEx } from "ng-block-ui";
import { ConfirmUserComponent } from './pages/security/confirmUser/confirmUser.component';
import { ContraseniaComponent } from './pages/security/contrasenia/contrasenia.component';
import { AppNotfoundComponent } from './pages/security/notfound/app.notfound.component';
import { AppAccessdeniedComponent } from './pages/security/accessdenied/app.accessdenied.component';
import { PerfilComponent } from './pages/security/perfil/perfil.component';
import { SpeedDialModule } from 'primeng/speeddial';
import { ContraseniaReenvioComponent } from './pages/security/contrasenia/contraseniaReenvio.component';
import { BlockUIComponent } from './common/blockUI';
import { MessageBusService } from './service/messagebusservice';
import { DemoAppComponent } from './pages/security/test/test-component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { SafePipe } from './common/pipe.safe';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DashboardComponent } from './pages/dashboard/requirements/dashboard.component';
import { DashboardListComponent } from './pages/dashboard/requirements/dashboard-list.component';
import { PendingsApprovalComponent } from './pages/pendings/pendings-approval/pendings-approval.component';
import { PendingsApprovalListComponent } from './pages/pendings/pendings-approval/pendings-approval-list.component';
import { CashRequirementComponent } from './pages/dashboard/cash-requirement/cash-requirement.component';
import { SupportSettlementComponent } from './pages/support-settlement/support/support-settlement.component';
import { NewSupportComponent } from './pages/support-settlement/new-support/new-support.component';
import { ApproveRepositionComponent } from './pages/dashboard/reposition/approve-reposition.component';
import { FundManagementComponent } from './pages/fund-management/fund-management.component';
import { TonnageComponent } from './pages/fund-management/tonnage/tonnage.component';
import { NewTonnageComponent } from './pages/fund-management/new-tonnage/new-tonnage.component';
import { SettleRequestComponent } from './pages/dashboard/settle-request/settle-request.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { NewFundComponent } from './pages/fund-management/new-fund/new-fund.component';
import { InteractionType } from '@azure/msal-browser';
const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Triden/') > -1
import { FeriadoComponent } from './pages/security/feriado/feriado.component';
import { FeriadoFormComponent } from './pages/security/feriado/feriado-form.component';
import { FeriadoListComponent } from './pages/security/feriado/feriado-list.component';
import { CommentComponent } from './pages/dashboard/comments/comments.component';
import { MsalModule, MSAL_INSTANCE, MsalInterceptor, MsalService, MsalGuard } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MSALInstanceFactory } from './app.msal.service';
import { AuthGuard } from './common/auth.guard';
import { ApproverConfigurationComponent } from './pages/approver-configuration/approver-configuration.component';
import { NewApproverComponent } from './pages/approver-configuration/new-approver/new-approvercomponent';
import { environment } from 'src/environments/environment';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        BlockUIModule,
        SpeedDialModule,
        EditorModule,
        CurrencyMaskModule,
        BlockUIModuleEx.forRoot({ template: BlockUIComponent }),
        MsalModule.forRoot(
            MSALInstanceFactory(),
            {
              interactionType: InteractionType.Redirect, // o 'popup'
              authRequest: {
                scopes: ['user.read'],
                redirectUri:environment.urlFrontend
              },
            },
            {
              interactionType: InteractionType.Redirect, // o 'popup'
              protectedResourceMap: new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']]]),
            }
            ),
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppRightMenuComponent,
        AppTopBarComponent,
        RolesComponent,
        MenuComponent,
        MenuFormComponent,
        MenuListomponent,
        AppLoginComponent,
        RolformComponent,
        RolListComponent,
        UsersComponent,
        UsersFormComponent,
        UsersListComponent,
        CatalogComponent,
        CatalogFormComponent,
        CatalogListComponent,
        ConfirmUserComponent,
        ContraseniaComponent,
        AppNotfoundComponent,
        AppAccessdeniedComponent,
        PerfilComponent,
        ContraseniaReenvioComponent,
        DemoAppComponent,
        SafePipe,
        DashboardComponent,
        DashboardListComponent,
        PendingsApprovalComponent,
        PendingsApprovalListComponent,
        CashRequirementComponent,
        SupportSettlementComponent,
        NewSupportComponent,
        ApproveRepositionComponent,
        FundManagementComponent,
        TonnageComponent,
        NewTonnageComponent,
        SettleRequestComponent,
        DocumentsComponent,
        NewFundComponent,
        FeriadoComponent,
        FeriadoFormComponent,
        FeriadoListComponent,
        CommentComponent,
        ApproverConfigurationComponent,
        NewApproverComponent
      
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        MenuService, MessageService, AppBreadcrumbService,
        GenericService, NgxImageCompressService,
          {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuthGuard,
        Utils,
        MessageBusService,
        DatePipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
