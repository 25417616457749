import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';
import { PermisoModel } from 'src/app/models/permiso.model';
import { DashboardConstants } from '../dashboard.constants';
import { CatalogModel } from "src/app/models/catalog.model";
import { MessageService } from "primeng/api";
import { AuthorizationService } from 'src/app/common/auth.service';
import { SecurityService } from 'src/app/service/securityservice';
import { Utils } from "src/app/utilities/utils";
import { SolicitudModel } from "src/app/models/caja-chica/solitud.model";



@Component({
  selector: 'app-settle-request',
  templateUrl: './settle-request.component.html',
  providers: [MessageService],

})
export class SettleRequestComponent implements OnInit {

  public readonly dashboardConstants = DashboardConstants;
  properties: Properties = new Properties();
  deleteCelulaDialog: boolean = false;
  @Input() isNewRequirement: boolean;
  @Input() isView: boolean;
  @Input() isDataSoli: boolean;
  @Input() isDataSoliLiquidacion : boolean;
  @Input() requirement: any
  permiso: PermisoModel;
  viewSupportSettle : boolean = false;
  @Output() requirementForm = new EventEmitter<any>();
  createComment : boolean = false
  view: boolean = false
  submitted: boolean = false
  listTypeRequest: CatalogModel[] = [];
  listReasonRequest: CatalogModel[] = [];
  listSolicitantArea: CatalogModel[] = [];
  listRejectRequest: CatalogModel[] = [];
  fondos: any[] = [];
  dataSolicitante: any = {};
  singleSolicitud:any
  aprobarRequerimiento: boolean = false
  envioRequerimiento: boolean = false
  rechazoRequerimiento: boolean = false
  vizualizarSoportes : boolean = false
  constructor(
    private messageService: MessageService,
    private auth: AuthorizationService,
    private securityServices: SecurityService,
    private utils: Utils

  ) {

  }
  /**
   * Inicio de pantalla
   */
  async ngOnInit() {
    this.requirement.solicitante.nombreCompleto = ''
    if(this.requirement.idSolicitud){
      this.view = true
      this.requirement.solicitante.nombreCompleto = this.requirement.solicitante.nombre + ' ' + this.requirement.solicitante.apellido
      this.requirement.fechaEsperadaLiquidacion = new Date(this.requirement.fechaEsperadaLiquidacion)
      this.requirement.fechaSolicitud = new Date(this.requirement.fechaSolicitud)
    }
    this.datosSolicitante();
    this.listTypeRequest = await this.getCatalogList(
        this.properties.idCatalogoTipoSolicitud
    );
    this.fondos = await this.getFondos();
    this.listReasonRequest = await this.getCatalogList(
        this.properties.idCatalogoMotivoSolicitud
    );
    this.listSolicitantArea = await this.getCentroCosto(
        this.properties.idCatalogoArea
    );
    this.listRejectRequest = await this.getCatalogList(
        this.properties.idCatalogoRechazoSolicitud
    );
  }
  async getCentroCosto(idCatalogoCuentaCosto) {
    let centroList: any[] = [];
    await this.securityServices
        .getCentroCuentaGasto(idCatalogoCuentaCosto)
        .then((resp) => {
            if (resp && resp.code === 200) {
                centroList = resp.data;
            } else if (resp.description) {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: resp.description,
                    life: this.properties.timeLifeMessages,
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: "Ocurrio un error al cargar los catalogos",
                    life: this.properties.timeLifeMessages,
                });
            }
        })
        .catch((err) => {
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: err.message,
                life: this.properties.timeLifeMessages,
            });
        });
    return centroList;
}

  closeNewRequirement() {
    const event = {
        type: "datosSolicitanteLiquidacion",
    };
    this.requirementForm.emit(event);
  }
  datosSolicitante() {
    const userLogin = this.auth.getCurrentUser();
    this.dataSolicitante.nombre =
        userLogin.userData.nombre + " " + userLogin.userData.apellido;
    this.dataSolicitante.numeroDocumentoIdentidad =
        userLogin.userData.numeroDocumentoIdentidad;
    this.dataSolicitante.email = userLogin.userData.email;

    if (this.requirement.idSolicitud) {
        this.requirement.fechaSolicitud = new Date(
            this.requirement.fechaSolicitud
        );
        this.dataSolicitante.nombre =  this.requirement.solicitante.nombre + " " + this.requirement.solicitante.apellido;
        this.dataSolicitante.numeroDocumentoIdentidad = this.requirement.solicitante.numeroDocumentoIdentidad
        this.dataSolicitante.email = this.requirement.solicitante.email
    } else {
        this.requirement.fechaSolicitud = new Date();
    }
}

  openSupportSettle(){
    this.viewSupportSettle = true;
  }

  closeSupportSettle(event){
    if(event.type === 'return'){
      this.viewSupportSettle = false;
    }
    if(event.type == 'reload'){
        this.closeNewRequirement()
    }
  }
  openComment(){
    this.singleSolicitud = this.requirement
    this.createComment = true
  }
  closeCommentForm(event){
    this.createComment = event.createComment
  }
  async getFondos() {
    let fondoList: any[] = [];
    await this.securityServices
        .getFondo(null, null, null)
        .then((resp) => {
            if (resp.code === 200) {
                resp.data.forEach((data) => {
                    let selectItem = {
                        label: data.nombreFondo,
                        value: data.idFondo,
                    };
                    if (data.estado === this.properties.stateActive) {
                        fondoList.push(selectItem);
                    }
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: resp.description,
                    life: this.properties.timeLifeMessages,
                });
            }
        })
        .catch((err) => {
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: err.message,
                life: this.properties.timeLifeMessages,
            });
        });
    return fondoList;
}
async getCatalogList(idCatalog: any) {
  let catalogList: any[] = [];
  await this.securityServices
      .getCatalogsByFather(idCatalog)
      .then((resp) => {
          if (resp && resp.code === 200) {
              resp.data.forEach((catalog) => {
                  let catalogo = new CatalogModel();
                  catalogo = catalog;
                  let selectItem = {
                      label: catalogo.nombre,
                      value: catalogo.idCatalogo,
                  };
                  if (catalogo.estado === this.properties.stateActive) {
                      catalogList.push(selectItem);
                  }
              });
          } else if (resp.description) {
              this.messageService.add({
                  severity: "error",
                  summary: this.properties.error,
                  detail: resp.description,
                  life: this.properties.timeLifeMessages,
              });
          } else {
              this.messageService.add({
                  severity: "error",
                  summary: this.properties.error,
                  detail: "Ocurrio un error al cargar los catalogos",
                  life: this.properties.timeLifeMessages,
              });
          }
      })
      .catch((err) => {
          this.messageService.add({
              severity: "error",
              summary: this.properties.error,
              detail: err.message,
              life: this.properties.timeLifeMessages,
          });
      });
  return catalogList;
}
rejectRequirement(requirement: SolicitudModel){
    this.rechazoRequerimiento = true
    this.requirement = requirement
    
}

async rejectReq(){
    this.requirement.estado = 'N'
    if(this.requirement.idCatalogoMotivoRechazo){
        await this.updateSolicitud()
    }
    
}
async updateSolicitud() {
    //TODO Eliminar fragmento cuando se cambie la bdd
    
    this.requirement.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    this.securityServices
        .updateSolicitud(this.requirement)
        .then((resp) => {
            if (resp.code === 200) {
                this.rechazoRequerimiento = false
                this.envioRequerimiento = false
                this.aprobarRequerimiento = false
                this.gotToRequirements();
                this.submitted = false;
                this.messageService.add({
                    severity: "success",
                    summary: this.properties.successful,
                    detail: this.dashboardConstants.newRequeriment.messages
                        .update,
                    life: this.properties.timeLifeMessages,
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: resp.description,
                    life: this.properties.timeLifeMessages,
                });
            }
        })
        .catch((err) => {
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: err.message,
                life: this.properties.timeLifeMessages,
            });
        });
}
    gotToRequirements() {
        const event: any = {};
        setTimeout(() => {
            this.requirement = {};
            event.type = "new";
            this.requirementForm.emit(event);
        }, 2000);
    }
    
}