import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from 'src/app/common/auth.service';
import { SecurityService } from 'src/app/service/securityservice';
import { CommonService, MESSAGE_TYPE } from 'src/app/utilities/commonservice';
import { SecurityConstants } from '../security.constants';

@Component({
  selector: 'app-contrasenia',
  templateUrl: './contrasenia.component.html'
})
export class ContraseniaComponent {

    constructor(
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private securityServices: SecurityService,
        private authService: AuthorizationService,
        private commonService: CommonService) {

      }

      urlTree: any;
      username: string;
      passWd: string;
      public readonly securityConstants = SecurityConstants;
      datos = this.activatedRoute.snapshot.paramMap.get('datos');

      login() {
          if(this.username == this.passWd && this.username != undefined && this.passWd != undefined){

            this.urlTree = this.router.parseUrl(this.router.url);
            const code = this.urlTree.queryParams.code;
            const username = this.urlTree.queryParams.username;
              const userModel = {
                username:username,
                cognitoCode:code,
                password:btoa(this.passWd)
              }
              this.commonService.clearMessage();
              this.securityServices.resetPass(userModel).then(resp => {
                if (resp.code === 200) {
                  this.authService.setCurrentUser(resp.data);
                  location.assign('/login');
                } else {
                    if (resp.description.indexOf('ExpiredCodeException') || resp.description.indexOf('Invalid code')) {
                      this.commonService.addMessage(MESSAGE_TYPE.ERROR, 'Código de acceso expirado, por favor solicite uno nuevo ingresando su correo.');
                      setTimeout(() => {
                        this.router.navigate(['reenvio']);
                      }, 4000);
                    } else {
                      this.commonService.addMessage(MESSAGE_TYPE.ERROR, resp.description);
                    }
                }
              }).catch(err => {
                console.log(err);
                this.commonService.addMessage(MESSAGE_TYPE.ERROR, this.securityConstants.login.messages.error);
              });
          } else {
              if(this.username == undefined || this.passWd == undefined){
                this.commonService.addMessage(MESSAGE_TYPE.ERROR, "No ingreso datos");
              }else{
                this.commonService.addMessage(MESSAGE_TYPE.ERROR, "Contraseñas no coinciden");
              }
          }
      }

}
