<div class="grid">
    <div class="height-bar col-12">
        <div class="p-fluid p-formgrid grid pt-5 px-3">
            <div class="field col-12 md:col-10">
                <h2 style="color: white;">Usuarios</h2>
                <h6 style="color: white;">Aquí puedes gestionar tus usuarios</h6>
            </div>

        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-breadcrumb  *ngIf="items.length > 0" (onItemClick)="onItemClick($event)" [model]="items" [home]="home"></p-breadcrumb>
            <p-toolbar styleClass="mb-1" *ngIf="!isAssignRol">
                <ng-template pTemplate="left">
                    <h5 *ngIf="!isAssignRol"></h5>
                    <h5 *ngIf="isAssignRol">{{ securityConstants.user.messages.rolesUser }}</h5>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="isNewUser" pButton pRipple id="saveUser" icon="{{properties.guardar.icono}}"
                        label="{{properties.guardar.valor}}" class="new-button mr-2"
                        (click)="saveUser()"></button>
                    <button *ngIf="isNewUser" pButton pRipple id="cancelUser" icon="{{properties.cancelar.icono}}"
                        label="{{properties.cancelar.valor}}" class="p-button-danger" (click)="cancelUser()"></button>
                    <button *ngIf="!isNewUser" pButton pRipple id="newUser" icon="{{properties.nuevo.icono}}"
                        label="{{properties.nuevo.valor}}" class="new-button mr-2 mb-2" (click)="newUser()"></button>
                    <button *ngIf="!isNewUser && selectedUsers && selectedUsers.length > 0" pButton pRipple
                        id="deleteUser" icon="{{properties.eliminar.icono}}" label="{{properties.eliminar.valor}}"
                        class="p-button-danger mr-2 mb-2" (click)="deleteSelectedUsers()"></button>
                </ng-template>
            </p-toolbar>
           
            <app-users-form *ngIf="isNewUser" (userAccion)="cancelUser()" [user]="user" [isEdit]="isEdit" [branches]="branches"></app-users-form>
            <app-users-list *ngIf="!isNewUser" (userAccion)="userAccion($event)"></app-users-list>
        </div>


        <p-dialog [(visible)]="deleteUsersDialog" header="{{properties.confirmation}}" [modal]="true"
            [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>{{securityConstants.user.confirmationMessages.deletes}}</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                    (click)="deleteUsersDialog = false"></button>
                <!-- <button pButton pRipple icon="pi pi-check" class="p-button-text" label="{{properties.yes}}"
                    (click)="confirmDeleteSelected()"></button> -->
            </ng-template>
        </p-dialog>

    </div>
</div>