import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FeriadoModel } from 'src/app/models/feriado.model';
import { Properties } from 'src/app/utilities/properties';
import { SecurityConstants } from '../security.constants';
import { FeriadoListComponent } from './feriado-list.component';
import { SecurityService } from 'src/app/service/securityservice';

@Component({
  selector: 'app-feriado-form',
  templateUrl: './feriado-form.component.html',
  providers: [MessageService]
})
export class FeriadoFormComponent implements OnInit {

  public readonly securityConstants = SecurityConstants;
  properties: Properties = new Properties();
  @Input() feriado: FeriadoModel;
  @Input() lastFeriado : number;
  @Input() isUpdate : boolean;
  submitted: boolean = false;
  state: boolean = false;
  fecha:any;
  nextIdFeriado:number;
  @Output() feriadoAccion = new EventEmitter<any>();
  
	@ViewChild(FeriadoListComponent) feriadoListComponent: FeriadoListComponent;

  constructor(private messageService: MessageService, private securityService: SecurityService) { }

  ngOnInit(): void {
    
    if(this.feriado && this.feriado.idFeriado){
      this.fecha = new Date(this.feriado.fechaFeriado);
      this.fecha.setDate(this.fecha.getDate() + 1);
      this.feriado.fechaFeriado =  this.fecha;
      this.state = this.feriado.estado == this.properties.stateActive ? true : false;
    }else{
      this.feriado = new FeriadoModel()
      this.feriado.fechaFeriado = new Date();
    }
  }

  saveFeriado() {
    this.submitted = true;
    if (!this.feriado.fechaFeriado) {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: 'Fecha  Feriado es un campo requerido', life: this.properties.timeLifeMessages });
    } else {
      if (this.feriado.idFeriado) {
        this.updateFeriado();
      } else {  
        this.createFeriado();
      }
    }
  }

  createFeriado() {
    let body = {
      fechaFeriado : this.feriado.fechaFeriado,
      estado:  this.properties.stateActive
    }
    this.securityService.createFeriado(body).then(resp => {
      if (resp.code == 200) {
        this.goToFeriados();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: "Registro Creado correctamente", life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.data, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  updateFeriado() {
    
    this.securityService.updateFeriado(this.feriado).then(resp => {
      if (resp.code === 200) {
        this.goToFeriados();
        this.messageService.add({ severity: 'success', summary: this.properties.successful, detail: "Registro Actualizado correctamente", life: this.properties.timeLifeMessages });
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  cancelFeriado() {
    this.feriado = new FeriadoModel();
    this.feriadoAccion.emit();
  }

  goToFeriados() {

    setTimeout(() => {
      this.feriado = new FeriadoModel();
      this.feriadoAccion.emit();
    }, 3000);

  }
}
