import { Component, OnInit } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';
import { PermisoModel } from 'src/app/models/permiso.model';
import { FundConstants } from '../fund-management/fund.constants';
import { DocumentsConstants } from './documentsConstants';
import { SupportConstants } from '../support-settlement/support.constants';
import { SecurityService } from 'src/app/service/securityservice';
import { MessageService } from 'primeng/api';
import { AuthorizationService } from "src/app/common/auth.service";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
})
export class DocumentsComponent implements OnInit {

  public readonly documentsConstants = DocumentsConstants;
  public readonly supportConstants = SupportConstants;
  properties: Properties = new Properties();
  permiso: PermisoModel;
  requirement: any;
  datosSolicitud: any = {};
  soportesLiquidacion: any[] = [];
  blockedRequirement: boolean = false;
  resumen: boolean = true;
  action: any;
  visibleButtons: boolean = false;
  viewTonage: boolean = false;
  isDocuments: boolean = true;
  newSupport: boolean;
  movimiento: any
  busqueda: string
  cargado: boolean = false;
  listRolUser: any
  listCustodio: any[]
  listArqueo: any[]
  listAdminN: any[]
  listAdminS: any[]

  constructor(private auth: AuthorizationService, private securityService: SecurityService, private messageService: MessageService) {

  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {
    this.getMovimientos();
  }
  viewDocumentSupport(movimiento) {
    this.newSupport = true;
    this.movimiento = movimiento
  }

  closeSupport(event) {
    if (event.type === 'cancel') {
      this.newSupport = false;
    }
  }
  async searchData(value) {
    this.cargado = false
    await this.securityService.getMovimientos(null, null, null, this.busqueda).then((resp) => {

      if (resp.code === 200) {
        this.cargado = true

        this.soportesLiquidacion = resp.data;
        
        this.soportesLiquidacion.forEach((objeto) => {
          objeto.fechaFactura = new Date(objeto.fechaFactura)
          objeto.fechaFactura = (objeto.fechaFactura).setDate((objeto.fechaFactura).getDate() + 1)
        });

        this.soportesLiquidacion = this.soportesLiquidacion.filter((obj) => obj.estado !== "OE" && obj.estado !== "PE")

        //Aqui se agrega el codigo para cargar filtro por persona usuario logeado
        if (this.listArqueo.length <= 0 && this.listAdminN.length <= 0 && this.listAdminS.length <= 0) {
          this.soportesLiquidacion = this.soportesLiquidacion.filter((res) =>
            this.auth.getCurrentUser().userData.idUsuario === res.auIdUsuarioCreacion
          )
        }
      }
      else {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: resp.description,
          life: this.properties.timeLifeMessages,
        });
      }
    })
      .catch((err) => {
        this.messageService.add({
          severity: "error",
          summary: this.properties.error,
          detail: err.message,
          life: this.properties.timeLifeMessages,
        });
      });
  }
  async getMovimientos() {
    await this.securityService.getInfoUser(this.auth.getCurrentUser().userData.idUsuario).then(async respInfo => {
      this.cargado = true;
      this.listCustodio = respInfo.data.menus.filter((res) => res.idRol === this.properties.idRolCustodio)
      this.listArqueo = respInfo.data.menus.filter((res) => res.idRol === this.properties.idRolArqueoCaja)
      this.listAdminN = respInfo.data.menus.filter((res) => res.idRol === this.properties.idRolAdministradorN)
      this.listAdminS = respInfo.data.menus.filter((res) => res.idRol === this.properties.idRolAdministradorS)
      if (this.listCustodio.length > 0) {
        await this.securityService.getMovimientos().then((resp) => {
          if (resp.code === 200) {
            this.soportesLiquidacion = resp.data;

            this.soportesLiquidacion.forEach((objeto) => {
              objeto.fechaFactura = new Date(objeto.fechaFactura)
              objeto.fechaFactura = (objeto.fechaFactura).setDate((objeto.fechaFactura).getDate() + 1)
            });

            this.soportesLiquidacion = this.soportesLiquidacion.filter((obj) => obj.estado !== "OE" && obj.estado !== "PE")

            //Aqui se agrega el codigo para cargar filtro por persona usuario logeado
            if (this.listArqueo.length <= 0 && this.listAdminN.length <= 0 && this.listAdminS.length <= 0) {
              this.soportesLiquidacion = this.soportesLiquidacion.filter((res) =>
                this.auth.getCurrentUser().userData.idUsuario === res.auIdUsuarioCreacion
              )
            }
          }
          else {
            this.messageService.add({
              severity: "error",
              summary: this.properties.error,
              detail: resp.description,
              life: this.properties.timeLifeMessages,
            });
          }
        })
          .catch((err) => {
            this.messageService.add({
              severity: "error",
              summary: this.properties.error,
              detail: err.message,
              life: this.properties.timeLifeMessages,
            });
          });
      } else {
        await this.securityService.getMovimientos().then((resp) => {
          if (resp.code === 200) {
            this.soportesLiquidacion = resp.data;

            this.soportesLiquidacion.forEach((objeto) => {
              objeto.fechaFactura = new Date(objeto.fechaFactura)
              objeto.fechaFactura = (objeto.fechaFactura).setDate((objeto.fechaFactura).getDate() + 1)
            });

            this.soportesLiquidacion = this.soportesLiquidacion.filter((obj) => obj.estado !== 'R' && obj.estado !== "OE" && obj.estado !== "PE")

          }
          else {
            this.messageService.add({
              severity: "error",
              summary: this.properties.error,
              detail: resp.description,
              life: this.properties.timeLifeMessages,
            });
          }
        })
          .catch((err) => {
            this.messageService.add({
              severity: "error",
              summary: this.properties.error,
              detail: err.message,
              life: this.properties.timeLifeMessages,
            });
          });
      }
    })

  }
}