import { environment } from "src/environments/environment";
import { CatalogModel } from "../models/catalog.model";
import { Properties } from "./properties";

export class Utils {

    properties: Properties = new Properties();
    constructor() {
    }

    esEmailValido(email: string): boolean {
        let mailValido = false;
        'use strict';
        if (!email) {
            return mailValido;
        }
        var EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
        if (email.match(EMAIL_REGEX)) {
            mailValido = true;
        }
        return mailValido;
    }
    
    calculateEndDate (tipoDuracion, duracion, fechaCreacion) {
        const fechaResultado = new Date(fechaCreacion);
        switch (tipoDuracion) {
            case 'H':
                fechaResultado.setHours(fechaResultado.getHours() + duracion);
            break;
            case 'D':
                fechaResultado.setDate(fechaResultado.getDate() + duracion);
                break;
            case 'S':
                fechaResultado.setDate(fechaResultado.getMonth() + (duracion *  7));
                break;
            case 'Q':
                fechaResultado.setDate(fechaResultado.getMonth() + (duracion *  15));
                break;
            case 'M':
                fechaResultado.setMonth(fechaResultado.getMonth() + duracion);
                break;

        }
        return fechaResultado;
    }
    
    getCatalogo(idCatalogo, catalogosGlobal): any {
        let catalogList: any[] = [];
        let catalogos = catalogosGlobal[idCatalogo];
        catalogos = catalogos ? catalogos: [];
        catalogos.forEach(catalog => {
          let catalogo = new CatalogModel();
          catalogo = catalog;
          let selectItem = { label: catalogo.nombre, value: catalogo.idCatalogo, code: catalogo.abreviacion };
          if (catalogo.estado === this.properties.stateActive) {
            catalogList.push(selectItem);
          }
        });
        return catalogList;
    }
    /**
     * Valida un numero de contenedor
     * @param containerNumber Numero de contenedor completo 
     * @returns TRUE si el numero es valido caso contrario FALSe
     */
    transformDate(date?: any){
        let now :any;
        if(date){
            now = new Date(date)
        }else{
            now = new Date()
        }
        return now.getFullYear() +
            "-" +
            (now.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            now.getDate().toString().padStart(2, "0");
    }
}
