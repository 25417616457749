export const ApproverConfigurationConstants = {
    grandTitle : 'Configuración del aprobador',
    title : '',
    addButton : 'Nueva configuración',
    usuarioSolicitante : {
        label : 'Usuario solicitante',
        required : 'Usuario solicitante es requerido'
    },
    usuarioAprobador : {
        label : 'Usuario aprobador',
        required : 'Usuario aprobador es requerido'
    },
    tipoAprobacion : {
        label : 'Tipo aprobación',
        required : 'Tipo aprobación es requerido'
    },
    tipoFondo : {
        label : 'Tipo fondo',
        required : 'Tipo fondo es requerido'
    },
    nivel : {
        label : 'Nivel',
        required : 'Nivel es requerido'
    },
    descripcion : {
        label : 'Descripción',
        required : 'Descripción es requerido'
    },
    estado: {
        label : 'Estado',
        required : 'Estado es requerido'
    },
    aprobadorFinal : {
        label : 'Aprobador final',
        required : 'Aprobador final es requerido'
    },
    aprobadorSuperior : {
        label : 'Aprobador superior',
        required : 'Aprobador superior es requerido'
    },

    newApprove : {
        title : "Nueva configuración del aprobador",
        subtitle : "Aquí puedes crear una nueva configuración del aprobador"
    },
    fondo : {
        label : "Fondo",
        required : "Fondo es requerido"
    }
    
}
