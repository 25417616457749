import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Properties } from "src/app/utilities/properties";

import { PermisoModel } from "src/app/models/permiso.model";
import { DashboardConstants } from "../dashboard.constants";
import { AuthorizationService } from "src/app/common/auth.service";
import { CatalogModel } from "src/app/models/catalog.model";
import { SecurityService } from "src/app/service/securityservice";
import { MessageService } from "primeng/api";
import { Utils } from "src/app/utilities/utils";
import { SolicitudModel } from "src/app/models/caja-chica/solitud.model";

@Component({
    selector: "app-cash-requirement",
    templateUrl: "./cash-requirement.component.html",
    providers: [MessageService],
})
export class CashRequirementComponent implements OnInit {
    public readonly dashboardConstants = DashboardConstants;
    properties: Properties = new Properties();
    deleteCelulaDialog: boolean = false;
    @Input() isNewRequirement: boolean;
    @Input() isView: boolean;
    @Input() isDataSoli: boolean;
    @Input() isApproveCash: boolean;
    @Input() requirement: any;
    permiso: PermisoModel;
    dataSolicitante: any = {};
    listTypeRequest: CatalogModel[] = [];
    listReasonRequest: CatalogModel[] = [];
    listSolicitantArea: any[];
    listRejectRequest: CatalogModel[] = [];
    fondos: any[] = [];
    submitted: boolean = false;
    view: boolean = false;
    envioRequerimiento: boolean = false;
    rechazoRequerimiento: boolean = false;
    @Output() requirementForm = new EventEmitter<any>();
    createComment: boolean = false;
    aprobarRequerimiento: boolean = false;
    singleSolicitud: SolicitudModel;
    feriadoCount: 0;
    catalog: any;
    recibirDocumento: boolean = false
    blocked : boolean = false
    
    constructor(
        private auth: AuthorizationService,
        private securityServices: SecurityService,
        private messageService: MessageService,
        private utils: Utils
    ) {}
    /**
     * Inicio de pantalla
     */
    async ngOnInit() {
        if (this.requirement.idSolicitud) {
            this.view = true;
            this.singleSolicitud = this.requirement;
        }
        this.datosSolicitante();
        this.listTypeRequest = await this.getCatalogList(
            this.properties.idCatalogoTipoSolicitud
        );
        this.listTypeRequest = this.listTypeRequest.filter(elem => elem.value == this.properties.idTipoSolicitudReembolso)
        this.fondos = await this.getFondos();
        this.listReasonRequest = await this.getCatalogList(
            this.properties.idCatalogoMotivoSolicitud
        );
        
        this.listRejectRequest = await this.getCatalogList(
            this.properties.idCatalogoRechazoSolicitud
        );
        this.listSolicitantArea = await this.getCentroCosto(
            this.properties.idCatalogoArea
        );
        
    }
    async getCentroCosto(idCatalogoCuentaCosto) {
        this.blocked =  true
        let centroList: any[] = [];
        await this.securityServices
            .getCentroCuentaGasto(idCatalogoCuentaCosto)
            .then((resp) => {
                if (resp && resp.code === 200) {
                    centroList = resp.data;
                    this.blocked =  false
                } else if (resp.description) {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: "Ocurrio un error al cargar los catalogos",
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
        return centroList;
    }
    celulaAccion(event: any) {
        if (event.type == "M") {
            this.isNewRequirement = true;
        }
    }
    cancelcelula() {
        this.isNewRequirement = false;
    }

    closeNewRequirement() {
        const event = {
            type: "return",
        };
        this.requirementForm.emit(event);
    }
    openComment() {
        this.createComment = true;
    }
    closeCommentForm(event) {
        this.createComment = event.createComment;
    }
    async datosSolicitante() {
        const userLogin = this.auth.getCurrentUser();
        this.dataSolicitante.nombre =
            userLogin.userData.nombre + " " + userLogin.userData.apellido;
        this.dataSolicitante.numeroDocumentoIdentidad =
            userLogin.userData.numeroDocumentoIdentidad;
        this.dataSolicitante.email = userLogin.userData.email;

        if (this.requirement.idSolicitud) {
            this.requirement.fechaSolicitud = new Date(
                this.requirement.fechaSolicitud
            );
            this.requirement.fechaEsperadaLiquidacion = new Date(
                this.requirement.fechaEsperadaLiquidacion
            );
            this.dataSolicitante.nombre =  this.requirement.solicitante.nombre + " " + this.requirement.solicitante.apellido;
            this.dataSolicitante.numeroDocumentoIdentidad = this.requirement.solicitante.numeroDocumentoIdentidad
            this.dataSolicitante.email = this.requirement.solicitante.email
        } else {
            this.requirement.fechaSolicitud = new Date();
            await this.getCatalog();
            let fecha = new Date()
            const dias = this.catalog[0].valor;
            fecha.setDate(fecha.getDate() + Number(dias));
            const initDate = this.utils.transformDate(this.requirement.fechaSolicitud)
            const endDate = this.utils.transformDate(fecha)
            await this.countFeriado(
                initDate,
                endDate
            );
            fecha.setDate(fecha.getDate() + Number(this.feriadoCount));
            this.requirement.fechaEsperadaLiquidacion = fecha
            
        }
    }
    async getCatalogList(idCatalog: any) {
        this.blocked =  true
        let catalogList: any[] = [];
        await this.securityServices
            .getCatalogsByFather(idCatalog)
            .then((resp) => {
                if (resp && resp.code === 200) {
                    resp.data.forEach((catalog) => {
                        let catalogo = new CatalogModel();
                        catalogo = catalog;
                        let selectItem = {
                            label: catalogo.nombre,
                            value: catalogo.idCatalogo,
                        };
                        if (catalogo.estado === this.properties.stateActive) {
                            catalogList.push(selectItem);
                        }
                    });
                    this.blocked =  false
                } else if (resp.description) {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: "Ocurrio un error al cargar los catalgos",
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
        return catalogList;
    }
    async getFondos() {
        this.blocked =  true
        let fondoList: any[] = [];
        await this.securityServices
            .getFondo(null, null, null)
            .then((resp) => {
                if (resp.code === 200) {
                    resp.data.forEach((data) => {
                        let selectItem = {
                            label: data.nombreFondo,
                            value: data.idFondo,
                        };
                        if (data.estado === this.properties.stateActive) {
                            fondoList.push(selectItem);
                        }
                    });
                    this.blocked =  false
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
        return fondoList;
    }

    async createSolicitud() {
        this.blocked =  true
        this.requirement.estado = this.properties.ESTADO_SOLI_POR_SOLICITADA;
        this.requirement.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
        this.securityServices
            .createSolicitud(this.requirement)
            .then((resp) => {
                if (resp.code === 200) {
                    this.blocked =  false
                    this.submitted = false;
                    this.gotToRequirements();
                    let mensaje = resp.data == true ? resp.description :  this.dashboardConstants.newRequeriment.messages
                    .create
                    let summary = resp.data == true ? this.properties.error: this.properties.successful
                    let severity = resp.data == true ? "error" : "success"
                    let life = resp.data == true ? 6000 : this.properties.timeLifeMessages
                    this.messageService.add({
                        severity: severity,
                        summary: summary ,
                        detail: mensaje,
                        life: life
                    });
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    async updateSolicitud() {
        this.blocked =  true
        this.securityServices
            .updateSolicitud(this.requirement)
            .then((resp) => {
                if (resp.code === 200) {
                    this.blocked =  false
                    this.rechazoRequerimiento = false;
                    this.envioRequerimiento = false;
                    this.aprobarRequerimiento = false;
                    this.recibirDocumento = false
                    this.gotToRequirements();
                    this.submitted = false;
                    if(resp.data === true){
                        this.messageService.add({
                            severity: "error",
                            summary: this.properties.error,
                            detail: resp.description,
                            life: 5000,
                        });
                    }else{
                        this.messageService.add({
                            severity: "success",
                            summary: this.properties.successful,
                            detail: this.dashboardConstants.newRequeriment.messages
                                .update,
                            life: this.properties.timeLifeMessages,
                        });
                    }
                    
                } else {
                    this.rechazoRequerimiento = false;
                    this.envioRequerimiento = false;
                    this.aprobarRequerimiento = false;
                    this.recibirDocumento = false
                    setTimeout(() => {
                        this.gotToRequirements();
                    }, 2000);
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.data,
                        life: 4000,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }

    async crearSolicitud() {
        this.submitted = true;
        if (
            this.requirement.fechaSolicitud &&
            this.requirement.idCatalogoTipoSolicitud &&
            this.requirement.idFondo &&
            this.requirement.idCatalogoAreaSolicitante &&
            this.requirement.idCatalogoMotivoSolicitud && this.requirement.valorSolicitado > 0
        ) {
            this.requirement.fechaSolicitud = new Date();
            this.requirement.idUsuarioSolicitante =
                this.auth.getCurrentUser().userData.idUsuario;
            if (this.requirement.idSolicitud) {
                await this.updateSolicitud();
            } else {
                await this.createSolicitud();
            }
        }
    }
    gotToRequirements() {
        const event: any = {};
        setTimeout(() => {
            this.requirement = {};
            event.type = "new";
            this.requirementForm.emit(event);
        }, 2000);
    }
    close() {
        const event: any = {};
        this.requirement = {};
        this.requirementForm.emit(event);
    }

    delivedRequirement(requirement: SolicitudModel) {
        this.requirement = requirement;
        this.envioRequerimiento = true;
    }

    receiveDocuments(requirement: SolicitudModel) {
        this.requirement = requirement;
        this.recibirDocumento = true;
    }

    async delivedReq() {
        this.requirement.estado = this.properties.ESTADO_SOLI_ENTREGADA;
        this.requirement.idCatalogoRechazoSolicitud = null
        await this.updateSolicitud();
    }
    async receiveReq() {
        this.requirement.estado = this.properties.ESTADO_SOLI_RECIBO_ENTREGADO;
        this.requirement.idCatalogoRechazoSolicitud = null
        await this.updateSolicitud();
    }
    
    rejectRequirement(requirement: SolicitudModel) {
        this.rechazoRequerimiento = true;
        this.requirement = requirement;
    }

    async rejectReq() {
        this.submitted = true
        this.requirement.estado = this.properties.ESTADO_SOLI_ANULADA;
        if(this.requirement.idCatalogoMotivoRechazo){
            
            await this.updateSolicitud();
        }
        
    }

    approveRequirement(requirement: SolicitudModel) {
        this.aprobarRequerimiento = true;
        this.requirement = requirement;
    }
    async approveReq(requirement: SolicitudModel) {
        this.requirement.estado = "S";
        await this.updateSolicitud();
    }
    async countFeriado(initDate, endDate) {
        this.blocked =  true
        await this.securityServices
            .countFeriados(initDate, endDate)
            .then((resp) => {
                if (resp.code === 200) {
                    this.blocked =  false
                    this.feriadoCount = resp.data;
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
    async getCatalog() {
        this.blocked =  true
        await this.securityServices
            .getCatalogs(null, this.properties.idCatalogoFechaLiquidacion)
            .then((resp) => {
                if (resp.code === 200) {
                    this.blocked =  false
                    this.catalog = resp.data;
                } else {
                    this.messageService.add({
                        severity: "error",
                        summary: this.properties.error,
                        detail: resp.description,
                        life: this.properties.timeLifeMessages,
                    });
                }
            })
            .catch((err) => {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: err.message,
                    life: this.properties.timeLifeMessages,
                });
            });
    }
}
