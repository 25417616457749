import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { AuthGuard } from './common/auth.guard';
import { AppLoginComponent } from './pages/security/login/app.login.component';
import { UsersComponent } from './pages/security/users/users.component';
import { RolesComponent } from './pages/security/roles/roles.component';
import { MenuComponent } from './pages/security/menu/menu.component';
import { CatalogComponent } from './pages/security/catalog/catalog.component';
import { ConfirmUserComponent } from './pages/security/confirmUser/confirmUser.component';
import { ContraseniaComponent } from './pages/security/contrasenia/contrasenia.component';
import { AppNotfoundComponent } from './pages/security/notfound/app.notfound.component';
import { AppAccessdeniedComponent } from './pages/security/accessdenied/app.accessdenied.component';
import { PerfilComponent } from './pages/security/perfil/perfil.component';
import { ContraseniaReenvioComponent } from './pages/security/contrasenia/contraseniaReenvio.component';
import { DemoAppComponent } from './pages/security/test/test-component';
import { DashboardComponent } from './pages/dashboard/requirements/dashboard.component';
import { PendingsApprovalComponent } from './pages/pendings/pendings-approval/pendings-approval.component';
import { CashRequirementComponent } from './pages/dashboard/cash-requirement/cash-requirement.component';
import { SupportSettlementComponent } from './pages/support-settlement/support/support-settlement.component';
import { NewSupportComponent } from './pages/support-settlement/new-support/new-support.component';
import { ApproveRepositionComponent } from './pages/dashboard/reposition/approve-reposition.component';
import { FundManagementComponent } from './pages/fund-management/fund-management.component';
import { TonnageComponent } from './pages/fund-management/tonnage/tonnage.component';
import { NewTonnageComponent } from './pages/fund-management/new-tonnage/new-tonnage.component';
import { SettleRequestComponent } from './pages/dashboard/settle-request/settle-request.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { FeriadoComponent } from './pages/security/feriado/feriado.component';
import { ApproverConfigurationComponent } from './pages/approver-configuration/approver-configuration.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {path: '', component: AppLoginComponent },
            {
                path: 'principal', component: AppMainComponent,
                children: [
                    {path: 'user', component: UsersComponent, canActivate: [AuthGuard]},
                    {path: 'roles', component: RolesComponent, canActivate: [AuthGuard]},
                    {path: 'menu', component: MenuComponent, canActivate: [AuthGuard]},
                    {path: 'catalog', component: CatalogComponent, canActivate: [AuthGuard]},
                    {path: 'feriados', component: FeriadoComponent, canActivate: [AuthGuard]},
                    {path: 'perfil', component: PerfilComponent, canActivate: [AuthGuard]},
                    {path: 'perfil/:code', component: PerfilComponent, canActivate: [AuthGuard]},
                    {path: 'test', component: DemoAppComponent, canActivate: [AuthGuard]},
                    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
                    {path: 'dashboard/:type', component: DashboardComponent, canActivate: [AuthGuard]},
                    {path: 'supportSettlement', component: SupportSettlementComponent, canActivate: [AuthGuard]},
                    {path: 'fundManagement/:type', component: FundManagementComponent, canActivate: [AuthGuard]},
                    {path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard]},
                    {path: 'approverConfiguration', component: ApproverConfigurationComponent, canActivate: [AuthGuard]},
                ]
            },
            {path: 'login', component: AppLoginComponent },
            {path: 'confirmUser', component: ConfirmUserComponent },
            {path: 'restablecer', component: ContraseniaComponent },
            {path: 'reenvio', component: ContraseniaReenvioComponent },
            {path: 'notfound', component: AppNotfoundComponent },
            {path: 'noauth', component: AppAccessdeniedComponent },
            {path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
