<p-toast></p-toast>
<div class="fluid">
    <p-table #dt [value]="feriados" responsiveLayout="stack" [rows]="properties.paginationRange"
        [globalFilterFields]="['fechaFeriado']" [paginator]="true" styleClass="p-datatable-products"
        [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" 
        selectionMode="multiple" [rowHover]="true" dataKey="idBicicletas">
        <ng-template pTemplate="caption">
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <h5 class="m-0"></h5>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="search-line" (input)="dt.filterGlobal($event.target.value, 'contains')"
                        placeholder="Buscar..." />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="fechaFestivo">Fecha Feriado <p-sortIcon field="fechaFeriado">
                </p-sortIcon>
            </th>
                <th>{{properties.accions}}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-feriado>
            <tr>
                <td><span class="p-column-title">Fecha Feriado</span>{{feriado.fechaFeriado | date: 'yyyy-MM-dd' }}</td>
                <td style="width:14%; min-width:10rem;">
                    <span class="p-column-title">{{properties.accions}}</span>
                    <div class="flex">
                        <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-text button-view-action mr-2"
                            (click)="editFeriado(feriado)"></button>
                            <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-text button-view-action mr-2"
                            (click)="confirmTrash(feriado)"></button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-dialog [(visible)]="deleteFeriado" header="{{properties.confirmation}}" [modal]="true"
        [style]="{width:'450px'}">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>¿Esta seguro de eliminar este feriado?</span>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
                (click)="deleteFeriado = false"></button>
            <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
                (click)="trashFeriado()"></button>
        </ng-template>
</p-dialog>