<p-toast></p-toast>
<div class="p-fluid p-formgrid grid">
    <div class="field col-12 md:col-4">
        <label htmlFor="name">{{securityConstants.catalog.name.field}}</label>
        <input type="text" pInputText id="name" [(ngModel)]="catalog.nombre" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !catalog.nombre}" maxlength="100" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !catalog.nombre">{{securityConstants.catalog.name.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="valor">{{securityConstants.catalog.value.field}}</label>
        <input type="text" pInputText id="valor" [(ngModel)]="catalog.valor" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !catalog.valor}" maxlength="500" />
        
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="valor">{{securityConstants.catalog.abreviation.field}}</label>
        <input type="text" pInputText id="abreviation" [(ngModel)]="catalog.abreviacion" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !catalog.abreviacion}" maxlength="10"/>
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !catalog.abreviacion">{{securityConstants.catalog.abreviation.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="valor">{{securityConstants.catalog.valueEnglish.field}}</label>
        <input type="text" pInputText id="valorIngles" [(ngModel)]="catalog.valorIngles" autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !catalog.valorIngles}" maxlength="500" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !catalog.valorIngles">{{securityConstants.catalog.valueEnglish.requiered}}</small>
    </div>
    <div class="field col-12 md:col-4">
        <label htmlFor="valor">{{securityConstants.catalog.abreviationEnglish.field}}</label>
        <input type="text" pInputText id="abreviation" [(ngModel)]="catalog.abreviacionIngles" autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !catalog.abreviacionIngles}" maxlength="10"/>
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !catalog.abreviacionIngles">{{securityConstants.catalog.abreviationEnglish.requiered}}</small>
    </div>
    
    <div class="field col-12 md:col-4">
        <label htmlFor="valor">{{securityConstants.catalog.order.field}}</label>
        <input type="number" max="99" min="0" pInputText id="orden" [(ngModel)]="catalog.orden" required autofocus
            [ngClass]="{'ng-invalid ng-dirty' : submitted && !catalog.orden}" />
        <small class="ng-dirty ng-invalid"
            *ngIf="submitted && !catalog.orden">{{securityConstants.catalog.order.requiered}}</small>
    </div>
    
    <div class="field col-12 md:col-4">
        <label htmlFor="state">{{securityConstants.catalog.status.field}}</label><br>
        <p-toggleButton [(ngModel)]="state" onLabel="{{properties.active}}" offLabel="{{properties.inactive}}"
            [style]="{'width': '10em'}"></p-toggleButton>
    </div>
</div>
<p-dialog [(visible)]="showRelacionados" header="Catalogos encontrados" [modal]="true" [style]="{width:'550px'}">
    <div class="flex align-items-center justify-content-center m-2">
        <p-table #dt [value]="catalogsRel" responsiveLayout="stack" [rows]="properties.paginationRange"
            [globalFilterFields]="['nombre','valor','estado']" [paginator]="true" styleClass="p-datatable-products"
            [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedCatalog"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
            selectionMode="simple" [rowHover]="true" dataKey="idCatalogo">

            <ng-template pTemplate="header">
                <tr>
                    <th>&nbsp;</th>
                    <th pSortableColumn="nombre">{{securityConstants.catalog.name.field}} <p-sortIcon field="nombre">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="valor">{{securityConstants.catalog.value.field}}<p-sortIcon field="valor"></p-sortIcon>
                    </th>
                    
                    <th>{{properties.accions}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-catalog>
                <tr>
                    <td>
                        <p-tableCheckbox [value]="catalog" (click)="confirmRelacionado(catalog)"></p-tableCheckbox>
                    </td>
                    <td><span class="p-column-title">{{securityConstants.catalog.name.field}}</span>{{catalog.nombre}}</td>
                    <td><span class="p-column-title">{{securityConstants.catalog.value.field}}</span>{{catalog.valor}}</td>
                    <td>
                        <span class="p-column-title">{{properties.accions}}</span>
                        <span style="cursor: pointer;" *ngIf="catalog.estado == properties.stateActive" (click)="confirmRelacionado(catalog)" [class]="'state-badge state-A'"> {{properties.active}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="showRelacionados = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="confirmRelacionado()"></button>
    </ng-template>
</p-dialog>