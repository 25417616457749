<p-toast></p-toast>
<p-table #dt [value]="catalogs" responsiveLayout="stack" [rows]="properties.paginationRange"
    [globalFilterFields]="['nombre','valor','estado']" [paginator]="true" styleClass="p-datatable-products"
    [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" [loading]="blockedCatalog"
    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" [(selection)]="selectedCatalogs"
    selectionMode="multiple" [rowHover]="true" dataKey="idCatalogo">
    <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0"></h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Buscar..."  #busqueda  />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            
            <th pSortableColumn="nombre">{{securityConstants.catalog.name.field}} <p-sortIcon field="nombre">
                </p-sortIcon>
            </th>
            <th pSortableColumn="valor">{{securityConstants.catalog.value.field}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="abreviacion">{{securityConstants.catalog.abreviation.field}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="valorIngles">{{securityConstants.catalog.valueEnglish.field}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="abreviacionIngles">{{securityConstants.catalog.abreviationEnglish.field}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="orden">{{securityConstants.catalog.order.field}}<p-sortIcon field="valor"></p-sortIcon>
            </th>
            <th pSortableColumn="estado">{{securityConstants.catalog.status.field}} <p-sortIcon field="estado">
                </p-sortIcon>
            </th>
            <th>{{properties.accions}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-catalog>
        <tr>
            
            <td><span class="p-column-title">{{securityConstants.catalog.name.field}}</span>{{catalog.nombre}}</td>
            <td><span class="p-column-title">{{securityConstants.catalog.value.field}}</span>{{catalog.valor}}</td>
            <td><span class="p-column-title">{{securityConstants.catalog.abreviation.field}}</span>{{catalog.abreviacion}}</td>
            <td><span class="p-column-title">{{securityConstants.catalog.valueEnglish.field}}</span>{{catalog.valorIngles}}</td>
            <td><span class="p-column-title">{{securityConstants.catalog.abreviationEnglish.field}}</span>{{catalog.abreviacionIngles}}</td>
            <td><span class="p-column-title">{{securityConstants.catalog.order.field}}</span>{{catalog.orden}}</td>
            <td>
                <span class="p-column-title">{{securityConstants.catalog.status.field}}</span>
                <span style="cursor: pointer;" *ngIf="catalog.estado == properties.stateActive" [class]="'state-badge state-A'" (click)="updateCatalog(catalog)"> {{properties.active}}</span>
                <span style="cursor: pointer;" *ngIf="catalog.estado == properties.stateInactive" [class]="'state-badge state-I'"  (click)="updateCatalog(catalog)"> {{properties.inactive}}</span>
            </td>
            <td style="width:14%; min-width:10rem;">
                <span class="p-column-title">{{properties.accions}}</span>
                <div class="flex">
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-text button-view-action mr-2"
                        (click)="editCatalog(catalog)"></button>
                    <!-- button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger mr-2"
                        (click)="deleteCatalog(catalog)"></button-->
                    <button pButton pRipple icon="pi pi-bars" class="p-button-rounded p-button-text button-view-action"
                        (click)="selectChildens(catalog)"></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-dialog [(visible)]="deleteCatalogDialog" header="{{properties.confirmation}}" [modal]="true" [style]="{width:'450px'}">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span *ngIf="catalog">{{securityConstants.catalog.confirmationMessages.delete}}<b>{{catalog.nombre}}</b>?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="cancel-button" label="{{properties.no}}"
            (click)="deleteCatalogDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="new-button" label="{{properties.yes}}"
            (click)="confirmDelete()"></button>
    </ng-template>
</p-dialog>