import { Component, OnInit } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';
import { PermisoModel } from 'src/app/models/permiso.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/app/service/securityservice';
import { MessageService } from 'primeng/api';
import { AuthorizationService } from 'src/app/common/auth.service';
import { FondoModel } from 'src/app/models/caja-chica/fondo.model';
import { ApproverConfigurationConstants } from './approver-configuration.constants';


@Component({
  selector: 'app-approverConfiguration',
  templateUrl: './approver-configuration.component.html',
  providers: [MessageService],
})
export class ApproverConfigurationComponent implements OnInit {

  properties: Properties = new Properties();
  public readonly approverConstants = ApproverConfigurationConstants;
  
  blocked :boolean = false;
  viewNewapproverConfiguration : boolean = false;
  aprobacion: any = {}
  aprobaciones : any[] = [];
  aprobador:any
  fondos:any
  idFondo:number;
  constructor(
    private router : Router, 
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private securityServices: SecurityService,
    private auth: AuthorizationService) {

  }
  /**
   * Inicio de pantalla
   */
  async ngOnInit() {
    this.getConfigAprobaciones();
    this.fondos = await this.getFondos();
  
  }

  newapproverConfiguration(){
    this.viewNewapproverConfiguration = true;
    this.aprobacion = {}
  }
  updateapproverConfiguration(aprobacion){
    this.viewNewapproverConfiguration = true;
    this.aprobacion = aprobacion
  }
  closeNewapproverConfiguration(event){
    if(event.type === 'close'){
      this.viewNewapproverConfiguration = false;
    }
    if(event.type === 'reload'){
      this.viewNewapproverConfiguration = false;
      this.getConfigAprobaciones();
    }
  }

  searchData(){
    this.getConfigAprobaciones();
  }
  getConfigAprobaciones(){
    this.securityServices.getConfiguracionAprobador(this.idFondo, this.aprobador).then(resp => {
      this.blocked = false;
      if (resp.code === 200) {
        this.aprobaciones = resp.data
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.blocked = false;
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  async getFondos() {
    let fondoList: any[] = [];
    await this.securityServices
        .getFondo(null, null, null)
        .then((resp) => {
            if (resp.code === 200) {
                resp.data.forEach((data) => {
                    let selectItem = {
                        label: data.nombreFondo,
                        value: data.idFondo,
                    };
                    if (data.estado === this.properties.stateActive) {
                        fondoList.push(selectItem);
                    }
                });
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: this.properties.error,
                    detail: resp.description,
                    life: this.properties.timeLifeMessages,
                });
            }
        })
        .catch((err) => {
            this.messageService.add({
                severity: "error",
                summary: this.properties.error,
                detail: err.message,
                life: this.properties.timeLifeMessages,
            });
        });
    return fondoList;
}
}