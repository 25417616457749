import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Properties } from 'src/app/utilities/properties';

import { DashboardConstants } from '../dashboard.constants';
import { SecurityService } from 'src/app/service/securityservice';
import { MessageService } from 'primeng/api';
import { ComentarioModel } from 'src/app/models/caja-chica/comentario.model';
import { SolicitudModel } from 'src/app/models/caja-chica/solitud.model';
import { AuthorizationService } from 'src/app/common/auth.service';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
})
export class CommentComponent implements OnInit {

  public readonly dashboardConstants = DashboardConstants;
  properties: Properties = new Properties();
  visible : boolean = false;
  @Input() createComment : boolean;
  @Input() singleSolicitud : SolicitudModel;
  @Input() movimiento : any;
  @Output() closeComment = new EventEmitter<any>();
  blocked : boolean = false
  listComentarios : ComentarioModel[] = [];
  comentario : ComentarioModel = new ComentarioModel();
  submitted: boolean = false
        constructor(private securityService : SecurityService, private messageService: MessageService, private auth: AuthorizationService) {

  }
  /**
   * Inicio de pantalla
   */
  ngOnInit(): void {    
    this.comentario.idUsuario = this.auth.getCurrentUser().userData.idUsuario; 
    if(this.singleSolicitud){
      this.comentario.idSolicitud = this.singleSolicitud ? this.singleSolicitud.idSolicitud : null;
      this.comentario.fechaComentario = new Date();
    }
    if(this.movimiento){
      this.comentario.idMovimiento = this.movimiento.idMovimiento ? this.movimiento.idMovimiento  : null;
    }
   
    this.getComentarios()
  }

  showDialog() {
    this.visible = true;
  }
  closeCommentForm(){ 
    const event = {
      createComment : false,
      type: "close",
  };
    this.closeComment.emit(event);
  }   
   
  getComentarios(){
    this.securityService.getComentarios(this.singleSolicitud?.idSolicitud, this.movimiento?.idMovimiento, this.properties.stateActive).then(resp => {
      if (resp.code === 200) {
        this.blocked = true;
        this.listComentarios = resp.data;
      } else {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
      }
    }).catch(err => {
      this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
    });
  }

  createComentarios(){
    this.submitted = true;
    this.comentario.idEmpresa = this.auth.getCurrentUser().userData.idEmpresa
    if(this.comentario.comentario){
      this.securityService.createComentario(this.comentario).then(resp => {
        if (resp.code === 200) {
          this.messageService.add({severity: "success",summary: this.properties.successful, detail: 'Comentario agregado correctamente',life: this.properties.timeLifeMessages,
        });
        this.getComentarios();
        this.comentario.comentario = null
        } else {
          this.messageService.add({ severity: 'error', summary: this.properties.error, detail: resp.description, life: this.properties.timeLifeMessages });
        }
      }).catch(err => {
        this.messageService.add({ severity: 'error', summary: this.properties.error, detail: err.message, life: this.properties.timeLifeMessages });
      });
    }
    
  }
}