<div class="pages-body login-page flex flex-column">
    <div class="p-3 flex justify-content-center flex-row align-items-center">
        <div class="flex" routerLink="/">
            <div class="logo">
                <img src="assets/layout/images/logo-moderna.png" style="height: 12rem;" alt=""/>
            </div>
        </div>
    </div>
    <p-toast></p-toast>
    <div class="align-self-center mt-auto-c mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1" style="background: white; border: 0ch;">
                <img src="assets/demo/images/blocks/logos/visto.png" alt="avatar" style="width: 80px; height: 80px;">
                <!-- <h2>CONFIRMACIÓN</h2> -->
            </div>

            <h5>{{ securityConstants.login.messages.confirmed }} </h5><br>
            <h4>{{ securityConstants.login.welcome }}</h4>

            <div class="pages-detail mb-6 px-6">{{ securityConstants.login.messages.nextButton }}</div>

            <p-button class="login-button mb-6 px-3" label="{{ securityConstants.login.continue }}" (click)="login()"></p-button>
            <div class="pages-detail mb-6 px-6"><a href="javascript: void(0)" (click)="reenviar()">{{ securityConstants.login.messages.reenviar }} </a> </div>
        </div>
    </div>
</div>
