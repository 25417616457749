import { AuditoriaModel } from "../auditoria.model";
import { DenominacionModel } from "./denominacion.model";

export class ArqueoModel extends AuditoriaModel {
    idArqueo : number;
    idFondo : number;
    idUsuarioArqueo : number;
    fecha : any;
    totalArqueo : number;
    totalDocumentos : number;
    observaciones : string;
    estado : string;
    denominaciones: DenominacionModel[];  
    idEmpresa?: number
    fechaAceptacion: Date
    fechaRechazo: Date
    idCatalogoMotivoRechazo:number
    porcentajeUso:number
    valorRequerimientosEntregadas: number
    valorRequerimientosAprobadas: number
    documentos: any[]
    constructor() {
        super();
        this.idArqueo = null;
        this.idFondo = null;
        this.idUsuarioArqueo = null;
        this.fecha = null;
        this.totalArqueo = null;
        this.totalDocumentos = null;
        this.observaciones = null;
        this.estado = null;
        this.denominaciones = []
        this.idEmpresa = null
        this.fechaAceptacion = null
        this.fechaRechazo = null
        this.idCatalogoMotivoRechazo = null
        this.porcentajeUso = 0
        this.valorRequerimientosAprobadas = null
        this.valorRequerimientosEntregadas = null
        this.documentos = null
    }
}
