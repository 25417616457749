import { Injectable } from '@angular/core';
import { GenericService, METHODS } from 'src/app/common/genericservice';
import { UrlServices } from 'src/app/common/url.services';
import { MenuModel } from 'src/app/models/menus.model';
import { RolModel } from 'src/app/models/roles.model';
import { CelulaModel } from '../models/celula.model';
import { UserModel } from '../models/user.model';
import { CatalogModel } from '../models/catalog.model';
import { Response } from '../common/Response';
import { Properties } from '../utilities/properties';
import { FeriadoModel } from '../models/feriado.model';
import { AuthorizationService } from '../common/auth.service';
import { azureCodes } from '../common/azure.codes';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class SecurityService {

  properties: Properties = new Properties();

  constructor(private genericService: GenericService, private urlService: UrlServices, private auth:AuthorizationService) {

  }
  /**
   * Obtiene los datos del usuario actual
   */
  loginUser(loginModel: any): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlLogin, loginModel, null, null);
  }

  ConfirmUser(loginModel: any): any {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlConfirm, loginModel, null, null);
  }

  getEmailReset(email: string): any {
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlReset + '?userName=' + email, null, null, null);
  }

  getEmailVerification(email: string): Promise<Response> {
    const userData = {
      userName: email
    }
    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urlReset, userData, null, null);
  }

  resetPass(loginModel: any): any {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlReset, loginModel, null, null);
  }

  //Roles{}
  getRoles(): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);

    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlRoles, null, null, queryParameters);
  }

  createRol(rol: RolModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlRoleCreate, rol, null, queryParameters);
  }

  updateRol(rol: RolModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urlRoleUpdate, rol, null, queryParameters);
  }

  deleteRol(roles: any[]): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlRoles + this.urlService.pathDelete, roles, null, null);
  }

  createMenuByRol(data: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlAsignarMenuRole, data, null, queryParameters);
  }

  getMenusByRol(idRol: number): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idRoles', idRol)
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);

    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlMenuByRol, null, null, queryParameters);
  }
  //Menu
  getMenu(): any {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlMenu, null, null, queryParameters);
  }

  createMenu(menu: MenuModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlMenuCreate, menu, null, queryParameters);
  }

  updateMenu(menu: MenuModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urlMenuUpdate, menu, null, queryParameters);
  }

  deleteMenu(menus: any): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlMenu + this.urlService.pathDelete, menus, null, null);
  }

  //Usuarios
  getUsers(idUsuario: number = null, idUsuarioCognito: string = null): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    if (idUsuario) {
      queryParameters.set('idUsuario', idUsuario);
    }
    if (idUsuarioCognito) {
      queryParameters.set('idUsuarioCognito', idUsuarioCognito);
    }
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlUsers, null, null, queryParameters);
  }

  getUsersByRol(idRol: number = null): Promise<Response> {
    const queryParameters = new Map();
    https://cajachicaprd.azurewebsites.net/api/UserListByRole?code=S98-lTnsdPnLcjnukMcaJ5McEDaSWI3SRolF8_6JTxnPAzFu5KmzSw==
    queryParameters.set('code', environment.codeEnv);

    if (idRol) {
      queryParameters.set('idRol', idRol);
    }
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlUserByRole, null, null, queryParameters);
  }

  getInfoUser(idUsuario): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    if (idUsuario) {
      queryParameters.set('idUsuario', idUsuario);
    }
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlInfoLogued, null, null, queryParameters);
  }

  createUser(user: UserModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlUserCreate, user, null, queryParameters);
  }

  updateUser(user: UserModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)
    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urlUserUpdate, user, null, queryParameters);
  }

  deleteUser(users: any[]): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlUsers + this.urlService.pathDelete, users, null, null);
  }

  deletePorts(ports: any[]): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.getAeropuertos + this.urlService.pathDelete, ports, null, null);
  }

  //Rol User
  getRolesByUser(idUser: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlRoleUserList + '?idUser=' + idUser, null, null, queryParameters);
  }

  createRolUser(rolUser: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlRoleUserCreate, rolUser, null, queryParameters);
  }

  updateRolUser(rolUser: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)
    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urleRoleUserUpdate, rolUser, null, queryParameters);
  }

  //Catalogos
  getCatalogs(filter = undefined, idCatalogo?: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)
    if(idCatalogo){
      queryParameters.set('idCatalogo', idCatalogo)
    }

    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlCatalogoList, null, null, queryParameters);
  }

  getCatalogsByFather(idCatalogoPadre: number, idCatalogoRelacionado: number = undefined): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);

    if (idCatalogoPadre) {
      queryParameters.set('idCatalogoPadre', idCatalogoPadre);
    }
    if (idCatalogoRelacionado) {
      queryParameters.set('idCatalogoRelacionado', idCatalogoRelacionado);
    }

    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getCatalogsByFather, null, null, queryParameters);
  }

  getCatalogsByFathers(idCatalogosPadre: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);

    return this.genericService.genericCallServices(METHODS.POST, this.urlService.getCatalogsByFathers,idCatalogosPadre, null, queryParameters);
  }

  createCatalog(catalog: CatalogModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)

    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlCatalogoCreate, catalog, null, queryParameters);
  }

  updateCatalog(catalog: CatalogModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)

    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urlCatalogoUpdate, catalog, null, queryParameters);
  }

  deleteCatalog(catalogs: any[]): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlCatalog + this.urlService.pathDelete, catalogs, null, null);
  }
  /**
    * Genera un QR para escaner por herramientas de seguridad para enlazar la cuenta
    * @returns Devuelve los datos de QR para escanear en pantalla
    */
  public mfaGenerateQR(): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlMfa, null, null, null);
  }
  /**
   * Genera un QR para escaner por herramientas de seguridad para enlazar la cuenta
   * @returns Devuelve los datos de QR para escanear en pantalla
   */
  public validateMfa(userCode: string): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlMfa + '?userCode=' + userCode, null, null, null);
  }
  /**
  * Genera un QR para escaner por herramientas de seguridad para enlazar la cuenta
  * @returns Devuelve los datos de QR para escanear en pantalla
  */
  public disableMfa(userCode: string): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urlMfa + '?userCode=' + userCode, null, null, null);
  }

  public getSignedUrl(name: string): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlSignedUrl + '?name=' + name, null, null, null);
  }

  public uploadSignedUrl(url: string, body: any): Promise<Response> {
    return this.genericService.genericCallServices(METHODS.FORMDATA, url, body, null, null);
  }

  public authLogin(client : any){
    return this.genericService.genericCallServices(METHODS.POST, 'https://login.microsoftonline.com/d7060bcc-bb26-4708-a91d-88362a86ae35/oauth2/v2.0/authorize', client,null, null
    )
  }

  // Feriados
  getFeriados(): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    return this.genericService.genericCallServices(METHODS.GET, this.urlService.urlFeriadoList, null, null, queryParameters);
  }

  createFeriado(feriado: FeriadoModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlFeriadoCreate, feriado, null, queryParameters);
  }

  updateFeriado(feriado: FeriadoModel): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv)
    return this.genericService.genericCallServices(METHODS.PUT, this.urlService.urlFeriadoUpdate, feriado, null, queryParameters);
  }

  public decodeToken(body : any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlDecodeToken, body, null, queryParameters);
  }

  getToken(tokenBody: any): any {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.POST, this.urlService.urlGetToken, tokenBody, null, queryParameters);
  }

  getSolicitudes(idUsuarioSolicitante: number, filterEstados?: any, idUsuarioCustodio?: any, idCatalogoTipoSolicitud?:any, busqueda?: any, fechaInicio?:any, fechaFin?:any, usuarioCustodio?:any): Promise<Response> {    
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idUsuarioSolicitante) {
      queryParameters.set('idUsuarioSolicitante', idUsuarioSolicitante);
    }
    
    if (filterEstados) {
      queryParameters.set('filterEstados', filterEstados);
    }
    if (idUsuarioCustodio) {
      queryParameters.set('idUsuarioCustodio', idUsuarioCustodio);
    }
    if (idCatalogoTipoSolicitud) {
      queryParameters.set('idCatalogoTipoSolicitud', idCatalogoTipoSolicitud);
    }
    if(busqueda){
      queryParameters.set('busqueda', busqueda);
    }
    if(fechaInicio){
      queryParameters.set('fechaInicio', fechaInicio);
    }
    if(fechaFin){
      queryParameters.set('fechaFin', fechaFin);
    }
    if(usuarioCustodio){
      queryParameters.set('usuarioCustodio', usuarioCustodio);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getSolicitudesList, null, null, queryParameters);
  }
  createSolicitud(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlSolicitudCreate, body, null, queryParameters);
  }
  updateSolicitud(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.PUT,this.urlService.urlSolicitudUpdate, body, null, queryParameters);
  }

  getFondo(idFondo: number, idUsuarioCustodio: number, idCatalogoTipoFondo?: number, estado? : string, filterEstados? : string, busqueda?: any, fechaInicio?:any, fechaFin?:any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idFondo) {
      queryParameters.set('idFondo', idFondo);
    }
    if (idUsuarioCustodio) {
      queryParameters.set('idUsuarioCustodio', idUsuarioCustodio);
    }
    if (idCatalogoTipoFondo) {
      queryParameters.set('idCatalogoTipoFondo', idCatalogoTipoFondo);
    }
    if (estado) {
      queryParameters.set('estado', estado);
    }
    if(busqueda){
      queryParameters.set('busqueda', busqueda);
    }
    if(fechaInicio){
      queryParameters.set('fechaInicio', fechaInicio);
    }
    if(fechaFin){
      queryParameters.set('fechaFin', fechaFin);
    }
    if (filterEstados) {
      queryParameters.set('filterEstados', filterEstados);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlFondoList, null, null, queryParameters);
  }
  createFondo(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlFondoCreate, body, null, queryParameters);
  }
  updateFondo(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.PUT,this.urlService.urlFondoUpdate, body, null, queryParameters);
  }

  getArqueos(idFondo?: number, busqueda?: any, fechaInicio?:any, fechaFin?:any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idFondo) {
      queryParameters.set('idFondo', idFondo);
    }
    if(busqueda){
      queryParameters.set('busqueda', busqueda);
    }
    if(fechaInicio){
      queryParameters.set('fechaInicio', fechaInicio);
    }
    if(fechaFin){
      queryParameters.set('fechaFin', fechaFin);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getArqueoList, null, null, queryParameters);
  }

  createArqueo(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlArqueoCrete, body, null, queryParameters);
  }
  updateArqueo(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.PUT,this.urlService.urlArqueoUpdate, body, null, queryParameters);
  }

  getComentarios(idSolicitud?: number, idMovimiento? : number, estado? : string): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idSolicitud) {
      queryParameters.set('idSolicitud', idSolicitud);
    }
    if (idMovimiento) {
      queryParameters.set('idMovimiento', idMovimiento);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getComentarios, null, null, queryParameters);
  }

  createComentario(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlCreateComentarios, body, null, queryParameters);
  }
  updateComentario(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.PUT,this.urlService.urlUpdateComentarios, body, null, queryParameters);
  }
  countFeriados(initDate?: any, endDate? : any,): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    if (initDate) {
      queryParameters.set('initDate', initDate);
    }
    if (endDate) {
      queryParameters.set('endDate', endDate);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlCountFeriados, null, null, queryParameters);
  }
  valorDocumentosMovimientos(filterEstados: any, idFondo: any, idCatalogoTipoSolicitud:any, estadoMovimientos: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (filterEstados) {
      queryParameters.set('filterEstados', filterEstados);
    }
    if (estadoMovimientos) {
      queryParameters.set('estadoMovimientos', estadoMovimientos);
    }
    if (idFondo) {
      queryParameters.set('idFondo', idFondo);
    }
    if (idCatalogoTipoSolicitud) {
      queryParameters.set('idCatalogoTipoSolicitud', idCatalogoTipoSolicitud);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlValorDocMovimientos, null, null, queryParameters);
  }
  valorDocumentosSolicitudes(filterEstados: any, idFondo: any, idCatalogoTipoSolicitud:any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (filterEstados) {
      queryParameters.set('filterEstados', filterEstados);
    }
    if (idFondo) {
      queryParameters.set('idFondo', idFondo);
    }
    if (idCatalogoTipoSolicitud) {
      queryParameters.set('idCatalogoTipoSolicitud', idCatalogoTipoSolicitud);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlValorDocSolicitudes, null, null, queryParameters);
  }
  getMovimientosIdSolicitudRepo(filterEstados?: any, idSolicitud?: any, busqueda?:string): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (filterEstados) {
      queryParameters.set('filterEstados', filterEstados);
    }
    if (idSolicitud) {
      queryParameters.set('idSolicitud', idSolicitud);
    }
    if (busqueda) {
      queryParameters.set('busqueda', busqueda);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getMovimientosIdSolicitudRepo, null, null, queryParameters);
  }

  getDenominaciones(idSolicitud?: number, idMovimiento? : number, estado? : string): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idSolicitud) {
      queryParameters.set('idSolicitud', idSolicitud);
    }
    if (idMovimiento) {
      queryParameters.set('idMovimiento', idMovimiento);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlDenominacionList, null, null, queryParameters);
  }

  createDenominacion(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlDenominacionCreate, body, null, queryParameters);
  }
  updateDenominacion(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.PUT,this.urlService.urlDenominacionUpdate, body, null, queryParameters);
  }
  getSumMovimientosSolicitudesRepo(filterEstados: any, idSolicitud: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (filterEstados) {
      queryParameters.set('filterEstados', filterEstados);
    }
    if (idSolicitud) {
      queryParameters.set('idSolicitud', idSolicitud);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getMovimientosIdSolicitudRepoSum, null, null, queryParameters);
  }
  aprobarReposicion(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlAprobarReposicion, body, null, queryParameters);
  }

  rechazarReposicion(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlRechazarReposicion, body, null, queryParameters);
  }

  getMovimientos(idSolicitud?: number, idMovimiento? : number, estado? : string, busqueda?:string): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idSolicitud) {
      queryParameters.set('idSolicitud', idSolicitud);
    }
    if (idMovimiento) {
      queryParameters.set('idMovimiento', idMovimiento);
    }
    if (estado) {
      queryParameters.set('estado', estado);
    }
    if (busqueda) {
      queryParameters.set('busqueda', busqueda);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getMovimientos, null, null, queryParameters);
  }

  getConfiguracionAprobador(idFondo?:number,aprobador?:string ,idConfiguracionAprobador?: number, idUsuarioAprobador? : number, estado? : string){
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idConfiguracionAprobador) {
      queryParameters.set('idConfiguracionAprobador', idConfiguracionAprobador);
    }
    if (idUsuarioAprobador) {
      queryParameters.set('idUsuarioAprobador', idUsuarioAprobador);
    }
    if (estado) {
      queryParameters.set('estado', estado);
    }
    if(aprobador){
      queryParameters.set('aprobador', aprobador);
    }
    if (idFondo) {
      queryParameters.set('idFondo', idFondo);
      
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.getConfiguracionAprobador, null, null, queryParameters);
  }

  getCentroCuentaGasto(idCatalogoTipoCtaCos?: number){
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idCatalogoTipoCtaCos) {
      queryParameters.set('idCatalogoTipoCtaCos', idCatalogoTipoCtaCos);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlCentroCostoCuentaGastoList, null, null, queryParameters);
  }
  createConfiguracionAprobador(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlCreateConfiguracionAprobador, body, null, queryParameters);
  }
  updateConfiguracionAprobador(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.PUT,this.urlService.urlUpdateConfiguracionAprobador, body, null, queryParameters);
  }
  createMovimientos(body:any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
   
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlMovimientoComprobanteCreate,body, null, queryParameters);
  }
  updateMovimientos(body:any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
   
    return this.genericService.genericCallServices(METHODS.PUT,this.urlService.urlMovimientoComprobanteUpdate,body, null, queryParameters);
  }
  liquidarSolicitud(body: any): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    
    return this.genericService.genericCallServices(METHODS.POST,this.urlService.urlSolicitudLiquidar, body, null, queryParameters);
  }
  getEmpresas(){
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlEmpresasList, null, null, queryParameters);
  }
  validateNumberDocumento(idCatalogoTipoDocumento: number, establecimiento : string, puntoVenta : string, secuencial : string, numeroIdentificacion: string): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idCatalogoTipoDocumento) {
      queryParameters.set('idCatalogoTipoDocumento', idCatalogoTipoDocumento);
    }
    if (establecimiento) {
      queryParameters.set('establecimiento', establecimiento);
    }
    if (puntoVenta) {
      queryParameters.set('puntoVenta', puntoVenta);
    }
    if (secuencial) {
      queryParameters.set('secuencial', secuencial);
    }
    if (numeroIdentificacion) {
      queryParameters.set('numeroIdentificacion', numeroIdentificacion);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.validateNumeroDocumento, null, null, queryParameters);
  }
  findOneValesProvisionales(idFondo: number): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idFondo) {
      queryParameters.set('idFondo', idFondo);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlFindOneValesProvisionales, null, null, queryParameters);
  }
  findOneComprobantes(idFondo: number): Promise<Response> {
    const queryParameters = new Map();
    queryParameters.set('code', environment.codeEnv);
    queryParameters.set('idEmpresa', this.auth.getCurrentUser().userData.idEmpresa);
    if (idFondo) {
      queryParameters.set('idFondo', idFondo);
    }
    return this.genericService.genericCallServices(METHODS.GET,this.urlService.urlFindOneComprobantes, null, null, queryParameters);
  }
}
